import Cookies from 'js-cookie';
import { CONFIG } from '../config';
import { AppRouting } from '../services/appRoutingResolver';

const CODE_VERIFIER_COOKE = 'pkce_code_verifier';

// Function to generate a code verifier for PKCE (Proof Key for Code Exchange)
const generateCodeVerifier = () => {
  const array = new Uint8Array(32);
  const rootOrigin = `.${AppRouting.getRootOrigin()}`;
  window.crypto.getRandomValues(array);

  const codeVerifier = btoa(String.fromCharCode(...Array.from(array)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  // Save the code_verifier in a cookie
  Cookies.set(CODE_VERIFIER_COOKE, codeVerifier, {
    domain: rootOrigin,
    secure: true,
    sameSite: 'Lax',
    expires: 1,
  });

  return codeVerifier;
};

// Function to generate a code challenge from a code verifier for Microsoft login using PKCE
const generateCodeChallenge = async (codeVerifier: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);

  return btoa(String.fromCharCode(...Array.from(new Uint8Array(digest))))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

// Retrieves the `code_verifier` from the cookies and deletes it immediately after retrieval.
export const getAndDeleteCodeVerifier = (): string | undefined => {
  const rootOrigin = `.${AppRouting.getRootOrigin()}`;
  const codeVerifier = Cookies.get(CODE_VERIFIER_COOKE);
  Cookies.remove(CODE_VERIFIER_COOKE, {
    domain: rootOrigin, // Ensure it removes from the root domain
  });

  return codeVerifier;
};

export const getGoogleSocialLoginUrl = (clientId: string, redirectUri: string): string => {
  const searchParams: URLSearchParams = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    include_granted_scopes: 'true',
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    state: '',
  });

  const url: URL = new URL('https://accounts.google.com/o/oauth2/v2/auth');
  url.search = searchParams.toString();

  return url.toString();
};

export const getMicrosoftSocialLoginUrl = async (clientId: string, redirectUri: string): Promise<string> => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  const searchParams: URLSearchParams = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: 'openid profile email',
    response_type: 'code',
    authority: 'https://login.microsoftonline.com/common',
    response_mode: 'query',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  });

  const url: URL = new URL(`https://login.microsoftonline.com/${CONFIG.MICROSOFT_TENANT_ID}/oauth2/v2.0/authorize`);
  url.search = searchParams.toString();

  return url.toString();
};

export const validatePassword = (password: string): boolean => {
  const minLength = 10;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);

  return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
};
