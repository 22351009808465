import { Link, useHistory } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import STRING_KEYS from '../../../../../language/keys';
import { formatLocations } from '../../../../../utils/location';
import {
  useGetOpenPositionsSelector,
  useGetPositionByIdFromPositionsQuerySelector,
} from '../../../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { useTypingEffect } from '../../../../../hooks/use-typing-effect';
import {
  ArrowDownIcon,
  Container,
  CurrentPositionTitle,
  CurrentPositionWrapper,
  DividerIcon,
  HomeIcon,
  MenuItemSubtitle,
  MenuItemTitle,
  PerfectMenu,
  PerfectMenuItem,
} from './header-toolbar.styled';

type HeaderToolbarProps = {
  positionId: string;
};

function HeaderToolbar({ positionId }: HeaderToolbarProps): JSX.Element {
  const { t: translate } = useTranslation();
  const {
    data: currentPosition,
  } = useGetPositionByIdFromPositionsQuerySelector(positionId);
  const { data: positions } = useGetOpenPositionsSelector();

  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(
    null,
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const history = useHistory();

  const togglePositionsMenu = (): void => {
    setAnchorElement(anchorElement ? null : ref.current);
  };

  const onPositionSelect = (selectedPositionId: string): void => {
    history.push(AppRouting.buildMatchesPageUrl(selectedPositionId));
  };

  const positionOptions: DropdownMenuItem[] = positions?.map((position) => ({
    id: position.id,
    title: position.jobTitle,
    subtitle: position.remotePosition ?
      translate(STRING_KEYS.REMOTE) :
      formatLocations(position.locations).join(' • '),
  }));

  const jobTitle = useTypingEffect(currentPosition.jobTitle);

  // Future improvement - add the ability the render components inside dropdown and put there the icons
  return (
    <Container>
      <Link to={ { pathname: AppRouting.ROUTES.HOME } }>
        <HomeIcon />
      </Link>
      <DividerIcon />

      {currentPosition && (
        <>
          <CurrentPositionWrapper ref={ ref } onClick={ togglePositionsMenu }>
            <CurrentPositionTitle>
              {jobTitle}
            </CurrentPositionTitle>

            <ArrowDownIcon />
          </CurrentPositionWrapper>

          <PerfectMenu
            isOpen={ !!anchorElement }
            onClose={ () => setAnchorElement(null) }
            anchorElement={ anchorElement || undefined }
            placement="bottom-start"
          >
            {positionOptions.map(({ id, title, subtitle }) => (
              <PerfectMenuItem key={ id } onClick={ () => onPositionSelect(id) }>
                <MenuItemTitle>{title}</MenuItemTitle>
                <MenuItemSubtitle>{subtitle}</MenuItemSubtitle>
              </PerfectMenuItem>
            ))}
          </PerfectMenu>
        </>
      )}
    </Container>
  );
}

export default HeaderToolbar;
