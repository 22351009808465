import { useEffect, useRef, useState } from 'react';

export const useTypingEffect = (text: string, typingSpeed = 50): string => {
  const [displayedText, setDisplayedText] = useState(text);
  const firstRender = useRef(true);

  useEffect(() => {
    let interval:NodeJS.Timeout;

    if (firstRender.current) {
      // Show first text instantly
      setDisplayedText(text);
      firstRender.current = false;
    } else {
      // Animate typing effect for new text
      setDisplayedText(''); // Start with an empty string
      let index = 0;

      interval = setInterval(() => {
        setDisplayedText((prev) => prev + (text?.[index] || ''));
        index++;
        if (index === text.length) {
          clearInterval(interval);
        }
      }, typingSpeed);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [text, typingSpeed]);

  return displayedText;
};
