export const POSITION_ID_PATH_PARAM = ':positionId';
export const ACCOUNT_ID_PATH_PARAM = ':accountId';

export const ROUTES = {
  POSITIONS: '/positions',
  POSITION_BY_ID: `/positions/${POSITION_ID_PATH_PARAM}`,
  POSITION_REGIONS: '/positions/regions',
  POSITION_SIMILAR_TITLES: '/positions/similar-titles',
  POSITION_USER_ASSIGN: `/positions/${POSITION_ID_PATH_PARAM}/users/assign`,
  POSITION_USER_UNASSIGN: `/positions/${POSITION_ID_PATH_PARAM}/users/un-assign`,
  POSITION_INSIGHTS: `/positions/${POSITION_ID_PATH_PARAM}/insights`,
  POSITION_ANALYTICS: `/positions/${POSITION_ID_PATH_PARAM}/analytics`,
  POSITION_STATE: `/positions/${POSITION_ID_PATH_PARAM}/state`,
  POSITION_ATS: `/positions/${POSITION_ID_PATH_PARAM}/ats-integration`,
  POSITION_STATISTICS: `/positions/${POSITION_ID_PATH_PARAM}/statistics`,
  POSITION_FEEDBACK: `/positions/${POSITION_ID_PATH_PARAM}/feedback`,
  SKIP_MATCH_TUNE: `/positions/${POSITION_ID_PATH_PARAM}/match-tune/skip`,
  POSITION_OVERVIEW: `/positions/${POSITION_ID_PATH_PARAM}/overview`,
  SKIP_POSITION_ASSISTANT: `/positions/${POSITION_ID_PATH_PARAM}/assistant/skip`,
  RETRY_MATCHING: `/positions/${POSITION_ID_PATH_PARAM}/latest-match-flow/retry`,
};

export const REDUCER_PATH = 'positionApi';

export const FIXED_CACHE_KEYS = {
  CLOSE_POSITION: 'CLOSE_POSITION',
};
