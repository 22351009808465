import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import KEYS from '../../../language/keys';
import { useGetMyUserQuery } from '../../../store/rtk-query/apis/user/user.toolkit-api';
import { useCreateCustomerSessionMutation } from '../../../store/rtk-query/apis/payment/payment.toolkit-api';
import Spinner from '../../Common/spinner/spinner.component';
import { CONFIG } from '../../../config';
import { StripePricingTable } from '../../Common/stripe-pricing-table/stripe-pricing-table.component';
import { THEME } from '../../../themes';
import {
  Container, Description, StyledBody, Subtitle, TextContainer, Title,
} from './buy-more-credits-dialog.styled';

export const BuyMoreCreditsDialog = ({
  isOpen,
  onClose,
}: BuyMoreCreditsDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const { data: { id: userId } = {} } = useGetMyUserQuery();
  const [createCustomerSession, { data: { sessionSecret = null } = {} }] = useCreateCustomerSessionMutation();

  useEffect(() => {
    if (isOpen && !sessionSecret) {
      createCustomerSession();
    }
  }, [createCustomerSession, isOpen, sessionSecret]);

  return (
    <Dialog
      isOpen={ isOpen }
      onClose={ onClose }
      widthSize={ DialogWidthSize.XXXLarge }
      portalUniqueId="buy-more-credits"
      zIndex={ THEME.Z_INDEX.HIGH + 3000 }
    >
      <StyledBody>
        <Container>
          <TextContainer>
            <Subtitle>{ translate(KEYS.BUY_MORE_CREDITS_DIALOG.SUBTITLE) }</Subtitle>
            
            <Title>{ translate(KEYS.BUY_MORE_CREDITS_DIALOG.TITLE) }</Title>

            <Description>{ translate(KEYS.BUY_MORE_CREDITS_DIALOG.DESCRIPTION) }</Description>
          </TextContainer>

          {sessionSecret ? (
            <StripePricingTable
              userId={ userId }
              pricingTableId={ CONFIG.STRIPE_CREDITS_PRICING_TABLE_ID }
              publishableKey={ CONFIG.STRIPE_PRICING_TABLE_PUBLISHABLE_KEY }
              customerSessionClientSecret={ sessionSecret }
            />
          ) : <Spinner width={ 100 } height={ 100 } animationDuration={ 1000 } />}

        </Container>
      </StyledBody>
    </Dialog>
  );
};
