import styled from 'styled-components';
import { ASSETS } from '../../../assets';
import { THEME } from '../../../themes';
import { Body1Font, Title1Font, Title2Font } from '../../../themes/fonts';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  position: fixed;
  backdrop-filter: blur(15px);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${THEME.SPACING.LOOSE};
  text-align: center;
  padding-block-start: ${THEME.SPACING.XLOOSE};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_SMALL}px) {
    padding-inline: 16px;
  }

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    padding-inline: 24px;
  }
`;

export const PerfectLogoAvatar = styled(ASSETS.PERFECT_AVATAR)`
  width: 64px;
  height: 64px;
`;

export const MessageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${THEME.SPACING.TINO};
`;

export const Title1 = styled.h2`
  ${Title2Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: 0.6;
`;

export const Title2 = styled.h1`
  ${Title1Font};
  font-weight: bold;
`;

export const Text = styled(Title1)`
  ${Body1Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: 0.6;
`;
