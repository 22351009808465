import styled, { css, keyframes } from 'styled-components';
import { THEME } from '../../../../../themes';

export const gradientAnimation = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 80px - 8px);
  max-width: 1400px;
`;

export const Content = styled.div<{ cornerRadius: number }>`
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: ${THEME.RADIUS.CURVY};
  z-index: 1;
`;

export const GlowEffect = styled.div<GlowProps>`
  position: absolute;
  top: -${(props) => props.borderOffset}px;
  left: -${(props) => props.borderOffset}px;
  right: -${(props) => props.borderOffset}px;
  bottom: -${(props) => props.borderOffset}px;
  border-radius: ${(props) => props.cornerRadius + 2}px;
  background: linear-gradient(90deg, #ff0660, #0090ff, #7e27e3, #ffb920, #ff0660);
  background-size: 300% 300%;
  z-index: 0;
  filter: blur(${(props) => props.glowIntensity}px);
  
  ${(props) => props.isAnimating && css`
    animation: ${gradientAnimation} ${props.animationSpeed}s ease infinite;
  `}
`;
