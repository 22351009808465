import styled from 'styled-components';
import { THEME } from '../../themes';
import { CaptionFont } from '../../themes/fonts';

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
  cursor: inherit;
`;

export const TooltipTip = styled.div`
  ${CaptionFont};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  width: max-content;
  max-width: 240px;
  padding: 8px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: ${THEME.COLORS.CONTENT_INVERSE};
  background: ${THEME.COLORS.SURFACE_INVERSE};
  z-index: 2900;
`;
