import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { LogoAnimation } from '../../../../../components/Common/Loaders/logo-animation/logo-animation.component';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: ${THEME.SPACING.BASE};
`;

export const PerfectLogoAnimation = styled(LogoAnimation)`
  width: 64px;
  height: 64px;
  color: ${THEME.COLORS.ACCENT};
`;
