import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Body1Font, Title3Font } from '../../../../themes/fonts';
import BaseButton from '../../../Common/Buttons/perfect-base-button/perfect-base-button.component';
import BaseInput from '../../../Common/perfect-base-input/perfect-base-input.component';

export const Title = styled.div`
  ${Title3Font};
  font-weight: 500;
  line-height: 1.5;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${THEME.SPACING.MEDIUM};
  gap: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.LIGHT_GREY};
`;

export const TitlesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SeatsCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const UpgradeButton = styled(BaseButton)`
  color: ${THEME.COLORS.SECONDARY};
  font-size: ${THEME.FONT_SIZE.BASE};
  font-weight: bold;
  letter-spacing: 1.34px;
  text-transform: uppercase;
  background-color: transparent;
`;

export const EmailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const PerfectInput = styled(BaseInput)<{ $isError: boolean }>`
  border-radius: 12px;
  height: 56px;
  width: 100%;
  font-size: 16px;
  border: solid 1px
    ${({ $isError }) => $isError ? THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_GHOST};

  input {
    ${Body1Font}

    ::placeholder {
      color: ${THEME.COLORS.PRIMARY_DARK};
    }
  }
`;

export const StyledButtonWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${THEME.SPACING.TINY};

  button {
    border-radius: ${THEME.RADIUS.BASE};

    :after {
      border-radius: ${THEME.RADIUS.BASE};
    }
  }
`;

export const PerfectButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  white-space: nowrap;
  width: 190px;
  height: 46px;
`;
