import { AnyAction, CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { removeFromArray } from '../../../../utils';
import { INITIAL_LOADING } from '../app-state.consts';

export const getPositionsFulfilledExtraReducer: CaseReducer<AppState, AnyAction> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_POSITIONS);
};

export const getPositionsRejectExtraReducer: CaseReducer<AppState, AnyAction> = (state) => {
  state.initialLoadings = removeFromArray(state.initialLoadings, INITIAL_LOADING.LOAD_POSITIONS);
};
