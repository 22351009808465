import {
  DeleteQuery, GetQuery, PatchQuery, PostQuery,
} from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import {
  CONVERSATION_ID_PATH_PARAM, MATCH_ID_PATH_PARAM, POSITION_ID_PATH_PARAM, ROUTES,
} from './outreach.consts';
import { CreateEmailSenderRequest } from './dto/request/create-email-sender.request';
import { ConversationByIdQueryArguments } from './dto/query-arguments/conversation-by-id.query-arguments';
import { SendMessageQueryArguments } from './dto/query-arguments/send-message-query.arguments';
import { SendMessageRequest } from './dto/request/send-message.query-arguments';
import { GetMessageSuggestionQueryArguments } from './dto/query-arguments/get-message-suggestion.query-arguments';
import { GetConversationsByPositionIdQueryArguments } from './dto/query-arguments/get-conversations-by-position-id.query-arguments';
import { ArchiveConversationByIdQueryArguments } from './dto/query-arguments/archive-conversation-by-id.query-arguments';
import { CreateEmailSenderQueryArguments } from './dto/query-arguments/create-email-sender.query-arguments';
import { DeleteEmailSenderQueryArguments } from './dto/query-arguments/delete-email-sender.query-arguments';

export const getConversationsByPositionIdQuery = ({ positionId }: GetConversationsByPositionIdQueryArguments): GetQuery => ({
  url: ROUTES.CONVERSATIONS_BY_POSITION.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getEmailSenderQuery = (): GetQuery => ({
  url: ROUTES.EMAIL_SENDER,
  method: QueryMethods.Get,
});

export const deleteEmailSenderQuery = (data: DeleteEmailSenderQueryArguments): DeleteQuery<DeleteEmailSenderQueryArguments> => ({
  url: ROUTES.EMAIL_SENDER,
  method: QueryMethods.Delete,
  data: { userId: data.userId, accountId: data.accountId },
});

export const createGoogleEmailSenderQuery = (data: CreateEmailSenderQueryArguments): PostQuery<CreateEmailSenderRequest> => ({
  url: ROUTES.CREATE_GOOGLE_EMAIL_SENDER,
  method: QueryMethods.Post,
  data: { authCode: data.authCode },
});

export const createMicrosoftEmailSenderQuery = (data: CreateEmailSenderQueryArguments): PostQuery<CreateEmailSenderRequest> => ({
  url: ROUTES.CREATE_MICROSOFT_EMAIL_SENDER,
  method: QueryMethods.Post,
  data: { authCode: data.authCode },
});

export const getConversationByPositionIdAndIdQuery = ({ conversationId, positionId }: ConversationByIdQueryArguments): GetQuery => ({
  url: ROUTES.CONVERSATION_BY_ID.replace(POSITION_ID_PATH_PARAM, positionId).replace(CONVERSATION_ID_PATH_PARAM, conversationId),
  method: QueryMethods.Get,
});

export const markConversationMessageAsSeen = ({ conversationId }: ConversationByIdQueryArguments): PatchQuery => ({
  url: ROUTES.MARK_CONVERSATION_MESSAGE_AS_SEEN.replace(CONVERSATION_ID_PATH_PARAM, conversationId),
  method: QueryMethods.Patch,
});

const sendMessageQueryHandler = (url: string, data: SendMessageQueryArguments): PostQuery<SendMessageRequest> => ({
  url,
  method: QueryMethods.Post,
  data: {
    senderUserId: data.senderUserId,
    recipient: data.recipient,
    subject: data.subject,
    html: data.html,
    text: data.text,
    inReplyTo: data.inReplyTo,
    inReplyToPrimaryId: data.inReplyToPrimaryId,
    type: data.type,
  },
});

export const sendMessageQuery = (data: SendMessageQueryArguments): PostQuery<SendMessageRequest> => {
  const url = ROUTES.CONVERSATION_MESSAGES
    .replace(POSITION_ID_PATH_PARAM, data.positionId)
    .replace(MATCH_ID_PATH_PARAM, data.matchId);

  return sendMessageQueryHandler(url, data);
};

export const markMessageAsSentQuery = (data: SendMessageQueryArguments): PostQuery<SendMessageRequest> => {
  const url = ROUTES.MARK_MESSAGE_AS_SENT
    .replace(POSITION_ID_PATH_PARAM, data.positionId)
    .replace(MATCH_ID_PATH_PARAM, data.matchId);

  return sendMessageQueryHandler(url, data);
};

export const getMessageSuggestionQuery = ({ positionId, matchId, messageSuggestionType }: GetMessageSuggestionQueryArguments): GetQuery => ({
  url: ROUTES.CONVERSATION_MESSAGE_SUGGESTIONS
    .replace(POSITION_ID_PATH_PARAM, positionId)
    .replace(MATCH_ID_PATH_PARAM, matchId),
  method: QueryMethods.Get,
  params: {
    messageSuggestionType,
  },
});

export const archiveConversationQuery = ({ conversationId, positionId }: ArchiveConversationByIdQueryArguments): PostQuery => ({
  url: ROUTES.ARCHIVE_CONVERSATION_BY_ID
    .replace(POSITION_ID_PATH_PARAM, positionId)
    .replace(CONVERSATION_ID_PATH_PARAM, conversationId),
  method: QueryMethods.Post,
});
