import styled from 'styled-components';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ghostElevation } from '../../../../themes/elevations';
import { hexToRgbA } from '../../../../utils/colors';
import { calculateOutreachPageHeight } from '../../components/outreach/outreach-layout/outreach.utils';
import { HEADER_HEIGHT } from '../../../../components/Common/headers/header/header.styled';
import { ASSETS } from '../../../../assets';

export const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${THEME.COLORS.PURE_WHITE};
  z-index: ${THEME.Z_INDEX.HIGHEST + 100};
`;

export const Wrapper = styled.div<{ isGlobalBannerVisible: boolean }>`
  display: flex;
  height: ${({ isGlobalBannerVisible }) => calculateOutreachPageHeight({ isBannerVisible: false, isGlobalBannerVisible })};
  gap: ${THEME.SPACING.BASE};
  padding: 0 ${THEME.SPACING.LOOSE} ${THEME.SPACING.BASE} ${THEME.SPACING.LOOSE};
  transition: all 300ms ease-in-out;
  width: 100%;
  z-index: 5;

  @media (min-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    justify-content: center;
  }
`;

export const Container = styled.div<{ isChatMinimized: boolean}>`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${parseInt(THEME.SPACING.TINY)}px);
  transition: width 300ms ease-in-out;
  width: 100%;
  transform-origin: left;
  position: relative;
  max-width: 1400px;
  border-radius: ${THEME.RADIUS.CURVY};
  background-color: ${THEME.COLORS.PURE_WHITE};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    width: 100%;
  }
`;

export const MatcherFab = styled.div`
  display: flex;
  align-items: start;
  position: fixed;
  bottom: 32px;
  z-index: 101;
  left: 36px;
  gap: ${THEME.SPACING.TINY};
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINO};
  flex-direction: row;
`;

export const EditPositionButton = styled(BaseButton)`
  ${ghostElevation};
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.SECONDARY};
  text-transform: capitalize;
  letter-spacing: 0.1px;
  border: solid 1px ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.GHOST)};
`;

export const HourglassIcon = styled(ASSETS.HOURGLASS)`
  width: 10px;
`;

export const ExclamationMarkIcon = styled.div`
  width: 19px;
  height: 16px;
  background-color: ${THEME.COLORS.TRUE_BLACK};
  border-radius: ${THEME.RADIUS.CIRCLE};
  color: ${THEME.COLORS.PURE_WHITE};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 2px;
`;

export const RetryButton = styled(BaseButton)`
  width: 75px;
  height: 18px;
  color: ${THEME.COLORS.ACCENT};
  text-transform: uppercase;
  padding: 0;
`;

export const RetryIcon = styled(ASSETS.RETRY_OUTLINE)`
  width: 18px;
  height: 18px;
`;
