/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { SubDomainType } from '../store/slices/app-state/app-state.enums';
import { PricingPlan } from '../enums/user-plan.enum';
import { AccountStatusEnum } from '../enums/account-status.enum';
import { useGetMyUserQuery } from '../store/rtk-query/apis/user/user.toolkit-api';
import { useGetAccountByIdQuery } from '../store/rtk-query/apis/account/account.toolkit-api';
import { useFronteggWatcher } from './frontegg-watcher.hooks';
import { useCurrentSubDomain } from './use-current-sub-domain';

type UsePerfectAuthentication = {
  isAuthenticated: boolean,
  shouldCompleteUserActivationFlow: boolean,
  isPlanExpired: boolean;
}

export const usePerfectAuthentication = (): UsePerfectAuthentication => {
  const { currentSubDomainName, currentSubDomainType } = useCurrentSubDomain();

  const { isAuthenticated } = useFronteggWatcher();

  const {
    data: user,
    isSuccess: isUserSuccess,
  } = useGetMyUserQuery(undefined, { skip: !isAuthenticated });
  const {
    data: account,
    isSuccess: isAccountSuccess,
  } = useGetAccountByIdQuery(user?.accountId, { skip: !user?.accountId });

  const {
    isOnboarding, role: userRole,
  } = user ?? {};

  const {
    subDomainName, status: accountStatus, plan,
  } = account ?? {};

  const userSubDomain = subDomainName?.toLowerCase();

  const isUserAuthenticatedCompletely = useMemo(() => {
    if (userSubDomain === currentSubDomainName) {
      return currentSubDomainType === SubDomainType.Account && isUserSuccess && isAccountSuccess;
    }

    return false;
  }, [userSubDomain, currentSubDomainName, currentSubDomainType, isUserSuccess, isAccountSuccess]);

  return {
    isAuthenticated: isUserAuthenticatedCompletely,
    shouldCompleteUserActivationFlow: isUserAuthenticatedCompletely && !isOnboarding && !userRole,
    isPlanExpired: (plan?.isExpired || plan?.pricingPlan === PricingPlan.Lite) && accountStatus !== AccountStatusEnum.Onboarding,
  };
};
