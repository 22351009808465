import { SocialLoginProviders } from '@frontegg/rest-api';
import { ILoginViaSocialLoginPayload } from '@frontegg/redux-store/auth/SocialLogins/interfaces';
import { SplashScreen } from '../../Common/Loaders/SplashScreen/SplashScreen';
import { AppRouting } from '../../../services/appRoutingResolver';
import { usePerfectSocialSuccessHooks } from '../../../hooks/use-perfect-social-success.hooks';
import { UserIdentifyMethod } from '../../../store/slices/app-state/app-state.enums';
import { getAndDeleteCodeVerifier } from '../../../utils/frontegg';

export const MicrosoftSuccessScreen = (): JSX.Element => {
  const buildLoginPayload = (code: string): ILoginViaSocialLoginPayload => {
    const codeVerifier = getAndDeleteCodeVerifier();

    return {
      code,
      provider: SocialLoginProviders.Microsoft,
      codeVerifier,
      redirectUri: AppRouting.buildMicrosoftLoginSuccessUrl(window.location),
    };
  };

  usePerfectSocialSuccessHooks(UserIdentifyMethod.Microsoft, buildLoginPayload);

  return <SplashScreen title="Social Login" />;
};
