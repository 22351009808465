import { getSubDomain } from '../utils/url';
import { SubDomainType } from '../store/slices/app-state/app-state.enums';
import { CONFIG } from '../config';

export const useCurrentSubDomain = (): {
  currentSubDomainName: string;
  currentSubDomainType: SubDomainType;
} => {
  const currentSubDomainName = getSubDomain(window.location.hostname);
  let currentSubDomainType: SubDomainType;

  switch (currentSubDomainName) {
    case CONFIG.GLOBAL_LOGIN_SUBDOMAIN: {
      currentSubDomainType = SubDomainType.Auth;
      break;
    }
    case CONFIG.GLOBAL_ACTIVATION_SUBDOMAIN: {
      currentSubDomainType = SubDomainType.Activation;
      break;
    }
    case CONFIG.ONBOARDING_SUBDOMAIN: {
      currentSubDomainType = SubDomainType.Onboarding;
      break;
    }
    case CONFIG.GOOGLE_EMAIL_OAUTH: {
      currentSubDomainType = SubDomainType.GoogleEmailOAuth;
      break;
    }
    case '':
    case null: {
      currentSubDomainType = SubDomainType.None;
      break;
    }
    default: {
      currentSubDomainType = SubDomainType.Account;
    }
  }

  return { currentSubDomainName, currentSubDomainType };
};
