import { css, FlattenSimpleInterpolation, keyframes } from 'styled-components';

type AnimationParams = {
    animationDuration: number,
    animationDelay: number,
    timingFunction?: 'linear' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out',
    direction?: 'normal' | 'reverse' | 'alternate' | 'alternate-reverse',
    fillMode?: 'none' | 'both' | 'forwards' | 'backwards',
    timeUnit?: 's' | 'ms',
}

export const fadeInKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInAnimation = ({
  animationDuration,
  animationDelay,
  timingFunction = 'ease-in-out',
  direction = 'normal',
  fillMode = 'both',
  timeUnit = 'ms',
}:AnimationParams): FlattenSimpleInterpolation => {
  return css`${fadeInKeyFrames} ${animationDuration}${timeUnit} ${timingFunction}
    ${animationDelay}${timeUnit} ${direction} ${fillMode}`;
};

const shakeKeyframes = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
`;

export const shakeAnimation = ({
  animationDuration,
  animationDelay,
  direction = 'normal',
  fillMode = 'both',
  timeUnit = 'ms',
}:AnimationParams):FlattenSimpleInterpolation => {
  return css`${shakeKeyframes} 
  ${animationDuration}${timeUnit} cubic-bezier(0.175, 0.885, 0.320, 1.275)
  ${animationDelay}${timeUnit} ${direction} ${fillMode}`;
};

export const pulsateAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const pulsateAnimationWithDelay = keyframes`
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  100%, 20% {
    transform: scale(1);
  }
`;

export const scaleUpCenterAnimation = keyframes`
0% {
  -webkit-transform: scale(0);
          transform: scale(0);
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
`;

export const fadeAnimation = css`
  &.fade-appear,
  &.fade-enter {
    opacity: 0;
    z-index: 1;
  }
  &.fade-appear-active,
  &.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms linear 250ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 250ms linear;
  }
`;

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const scale = css`
  transition: transform 200ms;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
`;
