import styled from 'styled-components';
import { THEME } from '../../../../themes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-block-start: ${THEME.SPACING.MEDIUM};
    height: 100%;

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_SMALL}px) {
    padding-inline: 16px;
  }

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    padding-inline: 24px;
  }
`;

export const ContentInnerWrapper = styled.div`
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    max-width: 400px;
  }
`;
