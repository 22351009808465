/* eslint-disable react/jsx-props-no-spreading */
import {
  forwardRef, Ref, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PhoneInput from 'react-phone-number-input/input';
import flags from 'react-phone-number-input/flags';
import { Country, getCountries } from 'react-phone-number-input';
import countryNames from 'react-phone-number-input/locale/en.json';
import { isNil } from 'lodash';
import {
  AdornmentContainer,
  ArrowDownIcon,
  CountrySelectWrapper,
  Input,
  InputContainer,
  PerfectMenu,
  PerfectMenuItem,
} from './perfect-phone-input.styled';
import { PerfectPhoneInputProps } from './perfect-phone-input';
import { PerfectInputSize } from './perfect-phone-input.enums';

export const PerfectPhoneInput = forwardRef((
  props: PerfectPhoneInputProps,
  ref: Ref<HTMLInputElement>,
) => {
  const {
    size = PerfectInputSize.Medium,
    fullWidth,
    disabled,
    EndAdornment,
    StartAdornment,
    placeholder,
    value,
    endAdornmentClickHandler,
    onChange,
    name,
    ...otherProps
  } = props;

  const [phoneNumber, setPhoneNumber] = useState<string>(value || '');
  const [selectedCountry, setSelectedCountry] = useState<Country>(null);
  const [isCountriesMenuOpen, setIsCountriesMenuOpen] = useState<boolean>(false);

  const countrySelectWrapperRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (inputValue: string | undefined) => {
    setPhoneNumber(inputValue || '');
    onChange?.(inputValue || '');
  };

  const toggleCountriesMenu = () => {
    setIsCountriesMenuOpen(!isCountriesMenuOpen);
  };

  const handleCountrySelect = (newCountry: Country) => {
    setSelectedCountry(newCountry);
    setIsCountriesMenuOpen(false);
  };

  const getCountryByCurrentIpAddress = useCallback(async ():Promise<Country> => {
    try {
      const response = await fetch('https://ipapi.co/json');
      const data = await response.json();
      
      return data.country_code;
    } catch (e) {
      return 'US';
    }
  }, []);

  useEffect(() => {
    if (isNil(selectedCountry)) {
      getCountryByCurrentIpAddress().then((country) => {
        setSelectedCountry(country);
      });
    }
  }, [getCountryByCurrentIpAddress, selectedCountry]);

  const SelectedCountryFlag = useMemo(() => flags[selectedCountry], [selectedCountry]);

  return (
    <InputContainer
      disabled={ !!disabled }
      fullWidth={ !!fullWidth }
      size={ size }
      { ...otherProps }
    >
      {!!StartAdornment && <AdornmentContainer>{StartAdornment}</AdornmentContainer>}

      <CountrySelectWrapper ref={ countrySelectWrapperRef } onClick={ toggleCountriesMenu }>
        {SelectedCountryFlag && <SelectedCountryFlag title={ selectedCountry } />}

        <ArrowDownIcon />
      </CountrySelectWrapper>

      <PerfectMenu isOpen={ isCountriesMenuOpen } onClose={ () => setIsCountriesMenuOpen(false) } anchorElement={ countrySelectWrapperRef.current }>
        {getCountries().map((country) => {
          const Flag = flags[country];
          
          return (
            <PerfectMenuItem key={ country } onClick={ () => handleCountrySelect(country) }>
              <Flag title={ country } />
              <span>{countryNames[country]}</span>
            </PerfectMenuItem>
          );
        })}
      </PerfectMenu>

      <PhoneInput
        country={ selectedCountry }
        value={ phoneNumber }
        onChange={ handleInputChange }
        placeholder={ placeholder }
        name={ name }
        inputComponent={ Input }
        ref={ ref }
      />

      {!!EndAdornment && (
        <AdornmentContainer onClick={ (event) => endAdornmentClickHandler?.(event) }>
          {EndAdornment}
        </AdornmentContainer>
      )}
    </InputContainer>
  );
});

PerfectPhoneInput.displayName = 'PerfectPhoneInput';
