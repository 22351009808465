import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { useGetAccountBySubdomainQuery } from '../../../store/rtk-query/apis/account/account.toolkit-api';
import { AccountLogoImage, AccountName, Container } from './account-logo.styled';
import { AccountLogoProps } from './account-logo';

export const AccountLogo = ({ userSubDomain }: AccountLogoProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const { data: { logo, displayName } = {} } = useGetAccountBySubdomainQuery(userSubDomain, { skip: !userSubDomain });

  const renderLogo = (): JSX.Element | null => {
    if (logo) {
      return (
        <AccountLogoImage
          src={ logo }
          alt={ translate(STRING_KEYS.LOGO_ALT, { name: displayName }) }
        />
      );
    }

    if (displayName) {
      return <AccountName>{displayName}</AccountName>;
    }

    return null;
  };

  return (
    <Container>
      {renderLogo()}
    </Container>
  );
};
