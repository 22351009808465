import styled, { css } from 'styled-components';
import MatchCard from '../match-card/match-card.component';
import { THEME } from '../../../../themes';
import { HEADER_HEIGHT } from '../../../../components/Common/headers/header/header.styled';
import { ASSETS } from '../../../../assets';
import { passMatch, likeMatch } from './matches-carousel.animation';

type MatchCardContainerProps = {
  $playLikeAnimation?: boolean,
  $playPassAnimation?: boolean,
  $matchCardIndex: number,
  $matchCardActiveIndex: number,
  $isAnimationRunning: boolean,
  onAnimationEnd: () => void,
};

const getAnimation = (playLakeAnimation: boolean, playPassAnimation: boolean) => {
  if (playLakeAnimation) {
    return css`
      animation: ${likeMatch} 0.4s ease-in;
    `;
  }

  if (playPassAnimation) {
    return css`
      animation: ${passMatch} 0.4s ease-in;
    `;
  }

  return css``;
};

export const Carousel = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${parseInt(THEME.SPACING.TINY)}px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  width: 100%;
`;

export const MatchCardContainer = styled(MatchCard)<MatchCardContainerProps>`
  justify-content: center;
  z-index: ${({ $matchCardIndex }) => 50 - $matchCardIndex};
  display: ${({ $matchCardActiveIndex, $matchCardIndex }) => $matchCardIndex < $matchCardActiveIndex ? 'none' : 'flex'};
  transition: opacity 1s;
  opacity: ${({ $matchCardActiveIndex, $matchCardIndex, $isAnimationRunning }) => {
    if ($isAnimationRunning && $matchCardIndex === $matchCardActiveIndex + 1) {
      return 1;
    }

    return $matchCardIndex === $matchCardActiveIndex ? 1 : 0;
  }};
  height:${({ $matchCardActiveIndex, $matchCardIndex, $isAnimationRunning }) => {
    if ($matchCardIndex !== $matchCardActiveIndex) {
      if ($isAnimationRunning && ($matchCardIndex === $matchCardActiveIndex + 1 || $matchCardIndex === $matchCardActiveIndex - 1)) {
        return undefined;
      }

      return '1px';
    }
    
    return undefined;
  }};
  ${({ $playLikeAnimation, $playPassAnimation }) =>  getAnimation(!!$playLikeAnimation, !!$playPassAnimation)};
  position: absolute;
  top: 0;
  overflow-x: hidden;
  
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    width: 100%;
  }
`;

export const BottomFader = styled.div`
  content: '';
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${THEME.COLORS.PURE_WHITE});
  height: 64px;
  width: 100%;
  bottom: 0;
  z-index: 2100;
  display: flex;
  justify-content: center;
  border-radius: ${THEME.RADIUS.CURVY};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    width: 100%;
  }
`;

export const BottomFaderIcon = styled(ASSETS.ARROW_DOWN)`
  fill: ${THEME.COLORS.CONTENT_A};
  width: 20px;
  opacity: ${THEME.OPACITY.LOW};
`;
