import { SocialLoginProviders } from '@frontegg/rest-api';
import { ILoginViaSocialLoginPayload } from '@frontegg/redux-store/auth/SocialLogins/interfaces';
import { SplashScreen } from '../../Common/Loaders/SplashScreen/SplashScreen';
import { AppRouting } from '../../../services/appRoutingResolver';
import { usePerfectSocialSuccessHooks } from '../../../hooks/use-perfect-social-success.hooks';
import { UserIdentifyMethod } from '../../../store/slices/app-state/app-state.enums';

export const GoogleSuccessScreen = (): JSX.Element => {
  const buildLoginPayload = (code: string): ILoginViaSocialLoginPayload => {
    return {
      code,
      provider: SocialLoginProviders.Google,
      redirectUri: AppRouting.buildGoogleLoginSuccessUrl(window.location),
    };
  };

  usePerfectSocialSuccessHooks(UserIdentifyMethod.Google, buildLoginPayload);

  return <SplashScreen title="Social Login" />;
};
