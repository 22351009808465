import {
  ChangeEventHandler,
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PerfectMenuSize } from '../perfect-base-menu/perfect-base-menu.enums';
import BaseMenu from '../perfect-base-menu/perfect-base-menu.component';
import {
  ArrowIcon,
  ArrowIconContainer,
  Container,
  ContentContainer,
  Input,
  Label,
  LabelContainer,
  LabelIconWrapper,
} from './select.styled';
import { SelectProps } from './select';

const Select = ({
  label,
  LabelIcon,
  ActionIcon,
  placeholder,
  value,
  onChangeHandler,
  onInputChange,
  children,
  StartAdornment,
  menuProps,
  disabled = false,
  formatDisplayValue,
  placement,
  isAutocomplete = false,
  showEmptyResults = false,
  ...otherProps
}: SelectProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [inputValue, setInputValue] = useState<string>('');
  // Add a flag to track if we're actively searching
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const anchorElementRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [
    selectedElement,
    setSelectedElement,
  ] = useState<SelectItemProps | null>(null);

  const openOptionsMenu = useCallback(() => {
    setAnchorElement(anchorElementRef?.current);
  }, []);

  const closeOptionsMenu = useCallback((preserveInput = false, shouldBlur = false) => {
    setAnchorElement(null);
    // Only clear input when we're not preserving it
    if (!preserveInput) {
      setInputValue('');
    }

    // Reset searching state
    setIsSearching(false);

    // Optionally blur the input
    if (shouldBlur && isAutocomplete) {
      inputRef.current?.blur();
    }
  }, [isAutocomplete]);

  const onSelectItemClick = useCallback(
    (selectedValue: string) => {
      onChangeHandler(selectedValue);
      setIsSearching(false);
      closeOptionsMenu(false, true); // Always clear input and blur when an item is selected

      // Extra insurance to completely remove focus
      setTimeout(() => {
        document.body.focus();
        if (document.activeElement === inputRef.current) {
          inputRef.current?.blur();
        }
      }, 0);
    },
    [closeOptionsMenu, onChangeHandler],
  );

  const childrenWithProps = useMemo(() => {
    return Children.map(children, (child) => {
      const item = child as ReactElement<PropsWithChildren<SelectItemProps>>;

      // Checking isValidElement is the safe way and avoids a TS error too.
      if (isValidElement(item)) {
        if (
          !showEmptyResults &&
          inputValue.length &&
          !item.props.displayValue
            ?.toLowerCase()
            ?.includes(inputValue.toLowerCase())
        ) {
          return null;
        }

        const selected = value !== undefined && value === item.props.value;

        if (selected) {
          setSelectedElement(item.props);
        }

        return cloneElement(item, {
          onClickHandler: onSelectItemClick,
          selected,
          value: item.props.value,
          children: item.props.children,
        });
      }

      return item;
    });
  }, [children, inputValue, onSelectItemClick, value, showEmptyResults]);

  // Add this to check if there are any matching results
  const hasNoResults = useMemo(() => {
    // Check if all children are filtered out
    const filteredChildren = Children.toArray(childrenWithProps).filter(Boolean);
    
    return inputValue.length > 0 && filteredChildren.length === 0;
  }, [childrenWithProps, inputValue]);

  const handleContainerClick = useCallback(() => {
    if (!disabled && anchorElement && (!isSearching || hasNoResults)) {
      // When closing the menu, preserve input in autocomplete mode if there's text and no results
      closeOptionsMenu(isAutocomplete && inputValue.length > 0 && hasNoResults, true);
    } else if (!disabled && !anchorElement) {
      openOptionsMenu();
      if (isAutocomplete) {
        inputRef.current?.focus();
      }
    }
  }, [
    anchorElement,
    closeOptionsMenu,
    disabled,
    isAutocomplete,
    openOptionsMenu,
    inputValue,
    hasNoResults,
    isSearching,
  ]);

  // Make sure the dropdown stays open even when props change
  useEffect(() => {
    // If we're typing and dropdown is open, keep it open
    if (inputValue.length > 0 && !anchorElement) {
      openOptionsMenu();
    }
  }, [inputValue, anchorElement, openOptionsMenu]);

  // Add this effect to keep focus on the input when typing
  useEffect(() => {
    if (inputValue.length > 0 && isAutocomplete) {
      inputRef.current?.focus();
    }
  }, [inputValue, isAutocomplete]);

  // Document click handler for handling outside clicks
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      // Only process if the menu is open
      if (!anchorElement) return;

      // Check if click is outside both the select container and menu
      const isOutsideSelect = anchorElementRef.current && !anchorElementRef.current.contains(event.target as Node);
      const isOutsideMenu = !document.querySelector('#menu-container')?.contains(event.target as Node);

      if (isOutsideSelect && isOutsideMenu) {
        // Force focus away from the select component entirely
        document.body.focus();
        // If the browser doesn't support focus on body, blur the input as fallback
        inputRef.current?.blur();

        // Always close menu and reset search state when clicking outside
        closeOptionsMenu(false, true);
        setIsSearching(false);
      }
    };

    document.addEventListener('mousedown', handleDocumentClick);

    return () => document.removeEventListener('mousedown', handleDocumentClick);
  }, [anchorElement, closeOptionsMenu]);

  // Handle open/reopen behavior
  useEffect(() => {
    // If inputValue is set and the dropdown should be open but isn't, open it
    if (inputValue.length > 0 && isAutocomplete && !anchorElement) {
      openOptionsMenu();
      inputRef.current?.focus();
    }
  }, [inputValue, isAutocomplete, anchorElement, openOptionsMenu]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value ?? '';
    setInputValue(newValue);
    onInputChange?.(newValue);

    // Set search mode when typing in autocomplete
    if (isAutocomplete && newValue.length > 0) {
      setIsSearching(true);
    } else if (newValue.length === 0) {
      setIsSearching(false);
    }

    // Only clear the value if the input is explicitly cleared by the user
    if (!newValue.length) {
      onChangeHandler(null);
    }

    // Keep the dropdown open even when input changes
    if (!anchorElement) {
      openOptionsMenu();
    }
  };

  const renderLabel = (): JSX.Element | undefined => {
    if (!label && !LabelIcon) {
      return undefined;
    }

    return (
      <LabelContainer>
        {LabelIcon && <LabelIconWrapper>{LabelIcon}</LabelIconWrapper>}
        {label && <Label>{label}</Label>}
      </LabelContainer>
    );
  };

  let selectedItemDisplayedValue = value;

  if (!!value && selectedElement) {
    if (formatDisplayValue) {
      selectedItemDisplayedValue = formatDisplayValue(selectedElement);
    } else {
      selectedItemDisplayedValue =
        selectedElement.displayValue ?? selectedElement.value;
    }
  }

  const handleCloseMenu = () => {
    // Don't close the menu during active search with results
    if (isSearching && !hasNoResults) {
      return;
    }

    // For outside clicks, ALWAYS blur regardless of input state
    // We can still decide whether to preserve input value or not
    const preserveInput = isAutocomplete && inputValue.length > 0 && hasNoResults;
    closeOptionsMenu(preserveInput, true);
    setIsSearching(false);

    // Extra guarantee that focus is lost when clicking outside
    if (isAutocomplete) {
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <Container
        ref={ anchorElementRef }
        onClick={ handleContainerClick }
        isActive={ !!anchorElement }
        disabled={ disabled }
        tabIndex={ -1 }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...otherProps }
      >
        {StartAdornment}

        <ContentContainer>
          {renderLabel()}

          <Input
            value={
              inputValue.length ? inputValue : selectedItemDisplayedValue ?? ''
            }
            placeholder={ placeholder }
            readOnly={ !isAutocomplete || disabled }
            onChange={ handleInputChange }
            autoComplete="new-password"
            ref={ inputRef }
          />
        </ContentContainer>

        {ActionIcon ?? (
          <ArrowIconContainer>
            <ArrowIcon />
          </ArrowIconContainer>
        )}
      </Container>

      <BaseMenu
        isOpen={ !!anchorElement }
        anchorElement={ anchorElement || undefined }
        placement={ placement ?? 'auto-start' }
        onClose={ handleCloseMenu }
        size={ PerfectMenuSize.Large }
        Title={ menuProps?.Title }
        disabledArrowNavigation
      >
        { childrenWithProps }
      </BaseMenu>
    </>
  );
};

export default Select;
