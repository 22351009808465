import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import {
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../language/keys';
import Tooltip from '../../../../../components/tooltip/tooltip.component';
import {
  AddIcon,
  BottomContainer,
  Container,
  ExplanationIcon,
  IconWrapper,
  Label,
  LabelWrapper,
  LinkButton,
  StyledProgressBar,
  UnitsCount,
  UpgradeWrapper,
} from './account-plan-progress-bar.styled';

export const AccountPlanProgressBar = forwardRef<
  HTMLDivElement,
  AccountPlanProgressBarProps
>(
  (
    {
      label,
      usedUnits,
      maxUnits,
      upgradeLabel,
      onUpgrade,
      almostOutOfUnitsLabel,
      outOfUnitsLabel,
      tooltipLabel,
    }: AccountPlanProgressBarProps,
    ref,
  ): JSX.Element => {
    const { t: translate } = useTranslation();

    const progressPercentage = (usedUnits / maxUnits) * 100;
    const isAlmostOutOfUnits = progressPercentage > 80;
    const isOutOfUnits = progressPercentage >= 100;

    const renderOutOfUnitsLabel = () => {
      if (isOutOfUnits && outOfUnitsLabel?.length) {
        return ` - ${outOfUnitsLabel}`;
      }

      if (isAlmostOutOfUnits && almostOutOfUnitsLabel?.length) {
        return ` - ${almostOutOfUnitsLabel}`;
      }

      return '';
    };

    return (
      <Container ref={ ref }>
        <LabelWrapper>
          <IconWrapper>
            <Label>{label}</Label>

            {tooltipLabel && (
              <Tooltip content={ tooltipLabel }>
                <ExplanationIcon />
              </Tooltip>
            )}
          </IconWrapper>

          <UpgradeWrapper onClick={ onUpgrade }>
            <AddIcon />

            <LinkButton variant={ PerfectButtonVariants.Link }>
              {upgradeLabel}
            </LinkButton>
          </UpgradeWrapper>
        </LabelWrapper>

        <StyledProgressBar progressPercentage={ progressPercentage } />

        <BottomContainer>
          <UnitsCount>
            {translate(STRING_KEYS.HOMEPAGE.ACCOUNT_PLAN_PROGRESS_BAR.COUNT, {
              usedUnits,
              maxUnits,
            })}
            {renderOutOfUnitsLabel()}
          </UnitsCount>
        </BottomContainer>
      </Container>
    );
  },
);

AccountPlanProgressBar.displayName = 'AccountPlanProgressBar';
