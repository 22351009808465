export enum SocketEventName {
  // Match Events
  MatchPublished = 'MatchPublished',
  MatchFlowFinishedSuccessfully = 'MatchFlowFinishedSuccessfully',
  MatchFlowFailed = 'MatchFlowFailed',
  PositionUpdated = 'PositionUpdated',

  // Talent Events
  ContactDetailsLookupCompleted = 'ContactDetailsLookupCompleted',

  // Outreach Events
  OutreachTalentReplied = 'OutreachTalentReplied',

  // Conversation Events
  ConversationArchived = 'ConversationArchived',

  // Sequence Events
  SequenceCreationCompleted = 'SequenceCreationCompleted',
  SequenceCreationFailed = 'SequenceCreationFailed',

  // User Events
  AssignRoleToUser = 'AssignRoleToUser',

  // Recruiter Agent Events
  AgentGenerationAndMatchingStateChanged = 'AgentGenerationAndMatchingStateChanged',
  RecruiterAgentConversationMessageAdded = 'ConversationMessageAdded',
}
