import React from 'react';
import { ConversationMessageType } from '../../../../../enums/conversation-message-type.enum';
import { ChatAiMessage } from './chat-ai-message/chat-ai-message.component';
import { ChatUserMessage } from './chat-user-message/chat-user-message.component';
import { ChatMessageProps } from './chat-message';

export const ChatMessage = ({
  conversationMessage,
  onActionClick,
  onTypingComplete,
  isAgentGenerating,
}: ChatMessageProps): JSX.Element => {
  const isAiMessage = conversationMessage.messageType === ConversationMessageType.AgentMessage;
  const isCsMessage = conversationMessage.messageType === ConversationMessageType.CustomerSuccessMessage;

  const renderedMessage = isAiMessage || isCsMessage ? (
    <ChatAiMessage
      conversationMessage={ conversationMessage }
      onActionClick={ onActionClick }
      onTypingComplete={ onTypingComplete }
      isAgentGenerating={ isAgentGenerating }
    />
  ) :
    <ChatUserMessage conversationMessage={ conversationMessage } />;

  return (
    <>
      { renderedMessage }
    </>
  );
};
