import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  AtomicBlockUtils, ContentBlock, ContentState, Editor, EditorState,
} from 'draft-js';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import {
  PerfectButtonHoverVariants, PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../../../language/keys';
import {
  DRAFT_HANDLE_VALUE,
  DRAFT_NOT_HANDLE_VALUE,
} from '../../../../../../components/Common/perfect-text-editor/perfect-text-editor.const';
import {
  EditorContainer,
  ClearIcon,
  EditorMainContainer,
  ActionContainer,
  PasteIcon,
  ClearButton,
  PasteButton,
} from './signature-editor.styled';

const logger = log.getLogger('SIGNATURE_EDITOR');

export const SignatureEditor = ({
  emailSignature,
  changeHandler,
}: SignatureProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isLocked, setIsLocked] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    if (emailSignature && !isInitialized) {
      const contentState = ContentState.createFromText(' '); // Dummy block to allow insertion
      const withEntity = contentState.createEntity('HTML', 'MUTABLE', { htmlContent: emailSignature });
      const entityKey = withEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.createWithContent(withEntity);
      const withAtomic = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');

      setEditorState(withAtomic);
      setIsLocked(true);
      setIsInitialized(true);
    }
  }, [emailSignature, isInitialized]);

  const handlePastedText = (
    text: string,
    html: string | undefined,
  ) => {
    if (html) {
      const emptyEditorState = EditorState.createEmpty();
      const contentState = emptyEditorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'HTML',
        'MUTABLE',
        { htmlContent: html },
      );

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorStateWithEntity = EditorState.createWithContent(contentStateWithEntity);
      const withAtomic = AtomicBlockUtils.insertAtomicBlock(newEditorStateWithEntity, entityKey, ' ');

      // Set editor state with completely new content
      setEditorState(withAtomic);
      setIsLocked(true);
      changeHandler(html);

      return DRAFT_HANDLE_VALUE;
    }

    return DRAFT_NOT_HANDLE_VALUE;
  };

  const HtmlBlock = ({ block, contentState }: { block: ContentBlock; contentState: ContentState }) => {
    const entityKey = block.getEntityAt(0);
    if (!entityKey) return null;

    try {
      const entity = contentState.getEntity(entityKey);
      const entityData = entity.getData();

      return (
        <div
          contentEditable
          suppressContentEditableWarning
          dangerouslySetInnerHTML={ { __html: entityData.htmlContent } }
        />
      );
    } catch (error) {
      return null;
    }
  };

  const blockRendererFn = (block: ContentBlock) => {
    if (block.getType() === 'atomic') {
      const entity = block.getEntityAt(0);
      if (entity) {
        return {
          component: HtmlBlock,
        };
      }
    }

    return null;
  };

  const handleClear = () => {
    setEditorState(EditorState.createEmpty());
    setIsLocked(false);
    changeHandler('');
  };

  const handleManualPaste = async () => {
    try {
      const permission = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName });
      if (permission.state === 'denied') {
        return;
      }

      // Added ESLint disable rule because the Navigator type doesn't have a read function
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const clipboardItems = await (navigator.clipboard as any).read();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const htmlItem = clipboardItems.find((item: any) => item.types.includes('text/html'));

      if (!htmlItem) {
        return;
      }

      const blob = await htmlItem.getType('text/html');
      const html = await blob.text();
      if (html) {
        handlePastedText('', html);
      }
    } catch (err) {
      logger.warn('Failed to read HTML from clipboard:', err);
    }
  };

  const onChangeEditorState = (newState: EditorState) => {
    if (!isLocked) {
      setEditorState(newState);
      changeHandler(newState.getCurrentContent().getPlainText());
    }
  };

  return (
    <EditorMainContainer
      onClick={ () => editorRef.current?.focus() }
    >
      <EditorContainer
        id={ isLocked ? 'pasted-signature' : undefined }
      >
        <Editor
          ref={ editorRef }
          editorState={ editorState }
          onChange={ onChangeEditorState }
          handlePastedText={ (text, html) => handlePastedText(text, html) }
          blockRendererFn={ (block) => blockRendererFn(block) }
          readOnly={ false }
          handleBeforeInput={ () => (isLocked ? DRAFT_HANDLE_VALUE : DRAFT_NOT_HANDLE_VALUE) }
          placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.EMAIL_SIGNATURE_EDITOR.PLACEHOLDER) }
        />
      </EditorContainer>
      <ActionContainer>
        <PasteButton
          type="button"
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onMouseDown={ handleManualPaste }
          variant={ PerfectButtonVariants.Ghost }
          StartIcon={ PasteIcon }
        >
          { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.EMAIL_SIGNATURE_EDITOR.PASTE_BUTTON) }
        </PasteButton>
        <ClearButton
          type="button"
          onMouseDown={ handleClear }
          variant={ PerfectButtonVariants.Ghost }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          StartIcon={ ClearIcon }
        >
          { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.EMAIL_SIGNATURE_EDITOR.CLEAR_BUTTON) }
        </ClearButton>
      </ActionContainer>
    </EditorMainContainer>
  );
};
