import styled from 'styled-components';
import { Body2Font } from '../../../../../themes/fonts';
import { FLUENT_UI_EMOJIS } from '../../../../../assets';
import { THEME } from '../../../../../themes';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${THEME.COLORS.SECONDARY}1F;
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.BASE};
`;

export const TitlesAndLockIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINO};
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title1 = styled.div`
  ${Body2Font};
  font-weight: 700;
`;

export const Title2 = styled.div`
  ${Body2Font};
`;

export const LockIcon = styled(FLUENT_UI_EMOJIS.LOCKED)``;
