import styled from 'styled-components';
import { CaptionFont, OverlineFont } from '../../../../../themes/fonts';
import { THEME } from '../../../../../themes';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import Progress from '../../../../../components/Common/progress/progress.component';
import { ASSETS } from '../../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
  width: 250px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.div`
  ${CaptionFont};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

const getProgressBarColorByPercentage = (progressPercentage: number) => {
  if (progressPercentage >= 100) {
    return THEME.COLORS.ALERT;
  }

  if (progressPercentage >= 80) {
    return THEME.COLORS.WARNING;
  }

  return THEME.COLORS.PRIMARY_DARK;
};

export const StyledProgressBar = styled(Progress).attrs(
  ({ progressPercentage }) => ({
    color: getProgressBarColorByPercentage(progressPercentage),
  }),
)`
  width: 100%;
  height: 8px;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UnitsCount = styled.div`
  ${OverlineFont};
  font-weight: 600;
  text-transform: uppercase;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const UpgradeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
  cursor: pointer;
`;

export const LinkButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  font-weight: bold;
`;

export const AddIcon = styled(ASSETS.ADD)`
  width: 12px;
  height: 12px;
`;

export const ExplanationIcon = styled(ASSETS.EXPLANATION)`
  width: 12px;
  height: 12px;
`;
