import { createSlice } from '@reduxjs/toolkit';
import { initialOnboardingState } from './onboarding-state.initial-state';
import { setPromoReducer } from './reducers/obarding-user-data.reducer';
import { clearPromoAndAiChatPromptReducer, setAiChatPromptReducer } from './reducers/obarding-action.reducer';

export const OnboardingStateSlice = createSlice({
  name: 'onboarding-state',
  initialState: initialOnboardingState,
  reducers: {
    setPromo: setPromoReducer,
    setAiChatPrompt: setAiChatPromptReducer,
    clearPromoAndAiChatPrompt: clearPromoAndAiChatPromptReducer,
  },
});

export const {
  setPromo,
  setAiChatPrompt,
  clearPromoAndAiChatPrompt,
} = OnboardingStateSlice.actions;

export default OnboardingStateSlice.reducer;
