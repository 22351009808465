import { useCallback, useEffect } from 'react';
import { useAuth, useAuthActions } from '@frontegg/react';
import log from 'loglevel';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppState } from '../store/selectors';
import BroadcastChannelManager from '../services/broadcastChannelManager';
import AnalyticsIdManager from '../services/analyticsIdManager';
import trackingIdManager from '../services/trackingIdManager';
import RudderStackAnalyticsAgent from '../services/rudderStackAnalyticsAgent';
import UserLoggingInfoManger from '../services/userLoggingInfoManger';
import { AppRouting } from '../services/appRoutingResolver';
import { accountQuery } from '../store/rtk-query/apis/account/account.toolkit-api';
import { userQuery } from '../store/rtk-query/apis/user/user.toolkit-api';
import { positionQuery } from '../store/rtk-query/apis/position/position.toolkit-api';
import socketManager from '../services/socket-io-manager';
import { usePrevious } from './states';

const logger = log.getLogger('USER_WATCHER_HOOK');

export const useUserWatcher = (): void => {
  const { isOnboardingLogout, isLoggingOutFromRemoteTab } = useAppState();

  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth();

  const prevIsAuthenticated = usePrevious<boolean>(isAuthenticated);

  const actions = useAuthActions();
  const history = useHistory();

  const handleUserLoggingOut = useCallback(() => {
    logger.debug('logging out user from all services');

    if (!isLoggingOutFromRemoteTab) {
      // send logout event to all opened tabs
      BroadcastChannelManager.sendLogoutMessage();
    }

    // analytics-session-id
    AnalyticsIdManager.remove();

    if (!isOnboardingLogout) {
      trackingIdManager.regenerateTrackingId();
    }
    // super user
    // disableSuperUser();
    // frontegg
    actions.logout();
    dispatch(accountQuery.util.resetApiState());
    dispatch(positionQuery.util.resetApiState());
    dispatch(userQuery.util.resetApiState());

    // RudderStack
    RudderStackAnalyticsAgent.reset();

    // clear enriching logs with user specific data...
    UserLoggingInfoManger.clear();

    socketManager.disconnectAllSockets();
    // clear route
    history.push(AppRouting.ROUTES.GLOBAL_LOGIN);
  }, [actions, dispatch, history, isLoggingOutFromRemoteTab, isOnboardingLogout]);

  useEffect(() => {
    logger.trace(
      'user state hook run',
      {
        prevIsAuthenticated,
        currentIsAuthenticated: isAuthenticated,
      },
    );

    if (!!prevIsAuthenticated && !isAuthenticated) {
      logger.debug(
        'detected user-login state change to false. meaning the user just logged out',
        {
          prevIsAuthenticated,
          currentIsAuthenticated: isAuthenticated,
        },
      );

      handleUserLoggingOut();
    }
  }, [prevIsAuthenticated, handleUserLoggingOut, isAuthenticated]);
};
