import styled, { css } from 'styled-components';
import { hexToRgbA } from '../../../../../utils/colors';
import { THEME } from '../../../../../themes';
import { Scrollbar } from '../../../../../themes/scrollbar';

export const CHAT_INPUT_HEIGHT = 112;

export const MinimizeWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2300;
`;

export const ChatContainer = styled.div<{ isMinimized: boolean, hide: boolean }>`
  ${Scrollbar};
  max-width: ${({ isMinimized }) => isMinimized ? '0' : '400px'};
  min-width: ${({ isMinimized }) => isMinimized ? '0' : '400px'};
  position: relative;
  display: flex;
  visibility: ${({ hide }) => hide ? 'hidden' : 'visible'}; // Changed from display: none
  flex-direction: column;
  border-radius: ${THEME.RADIUS.CURVY};
  overflow: hidden;
  margin-left: ${THEME.SPACING.BASE};
  margin-bottom: 9px;
  height: inherit;
  flex-shrink: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(25px) brightness(87%);
    z-index: -1;
    background-color: ${hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.LOW)};
  }

  transition: max-width 300ms ease-in-out, min-width 300ms ease-in-out, opacity 300ms ease-in-out;
  transform-origin: bottom left;

  opacity: ${({ isMinimized }) => isMinimized ? 0 : 1};

  ${(props) => props.isMinimized && css`
    transform: scale(0);
    margin-left: 0;
  `}

  @media only screen and (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_BASE}px) and (min-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    max-width: ${({ isMinimized }) => isMinimized ? '0' : '360px'};
    min-width: ${({ isMinimized }) => isMinimized ? '0' : '360px'};
  }
  
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    max-width: ${({ isMinimized }) => isMinimized ? '0' : '360px'};
    min-width: ${({ isMinimized }) => isMinimized ? '0' : '360px'};
    position: fixed;
    z-index: 2500;
    height: calc(100vh - 80px - 100px);
    bottom: 82px;
    left: 24px;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: solid 2px rgba(19, 15, 53, ${THEME.OPACITY.GHOST});
    backdrop-filter: blur(30px);
  }
`;

export const ChatBox = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
