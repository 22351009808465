import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { KEYBOARD_KEYS } from '../../../../../consts/keyboard_keys';
import { IconButtonVariants } from '../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import {
  PerfectButtonHoverVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ChatInputProps } from './chat-input';
import {
  AlertIcon, ArrowLoopIcon,
  ArrowUpIcon,
  ButtonContainer, ErrorContainer, ErrorMessage,
  InputContainer,
  InputField,
  SendButton,
} from './chat-input.styled';

export const ChatInput = ({
  isDisabled, onSubmit, error, isLoading, isMessageCreated, value,
}: ChatInputProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    setUserInput(value);
  }, [value]);

  useEffect(() => {
    if (isMessageCreated && !isLoading) {
      setUserInput('');
    }
  }, [isLoading, isMessageCreated]);

  const onKeyDownHandler = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === KEYBOARD_KEYS.ENTER && !e.shiftKey) {
      e.preventDefault();

      if (isDisabled || !userInput) {
        return;
      }

      onSubmit(userInput);
    }
  }, [isDisabled, userInput, onSubmit]);

  const renderErrorState = () => {
    if (!error) {
      return null;
    }

    return (
      <ErrorContainer>
        <AlertIcon />
        <ErrorMessage>
          {error.message}
        </ErrorMessage>
      </ErrorContainer>
    );
  };

  return (
    <InputContainer>
      <InputField
        value={ userInput }
        onChange={ (e) => setUserInput(e.target.value) }
        placeholder={ translate(STRING_KEYS.MATCHES_PAGE.CHAT.INPUT.PLACEHOLDER) }
        onKeyDown={ onKeyDownHandler }
      />
      <ButtonContainer hasError={ !!error }>
        { renderErrorState() }
        <SendButton
          type="button"
          hasError={ !!error }
          onClick={ () => onSubmit(userInput) }
          disabled={ isDisabled }
          variant={ IconButtonVariants.Fill }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          StartIcon={ (error) ? ArrowLoopIcon : ArrowUpIcon }
          isLoading={ isLoading }
          isEmpty={ !!userInput }
        />
      </ButtonContainer>
    </InputContainer>
  );
};
