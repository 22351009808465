import { useEffect, useState } from 'react';
import { UpgradePlanDialog } from '../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { useGetMyAccountSelector } from '../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { PricingPlan } from '../enums/user-plan.enum';
import BuyPositionDialogs from '../components/dialogs/buy-position-dialogs/buy-position-dialogs.component';
import { UpgradePlanDialogVariation } from '../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.enums';
import UpgradeToScaleDialog
  from '../components/dialogs/info-dialogs/upgrade-to-scale-dialog/upgrade-to-scale-dialog.component';
import { useGetPositionPriceSelector } from '../store/rtk-query/apis/payment/selectors/get-position-price.selector';

enum UseCreatePositionUpgradeModalType {
  BuyPosition = 'BUY_POSITION',
  UpgradePlan = 'UPGRADE_PLAN',
  UpgradeToScale = 'UPGRADE_TO_SCALE',
}

export type UseCreatePositionUpgradeModalArguments = {
  isOpen: boolean;
  createPositionHandler: () => void;
  closeHandler: () => void;
};

export const usePositionLimitUpgradeModal = ({ isOpen, createPositionHandler, closeHandler }: UseCreatePositionUpgradeModalArguments): JSX.Element | undefined => {
  const [modalType, setModalType] = useState<UseCreatePositionUpgradeModalType | undefined>(undefined);
  const { data: { plan: { pricingPlan, maximumOpenPositions = 0, positionsLimit = 0 } } } = useGetMyAccountSelector();
  const { data: { price: positionPrice } } = useGetPositionPriceSelector();

  useEffect(() => {
    let type;
    if (isOpen) {
      if (positionPrice && pricingPlan === PricingPlan.Pro && (maximumOpenPositions < positionsLimit)) {
        type = UseCreatePositionUpgradeModalType.BuyPosition;
      } else if (pricingPlan === PricingPlan.Trial) {
        type = UseCreatePositionUpgradeModalType.UpgradePlan;
      } else {
        type = UseCreatePositionUpgradeModalType.UpgradeToScale;
      }
    }

    setModalType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onClose = () => {
    setModalType(undefined);
    closeHandler();
  };

  if (!isOpen) {
    return undefined;
  }

  switch (modalType) {
    case UseCreatePositionUpgradeModalType.BuyPosition:
      return (
        <BuyPositionDialogs
          isOpen={ isOpen }
          closeHandler={ onClose }
          createPositionHandler={ createPositionHandler }
        />
      );
    case UseCreatePositionUpgradeModalType.UpgradePlan:
      return (
        <UpgradePlanDialog
          isDialogOpen={ isOpen }
          onCloseDialog={ onClose }
          variation={ UpgradePlanDialogVariation.NeedMoreMatches }
        />
      );
    case UseCreatePositionUpgradeModalType.UpgradeToScale:
      return (
        <UpgradeToScaleDialog
          isDialogOpen={ isOpen }
          closeButtonHandler={ onClose }
        />
      );
    default:
      return undefined;
  }
};
