import styled  from 'styled-components';
import { BACKGROUND } from '../../../../assets';

export const LayoutContainer = styled.div<{ globalHeaderHeight: number, isMatchesPage: boolean }>`
  height: 100vh;
  position: relative;
  padding-top: ${({ globalHeaderHeight }) => `${globalHeaderHeight}px`};
  
  ${({ isMatchesPage }) => isMatchesPage && `
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(30px);
      opacity: 0.2;
      background: url("${BACKGROUND.MESH_BACKGROUND}") no-repeat center center/cover;
      z-index: -1;
    }
  
    &::after {
      content: "";
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      background: inherit;
      z-index: 0;
      mask: linear-gradient(white 40px, white 50px); /* Soft edge effect */
    }
  `}
`;

export const Body = styled.div<{ globalHeaderHeight: number }>`
  height: ${({ globalHeaderHeight }) => `calc(100vh - ${globalHeaderHeight}px)`};
  display: flex;
  flex-direction: column;
`;

export const LayoutHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const SelectionGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
