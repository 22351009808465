import styled from 'styled-components';
import { CaptionFont } from '../../../themes/fonts';
import { THEME } from '../../../themes';

export const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Line = styled.span`
  flex-grow: 1;
  height: 1px;
  background-color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.GHOST};
`;

export const Label = styled.span`
  ${CaptionFont};
  padding: 0 12px;
  font-size: 14px;
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.LOW};
  white-space: nowrap;
`;

export const Divider = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.LOW};
  margin-top: ${THEME.SPACING.LOOSE};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: revert;
  align-items: center;
  
  :before, :after {
    content: '';
    background-color: ${THEME.COLORS.PRIMARY_DARK};
    opacity: ${THEME.OPACITY.LOW};
    height: 1px;
    flex-grow: 1;
  }
  
  span {
    margin: 0 15px;
  }
`;
