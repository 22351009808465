import {
  MenuOption, Subtitle, Title, TitlesWrapper,
} from './plan-upgrade-options-menu-item.styled';

type PlanUpgradeOptionsMenuItemProps = {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  onClick: () => void;
  isDisabled: boolean;
};

export const PlanUpgradeOptionsMenuItem = ({
  icon, title, subtitle, onClick, isDisabled,
}:PlanUpgradeOptionsMenuItemProps):JSX.Element => {
  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <MenuOption onClick={ handleClick } $isDisabled={ isDisabled }>
      {icon}

      <TitlesWrapper>
        <Title>{title}</Title>

        <Subtitle>{subtitle}</Subtitle>
      </TitlesWrapper>
    </MenuOption>
  );
};
