import { Redirect, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ResetPasswordScreen from '../ResetPasswordScreen';
import ForgotPasswordScreen from '../ForgotPasswordScreen';
import { GoogleSuccessScreen } from '../login-and-registration/social-login-success/google-login-success';
import { AppRouting } from '../../services/appRoutingResolver';
import { Login } from '../login-and-registration/login/login.component';
import { useAppState } from '../../store/selectors';
import AcceptTenantInviteScreen from '../AcceptTenantInviteScreen';
import { MicrosoftSuccessScreen } from '../login-and-registration/social-login-success/microsoft-login-success';
import { removeInitialLoadings } from '../../store/slices/app-state/app-state.toolkit-slice';
import { GuardedRoute } from './guarded-route/guarded-route';

export const AuthRouter = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const { showSplashScreen } = useAppState();

  useEffect(() => {
    if (showSplashScreen) {
      dispatch(removeInitialLoadings());
    }
  }, [dispatch, showSplashScreen]);

  if (showSplashScreen) {
    return null;
  }

  return (
    <Switch>
      <GuardedRoute
        path={ AppRouting.ROUTES.ACCEPT_TENANT_INVITE }
        exact
        withMissingUserInfoCheck
        withAccountSubDomainCheck
      >
        <AcceptTenantInviteScreen />
      </GuardedRoute>

      <GuardedRoute
        path={ AppRouting.ROUTES.GLOBAL_LOGIN }
        exact
        withMissingUserInfoCheck
        withAccountSubDomainCheck
        withOnboardingCheck
      >
        <Login />
      </GuardedRoute>

      <GuardedRoute
        path={ AppRouting.ROUTES.RESET_PASSWORD }
        exact
        withMissingUserInfoCheck
        withAccountSubDomainCheck
      >
        <ResetPasswordScreen />
      </GuardedRoute>

      <GuardedRoute
        path={ AppRouting.ROUTES.FORGOT_PASSWORD }
        exact
        withMissingUserInfoCheck
        withAccountSubDomainCheck
      >
        <ForgotPasswordScreen />
      </GuardedRoute>

      <GuardedRoute
        path={ AppRouting.ROUTES.SUCCESS_GOOGLE_LOGIN }
        exact
        withMissingUserInfoCheck
        withAccountSubDomainCheck
      >
        <GoogleSuccessScreen />
      </GuardedRoute>

      <GuardedRoute
        path={ AppRouting.ROUTES.SUCCESS_MICROSOFT_LOGIN }
        exact
        withMissingUserInfoCheck
        withAccountSubDomainCheck
      >
        <MicrosoftSuccessScreen />
      </GuardedRoute>

      <GuardedRoute path="*">
        <Redirect to={ AppRouting.ROUTES.GLOBAL_LOGIN } />
      </GuardedRoute>
    </Switch>
  );
};
