import { UserInfoResponse } from '../dto/response/user-info.response';
import { useAppSelector } from '../../../../selectors';
import { userQuery } from '../user.toolkit-api';

const getMyUserSelectorSelector = userQuery.endpoints.getMyUser.select();

export const useMyUserSelector = (): AppSelectorType<UserInfoResponse> => {
  const {
    data: user, isLoading, isError, isSuccess, error,
  } = useAppSelector(getMyUserSelectorSelector);

  return {
    data: user || {} as UserInfoResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
