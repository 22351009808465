import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useAuthActions, useLoginState, useSignUpState } from '@frontegg/react';
import { useDispatch } from 'react-redux';
import { ISocialLoginProviderConfigurationV2 } from '@frontegg/rest-api';
import { PAGE_NAMES } from '../../../consts/pages';
import { COMPONENT_NAME, EVENT_GROUP } from '../../../consts/analytics';
import { SIGNUP_ERROR_MESSAGES } from '../../../consts';
import STRING_KEYS from '../../../language/keys';
import { isValidEmail } from '../../../utils';
import { getGoogleSocialLoginUrl, getMicrosoftSocialLoginUrl, validatePassword } from '../../../utils/frontegg';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { AppRouting } from '../../../services/appRoutingResolver';
import { setUserJustSignedUp } from '../../../store/slices/app-state/app-state.toolkit-slice';
import { usePageFilters } from '../../../hooks/use-page-filters.hooks';
import { useValidateUserEmailDomainMutation } from '../../../store/rtk-query/apis/user/user.toolkit-api';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../../modules/onboarding/consts/onboarding-analytics.consts';
import { isPersonalEmailError } from '../../../modules/onboarding/utils/onboarding.utils';
import { LoginAndRegistrationLayout } from '../login-and-reqistration-layout/login-and-registration-layout.component';
import { SocialLoginOptions } from '../login/login-form/social-login/social-login.component';
import { PerfectSpacer } from '../../Common/perfect-spacer/perfect-spacer.component';
import { PerfectDivider } from '../../Common/perfect-divider/perfect-divider.component';
import {
  AlreadyHaveAccountText, Background, LoginButton, Wrapper,
} from './registration.styled';
import { RegistrationForm } from './registration-form/registration-form.component';
import { RegistrationProps } from './registration';

export const SIGN_UP_ERRORS = {
  USER_EXIST: 'User already exists',
};

const ONBOARDING_ACCOUNT_NAME = `onboarding-${v4()}`;

const QUERY_PARAM_ERROR_CODE = 'errorCode';

export const Registration = ({
  onRegistrationComplete,
  errorCode,
}: RegistrationProps): JSX.Element => {
  const { deleteFiltersByKey } = usePageFilters();

  const [localEmailError, setLocalEmailError] = useState<boolean>(false);
  const [localPasswordError, setLocalPasswordError] = useState<boolean>(false);
  const [termsOfUseModalVisible, setTermsOfUseModalVisible] = useState<boolean>(
    false,
  );

  const { t: translate } = useTranslation();
  const { analyticsTrackEvent, analyticsPageEvent } = useAnalyticsContext();

  const dispatch = useDispatch();

  const { signUpUser, login, resetSignUpState } = useAuthActions();
  const { loading: signUpLoading, error: signUpError = '' } = useSignUpState();
  const { loading: loginLoading } = useLoginState();

  const errorMessageKey =
    SIGNUP_ERROR_MESSAGES[errorCode] || STRING_KEYS.SIGNUP_ERRORS.UNDEFINED;

  useEffect(() => {
    analyticsPageEvent({
      eventName: ONBOARDING_ANALYTICS_EVENTS.SIGN_UP_PAGE_VIEWED,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    validateUserEmailDomain,
    {
      isLoading: isUserEmailDomainValidating,
      isError: isUserEmailDomainValidationFailed,
      error: userEmailDomainValidationError,
    },
  ] = useValidateUserEmailDomainMutation();

  const signUpAndLogin = useCallback(
    async (email: string, password: string) => {
      try {
        await validateUserEmailDomain({ email }).unwrap();
      } catch (e) {
        setLocalEmailError(true);
      }

      signUpUser({
        email,
        password,
        companyName: ONBOARDING_ACCOUNT_NAME,
        events: {
          signUpComplete: () => {
            dispatch(setUserJustSignedUp());

            login({
              email,
              password,
              callback: () => {
                onRegistrationComplete();
              },
            });
          },
        },
      });
    },
    [
      dispatch,
      login,
      onRegistrationComplete,
      signUpUser,
      validateUserEmailDomain,
    ],
  );

  const sendTrackAnalyticEvent = (eventName: string) => {
    const trackEventPayload = {
      eventName,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
      componentName: COMPONENT_NAME.SIGN_IN,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const loginTrackAnalyticEvent = () => {
    const trackEventPayload = {
      eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_LOGIN_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const handleSignUpClick = async (email: string, password: string) => {
    sendTrackAnalyticEvent(
      ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_LOGIN_CLICKED,
    );

    if (!isValidEmail(email)) {
      setLocalEmailError(true);

      return;
    }

    if (!validatePassword(password)) {
      setLocalPasswordError(true);

      return;
    }

    await signUpAndLogin(email, password);
  };

  const handleGoogleLoginClick = (
    configuration: ISocialLoginProviderConfigurationV2,
  ) => {
    sendTrackAnalyticEvent(
      ONBOARDING_ANALYTICS_EVENTS.CONTINUE_WITH_GOOGLE_CLICKED,
    );
    const redirectUrl = AppRouting.buildGoogleLoginSuccessUrl(window.location);

    window.location.href = getGoogleSocialLoginUrl(
      configuration.clientId as string,
      redirectUrl,
    );
  };

  const handleMicrosoftLoginClick = async (
    configuration: ISocialLoginProviderConfigurationV2,
  ) => {
    sendTrackAnalyticEvent(
      ONBOARDING_ANALYTICS_EVENTS.CONTINUE_WITH_MICROSOFT_CLICKED,
    );
    const redirectUrl = AppRouting.buildMicrosoftLoginSuccessUrl(
      window.location,
    );

    window.location.href = await getMicrosoftSocialLoginUrl(
      configuration.clientId as string,
      redirectUrl,
    );
  };

  const handleEmailInputChange = (newValue: string) => {
    resetSignUpState();

    if (isValidEmail(newValue)) {
      setLocalEmailError(false);
    }
  };

  const handlePasswordInputChange = (newValue: string) => {
    resetSignUpState();

    if (validatePassword(newValue)) {
      setLocalPasswordError(false);
    }
  };

  const onErrorCloseHandler = () => {
    deleteFiltersByKey(QUERY_PARAM_ERROR_CODE);
  };

  const handleCloseTermsOfUseModal = () => {
    setTermsOfUseModalVisible(false);
  };

  const getEmailError = (): string => {
    if (isUserEmailDomainValidationFailed) {
      if (userEmailDomainValidationError?.code) {
        const internalCode = parseInt(userEmailDomainValidationError?.code);
        if (isPersonalEmailError(internalCode)) {
          return translate(STRING_KEYS.ONBOARDING.PERSONAL_EMAIL_ERROR);
        }
      }

      return translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_WORK_EMAIL,
      );
    }

    if (localEmailError) {
      return translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_EMAIL,
      );
    }

    return '';
  };

  const getSignupError = (): string => {
    switch (signUpError) {
      case SIGN_UP_ERRORS.USER_EXIST: {
        return translate(STRING_KEYS.ONBOARDING.USER_EXIST);
      }
      default: {
        return signUpError;
      }
    }
  };

  const handleLoginClick = () => {
    loginTrackAnalyticEvent();

    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  };

  return (
    <LoginAndRegistrationLayout
      error={ !!errorCode && translate(errorMessageKey) }
      onErrorCloseHandler={ onErrorCloseHandler }
      isLoading={ signUpLoading || loginLoading || isUserEmailDomainValidating }
      isTermsOfUseModalVisible={ termsOfUseModalVisible }
      handleCloseTermsOfUseModal={ handleCloseTermsOfUseModal }
      title={ (
        <>
          <p>{translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TITLE_1)}</p>
          <p>{translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TITLE_2)}</p>
        </>
      ) }
      subTitle={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_SUBTITLE) }
    >
      <PerfectSpacer height={ 32 } />

      <SocialLoginOptions
        onGoogleLoginClick={ handleGoogleLoginClick }
        onMicrosoftLoginClick={ handleMicrosoftLoginClick }
      />

      <PerfectSpacer height={ 32 } />

      <PerfectDivider
        label={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_DESCRIPTION) }
      />

      <PerfectSpacer height={ 32 } />

      <RegistrationForm
        onEmailChange={ handleEmailInputChange }
        onPasswordChange={ handlePasswordInputChange }
        onSubmit={ handleSignUpClick }
        isEmailError={ localEmailError }
        emailError={ getEmailError() }
        isPasswordError={ localPasswordError }
        passwordError={ translate(
          STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_PASSWORD,
        ) }
        isSubmitting={
          signUpLoading || loginLoading || isUserEmailDomainValidating
        }
        signUpError={ getSignupError() }
      />

      <PerfectSpacer height={ 8 } />

      <Wrapper>
        <AlreadyHaveAccountText>
          {translate(STRING_KEYS.ONBOARDING.ALREADY_HAVE_AN_ACCOUNT)}
        </AlreadyHaveAccountText>

        <LoginButton onClick={ handleLoginClick }>
          {translate(STRING_KEYS.ONBOARDING.LOGIN)}
        </LoginButton>
      </Wrapper>

      <Background />
    </LoginAndRegistrationLayout>
  );
};
