/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO [refactor] eslint-disable for temporary devs deployments
import { Redirect, Route, Switch } from 'react-router-dom';
import { useOnPaymentSuccess } from '../../hooks/use-on-payment-success.hooks';
import { useAcknowledgementsDialogs, useRenderAcknowledgementsDialogs } from '../../hooks/acknowledgements.hooks';
import { AppRouting } from '../../services/appRoutingResolver';
import { PRODUCT_AREA } from '../../consts/analytics';
import { NotFoundErrorPage } from '../Common/Errors/NotFoundErrorPage';
import { AnalyticsProvider } from '../../contexts/analytics';
import { useUpgradeModalFromQueryParam } from '../../hooks/upgrade-modal-from-query-param.hook';
import { ApplicationLayout } from '../../modules/application/components/application-layout/application-layout.component';
import { OutreachLayout } from '../../modules/application/components/outreach/outreach-layout/outreach.layout';
import ConversationEmpty from '../../modules/application/pages/conversation-empty/conversation-empty.page';
import ConversationsPage from '../../modules/application/pages/conversation/conversation.page';
import PositionLayout from '../../modules/application/components/position-layout/position-layout.component';
import PositionOverviewPage from '../../modules/application/pages/position-overview-page/position-overview.page';
import Homepage from '../../modules/application/pages/home/home.page';
import CampaignPage from '../../modules/application/pages/campaign/campaign.page';
import OutreachContentPreferencesPage
  from '../../modules/application/pages/outreach-content-preferences/outreach-content-preferences.page';
import MatchesPage from '../../modules/application/pages/matches/matches.page';
import { GuardedRoute } from './guarded-route/guarded-route';

export const ApplicationRouter = (): JSX.Element => {
  const AcknowledgementsDialogs = useRenderAcknowledgementsDialogs(useAcknowledgementsDialogs());
  const paymentComponent = useOnPaymentSuccess();
  const upgradeModal = useUpgradeModalFromQueryParam();

  return (
    <>
      { AcknowledgementsDialogs }
      { paymentComponent }
      { upgradeModal }

      <Switch>
        <GuardedRoute path={ AppRouting.ROUTES.POSITION } withAuthCheck withOnboardingCheck withMissingUserInfoCheck withAccountSubDomainCheck>
          <ApplicationLayout>
            <PositionLayout>
              <Switch>
                <Route path={ AppRouting.ROUTES.POSITION_OVERVIEW } exact>
                  <PositionOverviewPage />
                </Route>
                <Route path={ AppRouting.ROUTES.OUTREACH_POSITION }>
                  <OutreachLayout>
                    <Switch>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CONTENT_PREFERENCES } exact>
                        <OutreachContentPreferencesPage />
                      </Route>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CONVERSATIONS } exact>
                        <ConversationEmpty />
                      </Route>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CONVERSATION_ID } exact>
                        <ConversationsPage />
                      </Route>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CAMPAIGN_PREFERENCES } exact>
                        <CampaignPage />
                      </Route>
                      <Route path="*" exact>
                        <Redirect to={ AppRouting.ROUTES.HOME } exact />
                      </Route>
                    </Switch>
                  </OutreachLayout>
                </Route>
                <Route path={ AppRouting.ROUTES.MATCHES } exact>
                  <MatchesPage />
                </Route>
                <Route path="*" exact>
                  <Redirect to={ AppRouting.ROUTES.HOME } exact />
                </Route>
              </Switch>
            </PositionLayout>
          </ApplicationLayout>
        </GuardedRoute>

        <Route path={ AppRouting.ROUTES.ACTIVATION_COMPLETED } exact>
          {/* special route to navigate from activation flow to home screen */}
          <Redirect to={ AppRouting.ROUTES.HOME } />
        </Route>

        <GuardedRoute path={ AppRouting.ROUTES.HOME } exact withAuthCheck withOnboardingCheck withMissingUserInfoCheck withAccountSubDomainCheck>
          {/* Default Route. Keep it last in the list of routes */}
          <AnalyticsProvider productArea={ PRODUCT_AREA.HOMEPAGE }>
            <ApplicationLayout>
              <Homepage />
            </ApplicationLayout>
          </AnalyticsProvider>
        </GuardedRoute>
          
        <GuardedRoute path="*" withAuthCheck withOnboardingCheck withMissingUserInfoCheck withAccountSubDomainCheck>
          <ApplicationLayout>
            <NotFoundErrorPage />
          </ApplicationLayout>
        </GuardedRoute>
      </Switch>
    </>
  );
};
