import styled, { css } from 'styled-components';
import ReactTyped from 'react-typed';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import { Body1Font, Body2Font, Subtitle1Font } from '../../../../../themes/fonts';
import { TextEllipsis } from '../../../../../themes/text';
import MenuItem from '../../../perfect-base-menu/menu-item/menu-item.component';
import BaseMenu from '../../../perfect-base-menu/perfect-base-menu.component';

const TOOLBAR_HEIGHT = 48;

export const Container = styled.div`
  height: ${TOOLBAR_HEIGHT}px;
  display: flex;
  position: relative;
  align-items: center;
  min-width: 400px;
  max-width: 600px;
  width: fit-content;
  gap: ${THEME.SPACING.TINO};
`;

export const DividerIcon = styled(ASSETS.ARROW_RIGHT)`
  width: 9px;
  height: 15px;
  opacity: ${THEME.OPACITY.GHOST};
  fill: ${THEME.COLORS.PRIMARY_DARK};
`;

export const HomeIcon = styled(ASSETS.HOME_ICON)`
  width: 18px;
  height: 12px;
  fill: ${THEME.COLORS.PRIMARY_DARK};
  &:hover {
    fill: ${THEME.COLORS.ACCENT};
  }
`;

export const CurrentPositionWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: ${THEME.SPACING.TINO};
  padding: 8px 16px;

  :hover {
    color: ${THEME.COLORS.ACCENT};
  }
`;

const commonTitleStyles = css`
  ${Body1Font};
  font-weight: 500;`;

export const CurrentPositionTitle = styled.p`
  ${commonTitleStyles};
`;

export const CurrentPositionTitleTyped = styled(ReactTyped)`
  ${commonTitleStyles};
`;

export const ArrowDownIcon = styled(ASSETS.ARROW_DOWN)`
  height: 20px;
  fill: currentColor;
`;

export const PerfectMenu = styled(BaseMenu)`
  overflow-y: auto;
  max-height: 300px;
`;

export const PerfectMenuItem = styled(MenuItem)`
 && {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
   gap: 0;
   height: 56px;
 }
`;

export const MenuItemTitle = styled.div`
  ${Subtitle1Font}
`;

export const MenuItemSubtitle = styled.div<{ menuWidthPx?: number }>`
  ${TextEllipsis};
  max-width: 400px;
  ${Body2Font};
  opacity: 0.6;
`;
