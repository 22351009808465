import styled from 'styled-components';
import { Scrollbar } from '../../../../../../themes/scrollbar';
import { hexToRgbA } from '../../../../../../utils/colors';
import { THEME } from '../../../../../../themes';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../../../assets';

export const EditorMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 250px;
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.PURE_WHITE};
`;

export const EditorContainer = styled.div`
  ${Scrollbar};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  flex: 1;
  overflow: auto;
  & .DraftEditor-root {
    & .DraftEditor-editorContainer {
      position: relative;
      z-index: 1;
    }

    & .public-DraftEditorPlaceholder-root {
      opacity: ${THEME.OPACITY.LOW};
      position: absolute;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 ${THEME.RADIUS.ROUND} ${THEME.RADIUS.ROUND};
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  padding: ${THEME.SPACING.MICRO}
`;

export const ClearButton = styled(BaseButton)`
  color: ${THEME.COLORS.ALERT};
  background-color: unset;
`;

export const PasteButton = styled(BaseButton)`
  color: ${THEME.COLORS.PRIMARY_DARK};
  background-color: unset;
`;

export const ClearIcon = styled(ASSETS.ARROW_LOOP)`
  width: 14px;
  height: 16px;
`;

export const PasteIcon = styled(ASSETS.COPY_TEXT)`
  width: 13px;
  height: 16px;
`;
