export const PERMISSIONS = {
  position: {
    viewAll: 'app:position:*:association-type:*:/view',
    viewAssociated: 'app:position:[ASSOCIATED]:association-type:*:/view',
    editAll: 'app:position:*:association-type:*:/edit',
    editAssociated: 'app:position:[ASSOCIATED]:association-type:*:/edit',
    assignAll: 'app:user:*:position:*:association-type:*:/assign',
    assignAssociated: 'app:user:*:position:[ASSOCIATED]:association-type:*:/assign',
    unassignAll: 'app:user:*:position:*:association-type:*:/un-assign',
    unassignAssociated: 'app:user:*:position:[ASSOCIATED]:association-type:*:/un-assign',
    createAll: 'app:position:*:/create',
  },
  user: {
    inviteAll: 'app:user:*:role:*:/invite',
    inviteCollaborator: 'app:user:*:role:[COLLABORATOR]:/invite',
    editAll: 'app:user:*:role:*:/edit',
    deleteAll: 'app:user:*:role:*:/delete',
  },
  billing: {
    view: 'app:billing/view',
    edit: 'app:billing/edit',
    upgrade: 'app:billing/upgrade',
  },
};
