import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import OutreachContentPreferencesSection
  from '../../../pages/outreach-content-preferences/outreach-content-preferences-section/outreach-content-preferences-section.component';
import NumberInputItem from '../input-items/number-input-item/number-input-item.component';
import {
  OutreachContentPreferencesFormikKeys,
} from '../../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import SwitchInputItem from '../input-items/switch-input-item/switch-input-item.component';
import {
  EmailSenderIntegrationStatusEnum,
  useGetEmailSenderIntegrationStatus,
} from '../../../../../store/rtk-query/apis/outreach/hooks/get-email-sender.query-hook';
import { MoreOptionsProps } from './more-options';
import {
  Container,
  IncludeEmailSignatureWrapper,
  TrackEmailOpensWrapper,
} from './more-options.styled';
import TrackEmailOpensBanner from './track-email-opens-banner/track-email-opens-banner.component';
import { SignatureEditor } from './signature-editor/signature-editor.component';

const MoreOptions = ({
  includeEmailSignature,
  messageCharactersLength,
  includeEmailTracking,
  emailSignature,
  onChangeHandler,
}: MoreOptionsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const emailSenderStatus = useGetEmailSenderIntegrationStatus();
  const isEmailSenderConnected = emailSenderStatus === EmailSenderIntegrationStatusEnum.Active;

  const onMessageCharactersLengthChange = (updatedLength: number) => {
    let value: number | undefined = updatedLength;

    if (updatedLength === 0) {
      value = undefined;
    }

    onChangeHandler(OutreachContentPreferencesFormikKeys.MessageCharactersLength, value);
  };

  const onIncludeEmailSignatureToggleChange = (value: boolean) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.IncludeEmailSignature, value);
  };

  const onEmailSignatureChange = (value: string) => {
    onChangeHandler(OutreachContentPreferencesFormikKeys.EmailSignature, value);
  };

  return (
    <>
      <OutreachContentPreferencesSection
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.TITLE) }
      >
        <Container>
          <NumberInputItem
            title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.TITLE) }
            description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.DESCRIPTION) }
            placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.PLACEHOLDER) }
            inputLabel={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.INPUT_LABEL) }
            value={ messageCharactersLength }
            min={ 0 }
            max={ 500 }
            changeHandler={ onMessageCharactersLengthChange }
          />
          <IncludeEmailSignatureWrapper>
            <SwitchInputItem
              title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.TITLE) }
              description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.DESCRIPTION) }
              value={ includeEmailSignature }
              changeHandler={ onIncludeEmailSignatureToggleChange }
              disabled={ !isEmailSenderConnected }
            />
            {
              (includeEmailSignature && isEmailSenderConnected) && (
                <SignatureEditor
                  changeHandler={ onEmailSignatureChange }
                  emailSignature={ emailSignature }
                />
              )
            }
          </IncludeEmailSignatureWrapper>
          <TrackEmailOpensWrapper>
            <SwitchInputItem
              title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.TITLE) }
              description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.DESCRIPTION) }
              value={ includeEmailTracking }
              changeHandler={ (value) => onChangeHandler(OutreachContentPreferencesFormikKeys.IncludeEmailTracking, value) }
            />
            <TrackEmailOpensBanner />
          </TrackEmailOpensWrapper>
        </Container>
      </OutreachContentPreferencesSection>
    </>
  );
};

export default MoreOptions;
