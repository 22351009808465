import { AllowedCompany } from '../../../../store/rtk-query/apis/onboarding/dto/response/get-allowed-companies.response';
import { ASSETS } from '../../../../assets';

export const ROLES = [
  { value: 'RECRUITER_FOR_OWN_COMPANY', label: 'Recruiting for my company ' },
  {
    value: 'RECRUITER_FOR_OTHER_COMPANIES',
    label: 'Recruiter for other companies',
  },
  { value: 'OTHER', label: 'Other' },
];

export const CUSTOM_COMPANY: AllowedCompany = {
  id: 'Other',
  name: 'Other',
  linkedInUrl: 'https://linkedin.com/company/goperfect-labs',
  sanitizedLinkedInUrl: 'linkedin.com/company/goperfect-labs',
  logoUrl: ASSETS.EXPERIENCE_FALLBACK,
};
