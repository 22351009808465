import styled, { css, keyframes } from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Body1Font, Subtitle1Font, Title1Font, Title2Font,
} from '../../../../themes/fonts';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 40px;
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

export const Title = styled.h1`
  ${Title1Font};
  color: ${THEME.COLORS.CONTENT_A};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    ${Title2Font};
  }
`;

export const Subtitle = styled.h2`
  ${Title1Font};
  font-weight: 700;
  color: ${THEME.COLORS.CONTENT_A};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    ${Title2Font};
    font-weight: 700;
  }
`;

export const Description = styled.span`
  ${Body1Font};
  line-height: 24px;
  opacity: 0.6;
  font-weight: 300;
  color: ${THEME.COLORS.CONTENT_A};
  max-width: 400px;
  text-align: center;
`;

export const EnterCodeInstruction = styled.span`
  ${Body1Font};
  line-height: 24px;
  font-weight: 700;
  color: ${THEME.COLORS.CONTENT_A};
  max-width: 400px;
  text-align: center;
`;

const shakeHorizontal = keyframes`
  0%,
  100% { 
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  } 
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
`;

export const VerificationRectContainer = styled.div<{
  animationToggle: boolean;
}>`
  animation: ${({ animationToggle }) => animationToggle &&
    css`
      ${shakeHorizontal} 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
      border: 2px solid ${THEME.COLORS.ALERT};
    `};
`;

export const HelperTextWrapper = styled.div`
  text-align: center;
`;

export const ErrorText = styled.div`
  ${Body1Font};
  font-weight: 500;
  color: ${THEME.COLORS.ALERT};
`;

export const HumanHelpTextWrapper = styled.div`
  display: block;
  margin-top: ${THEME.SPACING.MICRO};
`;

export const ResendCode = styled.a`
  ${Subtitle1Font};
  text-decoration: underline;
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
  display: inline-block;
`;

export const LinkToMail = styled.a`
  ${Subtitle1Font};
  text-decoration: underline;
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
  display: inline-block;
`;

export const NoLuckText = styled.div`
  ${Subtitle1Font};
  margin-right: ${THEME.SPACING.TINO};
  opacity: ${THEME.OPACITY.MED};
  display: inline-block;
`;

export const SendingCodeText = styled.div`
  ${Subtitle1Font};
  line-height: 1.5;
  margin-top: ${THEME.SPACING.XLOOSE};
  margin-right: ${THEME.SPACING.TINY};
  white-space: pre-line;
`;

export const VerificationDigitInput = styled.input.attrs({
  maxLength: 1,
})`
  ${Title2Font};
  line-height: 1.38;
  font-weight: bold;
  color: ${THEME.COLORS.ACCENT};
  width: 64px;
  height: 64px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border: 1px solid ${THEME.COLORS_RGB.PRIMARY_GHOST};
  margin-right: ${THEME.SPACING.TINY};
  caret-color: ${THEME.COLORS.ACCENT};
  text-align: center;

  :focus {
    outline: none;
  }

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_SMALL}px) {
    width: 44px;
    height: 44px;
  }
`;

export const LoaderWrapper = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Loader = styled.div`
  @keyframes rotateKeyframe {
    100% {
      transform: rotate(1turn);
    }
  }

  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, ${THEME.COLORS.ACCENT} 94%, #0000)
      top/8px 8px no-repeat,
    conic-gradient(#0000 30%, ${THEME.COLORS.ACCENT});
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: rotateKeyframe 1s infinite linear;
`;

export const PerfectButton = styled(BaseButton)`
  opacity: 0.6;
`;
