import React from 'react';
import { CustomSnackBar } from '..';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';

type ErrorSnackBarProps = {
  title?: string,
  description?: string,
  actionTitle?: string,
  shouldAutoDismiss?: boolean,
  onStartClosing?: () => void,
  onActionClick?: () => void,
  onClose?: () => void,
  isOpen?: boolean,
  anchor?:AnchorDirections,
}

export const ErrorSnackBar = ({
  title,
  description,
  actionTitle,
  shouldAutoDismiss,
  onStartClosing,
  onActionClick,
  isOpen,
  anchor,
  onClose,
}: ErrorSnackBarProps):JSX.Element => {
  return (
    <CustomSnackBar
      isOpen={ isOpen }
      anchor={ anchor }
      icon={ ASSETS.ERROR_RECTANGLE }
      iconColor={ THEME.COLORS.ALERT }
      title={ title }
      titleColor={ THEME.COLORS.ALERT }
      description={ description }
      actionTitle={ actionTitle }
      actionColor={ THEME.COLORS.ACCENT }
      onActionClick={ onActionClick }
      onStartClosing={ onStartClosing }
      shouldAutoDismiss={ shouldAutoDismiss }
      onClose={ onClose }
    />
  );
};

ErrorSnackBar.defaultProps = {
  onActionClick: undefined,
  onClose: undefined,
  description: '',
  title: '',
  actionTitle: '',
  shouldAutoDismiss: false,
  isOpen: false,
  anchor: 'top-center',
  onStartClosing: undefined,
};
