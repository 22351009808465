import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import STRING_KEYS from '../../../../language/keys';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { PricingPlan } from '../../../../enums/user-plan.enum';
import {
  BuyMoreCreditsDialog,
} from '../../../../components/dialogs/buy-more-credits-dialog/buy-more-credits-dialog.component';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../consts/analytics';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { UpgradePlanDialog } from '../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { CONFIG } from '../../../../config';
import {
  ButtonGroup,
  Container,
  Content,
  Description,
  GetMoreCreditsButton,
  LockIcon,
  MessageIcon,
  PlusIcon,
  TalkToSales,
  Title,
} from './matches-credits-paywall.styled';
import { MatchesCreditsPaywallProps } from './matches-credits-paywall';

export const MatchesCreditsPaywall = ({ accountPlan }: MatchesCreditsPaywallProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isBuyMoreCreditsDialogOpen, setIsBuyMoreCreditsDialogOpen] = useState(false);
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);
  const { analyticsTrackEvent } = useAnalyticsContext();

  const onBuyMoreCreditsDialogClose = () => {
    setIsBuyMoreCreditsDialogOpen(false);
  };

  const onUpgradePlanDialogClose = () => {
    setIsUpgradePlanDialogOpen(false);
  };

  const onGetMoreCreditsClick = () => {
    if (accountPlan.pricingPlan === PricingPlan.Trial) {
      setIsBuyMoreCreditsDialogOpen(false);
      setIsUpgradePlanDialogOpen(true);
    } else {
      setIsUpgradePlanDialogOpen(false);
      setIsBuyMoreCreditsDialogOpen(true);
    }

    analyticsTrackEvent({
      eventName: ANALYTICS_EVENTS.BUY_MORE_CREDITS_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.POSITION_PAGE,
    });
  };

  const onTalkToSalesClick = () => {
    const eventData = {
      eventName: ANALYTICS_EVENTS.UPGRADE_BUTTON_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.HOMEPAGE,
    };
    analyticsTrackEvent(eventData);

    window.open(CONFIG.CONTACT_SALES_URL, '_blank');
  };
  
  return (
    <>
      <Container>
        <LockIcon />
        <Content>
          <Title>{ translate(STRING_KEYS.MATCHES_PAGE.CREDITS_PAYWALL.TITLE) }</Title>
          <Description> { translate(STRING_KEYS.MATCHES_PAGE.CREDITS_PAYWALL.DESCRIPTION) } </Description>
          <ButtonGroup>
            <GetMoreCreditsButton
              StartIcon={ PlusIcon }
              onClick={ onGetMoreCreditsClick }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
            >
              { translate(STRING_KEYS.MATCHES_PAGE.CREDITS_PAYWALL.GET_MORE_BUTTON) }
            </GetMoreCreditsButton>
            <TalkToSales
              StartIcon={ MessageIcon }
              onClick={ onTalkToSalesClick }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              variant={ PerfectButtonVariants.Ghost }
            >
              { translate(STRING_KEYS.MATCHES_PAGE.CREDITS_PAYWALL.TALK_TO_SALES) }
            </TalkToSales>
          </ButtonGroup>
        </Content>
      </Container>
      <BuyMoreCreditsDialog
        isOpen={ isBuyMoreCreditsDialogOpen }
        onClose={ onBuyMoreCreditsDialogClose }
      />
      <UpgradePlanDialog
        isDialogOpen={ isUpgradePlanDialogOpen }
        onCloseDialog={ onUpgradePlanDialogClose }
      />
    </>
  );
};
