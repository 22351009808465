import { ConversationMessage } from '../dto/response/conversation-message';
import { ConversationMessageType } from '../../../../../enums/conversation-message-type.enum';

export const getConversationByPositionIdResponseTransformer = (response: PaginationResponse<ConversationMessage[]>): PaginationResponse<ConversationMessage[]> => {
  const updatedConversation = {
    ...response,
    results: response.results.map((message) => ({
      ...message,
      isTyping: false,
      suggestionsDisabled: true, // Set default value
    })),
  };

  if (updatedConversation.results[response.results.length - 1] &&
    updatedConversation.results[response.results.length - 1].messageType !== ConversationMessageType.UserMessage) {
    updatedConversation.results[response.results.length - 1].suggestionsDisabled = false;
  }

  return updatedConversation;
};
