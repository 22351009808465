import { LogLevelNumbers, RootLogger } from 'loglevel';
import LogRocket from 'logrocket';
import TrackingIdManager from '../../services/trackingIdManager';
import AnalyticsIdManager from '../../services/analyticsIdManager';
import UserLoggingInfoManger from '../../services/userLoggingInfoManger';
import { ApiError } from '../../consts/api';
import { LogMessage } from '../models/log-message.interface';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isError = (e) => e && e?.stack && e?.message && typeof e?.stack === 'string' &&
    typeof e?.message === 'string';

const messageFormatterMiddleware = (logger: RootLogger): void => {
  const originalFactory = logger.methodFactory;
  logger.methodFactory =
    (methodName: string, logLevel: LogLevelNumbers, loggerName: string | symbol) => {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);

      return (...message: unknown[]) => {
        let error: unknown;
        let additionalInfo = {};
        const messageParts = message.filter((item: unknown) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (item instanceof Error || isError(item) || item?.isAxiosError) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (item.isApiError) {
              const apiError = item as ApiError;
              const { serverError, errorMetadata } = apiError.error;
              // remote logger formatter removes any nested object so we are flattening it to the "root" level
              error = {
                ...errorMetadata,
                ...serverError,
              };
            } else {
              error = item;
            }
            // since it is our "dedicated" error we remove from the "array"

            return false;
          }
          if (typeof item === 'object' && !Array.isArray(item)) {
            additionalInfo = {
              ...additionalInfo,
              ...item,
            };

            return false;
          }

          return true;
        });

        const logMessage: LogMessage = {
          msg: messageParts.join(' '),
          context: String(loggerName),
          trackingId: TrackingIdManager.getId(),
          level: methodName,
          sessionId: AnalyticsIdManager.getId(),
          userData: UserLoggingInfoManger.getUserInfoLoggingData(),
          additionalInfo,
          error,
        };

        if (methodName === 'error') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const fsSessionUrl: string = window.FS?.getCurrentSessionURL?.();
          if (fsSessionUrl) {
            logMessage.fullStory = {
              fsSessionUrl,
            };
          }

          if (LogRocket.sessionURL) {
            logMessage.logRocket = { logRocketUrl: LogRocket.sessionURL };
          }
        }
        rawMethod(JSON.stringify(logMessage));
      };
    };
  logger.setLevel(logger.getLevel());
};

export default messageFormatterMiddleware;
