import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { AccountPlanProgressBar } from '../account-plan-progress-bar/account-plan-progress-bar.component';
import { useGetOpenPositionsQuery } from '../../../../../store/rtk-query/apis/position/position.toolkit-api';
import { useGetMyUserQuery } from '../../../../../store/rtk-query/apis/user/user.toolkit-api';
import { useGetAccountByIdQuery } from '../../../../../store/rtk-query/apis/account/account.toolkit-api';

export const ActivePositionsProgressBar = ({
  onUpgrade,
}: ActivePositionsProgressBarProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const { data: openPositions = [] } = useGetOpenPositionsQuery();
  const { data: { accountId } = {} } = useGetMyUserQuery();
  const { data: { plan: { maximumOpenPositions = 0 } } = {} } = useGetAccountByIdQuery(accountId, { skip: !accountId });

  return (
    <AccountPlanProgressBar
      label={ translate(STRING_KEYS.HOMEPAGE.ACCOUNT_PLAN_PROGRESS_BAR.ACTIVE_POSITIONS.LABEL) }
      usedUnits={ openPositions.length }
      maxUnits={ maximumOpenPositions }
      upgradeLabel={ translate(STRING_KEYS.UPGRADE) }
      onUpgrade={ onUpgrade }
    />
  );
};
