import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    position: fixed;
    overflow: hidden;
      height: 100%;
      width: 100%;
      
      #root {
          height: 100%;
          width: 100%;
      }
  }
`;
