import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { CaptionFont, Subtitle2Font } from '../../../../../../themes/fonts';
import { TextEllipsis } from '../../../../../../themes/text';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${TextEllipsis};
  ${Subtitle2Font};
  font-weight: bold;
  max-width: 330px;
`;

export const Description = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.MED};
  display: inline-block;
  vertical-align: middle;
`;

export const InvitePendingWrapper = styled.div`
  display: block;
`;

export const UnreadEmailIcon = styled.div`
  margin-right: ${THEME.SPACING.MICRO};
  display: inline-block;
  vertical-align: middle;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;
