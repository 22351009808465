import { Trans, useTranslation } from 'react-i18next';
import Tooltip from '../../../../components/tooltip/tooltip.component';
import STRING_KEYS from '../../../../language/keys';
import { CONFIG } from '../../../../config';
import {
  Container, LearnMoreLink, Text, TextWrapper, Title,
} from './scheduled-message-tooltip.styled';

export const ScheduledMessageTooltip = ({ children }: ScheduledMessageTooltipProps) : JSX.Element => {
  const { t: translate } = useTranslation();
  
  return (
    <Tooltip
      customTooltipStyle={ {
        maxWidth: '600px',
      } }
      showTooltipDelay={ 500 }
      content={ (
        <Container>
          <TextWrapper>
            <Title>
              {translate(STRING_KEYS.SCHEDULED_MESSAGE_TOOLTIP.TITLE)}
            </Title>
            <Text>
              <Trans
                i18nKey={ STRING_KEYS.SCHEDULED_MESSAGE_TOOLTIP.DESCRIPTION }
              >
                <LearnMoreLink href={ CONFIG.SEND_GUARD_LINK } target="_blank" />
              </Trans>
            </Text>
          </TextWrapper>
        </Container>
      ) }
    >
      {children}
    </Tooltip>
  );
};
