import { useAuth } from '@frontegg/react';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetMyUserQuery } from '../store/rtk-query/apis/user/user.toolkit-api';
import { useCurrentSubDomain } from '../hooks/use-current-sub-domain';
import { useGetAccountByIdQuery } from '../store/rtk-query/apis/account/account.toolkit-api';
import { addSubDomainToUrl, replaceSubDomain } from '../utils/url';
import { SplashScreen } from '../components/Common/Loaders/SplashScreen/SplashScreen';

/**
 *  This guard ensures that users who are authenticated and are not in onboarding are redirected to the correct subdomain of their account.
 */
export const AccountSubDomainGuard = ({ children }:PropsWithChildren<unknown>):JSX.Element => {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();

  const { search: queryParams } = useLocation();

  const { currentSubDomainName } = useCurrentSubDomain();

  const { data: { accountId, isOnboarding: isUserInOnboarding } = {}, isLoading: isLoadingUser } = useGetMyUserQuery(undefined, { skip: !isAuthenticated });
  const { data: { subDomainName } = {}, isLoading: isAccountLoading } = useGetAccountByIdQuery(accountId, { skip: !accountId });

  const userSubDomain = subDomainName?.toLowerCase();

  if (isAuthenticated && !isUserInOnboarding && userSubDomain && userSubDomain !== currentSubDomainName) {
    const { location } = window;

    const newUrl = !currentSubDomainName ?
      addSubDomainToUrl(location.origin, location.host, userSubDomain) :
      replaceSubDomain(location.origin, currentSubDomainName, userSubDomain);

    const urlWithQueryParams = newUrl + queryParams;

    window.location.replace(urlWithQueryParams);

    return null;
  }

  if (isLoadingAuth || isLoadingUser || isAccountLoading) {
    return <SplashScreen title="Account Sub-domain Guard" />;
  }

  return <>{children}</>;
};
