import React, { useEffect } from 'react';
import { ANALYTICS_EVENTS, EVENT_GROUP, EVENT_TYPE } from '../../../../consts/analytics';
import { RudderStackDestination } from '../../../../services/rudderStackAnalyticsAgent';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../create-position/consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { ONBOARDING_ANALYTICS_EVENTS, PAGE_NAMES } from '../../consts/onboarding-analytics.consts';
import { useGetAccountByIdQuery } from '../../../../store/rtk-query/apis/account/account.toolkit-api';
import { useGetMyUserQuery, useGetMyUserQueryState } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import { useGetOpenPositionsQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { replaceSubDomain } from '../../../../utils/url';
import { CONFIG } from '../../../../config';
import {
  CreatePositionTransition,
} from '../../../../components/Common/create-position-transition/create-position-transition.component';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { useGetUserOnboardingQuery } from '../../../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';

export const OnboardingCompletedTransitionPage = (): JSX.Element => {
  const { analyticsPageEvent, analyticsTrackEvent } = useAnalyticsContext();

  const {
    data: { isOnboarding: isUserInOnboardingState, accountId } = {},
  } = useGetMyUserQueryState(undefined);
  useGetMyUserQuery(undefined, {
    pollingInterval: isUserInOnboardingState ? 3000 : undefined,
  });

  const { data: positions } = useGetOpenPositionsQuery(undefined, {
    skip: isUserInOnboardingState,
  });

  const { data: onboarding } = useGetUserOnboardingQuery();

  const { data: { subDomainName = null } = {} } = useGetAccountByIdQuery(
    accountId as string,
    {
      skip: isUserInOnboardingState || !accountId,
      pollingInterval: 3000,
    },
  );

  const isReadyToGoToPosition =
    !isUserInOnboardingState && subDomainName && positions?.length;

  const handlePositionReady = () => {
    const redirectUrl = replaceSubDomain(
      window.location.origin,
      CONFIG.ONBOARDING_SUBDOMAIN,
      subDomainName,
    );

    window.location.replace(`${redirectUrl}${AppRouting.buildMatchesPageUrl(positions[0].id)}`);
  };

  useEffect(() => {
    if (accountId) {
      analyticsPageEvent({
        eventName:
          CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_FINISH_PAGE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: PAGE_NAMES.FINISHED_ONBOARDING,
      });

      const intercomTrackEventPayload = {
        eventName: ANALYTICS_EVENTS.INTERCOM_TRIGGER_ONBOARDING_COMPLETED,
        event_type: EVENT_TYPE.INTERCOM_TRIGGER,
        pageName: PAGE_NAMES.FINISHED_ONBOARDING,
      };
      // send only to the INTERCOM integration
      analyticsTrackEvent(intercomTrackEventPayload, [
        RudderStackDestination.INTERCOM,
      ]);

      const trackEventPayload = {
        eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_COMPLETED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: PAGE_NAMES.FINISHED_ONBOARDING,
      };

      analyticsTrackEvent(trackEventPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <CreatePositionTransition
      onTransitionComplete={ handlePositionReady }
      isLoading={ !isReadyToGoToPosition }
      userName={ onboarding?.user?.name?.split(' ')?.[0] ?? '' }
    />
  );
};
