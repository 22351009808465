import { createSlice } from '@reduxjs/toolkit';
import { accountQuery } from '../../rtk-query/apis/account/account.toolkit-api';
import { positionQuery } from '../../rtk-query/apis/position/position.toolkit-api';
import { initialAppState } from './app-state.initial-state';
import { logoutReducer } from './reducers/logout.reducers';
import { setReferrerAndViewIdReducer } from './reducers/referrer-and-view-id.reducers';
import {
  removeInitialLoadingReducer,
  setFronteggLoadingCompletedReducer,
  setPositionsLoadingCompletedReducer,
  setRudderstackLoadingCompletedReducer,
} from './reducers/loadings.reducers';
import {
  getAccountByIdFulfilledExtraReducer,
  getAccountByIdRejectExtraReducer,
} from './extra-reducers/accounts.reducers';
import { getPositionsFulfilledExtraReducer, getPositionsRejectExtraReducer } from './extra-reducers/positions.reducers';
import { setUserIdentifyMethodReducer, setUserJustSignedUpReducer } from './reducers/set-user-identify-method.reducers';
import { setIsOnboardingLogoutReducer } from './reducers/set-onboarding-logout.reducers';
import {
  socketOutreachConnectedReducer,
  socketOutreachDisconnectedReducer,
  socketRecruiterAgentConnectedReducer,
  socketRecruiterAgentDisconnectedReducer,
  socketTalentAcquisitionConnectedReducer,
  socketTalentAcquisitionDisconnectedReducer,
  socketUserConnectedReducer,
  socketUserDisconnectedReducer,
} from './reducers/socket.reducers';
import { deleteSnackbarReducer, setSnackbarReducer } from './reducers/snackbar.reducers';
import { setLatestAppVersionReducer } from './reducers/app-version.reducers';
import { disableFlashingFaviconReducer, enableFlashingFaviconReducer } from './reducers/favicon.reducers';
import { disableIsUnsavedChangesReducer, enableIsUnsavedChangesReducer } from './reducers/unsaved-changes.reducers';
import { setUserRoleWasChangedReducer } from './reducers/user-role.reducers';

const AppStateSlice = createSlice({
  name: 'app-state',
  initialState: initialAppState,
  reducers: {
    logout: logoutReducer,
    setReferrerAndViewId: setReferrerAndViewIdReducer,
    removeInitialLoadings: removeInitialLoadingReducer,
    setRudderstackLoadingCompleted: setRudderstackLoadingCompletedReducer,
    setFronteggLoadingCompleted: setFronteggLoadingCompletedReducer,
    setPositionsLoadingCompleted: setPositionsLoadingCompletedReducer,
    setUserIdentifyMethod: setUserIdentifyMethodReducer,
    setUserJustSignedUp: setUserJustSignedUpReducer,
    setIsOnboardingLogout: setIsOnboardingLogoutReducer,
    socketTalentAcquisitionConnected: socketTalentAcquisitionConnectedReducer,
    socketTalentAcquisitionDisconnected: socketTalentAcquisitionDisconnectedReducer,
    socketOutreachConnected: socketOutreachConnectedReducer,
    socketOutreachDisconnected: socketOutreachDisconnectedReducer,
    socketUserConnected: socketUserConnectedReducer,
    socketUserDisconnected: socketUserDisconnectedReducer,
    socketRecruiterAgentConnected: socketRecruiterAgentConnectedReducer,
    socketRecruiterAgentDisconnected: socketRecruiterAgentDisconnectedReducer,
    setSnackbar: setSnackbarReducer,
    deleteSnackbar: deleteSnackbarReducer,
    setLatestAppVersion: setLatestAppVersionReducer,
    enableFlashingFavicon: enableFlashingFaviconReducer,
    disableFlashingFavicon: disableFlashingFaviconReducer,
    enableIsUnsavedChanges: enableIsUnsavedChangesReducer,
    disableIsUnsavedChanges: disableIsUnsavedChangesReducer,
    setUserRoleWasChanged: setUserRoleWasChangedReducer,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(accountQuery.endpoints.getAccountById.matchRejected, getAccountByIdRejectExtraReducer)
      .addMatcher(accountQuery.endpoints.getAccountById.matchFulfilled, getAccountByIdFulfilledExtraReducer)
      .addMatcher(positionQuery.endpoints.getOpenPositions.matchFulfilled, getPositionsFulfilledExtraReducer)
      .addMatcher(positionQuery.endpoints.getOpenPositions.matchRejected, getPositionsRejectExtraReducer);
  },
});

export const {
  setUserRoleWasChanged,
  setLatestAppVersion,
  socketTalentAcquisitionConnected,
  socketTalentAcquisitionDisconnected,
  socketOutreachConnected,
  socketOutreachDisconnected,
  socketUserConnected,
  socketUserDisconnected,
  socketRecruiterAgentConnected,
  socketRecruiterAgentDisconnected,
  setIsOnboardingLogout,
  logout,
  setReferrerAndViewId,
  setRudderstackLoadingCompleted,
  setFronteggLoadingCompleted,
  removeInitialLoadings,
  setPositionsLoadingCompleted,
  setUserIdentifyMethod,
  setUserJustSignedUp,
  setSnackbar,
  deleteSnackbar,
  enableFlashingFavicon,
  disableFlashingFavicon,
  enableIsUnsavedChanges,
  disableIsUnsavedChanges,
} = AppStateSlice.actions;

export default AppStateSlice.reducer;
