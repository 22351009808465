import { useTranslation } from 'react-i18next';
import { PRIVACY_CLASS } from '../../../../consts/fullstory';
import { getInitials, joinStrings } from '../../../../utils';
import { WithSkeletonLoader } from '../../../HOC/WithSkeletonLoader';
import { InitialsAvatar } from '../../../Common/InitialsAvatar';
import AtsButton from '../../ats-button/ats-button.component';
import { THEME } from '../../../../themes';
import { MATCH_STATUSES } from '../../../../consts';
import STRING_KEYS from '../../../../language/keys';
import { SmartBadge } from '../../../../@types/smartBadges';
import { ExactLocationDto } from '../../../../models/dto/location.dto';
import {
  AvatarContainer,
  Container,
  InfoContainer,
  InfoRow,
  MetaDataRow,
  TalentName,
  StyledAvatar,
  ContentWrapper,
  PageContainer,
  AvatarBadge,
  ToReviewIcon,
  EngagedIcon,
  DeclinedIcon,
  TalentLocation,
  TalentInfoWrapper,
  LocationPinIcon,
} from './talent-bio.styled';
import { BadgesList } from './badges-list/badges-list.component';
import { TalentInfo } from './talent-info/talent-info.component';

type TalentBioProps = {
  matchId: string,
  positionId: string,
  profileImage?: string,
  firstName: string,
  lastName?: string,
  engagementStatus: string,
  currentJobTitle: string,
  location: ExactLocationDto,
  isLoading: boolean,
  currentCompanyName?: string,
  currentJobExperienceInMonths?: number,
  smartBadges: SmartBadge[],
  matchSummary?: string;
  totalYearsOfExperience: number,
  matchViewed: boolean;
  previousCompanyNames: string[];
  trackMatchElementViewed: (eventName: string, componentElementType: string, componentMetadata?: string) => void;
}

const colorMap = {
  [MATCH_STATUSES.TO_REVIEW]: { backgroundColor: THEME.COLORS.PRIMARY, textColor: THEME.COLORS.PURE_WHITE },
  [MATCH_STATUSES.ENGAGED]: { backgroundColor: THEME.COLORS.ACCENT, textColor: THEME.COLORS.PURE_WHITE },
  [MATCH_STATUSES.DECLINED]: { backgroundColor: THEME.COLORS.GREY, textColor: THEME.COLORS.PRIMARY_DARK },
};

export const TalentBio = ({
  positionId,
  matchId,
  firstName,
  lastName,
  profileImage,
  engagementStatus,
  isLoading,
  currentJobTitle,
  location,
  currentCompanyName,
  currentJobExperienceInMonths,
  smartBadges,
  matchSummary,
  totalYearsOfExperience,
  matchViewed,
  previousCompanyNames,
  trackMatchElementViewed,
}: TalentBioProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const withLoader = (
    content: JSX.Element | string | undefined,
    minWidth?: number | string | null,
    type?: 'circle' | 'line',
  ) => {
    return WithSkeletonLoader(isLoading, content, minWidth, type);
  };

  const renderAvatarBadge = () => {
    let translationKey: string;
    let icon;

    switch (engagementStatus) {
      case MATCH_STATUSES.TO_REVIEW: {
        translationKey = STRING_KEYS.TO_REVIEW;
        icon = <ToReviewIcon />;
        break;
      }
      case MATCH_STATUSES.ENGAGED: {
        translationKey = STRING_KEYS.ENGAGED;
        icon = <EngagedIcon />;
        break;
      }
      case MATCH_STATUSES.DECLINED: {
        translationKey = STRING_KEYS.DECLINED;
        icon = <DeclinedIcon />;
        break;
      }
      default:
        return null;
    }

    return (
      <AvatarBadge
        backgroundColor={ colorMap[engagementStatus]?.backgroundColor }
        textColor={ colorMap[engagementStatus]?.textColor }
      >
        { icon }
        { translate(translationKey) }
      </AvatarBadge>
    );
  };

  const renderTalentLocation = () => {
    if (!location) {
      return null;
    }

    const locationString = location.city ? joinStrings(location.city, location.state || location.country) : joinStrings(location.state, location.country);

    return (
      <TalentLocation>
        <LocationPinIcon />
        <span>{ locationString }</span>
      </TalentLocation>
    );
  };

  const talentFullName = `${firstName} ${lastName}`;

  return (
    <PageContainer>
      <Container>
        {!isLoading && (
          <>
            <ContentWrapper>
              <AvatarContainer>
                {
                  withLoader(
                    <StyledAvatar
                      color={ colorMap[engagementStatus]?.backgroundColor }
                      className={ PRIVACY_CLASS.EXCLUDE }
                      alt={ talentFullName }
                      src={ profileImage }
                      sx={ { width: '100%', height: '100%' } }
                    >
                      <InitialsAvatar
                        alt={ talentFullName }
                        message={ getInitials(talentFullName) }
                        fontSize="38px"
                      />
                    </StyledAvatar>,
                    null,
                    'circle',
                  )
                }
                { renderAvatarBadge() }
              </AvatarContainer>

              <InfoContainer>
                <InfoRow>
                  <TalentInfoWrapper>
                    <TalentName className={ PRIVACY_CLASS.EXCLUDE }>{ talentFullName }</TalentName>
                    { renderTalentLocation() }
                  </TalentInfoWrapper>

                  <AtsButton
                    matchId={ matchId }
                    positionId={ positionId }
                  />
                </InfoRow>
                <MetaDataRow>
                  <div id="match-summary-block">
                    <TalentInfo
                      matchId={ matchId }
                      matchSummary={ matchSummary }
                      firstName={ firstName }
                      currentJobTitle={ currentJobTitle }
                      currentCompanyName={ currentCompanyName }
                      currentJobExperienceInMonths={ currentJobExperienceInMonths }
                      matchViewed={ matchViewed }
                      highlightWords={ previousCompanyNames }
                    />
                  </div>
                </MetaDataRow>

              </InfoContainer>
            </ContentWrapper>
            {!!smartBadges.length && (
              <BadgesList
                matchId={ matchId }
                smartBadges={ smartBadges }
                talentTotalYearsOfExperience={ totalYearsOfExperience }
                trackMatchElementViewed={ trackMatchElementViewed }
              />
            )}
          </>
        )  }
      </Container>
    </PageContainer>
  );
};

TalentBio.defaultProps = {
  profileImage: undefined,
  lastName: undefined,
  currentCompanyName: undefined,
  currentJobExperienceInMonths: undefined,
  matchSummary: undefined,
};
