import { PropsWithChildren, useEffect } from 'react';
import LogRocket from 'logrocket';
import { useAuth } from '@frontegg/react';
import { CONFIG } from '../../config';
import { useGetMyUserQuery } from '../../store/rtk-query/apis/user/user.toolkit-api';
import { useGetAccountByIdQuery } from '../../store/rtk-query/apis/account/account.toolkit-api';

export const LogRocketProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { isAuthenticated } = useAuth();

  const {
    data: {
      id, firstName, lastName, email, accountId,
    } = {},
  } = useGetMyUserQuery(undefined, { skip: !isAuthenticated });
  const {
    data: { plan, subDomainName } = {},
  } = useGetAccountByIdQuery(accountId, { skip: !accountId });

  useEffect(() => {
    if (CONFIG.IS_LOGROCKET_ENABLED) {
      LogRocket.init(CONFIG.LOGROCKET_APP_ID, {
        rootHostname: `.${CONFIG.URL_DOMAIN}`,
      });
    }
  }, []);

  useEffect(() => {
    if (CONFIG.IS_LOGROCKET_ENABLED) {
      LogRocket.identify(id, {
        id,
        name: `${firstName} ${lastName}`,
        email,
        accountId,
        pricingPlan: plan?.pricingPlan,
        subDomainName,
      });
    }
  }, [
    accountId,
    email,
    firstName,
    id,
    lastName,
    plan?.pricingPlan,
    subDomainName,
  ]);

  return <>{children}</>;
};
