import React, { useState } from 'react';
import { useAuthActions } from '@frontegg/react';
import { useTranslation } from 'react-i18next';
import { ISocialLoginProviderConfigurationV2 } from '@frontegg/rest-api';
import log from 'loglevel';
import STRING_KEYS from '../../../../language/keys';
import { isValidEmail } from '../../../../utils';
import { getGoogleSocialLoginUrl, getMicrosoftSocialLoginUrl } from '../../../../utils/frontegg';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { PerfectDivider } from '../../../Common/perfect-divider/perfect-divider.component';
import { PRIVACY_CLASS } from '../../../../consts/fullstory';
import { PerfectSpacer } from '../../../Common/perfect-spacer/perfect-spacer.component';
import { PerfectButtonSize } from '../../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import {
  Container,
  EmailIcon,
  ForgotPassword,
  Form,
  FormContainer,
  LetsGoButtonWrapper,
  PasswordIcon,
  PerfectButton,
  PerfectInput,
  ShowPassword,
  ValidationError,
} from './login-form.styled';
import { SocialLoginOptions } from './social-login/social-login.component';

const logger = log.getLogger('LOGIN_FORM');

type LoginFormProps = {
  errorMessage: string;
};

export const LoginForm = ({ errorMessage }: LoginFormProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  const actions = useAuthActions();

  const toggleIsPasswordShown = () => {
    setIsPasswordShown((prevState) => !prevState);
  };

  const resetErrors = () => {
    setEmailError('');
    setPasswordError('');
  };

  const submitForm = () => {
    resetErrors();

    let hasErrors = false;

    if (!email) {
      setEmailError(
        translate(STRING_KEYS.PLEASE_ENTER_INPUT_VALUE, {
          inputName: translate(STRING_KEYS.EMAIL),
        }),
      );
      hasErrors = true;
    } else if (!isValidEmail(email)) {
      setEmailError(
        translate(STRING_KEYS.PLEASE_ENTER_VALID_VALUE, {
          valueName: translate(STRING_KEYS.EMAIL_ADDRESS),
        }),
      );
      hasErrors = true;
    }

    if (!password) {
      setPasswordError(
        translate(STRING_KEYS.PLEASE_ENTER_INPUT_VALUE, {
          inputName: translate(STRING_KEYS.PASSWORD),
        }),
      );
      hasErrors = true;
    }

    if (!hasErrors) {
      actions.login({ email, password });
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm();
  };

  // todo: check if values of email/password should be passed to their components

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    actions.resetLoginState();
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    actions.resetLoginState();
    setPassword(event.currentTarget.value);
  };

  const handleGoogleLoginClick = (
    configuration: ISocialLoginProviderConfigurationV2,
  ) => {
    const redirectUrl = AppRouting.buildGoogleLoginSuccessUrl(window.location);

    logger.debug('navigating to google login with params', {
      clientId: configuration.clientId,
      redirectUrl,
    });

    window.location.href = getGoogleSocialLoginUrl(
      configuration.clientId as string,
      redirectUrl,
    );
  };

  const handleMicrosoftLoginClick = async (
    configuration: ISocialLoginProviderConfigurationV2,
  ) => {
    const redirectUrl = AppRouting.buildMicrosoftLoginSuccessUrl(
      window.location,
    );

    logger.debug('navigating to microsoft login with params', {
      clientId: configuration.clientId,
      redirectUrl,
    });

    window.location.href = await getMicrosoftSocialLoginUrl(
      configuration.clientId as string,
      redirectUrl,
    );
  };

  return (
    <FormContainer>
      <Form onSubmit={ handleFormSubmit }>
        <Container>
          <SocialLoginOptions
            onGoogleLoginClick={ handleGoogleLoginClick }
            onMicrosoftLoginClick={ handleMicrosoftLoginClick }
          />

          <PerfectSpacer height={ 32 } />

          <PerfectDivider
            label={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_DESCRIPTION) }
          />
        </Container>

        <PerfectSpacer height={ 32 } />

        <PerfectInput
          placeholder={ translate(STRING_KEYS.WORK_EMAIL) }
          value={ email }
          $isError={ !!emailError || !!errorMessage }
          onChange={ handleEmailChange }
          StartAdornment={ <EmailIcon /> }
        />

        {!!emailError?.length && (
          <ValidationError>{emailError}</ValidationError>
        )}

        <PerfectSpacer height={ 8 } />

        <PerfectInput
          type={ isPasswordShown ? 'text' : 'password' }
          className={ PRIVACY_CLASS.EXCLUDE }
          placeholder={ translate(STRING_KEYS.YOUR_PASSWORD) }
          value={ password }
          $isError={ !!passwordError || !!errorMessage }
          onChange={ handlePasswordChange }
          StartAdornment={ <PasswordIcon /> }
          EndAdornment={ (
            <ShowPassword onClick={ toggleIsPasswordShown }>
              {isPasswordShown ?
                translate(STRING_KEYS.HIDE) :
                translate(STRING_KEYS.SHOW)}
            </ShowPassword>
          ) }
        />

        {!!passwordError?.length && (
          <ValidationError>{passwordError}</ValidationError>
        )}

        {errorMessage && <ValidationError>{errorMessage}</ValidationError>}

        <PerfectSpacer height={ 8 } />

        <ForgotPassword
          onClick={ () => AppRouting.navigateToUrl(AppRouting.buildForgotPasswordPagePath()) }
        >
          {translate(STRING_KEYS.FORGOT_PASSWORD)}
        </ForgotPassword>

        <PerfectSpacer height={ 16 } />

        <LetsGoButtonWrapper isLoading={ false }>
          <PerfectButton type="submit" size={ PerfectButtonSize.ExtraLarge }>
            {translate(STRING_KEYS.LETS_GO)}
          </PerfectButton>
        </LetsGoButtonWrapper>
      </Form>
    </FormContainer>
  );
};
