import { useMemo } from 'react';
import { isNil } from 'lodash';
import { positionQuery } from '../position.toolkit-api';
import { useAppSelector } from '../../../../selectors';
import { MATCH_STATUSES } from '../../../../../consts';
import { outreachQuery } from '../../outreach/outreach.toolkit-api';
import { ConversationMinimizedResponseDto } from '../../outreach/dto/response/conversation-minimized.response';
import { MessageDirectionEnum } from '../../outreach/enums/message-direction.enum';
import { PositionRunStatusEnum } from '../../../../../enums/position-run-status.enum';
import { PositionResponse } from '../dto/response/position.response';

export const positionSelector = positionQuery.endpoints.getPositionById.select;
export const conversationsSelector = outreachQuery.endpoints.getConversationsByPositionId.select;

type UseGetPositionStatisticsQuerySelector = {
  toReviewMatches: number,
  isDailyLimit: boolean,
  emptyState: boolean,
  matchingProgress: boolean,
  lastRunFailed: boolean,
  repliedConversationsCount: number,
  noResults?: boolean,
  positionId?: string,
}

const getDefaultPositionStatistics = () => ({
  toReviewMatches: 0,
  isDailyLimit: false,
  matchingProgress: false,
  lastRunFailed: false,
  noResults: false,
  emptyState: false,
});

export const useGetPositionSelector = (positionId: string): AppSelectorType<PositionResponse> => {
  const {
    data: position, isLoading, isError, isSuccess, error,
  } = useAppSelector(positionSelector(positionId));

  return {
    data: position || {} as PositionResponse,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useGetPositionStatisticsQuerySelector = (positionId: string): AppSelectorType<UseGetPositionStatisticsQuerySelector> => {
  const {
    data: position, isLoading, isError, isSuccess, error,
  } = useAppSelector(positionSelector(positionId));

  const {
    data,
  } = useAppSelector(conversationsSelector({ positionId }));

  const filteredConversations = useMemo<ConversationMinimizedResponseDto[] | undefined>(() => {
    return data?.conversations?.filter(({ positionId: currentPositionId }) => currentPositionId === positionId);
  }, [data?.conversations, positionId]);

  const repliedConversationsCount = useMemo(() => {
    return filteredConversations?.reduce((acc, conversation) => {
      if (conversation.latestMessage && conversation.latestMessage.messageDirection === MessageDirectionEnum.Inbound && !conversation.latestMessage.seen) {
        acc++;
      }

      return acc;
    }, 0) || 0;
  }, [filteredConversations]);

  const positionStatistics = useMemo(() => {
    const statistics = getDefaultPositionStatistics();

    if (!position) {
      return statistics;
    }

    statistics.toReviewMatches = position.statistics?.engagementStatusStatistics?.find(({ name }) => name === MATCH_STATUSES.TO_REVIEW)?.amount || 0;

    if (isNil(position.latestRun?.status)) {
      statistics.emptyState = true;
    }

    if (!statistics.toReviewMatches && position.hasReachedMaxDailyMatches) {
      statistics.isDailyLimit = true;
    }

    if ((!statistics.toReviewMatches && position.latestRun?.status === PositionRunStatusEnum.Completed) || !position.latestRun) {
      statistics.matchingProgress = true;
    }

    if (position.latestRun?.status === PositionRunStatusEnum.InProgress) {
      statistics.matchingProgress = true;
    }

    if (
      !statistics.toReviewMatches &&
      !position.hasReachedMaxDailyMatches &&
      (position.latestRun?.status === PositionRunStatusEnum.Incomplete || position.latestRun?.status === PositionRunStatusEnum.Failed)
    ) {
      statistics.lastRunFailed = true;
      statistics.noResults = position.latestRun?.noResults;
    }

    return statistics;
  }, [position]);

  return {
    data: {
      ...positionStatistics,
      repliedConversationsCount,
      positionId: position?.id,
    },
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
