export enum SubDomainType {
  None,
  Auth,
  Activation,
  Account,
  Onboarding,
  GoogleEmailOAuth,
}

export enum UserIdentifyMethod  {
  Local = 'Local',
  Google = 'Google',
  Microsoft = 'Microsoft',
}
