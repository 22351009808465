import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { IntercomManager } from '../../../../../services/intercom-manager';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../../consts/analytics';
import {
  BottomPart,
  Container,
  Description,
  FirstParagraph,
  FirstParagraphWrapper,
  NotificationIcon,
  SecondParagraph,
  ChatLink,
  Title,
  TryAgainButton,
  RetryIcon,
} from './matches-error.styled';
import { MatchesErrorProps } from './matches-error';

export const MatchesError = ({ onClickRetry, isLoading }: MatchesErrorProps): JSX.Element => {
  const { t: translate } = useTranslation();
  
  const retryHandler = () => {
    onClickRetry();
  };

  const { analyticsTrackEvent } = useAnalyticsContext();

  useEffect(() => {
    analyticsTrackEvent({
      eventName: ANALYTICS_EVENTS.POSITION_ERROR_SHOWN,
      eventGroup: EVENT_GROUP.IMP,
      pageName: PRODUCT_AREA.POSITION_PAGE,
    });
  }, [analyticsTrackEvent]);

  return (
    <Container>
      <Title>
        { translate(STRING_KEYS.MATCHES_PAGE.ERROR.TITLE) }
      </Title>
      <Description>
        <span>{ translate(STRING_KEYS.MATCHES_PAGE.ERROR.DESCRIPTION) }</span>
        <TryAgainButton
          onClick={ retryHandler }
          isLoading={ isLoading }
          variant={ PerfectButtonVariants.Ghost }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          StartIcon={ RetryIcon }
        >
          { translate(STRING_KEYS.MATCHES_PAGE.ERROR.TRY_AGAIN) }
        </TryAgainButton>
      </Description>
      <BottomPart>
        <FirstParagraphWrapper>
          <NotificationIcon />
          <FirstParagraph>
            { translate(STRING_KEYS.MATCHES_PAGE.ERROR.BOTTOM.NOTIFY_ONCE_READY) }
          </FirstParagraph>
        </FirstParagraphWrapper>
        <SecondParagraph>
          <Trans i18nKey={ STRING_KEYS.MATCHES_PAGE.ERROR.BOTTOM.NEED_HELP }>
            <ChatLink onClick={ IntercomManager.showChat } />
          </Trans>
        </SecondParagraph>
      </BottomPart>
    </Container>
  );
};
