/* eslint-disable @typescript-eslint/no-unused-vars,react/jsx-props-no-spreading */
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { parseUrlValueByKey } from '../../../../../utils';
import { TimeAgoItem } from '../../../../../components/Common/TimeAgoItem';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import STRING_KEYS from '../../../../../language/keys';
import { ConversationMessageStatusEnum } from '../../../../../enums/outreach/conversation-message-status.enum';
import {
  ConversationSeenMessage, ConversationSentMessage, ConversationScheduledMessage, Row, TruncatedText,
} from '../conversation-message-state/conversation-message-state';
import Steps from '../../../../../components/Common/outreach-steps/outreach-steps.component';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import StyledAvatar from '../../../../../components/Common/styled-avatar/styled-avatar.component';
import { ConversationItemProps, ConversationMessage } from './conversation-item';
import { ConversationItemType } from './conversation-item.enums';
import {
  ContentContainer,
  ConversationItemContainer,
  DateContainer,
  Description,
  Dot,
  ErrorIcon,
  Message,
  NewLabelIcon,
  RepliedIcon,
  SendMessageLink,
  TaskLabelIcon,
  Title,
  BodyWrapper,
  TitleContainer,
  TitleWrapper,
} from './conversation-item.styled';

const outlineColorMapByType = {
  [ConversationItemType.Archived]: hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.GHOST),
  [ConversationItemType.New]: THEME.COLORS.PRIMARY,
  [ConversationItemType.Scheduled]: THEME.COLORS.PURE_WHITE,
  [ConversationItemType.Task]: THEME.COLORS.PURE_WHITE,
  [ConversationItemType.Default]: hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.GHOST),
  [ConversationItemType.Replied]: THEME.COLORS.ACCENT,
  [ConversationItemType.Error]: hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.GHOST),
};

const ConversationItem = ({
  type = ConversationItemType.Default,
  url,
  fullName,
  conversationMessage = {} as ConversationMessage,
  conversationId,
  positionId,
  isAutoMode,
  totalSteps,
  completedSteps,
  ...other
}: ConversationItemProps): JSX.Element => {
  const {
    text, date, isSelfMessage, shouldBlur,
  } = conversationMessage;

  const location = useLocation();
  const { t: translate } = useTranslation();
  const history = useHistory();

  const conversationIdFromUrl = parseUrlValueByKey(location.pathname, AppRouting.CONVERSATION_PATH_PARAM_KEY) as string;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (conversationIdFromUrl === conversationId) {
      ref.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const onItemClick = useCallback(() => {
    history.push({
      pathname: AppRouting.buildPositionConversationByIdPageUrl(positionId, conversationId),
      search: location.search,
    });
  }, [conversationId, history, location.search, positionId]);

  const AutoModeSteps = useMemo(() => {
    if (isAutoMode && totalSteps && typeof completedSteps === 'number') {
      return (
        <Steps
          totalSteps={ totalSteps }
          completedSteps={ completedSteps }
          activeStepColor={ type === ConversationItemType.Task ? THEME.COLORS.ACCENT : THEME.COLORS.PRIMARY_DARK }
          completedStepsColor={
            type === ConversationItemType.Scheduled || type === ConversationItemType.Default ?
              hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW) :
              THEME.COLORS.PRIMARY_DARK
          }
        />
      );
    }

    return undefined;
  }, [completedSteps, isAutoMode, totalSteps, type]);

  const TitleLabel = useMemo(() => {
    if (type === ConversationItemType.New) {
      return <NewLabelIcon />;
    }

    if (type === ConversationItemType.Task) {
      return <TaskLabelIcon />;
    }

    return undefined;
  }, [type]);

  const renderAvatar = () => (
    <StyledAvatar
      name={ fullName }
      initialsFontSizePx={ 16 }
      image={ url }
      outlineWidthPx={ 1 }
      outlineColor={ outlineColorMapByType[type] }
      borderWidthPx={ 1 }
      width={ 40 }
      height={ 40 }
    />
  );

  const renderMessage = () => {
    const isScheduled = type === ConversationItemType.Scheduled;
    const isSent = isSelfMessage && conversationMessage.status === ConversationMessageStatusEnum.Sent;
    const isSeen = isSelfMessage && conversationMessage.status === ConversationMessageStatusEnum.Seen;
  
    const icon: JSX.Element | null = (() => {
      if (isScheduled && !text) return <ConversationScheduledMessage />;
      if (isSeen) return <ConversationSeenMessage />;
      if (isSent) return <ConversationSentMessage />;
      
      return null;
    })();
  
    const message: string | JSX.Element = (() => {
      if (isScheduled && !text) {
        return translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.SCHEDULED_MESSAGE);
      }
  
      if (isScheduled && text) {
        return (
          <Row>
            <TruncatedText>{text}</TruncatedText>
            <>
              <Dot />
              <DateContainer>{date && <TimeAgoItem date={ date } />}</DateContainer>
            </>
          </Row>
        );
      }
  
      return text;
    })();
  
    return (
      <>
        {icon}
        <Message isLocked={ shouldBlur }>
          {message}
        </Message>
      </>
    );
  };

  const renderDescription = () => {
    if (type === ConversationItemType.Archived) {
      return (
        <>
          <Message>
            {translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.ARCHIVED)}
          </Message>
        </>
      );
    }

    if (type === ConversationItemType.Task) {
      return (
        <>
          <SendMessageLink>
            {translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.TASKS_MESSAGE)}
          </SendMessageLink>
        </>
      );
    }

    if (type === ConversationItemType.New) {
      return (
        <>
          <SendMessageLink>
            {translate(STRING_KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.SEND_MESSAGE)}
          </SendMessageLink>
        </>
      );
    }

    if (type === ConversationItemType.Error) {
      const translationKey = conversationMessage?.wasBounced ?
        STRING_KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.BOUNCED_ERROR_MESSAGE : STRING_KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.ERROR_MESSAGE;

      return (
        <>
          <Message>
            { translate(translationKey) }
          </Message>
        </>
      );
    }

    return (
      <>
        { renderMessage() }
      </>
    );
  };

  const renderIcon = () => {
    if (AutoModeSteps) {
      return undefined;
    }

    if (type === ConversationItemType.Replied) {
      return (<RepliedIcon />);
    }

    if (type === ConversationItemType.Error) {
      return (<ErrorIcon />);
    }

    return <></>;
  };

  return (
    <ConversationItemContainer
      active={ conversationIdFromUrl === conversationId }
      ref={ ref }
      onClick={ (e) => {
        e.stopPropagation();
        onItemClick();
      } }
      { ...other }
    >
      <ContentContainer>
        { renderAvatar() }
        <BodyWrapper>
          <TitleContainer>
            <TitleWrapper>
              { TitleLabel }
              <Title
                hasLabel={ !!TitleLabel }
                hasSteps={ !!AutoModeSteps }
              >
                { fullName }
              </Title>
            </TitleWrapper>
            { AutoModeSteps }
          </TitleContainer>
          <Description>
            { renderDescription() }
          </Description>
        </BodyWrapper>
      </ContentContainer>
      { renderIcon() }
    </ConversationItemContainer>
  );
};

export default ConversationItem;
