import { useTranslation } from 'react-i18next';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
  PerfectButtonVariants,
} from '../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../language/keys';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import Confetti from '../../Common/confetti/confetti.component';
import {
  Container,
  CreditsIcon,
  Description,
  GoToHomeButton,
  PinataIcon,
  TextContainer,
  Title,
} from './payment-success-dialog.styled';
import { PaymentSuccessDialogProps } from './payment-success-dialog';
import { PaymentSuccessDialogType } from './payment-success-dialog.consts';

export const PaymentSuccessDialog = ({
  goToHomepage,
  onCloseDialog,
  isDialogOpen,
  type,
}: PaymentSuccessDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();
  let titleKey;
  let descriptionKey;
  let buttonTextKey;
  let icon;
  
  switch (type) {
    case PaymentSuccessDialogType.SUBSCRIPTION:
      titleKey = STRING_KEYS.PAYMENT_SUCCESS_DIALOG.SUBSCRIPTION.TITLE;
      descriptionKey = STRING_KEYS.PAYMENT_SUCCESS_DIALOG.SUBSCRIPTION.DESCRIPTION;
      buttonTextKey = STRING_KEYS.PAYMENT_SUCCESS_DIALOG.SUBSCRIPTION.BUTTON_TITLE;
      icon = <PinataIcon />;
      break;
    case PaymentSuccessDialogType.CREDITS:
      titleKey = STRING_KEYS.PAYMENT_SUCCESS_DIALOG.CREDITS.TITLE;
      descriptionKey = STRING_KEYS.PAYMENT_SUCCESS_DIALOG.CREDITS.DESCRIPTION;
      buttonTextKey = STRING_KEYS.PAYMENT_SUCCESS_DIALOG.CREDITS.BUTTON_TITLE;
      icon = <CreditsIcon />;
      break;
    default:
      break;
  }

  return (
    <Dialog
      isOpen={ isDialogOpen }
      onClose={ onCloseDialog }
      widthSize={ DialogWidthSize.Medium }
    >
      <Container>
        <Confetti />
        { icon }
        <TextContainer>
          <Title>{ translate(titleKey) }</Title>
          <Description>{ translate(descriptionKey) }</Description>
        </TextContainer>
        <GoToHomeButton
          size={ PerfectButtonSize.Large }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          variant={ PerfectButtonVariants.Ghost }
          onClick={ goToHomepage }
        >
          { translate(buttonTextKey) }
        </GoToHomeButton>
      </Container>
    </Dialog>
  );
};
