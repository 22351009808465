import React from 'react';
import ReactDOM from 'react-dom';
import './app.css';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import log from 'loglevel';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from '../../reportWebVitals';
import '../../language';
import { persistor, store } from '../../store';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { App } from '../../components/App';
import UtmTrackingService from '../../services/utmTracker';
import { AppRouting } from '../../services/appRoutingResolver';
import { SplashScreen } from '../../components/Common/Loaders/SplashScreen/SplashScreen';

const logger = log.getLogger('ENTRY_APP_INDEX');

UtmTrackingService.checkForUTMParams();

logger.info('App information', {
  appInformation: {
    appVersion: process.env.REACT_APP_VERSION,
    currentDomain: window.location.origin,
    userAgent: navigator.userAgent,
  },
});

// check if we need to redirect the user to a global-login url
if (!AppRouting.redirectToGlobalLogin()) {
  // we only render the app if we aren't redirecting the user -
  // to prevent "flickering" in the UI of the first url that is trying to be loaded

  const queryClient = new QueryClient();

  // Main render
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={ store }>
          <PersistGate
            loading={ <SplashScreen title="Redux Persist Gate" /> }
            persistor={ persistor }
          >
            <QueryClientProvider client={ queryClient }>
              <App />
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
