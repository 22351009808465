import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useAuth, useAuthActions } from '@frontegg/react';
import log from 'loglevel';
import { useDispatch } from 'react-redux';
import { censorToken } from '../utils';
import { clearApiToken, setApiToken } from '../api';
import { logout, setFronteggLoadingCompleted } from '../store/slices/app-state/app-state.toolkit-slice';
import socketManager from '../services/socket-io-manager';
import { usePrevious } from './states';

const logger = log.getLogger('FRONTEGG_WATCHER_HOOK');

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;

export const useFronteggWatcher = (): { isAuthenticated: boolean } => {
  const dispatch = useDispatch();
  const [prevFronteggAuthState, saveFronteggAuthState] = useState<boolean>(
    false,
  );
  const { isAuthenticated, user } = useAuth();

  const { accessToken: frontEggToken, expiresIn, expires } = user || {};
  const prevFrontEggToken = usePrevious(frontEggToken);
  const { requestAuthorize } = useAuthActions();

  const refreshTimerRef = useRef<NodeJS.Timeout>();
  const requestAuthTimerRef = useRef<NodeJS.Timeout>();

  const handleFronteggTokenChange = (newToken: string, expiration: string) => {
    setApiToken(newToken, expiration);
    socketManager.setToken(newToken);
    logger.debug(
      'detected frontegg token change - api token header was changed',
      {
        newToken: censorToken(newToken),
        expiration,
      },
    );
  };

  /**
   * User was just authenticated by Frontegg (after login / page reload)
   */
  const handleFronteggLogin = () => {
    logger.debug('user was authenticated by frontegg');

    if (user) {
      setApiToken(user.accessToken, user.expires);
      socketManager.setToken(user.accessToken);
    }
  };

  /**
   * User was just un-authenticated by Frontegg
   */
  const handleFronteggLogout = () => {
    logger.debug('user was un-authenticated by frontegg');
    clearApiToken();
    // disableSuperUser();
  };

  /**
   * Frontegg authentication state just changed
   * (authenticated <-> not authenticated)
   */
  const handleFronteggAuthenticationStateChange = () => {
    if (isAuthenticated && user) {
      // user authenticated in after login / page reload
      handleFronteggLogin();
    } else {
      // user un-authenticated
      handleFronteggLogout();
    }
  };

  /**
   * Keep last Frontegg authentication state (to track and detect changes).
   * <FronteggWatcher> may be unmounted which lead to incorrect events,
   * so we keep the data at parent to make sure we always know what was the last state
   */
  const handleRefreshAuthenticationState = () => {
    if (prevFronteggAuthState !== isAuthenticated) {
      handleFronteggAuthenticationStateChange();
    }

    if (isAuthenticated) {
      dispatch(setFronteggLoadingCompleted());
    }
    saveFronteggAuthState(isAuthenticated);
  };

  const stopRefreshTokenTimer = useCallback(() => {
    if (refreshTimerRef.current) {
      logger.debug('refresh frontegg-token timer was stopped');
      clearTimeout(refreshTimerRef.current);
    }
  }, []);

  const startRefreshTokenTimer = useCallback(
    (time: number) => {
      stopRefreshTokenTimer();

      logger.debug(`refresh frontegg-token timer was set to ${time}ms`);

      refreshTimerRef.current = setTimeout(() => {
        logger.debug('refreshing frontegg-token');
        requestAuthorize(true);
      }, time);
    },
    [requestAuthorize, stopRefreshTokenTimer],
  );

  const stopRequestAuthTimer = useCallback(() => {
    if (requestAuthTimerRef.current) {
      logger.debug('request-auth timer was stopped');
      clearTimeout(requestAuthTimerRef.current);
    }
  }, []);

  const startRequestAuthTimer = useCallback(
    (time: number) => {
      stopRequestAuthTimer();

      logger.debug(`request-auth timer was set to ${time}ms`);

      requestAuthTimerRef.current = setTimeout(() => {
        logger.debug('request-auth time passed. requesting re-authenticating!');
        dispatch(logout());
      }, time);
    },
    [dispatch, stopRequestAuthTimer],
  );

  /**
   * Frontegg authentication watcher
   */
  useEffect(() => {
    // this component may be unmounted and remounted,
    // which mean the value of isAuthenticated may be the same between re-creations
    // so we just update the parent with current value
    handleRefreshAuthenticationState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  /**
   * Token watcher
   */
  useEffect(() => {
    if (prevFrontEggToken !== frontEggToken) {
      logger.debug('detected forntegg-token change', {
        oldTokenValue: censorToken(prevFrontEggToken),
        newTokenValue: censorToken(frontEggToken),
        expiresIn,
        expires,
      });

      if (isAuthenticated && frontEggToken) {
        logger.debug(
          'the user is authenticated with a new frontegg-token,',
          'triggering token-change callback and resetting timers',
        );

        // make sure that we update the token only if the user is still logged in
        // and there is actually a token
        handleFronteggTokenChange(frontEggToken, expires || '');
  
        // try to refresh the token 5 minutes before it expires
        const refreshTime = expiresIn ?
          expiresIn * 1000 - 5 * ONE_MINUTE :
          ONE_HOUR - ONE_MINUTE;
        startRefreshTokenTimer(refreshTime);

        // if for some reason the token won't be refreshed on time,
        // and current token is still exists,
        // we'll want to disconnect the user from the system so he will need to login again
        const requestReAuthTime = expiresIn ?
          expiresIn * 1000 - ONE_MINUTE :
          ONE_HOUR;
        startRequestAuthTimer(requestReAuthTime);
      } else {
        log.debug('user is not authenticated or there is no a frontegg token');
      }
    }
  }, [
    prevFrontEggToken,
    frontEggToken,
    isAuthenticated,
    startRefreshTokenTimer,
    startRequestAuthTimer,
    expiresIn,
    expires,
  ]);

  /**
   * Component unmount
   */
  useEffect(() => {
    return () => {
      logger.trace('Frontegg watcher is unmounting');
      stopRefreshTokenTimer();
      stopRequestAuthTimer();
    };
  }, [stopRefreshTokenTimer, stopRequestAuthTimer]);

  return {
    isAuthenticated: useMemo(() => isAuthenticated, [isAuthenticated]),
  };
};
