import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../language/keys';
import {
  OnboardingSetupProfileForm,
} from '../../components/onboarding-setup-profile-form/onboarding-setup-profile-form.component';
import {
  OnboardingSetupProfileFormState,
} from '../../components/onboarding-setup-profile-form/onboarding-setup-profile-form';
import { PAGE_TITLES } from '../../../../consts';
import {
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { PerfectSpacer } from '../../../../components/Common/perfect-spacer/perfect-spacer.component';
import { AccountRecruitingUsageType, OnboardingStep } from '../../enums/onboarding.enums';
import {
  useCreateUserOnboardingMutation,
  useUpdateOnboardingProgressMutation,
} from '../../../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { useAppSelector } from '../../../../store/selectors';
import { selectAiChatPrompt, selectPromo } from '../../../../store/selectors/onboarding.selectors';
import { clearPromoAndAiChatPrompt } from '../../../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import {
  PerfectButton, Subtitle, Title, TitlesWrapper, Wrapper,
} from './onboarding-setup-profile.styled';

export const OnboardingSetupProfilePage = ({
  onSignOut,
}: OnboardingSetupProfilePageProps): JSX.Element => {
  const dispatch = useDispatch();
  
  const { t: translate } = useTranslation();

  const promo = useAppSelector(selectPromo);
  const aiChatPrompt = useAppSelector(selectAiChatPrompt);

  const [createUserOnboarding] = useCreateUserOnboardingMutation();
  const [updateUserOnboarding] = useUpdateOnboardingProgressMutation();

  const handleSubmit = async (data: OnboardingSetupProfileFormState) => {
    // When using a social login, the user doesn't go through the `handleLoginComplete` callback, so just in case,
    // we make sure the onboarding gets the promo and prompt when he submits the setup profile form
    await createUserOnboarding({ promo, aiChatPrompt });

    await updateUserOnboarding({
      step: OnboardingStep.SetupProfile,
      dynamicUpdateParams: {
        userName: `${data.firstName} ${data.lastName}`,
        companyLinkedinUrl: data.companyLinkedInUrl as string,
        customCompanyName: data.customCompanyName,
        accountRecruitingUsageType: data.recruitingUsageType ?? AccountRecruitingUsageType.Other,
        phoneNumber: data.phoneNumber as string,
      },
    });

    dispatch(clearPromoAndAiChatPrompt());
  };

  useEffect(() => {
    document.title = PAGE_TITLES.ONBOARDING_SETUP_PROFILE;
  }, []);

  return (
    <Wrapper>
      <TitlesWrapper>
        <Title>
          {translate(STRING_KEYS.ONBOARDING.ONBOARDING_SETUP_PROFILE_TITLE)}
        </Title>

        <Subtitle>
          {translate(STRING_KEYS.ONBOARDING.ONBOARDING_SETUP_PROFILE_SUBTITLE)}
        </Subtitle>
      </TitlesWrapper>

      <PerfectSpacer height={ 50 } />

      <OnboardingSetupProfileForm onSubmit={ handleSubmit } />

      <PerfectSpacer height={ 16 } />

      <PerfectButton variant={ PerfectButtonVariants.Link } onClick={ onSignOut }>
        {translate(STRING_KEYS.ONBOARDING.SIGN_OUT)}
      </PerfectButton>
    </Wrapper>
  );
};
