import styled, { keyframes } from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';
import { CaptionFont } from '../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../utils/colors';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  animation: ${fadeIn} 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: both;
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 2200;
`;

export const ChatButton = styled.div<{ hide: boolean }>`
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background:
    radial-gradient(circle at top left, #F62660 0%, transparent 70%),
    radial-gradient(circle at bottom right, #8355F0 0%, transparent 70%),
    linear-gradient(135deg, #F62660 0%, #CA3BB2 50%, #8355F0 100%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${({ hide }) => !hide ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: ${fadeIn} 2s forwards;
  
  @media (max-width: 1366px) {
    display: flex;
  }
`;

export const ChatIcon = styled(ASSETS.PERFECT_AI)`
  width: 24px;
  height: 24px;
  color: ${THEME.COLORS.PURE_WHITE};
`;

export const MessageBubble = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
  background-color: ${THEME.COLORS.PURE_WHITE};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 330px;
  position: relative;

  backdrop-filter: blur(20px);
  border-bottom-left-radius: ${THEME.RADIUS.SHARP};
  border-bottom-right-radius: ${THEME.RADIUS.CURVY};
  border-top-left-radius: ${THEME.RADIUS.CURVY};
  border-top-right-radius: ${THEME.RADIUS.CURVY};

  animation: ${fadeIn} 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: both;
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const NliImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const AiTitle = styled.span`
  ${CaptionFont};
  align-content: center;
  font-weight: 500;
`;

export const CloseButton = styled.button`
  border: none;
  display: flex;
  background-color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.FOCUS)};
  border-radius: ${THEME.RADIUS.CIRCLE};
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: ${THEME.SPACING.MICRO};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${THEME.SPACING.TINY};
  right: ${THEME.SPACING.TINY};
`;

export const CloseIcon = styled(ASSETS.CLOSE)`
  width: 10px;
  color: ${THEME.COLORS.PURE_WHITE};
  cursor: pointer;
`;

export const Message = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  overflow: hidden;
  text-overflow: ellipsis;

  // Allows up to 3 lines
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
`;
