import { accountQuery } from '../account.toolkit-api';
import { AccountCreditsResponse } from '../dto/response/account-credits.response';

export const useGetAccountCreditsQuery = (): AppSelectorType<AccountCreditsResponse> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = accountQuery.useGetAccountCreditsQuery();

  return {
    data: data || {} as AccountCreditsResponse,
    error,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
