import { useIdleTimer } from 'react-idle-timer';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { v4 as uuidV4 } from 'uuid';
import analyticsIdManager from '../services/analyticsIdManager';
import { CONFIG } from '../config';
import { AppRouting } from '../services/appRoutingResolver';
import { parseUrlValueByKey } from '../utils';
import { useTrackVisitMutation } from '../store/rtk-query/apis/tracking/tracking.toolkit-api';
import { useAppState } from '../store/selectors';
import { refreshCacheAndReload } from '../utils/window';

const DEBOUNCE_TIME = CONFIG.ACTIVITY_DEBOUNCE_TIME * 1000;
const IDLE_TIMEOUT_MS = 30_000;
const SEND_TRACK_PAGE_VISIT_INTERVAL_MS = 20_000;

// Took it out of react lifecycle for now, in order avoid mismatch because setState is async
// Will be fixed when return to localStorage
let currentActivityStartTime: Date | undefined = new Date();

type UseActivityWatcherProps = {
  disableTrackPageVisits: boolean;
};

// Using now interval to report the server every 10 seconds about activity
// So for now all the local storage code is in comment
// Added 'disableTrackPageVisits' variable for easy fix. Need to split this hook and put the track page visit part inside the application layout (when the user is authenticated)
export const useActivityWatcher = ({ disableTrackPageVisits }: UseActivityWatcherProps): void => {
  const [isActive, setIsActive] = useState(true);
  const [shouldHardRefresh, setShouldHardRefresh] = useState(false);
  const { latestAppVersion } = useAppState();
  // const [currentActivityId, setCurrentActivityId] = useState<string | undefined>();
  const location = useLocation();
  const currentPageUrl = location.pathname;
  const currentPageName = AppRouting.getPageName(location.pathname) || '';
  const currentPositionId = parseUrlValueByKey(location.pathname, AppRouting.POSITIONS_PATH_PARAM_KEY);

  const [trackPageVisits] = useTrackVisitMutation();
  
  const [currentPageTrackId, setCurrentPageTrackId] = useState<string | undefined>('');
  // const [currentActivityStartTime, setCurrentActivityStartTime] = useState<Date | undefined>(new Date());

  useEffect(() => {
    if (CONFIG.SHOULD_CHECK_APP_VERSION) {
      if (latestAppVersion && latestAppVersion !== CONFIG.APP_VERSION) {
        setShouldHardRefresh(true);
      }
    }
  }, [latestAppVersion]);

  const sendTrackPageVisitRequest = () => {
    if (!currentPageTrackId || disableTrackPageVisits) {
      return;
    }

    trackPageVisits([{
      sessionId: analyticsIdManager.getId(),
      pageTrackId: currentPageTrackId,
      positionId: currentPositionId,
      pageUrl: currentPageUrl,
      pageName: currentPageName,
      startTime: currentActivityStartTime || new Date(),
      lastActivityTime: new Date(),
    }]);
  };

  const onAction = () => {
    analyticsIdManager.markAsActive();
  };

  const onActive = () => {
    setIsActive(true);
    currentActivityStartTime = new Date();
    // const sessionId = analyticsIdManager.getId();
    // const activityId = activityManager.createActivity({
    //   sessionId,
    //   pageUrl: currentPageUrl,
    //   pageName: currentPageName,
    //   positionId: currentPositionId,
    // });
    // setCurrentActivityId(activityId);
  };

  const onIdle = () => {
    setIsActive(false);
    sendTrackPageVisitRequest();
    currentActivityStartTime = undefined;
    if (shouldHardRefresh) {
      refreshCacheAndReload();
      setShouldHardRefresh(false);
    }
    // if (currentActivityId) {
    //   activityManager.updateLastActivityTime(currentActivityId);
    //   setCurrentActivityId(undefined);
    // }
  };

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: IDLE_TIMEOUT_MS,
    debounce: DEBOUNCE_TIME,
  });

  useEffect(() => {
    if (!isActive || disableTrackPageVisits) {
      return undefined;
    }

    const interval = setInterval(() => {
      sendTrackPageVisitRequest();
      // activityManager.updateLastActivityTime(currentActivityId);
    }, SEND_TRACK_PAGE_VISIT_INTERVAL_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivityStartTime, currentPageName, currentPageTrackId, currentPageUrl, currentPositionId, isActive, trackPageVisits, disableTrackPageVisits]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const sessions = activityManager.popSessions(currentActivityId);
  //     if (sessions?.length) {
  //       trackVisits(sessions);
  //     }
  //   }, 10 * 1000);
  //
  //   return () => clearInterval(interval);
  // }, [currentActivityId]);

  useEffect(() => {
    setCurrentPageTrackId(uuidV4());
    currentActivityStartTime = new Date();
    // setCurrentActivityStartTime(new Date());
    // sendTrackPageVisitRequest();
    // const sessionId = analyticsIdManager.getId();
    // if (currentActivityId) {
    //   activityManager.updateLastActivityTime(currentActivityId);
    // }
    // const activityId = activityManager.createActivity({
    //   sessionId,
    //   pageUrl: currentPageUrl,
    //   pageName: currentPageName,
    //   positionId: currentPositionId,
    // });
    // setCurrentActivityId(activityId);
    // activate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageName, currentPositionId]);

  useEffect(() => {
    if (currentPageTrackId) {
      sendTrackPageVisitRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageTrackId]);
};
