import styled from 'styled-components';
import { THEME } from '../../../../themes';

export const DialogBody = styled.div`
  width: 100%;
  max-height: 90vh;
  max-width: 90vw;
  padding: ${THEME.SPACING.LOOSE} ${THEME.SPACING.XLOOSE};
  overflow-y: auto;
`;
