/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect } from 'react';
import { CloseButton } from '../../Common/Buttons/dialog-close-button/dialog-close-button.component';
import Portal from '../../Common/Portal/portal.commponent';
import { Keymap } from '../../../consts/keyboard_keys';
import { THEME } from '../../../themes';
import { Backdrop, CloseButtonContainer, DialogContainer } from './base-dialog.styled';
import { DialogProps } from './base-dialog';
import { DialogWidthSize } from './base-dialog.enums';
import BaseDialogBody from './base-dialog-body/base-dialog-body.component';
import BaseDialogFooter from './base-dialog-footer/base-dialog-footer.component';
import BaseDialogHeader from './base-dialog-header/base-dialog-header.component';

const WIDTH_SIZE_MAP = {
  [DialogWidthSize.Small]: '480px',
  [DialogWidthSize.Medium]: '640px',
  [DialogWidthSize.Large]: '720px',
  [DialogWidthSize.XLarge]: '960px',
  [DialogWidthSize.XXLarge]: '1240px',
  [DialogWidthSize.XXXLarge]: '1440px',
};

const CONTAINER_ID = 'APPLICATION_DIALOG';

const Dialog = ({
  isOpen,
  onClose,
  disableBackdropClick,
  disableBackdropFilter = false,
  disableEscapeKeyDown,
  disableCloseButton,
  children,
  widthSize,
  portalUniqueId,
  zIndex = THEME.Z_INDEX.DIALOG,
  ...otherProps
}: DialogProps): JSX.Element => {
  const onBackdropClick = () => !disableBackdropClick ? onClose() : null;

  const onEscapeKeyDown = (event: Event) => {
    event.stopPropagation();
    if (!disableEscapeKeyDown) {
      onClose();
    }
  };

  const onKeyDownHandler = useCallback(
    (event) => {
      if (event.keyCode === Keymap.Escape) {
        onEscapeKeyDown(event);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', onKeyDownHandler);
    }

    return () => document.removeEventListener('keydown', onKeyDownHandler);
  }, [isOpen, onKeyDownHandler]);

  const getDialogSize = (): string => {
    if (widthSize) {
      return WIDTH_SIZE_MAP?.[widthSize as DialogWidthSize] || widthSize;
    }

    return WIDTH_SIZE_MAP[DialogWidthSize.Small];
  };

  return (
    <>
      {isOpen && (
        <Portal containerId={ `${CONTAINER_ID}_${portalUniqueId || ''}` } isGlobalPortal>
          <Backdrop
            onClick={ onBackdropClick }
            disableFilter={ disableBackdropFilter }
            $zIndex={ zIndex }
          />
          <DialogContainer
            widthSize={ getDialogSize() }
            $zIndex={ zIndex }
            { ...otherProps }
          >
            { !disableCloseButton && (
              <CloseButtonContainer>
                <CloseButton onClick={ onClose } />
              </CloseButtonContainer>
            )}
            { children }
          </DialogContainer>
        </Portal>
      )}
    </>
  );
};

Dialog.Header = BaseDialogHeader;
Dialog.Body = BaseDialogBody;
Dialog.Footer = BaseDialogFooter;

export default Dialog;
