import styled, { css } from 'styled-components';
import PerfectBaseButtonComponent
  from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../themes';

export const UpgradeButton = styled(PerfectBaseButtonComponent)<{
  $isLinkButton: boolean;
  disabled: boolean;
}>`
  color: ${({ disabled }) => disabled ? THEME.COLORS.PRIMARY_DARK : THEME.COLORS.PURE_WHITE};

  ${({ $isLinkButton }) => $isLinkButton &&
    css`
      color: ${THEME.COLORS.SECONDARY};
      font-size: ${THEME.FONT_SIZE.BASE};
      font-weight: bold;
      letter-spacing: 1.34px;
      text-transform: uppercase;
      background-color: transparent;
      padding: 0;
    `}

  ${({ $isLinkButton }) => !$isLinkButton &&
    css`
      background-color: ${THEME.COLORS.SECONDARY};
    `}
`;
