import { useAuth } from '@frontegg/react';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetMyUserQuery } from '../store/rtk-query/apis/user/user.toolkit-api';
import { useCurrentSubDomain } from '../hooks/use-current-sub-domain';
import { addSubDomainToUrl, replaceSubDomain } from '../utils/url';
import { SubDomainType } from '../store/slices/app-state/app-state.enums';
import { CONFIG } from '../config';
import { AppRouting } from '../services/appRoutingResolver';
import { SplashScreen } from '../components/Common/Loaders/SplashScreen/SplashScreen';

/**
 *  This guard ensures that users that have missing info (most likely because they were invited and only managed to set up a username and password)
 *  are redirected to the page where they can fill out that info.
 */
export const MissingUserInfoGuard = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();

  const { search: queryParams, pathname } = useLocation();

  const { currentSubDomainName, currentSubDomainType } = useCurrentSubDomain();

  const {
    data: { isOnboarding: isUserInOnboarding, role: userRole } = {},
    isLoading: isLoadingUser,
    isSuccess: isUserFetched,
  } = useGetMyUserQuery(undefined, { skip: !isAuthenticated });

  if (
    (currentSubDomainType !== SubDomainType.Activation || (
      currentSubDomainType === SubDomainType.Activation && pathname !== AppRouting.ROUTES.USER_INFO
    )) &&
    isUserFetched &&
    !isUserInOnboarding &&
    !userRole
  ) {
    const { location } = window;

    const newUrl = !currentSubDomainName ?
      addSubDomainToUrl(
        location.origin,
        location.host,
        CONFIG.GLOBAL_ACTIVATION_SUBDOMAIN,
      ) :
      replaceSubDomain(
        location.origin,
        currentSubDomainName,
        CONFIG.GLOBAL_ACTIVATION_SUBDOMAIN,
      );

    const urlWithQueryParams = newUrl + queryParams;

    window.location.replace(urlWithQueryParams);

    return null;
  }

  if (isLoadingAuth || isLoadingUser) {
    return <SplashScreen title="Missing User Info Guard" />;
  }

  return <>{children}</>;
};
