import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { RotatingText } from '../../../components/rotating-text/rotating-text.component';
import { PhraseData } from '../../../components/rotating-text/rotating-text';
import {
  Container,
  PerfectLogoAnimation,
} from './matching-progress.styled';

export const MatchingProgress = (): JSX.Element => {
  const { t: translate } = useTranslation();
  
  const phrases: PhraseData[] = [
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.GETTING_READY), duration: 2, key: '3276c469-d013-4632-a751-04e014980fc0' },
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.UNDERSTANDING_CONTEXT), duration: 4, key: '43fb402d-6181-46a5-9c35-7af6344426d1' },
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.FINDING_BEST_MATCHES), duration: 4.5, key: '6137ec31-64fd-4d48-8ea9-5abf24dffdb6' },
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.EVALUATING_FIT), duration: 4.5, key: '825ef147-96da-43af-9ca8-ad113f807bfc' },
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.SHORTLISTING_CANDIDATES), duration: 4, key: '016d41be-5547-482f-9bfe-c63be5f02258' },
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.BUILDING_PROFILES), duration: 3, key: '3ebfd905-c824-46f3-8d86-85225d797e8c' },
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.FINALIZING_MATCHES), duration: 3, key: '9afcfa29-204c-4d87-ad0b-555c8ac6c4ca' },
    { text: translate(STRING_KEYS.MATCHES_PAGE.MATCHING_PROGRESS.PREPARING_RESULTS), duration: 2, key: '35793f91-b0f4-475c-bedb-aff5cf4a1925' },
  ];
  
  return (
    <Container>
      <PerfectLogoAnimation />
      <RotatingText phrases={ phrases } stopAtEnd />
    </Container>
  );
};
