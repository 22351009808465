import { GetQuery, PostQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { ROUTES } from './payment.consts';
import { CreateCustomerPortalSessionRequest } from './dto/request/create-customer-portal-session.request';

export const getPositionPriceQuery = (): GetQuery => ({
  url: ROUTES.POSITION_PRICE,
  method: QueryMethods.Get,
});

export const buyPositionQuery = (): PostQuery => ({
  url: ROUTES.BUY_POSITION,
  method: QueryMethods.Post,
});

export const createCustomerPortalSessionQuery = ({ callbackUrl }:CreateCustomerPortalSessionRequest): PostQuery<CreateCustomerPortalSessionRequest> => ({
  url: '/payments/customer-portal-session',
  method: QueryMethods.Post,
  data: { callbackUrl: Buffer.from(callbackUrl).toString('Base64') },
});

export const createCustomerSessionQuery = (): PostQuery => ({
  url: '/payments/customer-session',
  method: QueryMethods.Post,
});
