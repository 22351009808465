import styled from 'styled-components';
import { CaptionFont, HeadlineFont } from '../../themes/fonts';
import { THEME } from '../../themes';
import { FLUENT_UI_EMOJIS } from '../../assets';
import BaseMenu from '../Common/perfect-base-menu/perfect-base-menu.component';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: ${THEME.SPACING.TINO};
  padding-block: ${THEME.SPACING.BASE};
`;

export const Title = styled.div`
  ${HeadlineFont};
  padding-inline: ${THEME.SPACING.BASE};
`;

export const Description = styled.div`
  padding-inline: ${THEME.SPACING.BASE};
  ${CaptionFont};
  opacity: 0.6;
`;

export const Warning = styled.div`
  background-color: ${THEME.COLORS.WARNING};
  padding: ${THEME.SPACING.BASE};
  text-align: center;
`;

export const TicketIcon = styled(FLUENT_UI_EMOJIS.TICKET)`
  width: 64px;
  height: 64px;
`;

export const HighVoltageIcon = styled(FLUENT_UI_EMOJIS.HIGH_VOLTAGE)`
  width: 64px;
  height: 64px;
`;

export const MenuOptionsWrapper = styled.div`
  padding-inline: ${THEME.SPACING.BASE};
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const PerfectMenu = styled(BaseMenu)`
z-index: ${THEME.Z_INDEX.HIGH - 2}`;
