import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AppRouting } from '../../services/appRoutingResolver';
import { removeInitialLoadings } from '../../store/slices/app-state/app-state.toolkit-slice';
import AccountActivationUserInfo from '../AccountActivationUserInfo';
import { SplashScreen } from '../Common/Loaders/SplashScreen/SplashScreen';
import { useAppState } from '../../store/selectors';
import AccountActivationScreen from '../AccountActivationScreen';

export const UserActivationRouter = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const {
    showSplashScreen,
  } = useAppState();

  useEffect(() => {
    if (showSplashScreen) {
      dispatch(removeInitialLoadings());
    }
  }, [dispatch, showSplashScreen]);

  if (showSplashScreen) {
    return null;
  }

  return (
    <Switch>
      <Route path={ AppRouting.ROUTES.USER_INFO } exact>
        <AccountActivationUserInfo />
      </Route>
      <Route path={ AppRouting.ROUTES.ACTIVATE_ACCOUNT } exact>
        <AccountActivationScreen />
      </Route>
      <Route path={ AppRouting.ROUTES.ACTIVATION_COMPLETED } exact>
        {/* special route to navigate from activation flow to home screen */}
        <SplashScreen title="Activation Completed" />
      </Route>
      <Route path="*">
        <Redirect to={ { pathname: AppRouting.ROUTES.USER_INFO } } push />
      </Route>
    </Switch>
  );
};
