import { positionQuery } from '../position.toolkit-api';
import { RetryMatchingQueryArguments } from '../dto/query-arguments/retry-matching.query-arguments';

type UseCreateConversationMessageMutationType = [
  ({ positionId }: RetryMatchingQueryArguments) => void,
  AppSelectorType<string | undefined, unknown>
];

export const useRetryMatchingMutation = (): UseCreateConversationMessageMutationType => {
  const [
    retryMatching,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useRetryMatchingMutation();

  const meta = {
    data, isLoading, isError, error, isSuccess, resetState: reset,
  };

  return [
    retryMatching,
    { ...meta },
  ];
};
