import React from 'react';
import { ChatActionsProps } from './chat-actions';
import { ActionButton, ActionContainer } from './chat-actions.styled';

export const ChatActions = ({ message, onActionClick }: ChatActionsProps): JSX.Element => {
  return (
    <ActionContainer>
      {message.suggestions?.map((suggestion: string) => (
        <ActionButton
          key={ suggestion }
          onClick={ () => onActionClick(suggestion) }
          disabled={ message.suggestionsDisabled }
        >
          {suggestion}
        </ActionButton>
      ))}
    </ActionContainer>
  );
};
