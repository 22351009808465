import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { CHAT_INPUT_HEIGHT } from '../perfect-ai-chat/perfect-ai-chat.styled';
import { Subtitle2Font } from '../../../../../themes/fonts';
import IconButton from '../../../../../components/Common/Buttons/icon-button/icon-button.component';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${THEME.COLORS.PURE_WHITE};
  padding: ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  height: ${CHAT_INPUT_HEIGHT}px;
  margin: ${THEME.SPACING.TINY};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, ${THEME.OPACITY.GHOST});
`;

export const InputField = styled.textarea`
  flex: 1;
  border: none;
  padding: ${THEME.SPACING.TINY};
  font-size: ${THEME.FONT_SIZE.BASE};
  outline: none;
  &::placeholder {
    color: ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.LOW)};
    font-weight: 500;
  }
  resize: none;
  z-index: ${THEME.Z_INDEX.HIGHEST};
  background-color: ${THEME.COLORS.TRANSPARENT};
`;

export const ButtonContainer = styled.div<{ hasError: boolean }>`
  display: flex;
  justify-content: ${({ hasError }) => (hasError ? 'space-between' : 'flex-end')};
  align-items: center;
  width: 100%;
`;

export const SendButton = styled(IconButton)<{ hasError: boolean, isEmpty: boolean }>`
  width: 32px;
  height: 24px;
  background-color: ${({ hasError }) => hasError ? THEME.COLORS.ALERT : THEME.COLORS.PRIMARY_DARK};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  animation: scale-in-center 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: transform 150ms ease, background-color 150ms ease, box-shadow 150ms ease;
  animation: ${({ isEmpty }) => isEmpty ? 'scale-in-center' : 'scale-out-center'} 250ms cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
  opacity: 0;

  @keyframes scale-in-center {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes scale-out-center {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const AlertIcon = styled(ASSETS.ALERT)`
  width: 16px;
  height: 16px;
  fill: ${THEME.COLORS.ALERT};
`;

export const ErrorMessage = styled.div`
  ${Subtitle2Font};
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${THEME.COLORS.ALERT};
`;

export const ArrowUpIcon = styled(ASSETS.ARROW_HEADING_UP)`
  width: 10px;
  fill: currentColor;
`;

export const ArrowLoopIcon = styled(ASSETS.ARROW_LOOP)`
  fill: currentColor;
  width: 14px;
  height: 16px;
`;
