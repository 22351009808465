/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { MouseEventHandler, useState } from 'react';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import {
  PerfectButtonHoverVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { PricingPlan } from '../../../../enums/user-plan.enum';
import STRING_KEYS from '../../../../language/keys';
import { PERMISSIONS } from '../../../../consts/permissions.const';
import { usePermissionCheck } from '../../../../hooks/use-permissions.hook';
import Tooltip from '../../../../components/tooltip/tooltip.component';
import { useGetAccountByIdQuery } from '../../../../store/rtk-query/apis/account/account.toolkit-api';
import { CONFIG } from '../../../../config';
import { UpgradePlanDialog } from '../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../consts/analytics';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { GenericUpgradeButtonProps } from './generic-upgrade-button';
import { UpgradeButton } from './generic-upgrade-button.styled';

export const GenericUpgradeButton = ({
  isLinkButton,
  onClick,
  ...otherProps
}: GenericUpgradeButtonProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);

  const { data: { accountId } = {} } = useGetMyUserQuery();
  const { data: { plan } = {} } = useGetAccountByIdQuery(accountId, {
    skip: !accountId,
  });

  const { allowed: isAllowedToUpgradePlan } = usePermissionCheck([
    PERMISSIONS.billing.upgrade,
  ]);

  const needsToContactSales =
    !plan?.isCreditsBased ||
    (plan?.isCreditsBased &&
      [PricingPlan.Tier1, PricingPlan.Tier2].includes(plan?.pricingPlan));

  let buttonTextKey = STRING_KEYS.UPGRADE;

  if (needsToContactSales) {
    buttonTextKey = STRING_KEYS.CONTACT_SALES;
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (needsToContactSales) {
      window.open(CONFIG.CONTACT_SALES_URL, '_blank');
    } else {
      setIsUpgradePlanDialogOpen(true);
    }

    const eventData = {
      eventName: ANALYTICS_EVENTS.UPGRADE_BUTTON_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.HOMEPAGE,
    };
    analyticsTrackEvent(eventData);

    onClick?.();
  };

  const renderButton = () => {
    return (
      <UpgradeButton
        onClick={ handleClick }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        $isLinkButton={ isLinkButton }
        disabled={ !isAllowedToUpgradePlan }
        { ...otherProps }
      >
        {translate(buttonTextKey)}
      </UpgradeButton>
    );
  };

  return (
    <>
      {isAllowedToUpgradePlan ? (
        renderButton()
      ) : (
        <Tooltip
          customTooltipStyle={ { maxWidth: '285px' } }
          showTooltipDelay={ 1000 }
          content={ <>{translate(STRING_KEYS.GENERIC_UPGRADE_BUTTON.TOOLTIP)}</> }
        >
          {renderButton()}
        </Tooltip>
      )}

      <UpgradePlanDialog
        isDialogOpen={ isUpgradePlanDialogOpen }
        onCloseDialog={ () => setIsUpgradePlanDialogOpen(false) }
      />
    </>
  );
};
