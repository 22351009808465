import { useEffect, useMemo } from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth, useAuthActions } from '@frontegg/react';
import { PRODUCT_AREA } from '../../consts/analytics';
import { AnalyticsProvider } from '../../contexts/analytics';
import { useGetUserOnboardingQuery } from '../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { useAppSelector, useAppState } from '../../store/selectors';
import { setAiChatPrompt, setPromo } from '../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import { AppRouting } from '../../services/appRoutingResolver';
import { useUrlQuery } from '../../hooks/route';
import { selectAiChatPrompt, selectPromo } from '../../store/selectors/onboarding.selectors';
import { removeInitialLoadings } from '../../store/slices/app-state/app-state.toolkit-slice';
import {
  OnboardingRegistrationPage,
} from '../../modules/onboarding/pages/onboarding-registration-page/onboarding-registration.page';
import { OnboardingStep } from '../../modules/onboarding/enums/onboarding.enums';
import {
  OnboardingSetupProfilePage,
} from '../../modules/onboarding/pages/onboarding-setup-profile-page/onboarding-setup-profile.page';
import {
  OnboardingStepsLayout,
} from '../../modules/onboarding/components/onboarding-steps-layout/onboarding-steps-layout.component';
import {
  OnboardingCompletedTransitionPage,
} from '../../modules/onboarding/pages/onboarding-completed-transition-page/onboarding-completed-transition.page';
import {
  OnboardingEmailVerificationPage,
} from '../../modules/onboarding/pages/onboarding-email-verification-page/onboarding-email-verification.page';

export const OnboardingRouter = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const { search: queryParams, pathname } = useLocation();

  const {  showSplashScreen } = useAppState();

  const { isAuthenticated } = useAuth();

  const { silentLogout } = useAuthActions();

  const { data: onboarding } = useGetUserOnboardingQuery(undefined, { skip: !isAuthenticated });

  const { step } = onboarding ?? {};

  const isCurrentRouteRequiresUserToBeLoggedIn = pathname !== '/' && pathname !== AppRouting.ROUTES.ONBOARDING_REGISTRATION;

  const pageQuery = useUrlQuery();
  const aiChatPromptInUrl = pageQuery.get(AppRouting.URL_PARAMS.ONBOARDING.AI_CHAT_PROMPT);
  const promoInUrl = pageQuery.get(AppRouting.URL_PARAMS.PROMO);

  const promoInState = useAppSelector(selectPromo);
  const aiChatPromptInState = useAppSelector(selectAiChatPrompt);

  const onboardingRedirectPath = useMemo(() => {
    switch (step) {
      case OnboardingStep.OnboardingCompleted:
        return AppRouting.ROUTES.ONBOARDING_COMPLETED;
      case OnboardingStep.EmailVerification:
        return AppRouting.ROUTES.ONBOARDING_EMAIL_VERIFICATION;
      case OnboardingStep.SetupProfile:
        return AppRouting.ROUTES.ONBOARDING_SETUP_PROFILE;
      default:
        return null;
    }
  }, [step]);

  // In case the page requires the user to be logged in, don't render it until the user is logged in,
  // otherwise network requests will start firing without a token
  const defaultUrl = isAuthenticated ?
    AppRouting.ROUTES.ONBOARDING_SETUP_PROFILE :
    `${AppRouting.ROUTES.ONBOARDING_REGISTRATION}${queryParams}`;

  useEffect(() => {
    // If the user is logged in, remove the splash screen
    if (showSplashScreen && (isAuthenticated || !isCurrentRouteRequiresUserToBeLoggedIn)) {
      dispatch(removeInitialLoadings());
    }
  }, [dispatch, isCurrentRouteRequiresUserToBeLoggedIn, isAuthenticated, showSplashScreen]);

  useEffect(() => {
    // Save the AI chat prompt from the query string to the state if it's different from the state
    if (!!aiChatPromptInUrl && aiChatPromptInUrl !== aiChatPromptInState) {
      dispatch(setAiChatPrompt(aiChatPromptInUrl));
    }
  }, [aiChatPromptInState, aiChatPromptInUrl, dispatch]);

  useEffect(() => {
    if (!!promoInUrl && promoInUrl !== promoInState) {
      dispatch(setPromo(promoInUrl));
    }
  }, [dispatch, promoInState, promoInUrl]);

  const handleSignOut = async () => {
    silentLogout({
      callback: () => {
        window.location.replace(AppRouting.buildGlobalOnboardingUrl(window.location));
      },
    });
  };

  // In case the page requires the user to be logged in, don't render it until the user is logged in,
  // otherwise network requests will start firing without a token
  if (isCurrentRouteRequiresUserToBeLoggedIn && !isAuthenticated) {
    return null;
  }

  if (onboardingRedirectPath && pathname !== onboardingRedirectPath) {
    return <Redirect to={ onboardingRedirectPath } />;
  }

  return (
    <AnalyticsProvider productArea={ PRODUCT_AREA.ONBOARDING_PAGE }>
      <Switch>
        <Route exact path="/">
          <Redirect to={ defaultUrl } />
        </Route>

        <Route exact path={ AppRouting.ROUTES.ONBOARDING }>
          <Redirect to={ defaultUrl } />
        </Route>

        <Route path={ AppRouting.ROUTES.ONBOARDING_COMPLETED } exact>
          <OnboardingCompletedTransitionPage />
        </Route>

        <Route path={ AppRouting.ROUTES.ONBOARDING_REGISTRATION } exact>
          <OnboardingRegistrationPage  />
        </Route>

        <OnboardingStepsLayout>
          <Route path={ AppRouting.ROUTES.ONBOARDING_SETUP_PROFILE } exact>
            <OnboardingSetupProfilePage onSignOut={ handleSignOut } />
          </Route>

          <Route path={ AppRouting.ROUTES.ONBOARDING_EMAIL_VERIFICATION } exact>
            <OnboardingEmailVerificationPage onSignOut={ handleSignOut } />
          </Route>
        </OnboardingStepsLayout>

        <Route path="*">
          <Redirect to={ defaultUrl } />
        </Route>
      </Switch>
    </AnalyticsProvider>
  );
};
