import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { AccountPlanProgressBar } from '../account-plan-progress-bar/account-plan-progress-bar.component';
import { useGetAccountCreditsQuery } from '../../../../../store/rtk-query/apis/account/account.toolkit-api';
import { PlanUpgradeOptionsMenu } from '../../../../../components/plan-upgrade-options-menu/plan-upgrade-options-menu';

export const MatchCreditsProgressBar = (): JSX.Element => {
  const { t: translate } = useTranslation();

  const {
    data: { creditsUsedInPeriod, totalCreditsInPeriod } = {},
  } = useGetAccountCreditsQuery();

  const [anchorElement, setAnchorElement] = useState<HTMLDivElement>(null);

  const ref = useRef(null);

  const onUpgrade = () => {
    setAnchorElement(ref.current);
  };

  return (
    <>
      <AccountPlanProgressBar
        ref={ ref }
        label={ translate(
          STRING_KEYS.HOMEPAGE.ACCOUNT_PLAN_PROGRESS_BAR.MATCH_CREDITS.LABEL,
        ) }
        usedUnits={ creditsUsedInPeriod }
        maxUnits={ totalCreditsInPeriod }
        upgradeLabel={ translate(
          STRING_KEYS.HOMEPAGE.ACCOUNT_PLAN_PROGRESS_BAR.MATCH_CREDITS.ADD_MORE,
        ) }
        onUpgrade={ onUpgrade }
        almostOutOfUnitsLabel={ translate(
          STRING_KEYS.HOMEPAGE.ACCOUNT_PLAN_PROGRESS_BAR.MATCH_CREDITS
            .RUNNING_LOW,
        ) }
        outOfUnitsLabel={ translate(
          STRING_KEYS.HOMEPAGE.ACCOUNT_PLAN_PROGRESS_BAR.MATCH_CREDITS.NEED_MORE,
        ) }
        tooltipLabel={ translate(
          STRING_KEYS.HOMEPAGE.ACCOUNT_PLAN_PROGRESS_BAR.MATCH_CREDITS.TOOLTIP,
        ) }
      />

      <PlanUpgradeOptionsMenu
        isOpen={ !!anchorElement }
        onClose={ () => setAnchorElement(null) }
        anchorElement={ anchorElement }
      />
    </>
  );
};
