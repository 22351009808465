import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootReducerState } from '../index';
import { UserIdentifyMethod } from '../slices/app-state/app-state.enums';
import { AppSnackbarDto } from '../slices/app-state/dto/app-snackbar.dto';

/**
 * App State
 */

export const useAppSelector: TypedUseSelectorHook<RootReducerState> = useSelector;

type AppStateSelector = {
  showSplashScreen: boolean,
  isLoggingOutFromRemoteTab: boolean,
  isOnboardingLogout: boolean,
  initialLoadings: Array<string>,
  userIdentifyMethod: UserIdentifyMethod,
  currentPageId: string,
  referrerPageId: string,
  connectedSocket: {
    talentAcquisition: boolean,
  },
  snackbar: AppSnackbarDto | undefined,
  latestAppVersion: string,
  isFaviconFlashing: boolean,
  isUnsavedChanges: boolean,
  userRoleWasChanged: boolean,
}

export const useAppState = (): AppStateSelector => {
  return useSelector(({ appReducer }: RootReducerState) => {
    return {
      showSplashScreen: appReducer.initialLoadings.length > 0,
      initialLoadings: appReducer.initialLoadings,
      isLoggingOutFromRemoteTab: appReducer.isLoggingOutFromRemoteTab,
      isOnboardingLogout: appReducer.isOnboardingLogout,
      userIdentifyMethod: appReducer.identifyMethod,
      currentPageId: appReducer.currentPageId,
      referrerPageId: appReducer.referrerPageId,
      connectedSocket: {
        talentAcquisition: appReducer.sockets.talentAcquisition.connected,
      },
      snackbar: appReducer.snackbar,
      latestAppVersion: appReducer.latestAppVersion,
      isFaviconFlashing: appReducer.isFaviconFlashing,
      isUnsavedChanges: appReducer.isUnsavedChanges,
      userRoleWasChanged: appReducer.userRoleWasChanged,
    };
  });
};
