export const SUB_DOMAIN_PATH_PARAM = ':subDomain';
export const ACCOUNT_ID_PATH_PARAM = ':accountId';

export const ROUTES = {
  ACCOUNT_INFO: `/accounts/account-info/${SUB_DOMAIN_PATH_PARAM}`,
  ACCOUNT_BY_ID: `/accounts/${ACCOUNT_ID_PATH_PARAM}`,
  ACCOUNT_CREDITS: '/accounts/credits',
};

export const REDUCER_PATH = 'accountApi';
