import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AIContainer,
  AiTitle,
  ChatAiBubble,
  ChatAIUser,
  NliImage,
} from '../chat-message/chat-ai-message/chat-ai-message.styled';
import { ASSETS } from '../../../../../assets';
import STRING_KEYS from '../../../../../language/keys';
import { AnimatedCursor } from '../animated-cursor/animated-cursor.component';

export const AiGeneratingMessage = ({
  isAgentGenerating,
}: AiGeneratingMessageProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <AIContainer>
      <ChatAiBubble>
        <ChatAIUser>
          <NliImage src={ ASSETS.NLI_LOGO } />
          <AiTitle>
            {translate(STRING_KEYS.MATCHES_PAGE.CHAT.AI_PERFECT)}
          </AiTitle>
        </ChatAIUser>
        { isAgentGenerating && <AnimatedCursor /> }
      </ChatAiBubble>
    </AIContainer>
  );
};
