export const isLengthValid = (password: string): boolean => {
  return password.length >= 10;
};

export const hasUpperOrLowerCase = (password: string): boolean => {
  return /[a-z]/.test(password) || /[A-Z]/.test(password);
};

export const hasUpperAndLowerCase = (password: string): boolean => {
  return /[a-z]/.test(password) && /[A-Z]/.test(password);
};

export const hasDigit = (password: string): boolean => {
  return /\d/.test(password);
};
