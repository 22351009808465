import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Subtitle1Font, Title1Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${THEME.SPACING.LARGE} ${THEME.SPACING.GRAND};
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title1 = styled.p`
  ${Title1Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.LOW};
  margin-bottom: ${THEME.SPACING.TINY};
  font-weight: 600;
`;

export const Title2 = styled.p`
  ${Title1Font};
  color: ${THEME.COLORS.CONTENT_A};
  margin-bottom: ${THEME.SPACING.BASE};
  font-weight: 600;
`;

export const SubTitle = styled.p`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  opacity: ${THEME.OPACITY.MED};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PromptsTitle = styled.p`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.LOW};
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const Suggestion = styled.p`
  ${Subtitle1Font};
  width: fit-content;
  border-radius: ${THEME.RADIUS.CURVY};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  margin-bottom: ${THEME.SPACING.TINY};
  padding: ${THEME.SPACING.BASE};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  cursor: pointer;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const ArrowDoodleIcon = styled(ASSETS.ARROW_DOODLE)`
  position: absolute;
  width: 54px;
  bottom: 23px;
  left: 17px;
  color: ${THEME.COLORS.ACCENT};
`;
