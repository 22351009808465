import styled from 'styled-components';
import { motion } from 'framer-motion';
import { THEME } from '../../../themes';
import { Title1Font } from '../../../themes/fonts';

export const BodyWrapper = styled.div`
  z-index: 2;
  position: relative;
  text-align: center;
`;

export const AnimatedBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${THEME.COLORS.ACCENT};
  color: ${THEME.COLORS.PURE_WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  border-radius: 50%;
  transform-origin: center;
  ${Title1Font};
  line-height: 1.64;
  font-weight: 300;
`;
