/* eslint-disable react/jsx-props-no-spreading,react/require-default-props */
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { PropsWithChildren } from 'react';
import { AccountSubDomainGuard } from '../../../guards/account-sub-domain.guard';
import { AuthGuard } from '../../../guards/auth.guard';
import { OnboardingGuard } from '../../../guards/onboarding.guard';
import { MissingUserInfoGuard } from '../../../guards/missing-user-info.guard';

type GuardedRouteProps = PropsWithChildren<
  RouteProps & {
    withAuthCheck?: boolean;
    withOnboardingCheck?: boolean;
    withAccountSubDomainCheck?: boolean;
    withMissingUserInfoCheck?: boolean;
  }
>;

export const GuardedRoute = ({
  withAuthCheck = false,
  withOnboardingCheck = false,
  withAccountSubDomainCheck = false,
  withMissingUserInfoCheck = false,
  children,
  ...otherProps
}: GuardedRouteProps): JSX.Element => {
  const guards = [
    ...withAccountSubDomainCheck ? [AccountSubDomainGuard] : [],
    ...withMissingUserInfoCheck ? [MissingUserInfoGuard] : [],
    ...withOnboardingCheck ? [OnboardingGuard] : [],
    ...withAuthCheck ? [AuthGuard] : [],
  ];

  const wrappedChildren = guards.reduce(
    (acc, Wrapper) => <Wrapper>{acc}</Wrapper>,
    children,
  );

  return <Route { ...otherProps }>{wrappedChildren}</Route>;
};
