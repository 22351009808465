import { createApi } from '@reduxjs/toolkit/query/react';
import { mergeWith } from 'lodash';
import log from 'loglevel';
import { axiosBaseQuery } from '../../utils/base-query';
import { sortPositionsByState } from '../../../../utils/positions';
import { PositionState } from '../../../../enums/position-state.enum';
import socketManager from '../../../../services/socket-io-manager';
import { SocketNamespace } from '../../../../enums/socket-namespace.enum';
import { SocketEventName } from '../../../../enums/socket-event-name.enum';
import {
  socketTalentAcquisitionConnected,
  socketTalentAcquisitionDisconnected,
} from '../../../slices/app-state/app-state.toolkit-slice';
import { matchQuery } from '../match/match.toolkit-api';
import { PositionAssistantTypeEnum } from '../../../../enums/position-assistant-type.enum';
import { PositionAssistantTaskEnum } from '../../../../enums/position-assistant-task.enum';
import { PositionAssistantMilestoneEnum } from '../../../../enums/position-assistant-milestone.enum';
import { PositionUpdatedWebsocketDto } from '../match/dto/socket/position-updated.socket';
import { RetryGenerationQueryArguments } from '../recruiter-agent/dto/query-arguments/retry-generation.query-arguments';
import {
  closePositionQuery,
  createPositionFeedbackQuery,
  createPositionQuery,
  getOpenPositionsQuery,
  getPositionAnalyticsQuery,
  getPositionByIdQuery,
  getPositionInsightsQuery,
  getPositionOverviewQuery,
  getPositionRegions,
  getPositionStatisticsQuery,
  getSimilarTitles,
  linkPositionToAtsQuery,
  retryMatchingQuery,
  skipMatchTuneQuery,
  skipPositionAssistantQuery,
  updatePositionAtsIntegrationQuery,
  updatePositionQuery,
  updatePositionUserRolesQuery,
} from './position.toolkit-queries';
import { REDUCER_PATH } from './position.consts';
import { PositionResponse } from './dto/response/position.response';
import { getPositionsResponseTransformer } from './transformers/position-response.transformer';
import {
  matchPublishedPositionCacheWebsocketListener,
  matchPublishedPositionsCacheWebsocketListener,
} from './websocket-listeners/match-published.wbsocket-listeners';
import {
  matchFlowFinishedPositionCacheWebsocketListeners,
  matchFlowFinishedPositionsCacheWebsocketListeners,
} from './websocket-listeners/match-flow-finished.websocket-listeners';
import {
  matchFlowFailedPositionCacheWebsocketListeners,
  matchFlowFailedPositionsCacheWebsocketListeners,
} from './websocket-listeners/match-flow-failed.websocket-listeners';
import { getPositionByIdResponseTransformer } from './transformers/position-by-id-response.transformer';
import { PositionStatisticsResponse } from './dto/response/position-statistics.response';
import { updatePositionUserRoles } from './position.toolkit-api-utils';
import { PositionRegionsResponse } from './dto/response/position-regions.response';
import { CreatePositionFeedbackQueryArguments } from './dto/query-arguments/create-position-feedback.query-arguments';
import { SimilarTitlesResponse } from './dto/response/similar-titles.response';
import { GetSimilarTitlesQueryArguments } from './dto/query-arguments/get-similar-titles.query-arguments';
import { PositionOverviewResponse } from './dto/response/position-overview.response';
import { AssistantOption } from './dto/response/position-assistant.response';

const logger = log.getLogger('POSITION_API');

export const positionQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['getPositionById'],
  endpoints: (builder) => ({
    createPosition: builder.mutation<PositionResponse, void>({
      query: createPositionQuery,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let newPosition: PositionResponse | null = null;

        try {
          const { data } = await queryFulfilled;
          newPosition = data;

          dispatch(
            positionQuery.util.updateQueryData(
              'getOpenPositions',
              undefined,
              (draft) => {
                if (newPosition) {
                  draft.push(newPosition);
                  sortPositionsByState(draft);
                }
              },
            ),
          );

          dispatch(
            positionQuery.util.updateQueryData(
              'getPositionById',
              newPosition.id,
              (draft) => {
                Object.assign(draft, newPosition || {});
              },
            ),
          );
        } catch (e) {
          if (newPosition) {
            dispatch(
              positionQuery.util.updateQueryData(
                'getOpenPositions',
                undefined,
                (draft) => {
                  return draft.filter(
                    (position) => position.id !== newPosition?.id,
                  );
                },
              ),
            );
          }
        }
      },
    }),
    getPositionOverview: builder.query<PositionOverviewResponse, string>({
      query: getPositionOverviewQuery,
    }),
    getOpenPositions: builder.query<PositionResponse[], void>({
      query: getOpenPositionsQuery,
      transformResponse: getPositionsResponseTransformer,
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData, cacheDataLoaded, dispatch, getCacheEntry,
        },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.TalentAcquisition,
          onSocketConnected: () => {
            dispatch(socketTalentAcquisitionConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketTalentAcquisitionDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(
            SocketEventName.MatchFlowFinishedSuccessfully,
            (socketPayload) => matchFlowFinishedPositionsCacheWebsocketListeners(
              socketPayload,
              updateCachedData,
            ),
          );
          socket.on(SocketEventName.MatchPublished, (socketPayload) => matchPublishedPositionsCacheWebsocketListener(
            socketPayload,
            updateCachedData,
          ),
          );
          socket.on(SocketEventName.MatchFlowFailed, (socketPayload) => matchFlowFailedPositionsCacheWebsocketListeners(
            socketPayload,
            updateCachedData,
          ),
          );
          socket.on(
            SocketEventName.PositionUpdated,
            (socketPayload: PositionUpdatedWebsocketDto) => {
              const index = (getCacheEntry().data ?? []).findIndex(
                ({ id }) => id === socketPayload?.position?.id,
              );

              if (index !== -1) {
                updateCachedData((state) => {
                  state[index] = mergeWith(
                    state[index],
                    socketPayload.position,
                    (obj, src) => (Array.isArray(obj) ? src : undefined),
                  );
                });
              }
            },
          );
        }
      },
    }),
    getPositionById: builder.query<PositionResponse, string>({
      query: getPositionByIdQuery,
      providesTags: ['getPositionById'],
      transformResponse: getPositionByIdResponseTransformer,
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData, cacheDataLoaded, dispatch, getCacheEntry,
        },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.TalentAcquisition,
          onSocketConnected: () => {
            dispatch(socketTalentAcquisitionConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketTalentAcquisitionDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(SocketEventName.MatchPublished, (socketPayload) => matchPublishedPositionCacheWebsocketListener(
            socketPayload,
            updateCachedData,
          ),
          );
          socket.on(
            SocketEventName.MatchFlowFinishedSuccessfully,
            (socketPayload) => matchFlowFinishedPositionCacheWebsocketListeners(
              socketPayload,
              updateCachedData,
            ),
          );
          socket.on(SocketEventName.MatchFlowFailed, (socketPayload) => matchFlowFailedPositionCacheWebsocketListeners(
            socketPayload,
            updateCachedData,
          ),
          );
          socket.on(
            SocketEventName.PositionUpdated,
            (socketPayload: PositionUpdatedWebsocketDto) => {
              if (socketPayload?.position?.id === getCacheEntry()?.data?.id) {
                updateCachedData((state) => {
                  mergeWith(state, socketPayload.position, (obj, src) => Array.isArray(obj) ? src : undefined,
                  );
                });
              }
            },
          );
        }
      },
    }),
    // TODO [refactor] should add return value type and transformers if needed
    getPositionInsights: builder.query({
      query: getPositionInsightsQuery,
    }),
    /** @deprecated - use overview api */
    getPositionStatistics: builder.query<PositionStatisticsResponse, string>({
      query: getPositionStatisticsQuery,
    }),
    // TODO [refactor] should add return value type and transformers if needed
    getPositionAnalytics: builder.query({
      query: getPositionAnalyticsQuery,
    }),
    getPositionRegions: builder.query<PositionRegionsResponse, void>({
      query: getPositionRegions,
    }),
    getSimilarTitles: builder.query<
      SimilarTitlesResponse,
      GetSimilarTitlesQueryArguments
    >({
      query: getSimilarTitles,
    }),
    // TODO [refactor] should add return value type and transformers if needed
    closePosition: builder.mutation({
      query: closePositionQuery,
      async onQueryStarted({ positionId }, { dispatch, queryFulfilled }) {
        const dispatchResult = dispatch(
          positionQuery.util.updateQueryData(
            'getOpenPositions',
            undefined,
            (draft) => {
              const foundPosition = draft.find(
                (position) => position.id === positionId,
              );
              if (foundPosition) {
                foundPosition.state = PositionState.Closed;
                sortPositionsByState(draft);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatchResult.undo();
        }
      },
    }),
    skipPositionAssistant: builder.mutation({
      query: skipPositionAssistantQuery,
      async onQueryStarted(
        { positionId, name, type },
        { dispatch, queryFulfilled },
      ) {
        const dispatchResult = dispatch(
          positionQuery.util.updateQueryData(
            'getPositionOverview',
            positionId,
            (draft) => {
              let assistants: AssistantOption<string>[] =
                type === PositionAssistantTypeEnum.Task ?
                  draft.assistant?.tasks :
                  draft.assistant?.milestones;
              assistants =
                assistants?.filter((assistant) => assistant.name !== name) ||
                [];
              if (type === PositionAssistantTypeEnum.Task) {
                draft.assistant.tasks = [
                  ...assistants,
                ] as AssistantOption<PositionAssistantTaskEnum>[];
              } else {
                draft.assistant.milestones = [
                  ...assistants,
                ] as AssistantOption<PositionAssistantMilestoneEnum>[];
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatchResult.undo();
        }
      },
    }),
    linkPositionToAts: builder.mutation({
      query: linkPositionToAtsQuery,
    }),
    updatePositionAtsIntegration: builder.mutation({
      query: updatePositionAtsIntegrationQuery,
    }),
    updatePosition: builder.mutation({
      query: updatePositionQuery,
      async onQueryStarted(
        { positionId, anonymizeTalentDetails },
        { dispatch, queryFulfilled },
      ) {
        const toReviewMatchesDispatchResult = dispatch(
          matchQuery.util.updateQueryData(
            'getPendingReviewMatches',
            { positionId },
            (draft) => {
              draft.results = [];
              draft.totalCount = 0;
              draft.page = 0;
              draft.limit = 0;
            },
          ),
        );
        const positionDispatchResult = dispatch(
          positionQuery.util.updateQueryData(
            'getPositionById',
            positionId,
            (position) => {
              position.matches = [];
              position.hasReachedMatchTuneThreshold = false;

              if (position.anonymizeTalentDetails !== undefined) {
                position.anonymizeTalentDetails = anonymizeTalentDetails;
              }
            },
          ),
        );
        const positionsDispatchResult = dispatch(
          positionQuery.util.updateQueryData(
            'getOpenPositions',
            undefined,
            (draft) => {
              const foundPosition = draft.find(
                (position) => position.id === positionId,
              );
              if (foundPosition) {
                foundPosition.matches = [];
                foundPosition.hasReachedMatchTuneThreshold = false;
              }
            },
          ),
        );

        try {
          const { data: updatedPosition } = await queryFulfilled;
          dispatch(
            positionQuery.util.updateQueryData(
              'getPositionById',
              positionId,
              (draft) => {
                draft.hasReachedMatchTuneThreshold =
                  updatedPosition.hasReachedMatchTuneThreshold;
                draft.anonymizeTalentDetails =
                  updatedPosition.anonymizeTalentDetails;
              },
            ),
          );
          dispatch(
            positionQuery.util.updateQueryData(
              'getOpenPositions',
              undefined,
              (draft) => {
                const foundPosition = draft.find(
                  (position) => position.id === positionId,
                );
                if (foundPosition) {
                  foundPosition.hasReachedMatchTuneThreshold =
                    updatedPosition.hasReachedMatchTuneThreshold;
                }
              },
            ),
          );
        } catch (e) {
          positionDispatchResult?.undo();
          positionsDispatchResult?.undo();
          toReviewMatchesDispatchResult?.undo();
        }
      },
    }),
    updatePositionUserRoles: builder.mutation({
      query: updatePositionUserRolesQuery,
      async onQueryStarted(
        {
          positionId, recruiters, hiringManagers, requestType,
        },
        { dispatch, queryFulfilled },
      ) {
        let positionDispatchResult;
        let positionsDispatchResult;
        if (recruiters || hiringManagers) {
          positionDispatchResult = dispatch(
            positionQuery.util.updateQueryData(
              'getPositionById',
              positionId,
              (position) => updatePositionUserRoles(
                position,
                requestType,
                recruiters,
                hiringManagers,
              ),
            ),
          );
          positionsDispatchResult = dispatch(
            positionQuery.util.updateQueryData(
              'getOpenPositions',
              undefined,
              (draft) => {
                const foundPosition = draft.find(
                  (position) => position.id === positionId,
                );
                if (foundPosition) {
                  updatePositionUserRoles(
                    foundPosition,
                    requestType,
                    recruiters,
                    hiringManagers,
                  );
                }
              },
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          positionDispatchResult?.undo();
          positionsDispatchResult?.undo();
        }
      },
    }),
    createPositionFeedback: builder.mutation<
      void,
      CreatePositionFeedbackQueryArguments
    >({
      query: createPositionFeedbackQuery,
    }),
    skipMatchTune: builder.mutation<void, string>({
      query: skipMatchTuneQuery,
      async onQueryStarted(positionId, { dispatch, queryFulfilled }) {
        const positionDispatchResult = dispatch(
          positionQuery.util.updateQueryData(
            'getPositionById',
            positionId,
            (position) => {
              position.hasReachedMatchTuneThreshold = false;
            },
          ),
        );
        const positionsDispatchResult = dispatch(
          positionQuery.util.updateQueryData(
            'getOpenPositions',
            undefined,
            (draft) => {
              const foundPosition = draft.find(
                (position) => position.id === positionId,
              );
              if (foundPosition) {
                foundPosition.hasReachedMatchTuneThreshold = false;
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          positionDispatchResult?.undo();
          positionsDispatchResult?.undo();
        }
      },
    }),
    retryMatching: builder.mutation<string, RetryGenerationQueryArguments>({
      query: retryMatchingQuery,
      invalidatesTags: ['getPositionById'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          logger.error('Error in retry matching:', error);
        }
      },
    }),
  }),
});

export const {
  useGetOpenPositionsQuery,
  useGetPositionByIdQuery,
  useCreatePositionMutation,
} = positionQuery;
