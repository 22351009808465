import { useAuth } from '@frontegg/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import STRING_KEYS from '../../../language/keys';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../../consts/analytics';
import { PAGE_NAMES } from '../../../consts/pages';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { PAGE_TITLES } from '../../../consts';
import { LoginAndRegistrationLayout } from '../login-and-reqistration-layout/login-and-registration-layout.component';
import { AppRouting } from '../../../services/appRoutingResolver';
import { PerfectSpacer } from '../../Common/perfect-spacer/perfect-spacer.component';
import { LoginForm } from './login-form/login-form.component';
import {
  Accent, DontHaveAccountText, SignUpButton, Wrapper,
} from './login.styled';

export const Login = (): JSX.Element => {
  // This effect runs once, after the first render
  useEffect(() => {
    document.title = PAGE_TITLES.LOGIN;
  }, []);

  const { t: translate } = useTranslation();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userSubDomain = searchParams.get('userSubDomain');

  const { loginState } = useAuth();

  const { analyticsPageEvent } = useAnalyticsContext();
  
  const { loading: isLoading, error: errorMessage } = loginState;

  useEffect(() => {
    analyticsPageEvent({
      eventName: ANALYTICS_EVENTS.LOGIN_PAGE_VIEWED,
      pageName: PAGE_NAMES.LOGIN_PAGE,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginAndRegistrationLayout
      isLoading={ isLoading }
      title={ <> {translate(STRING_KEYS.WELCOME_BACK_TO)} <Accent>{translate(STRING_KEYS.PERFECT)}</Accent></> }
      showAccountLogo
      userSubDomain={ userSubDomain }
    >
      <LoginForm errorMessage={ errorMessage } />

      <PerfectSpacer height={ 8 } />

      <Wrapper>
        <DontHaveAccountText>
          {translate(STRING_KEYS.DONT_HAVE_ACCOUNT)}
        </DontHaveAccountText>

        <SignUpButton onClick={ () => AppRouting.navigateToUrl(AppRouting.buildGlobalOnboardingUrl(window.location)) }>
          {translate(STRING_KEYS.SIGNUP)}
        </SignUpButton>
      </Wrapper>
    </LoginAndRegistrationLayout>
  );
};
