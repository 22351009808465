import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectItem from '../../../../components/Common/select/select-item/select-item.component';
import {
  useGetAllowedCompaniesQuery,
  useGetUserOnboardingQuery,
} from '../../../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { PerfectButtonSize } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ReactComponent as CastleIcon } from '../../../../assets/fluentui-emojis/castle_color.svg';
import { ReactComponent as BullseyeIcon } from '../../../../assets/fluentui-emojis/bullseye_color.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/fluentui-emojis/mobile-phone.svg';
import useDebounce from '../../../../hooks/debounce';
import Spinner from '../../../../components/Common/spinner/spinner.component';
import { ASSETS } from '../../../../assets';
import STRING_KEYS from '../../../../language/keys';
import { CUSTOM_COMPANY, ROLES } from './onboarding-setup-profile-form.consts';
import {
  Form,
  PerfectButton,
  PerfectInput,
  PerfectPhoneInput,
  PerfectSelect,
  PlusIcon,
  SelectCustomItem,
  SelectItemColumn,
  SelectItemRow,
  SelectItemTitle,
} from './onboarding-setup-profile-form.styled';
import { OnboardingSetupProfileFormProps, OnboardingSetupProfileFormState } from './onboarding-setup-profile-form';

export const OnboardingSetupProfileForm = ({
  onSubmit,
}: OnboardingSetupProfileFormProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [companyInputValue, setCompanyInputValue] = useState<string>('');
  const [showCustomCompanyInput, setShowCustomCompanyInput] = useState<boolean>(false);
  const debouncedCompanyInputValue = useDebounce(companyInputValue, 300);

  const {
    data: { allowedCompanies = [] } = {},
    isFetching: isAllowedCompaniesLoading,
  } = useGetAllowedCompaniesQuery(
    { searchQuery: debouncedCompanyInputValue },
    {},
  );

  const { data: { user, accountCompany } = {} } = useGetUserOnboardingQuery();

  const [firstName, lastName] = user?.name?.split(' ') ?? [];

  // When registering a user not through social provider, the default first name is the email
  const isUserNameSameAsEmail = user?.email === firstName;

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormik<OnboardingSetupProfileFormState>({
    initialValues: {
      firstName: isUserNameSameAsEmail ? null : firstName,
      lastName: isUserNameSameAsEmail ? null : lastName,
      companyLinkedInUrl: accountCompany?.companyLinkedinUrl ?? allowedCompanies[0]?.sanitizedLinkedInUrl ?? null,
      recruitingUsageType: null,
      phoneNumber: user?.phoneNumber,
      customCompanyName: null,
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('Required field'),
      lastName: Yup.string().required('Required field'),
      companyLinkedInUrl: Yup.string().required('Required field'),
      recruitingUsageType: Yup.string().required('Required field'),
      phoneNumber: Yup.string().optional().nullable(),
      customCompanyName: Yup.string()
        .nullable()
        .when('companyLinkedInUrl', {
          is: (companyLinkedInUrl: string) => companyLinkedInUrl === CUSTOM_COMPANY.sanitizedLinkedInUrl,
          then: (schema) => schema.required('Required field'),
          otherwise: (schema) => schema.notRequired().nullable(),
        }),
    }),
  });

  // Handle company selection
  const handleCompanySelection = (value: string) => {
    setFieldValue('companyLinkedInUrl', value);

    if (value === CUSTOM_COMPANY.sanitizedLinkedInUrl) {
      setShowCustomCompanyInput(true);
    } else {
      setShowCustomCompanyInput(false);
      setFieldValue('customCompanyName', null);
    }
  };

  // Handle custom company name change
  const handleCustomCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFieldValue('customCompanyName', value);
  };

  const handleCompanyInputChange = (value: string) => {
    setCompanyInputValue(value);
  };

  return (
    <Form onSubmit={ handleSubmit }>
      <PerfectInput
        name="firstName"
        placeholder={ translate(STRING_KEYS.ONBOARDING.FORM.FIRST_NAME) }
        value={ values.firstName ?? '' }
        onChange={ handleChange }
      />

      <PerfectInput
        name="lastName"
        placeholder={ translate(STRING_KEYS.ONBOARDING.FORM.LAST_NAME) }
        value={ values.lastName ?? '' }
        onChange={ handleChange }
      />

      <PerfectSelect
        $spanBothColumns
        onChangeHandler={ handleCompanySelection }
        placeholder={ translate(STRING_KEYS.ONBOARDING.FORM.YOUR_COMPANY) }
        value={ values.companyLinkedInUrl ?? '' }
        StartAdornment={ <CastleIcon /> }
        isAutocomplete
        placement="bottom"
        onInputChange={ handleCompanyInputChange }
        showEmptyResults
        ActionIcon={
          isAllowedCompaniesLoading ? (
            <Spinner width={ 20 } height={ 20 } animationDuration={ 1000 } />
          ) : null
        }
      >
        {allowedCompanies?.map(({ name, sanitizedLinkedInUrl, logoUrl }) => (
          <SelectItem
            value={ sanitizedLinkedInUrl as string }
            key={ sanitizedLinkedInUrl }
            displayValue={ name }
            imageUrl={ logoUrl ?? ASSETS.EXPERIENCE_FALLBACK }
          >
            <div>{name}</div>
          </SelectItem>
        ))}
        <SelectCustomItem
          key={ CUSTOM_COMPANY.sanitizedLinkedInUrl }
          value={ CUSTOM_COMPANY.sanitizedLinkedInUrl as string }
          displayValue={ CUSTOM_COMPANY.id }
        >
          <SelectItemColumn>
            <SelectItemTitle>{ translate(STRING_KEYS.ONBOARDING.FORM.SELECT_CUSTOM_COMPANY.TITLE) }</SelectItemTitle>
            <SelectItemRow>
              <PlusIcon />
              { translate(STRING_KEYS.ONBOARDING.FORM.SELECT_CUSTOM_COMPANY.ACTION) }
            </SelectItemRow>
          </SelectItemColumn>
        </SelectCustomItem>
      </PerfectSelect>

      {showCustomCompanyInput && (
        <PerfectInput
          $spanBothColumns
          name="customCompanyName"
          placeholder={ translate(STRING_KEYS.ONBOARDING.FORM.CUSTOM_COMPANY) }
          value={ values.customCompanyName ?? '' }
          onChange={ handleCustomCompanyChange }
        />
      )}

      <PerfectSelect
        $spanBothColumns
        onChangeHandler={ (value) => setFieldValue('recruitingUsageType', value) }
        placeholder={ translate(STRING_KEYS.ONBOARDING.FORM.RECRUITING_FOR) }
        value={ values.recruitingUsageType ?? '' }
        StartAdornment={ <BullseyeIcon /> }
        placement="bottom"
      >
        {ROLES.map(({ label, value }) => (
          <SelectItem key={ value } value={ value } displayValue={ label }>
            <div>{label}</div>
          </SelectItem>
        ))}
      </PerfectSelect>

      <PerfectPhoneInput
        $spanBothColumns
        name="phoneNumber"
        placeholder={ translate(STRING_KEYS.ONBOARDING.FORM.PHONE_NUMBER_OPTIONAL) }
        value={ values.phoneNumber ?? '' }
        StartAdornment={ <PhoneIcon /> }
        onChange={ (value) => setFieldValue('phoneNumber', value) }
      />

      <PerfectButton
        $spanBothColumns
        type="submit"
        size={ PerfectButtonSize.ExtraLarge }
        isLoading={ isSubmitting }
        disabled={ !isValid }
      >
        { translate(STRING_KEYS.ONBOARDING.FORM.CONTINUE) }
      </PerfectButton>
    </Form>
  );
};
