import styled from 'styled-components';
import { Body2Font, CaptionFont } from '../../../../../../themes/fonts';
import { THEME } from '../../../../../../themes';
import { fadeIn } from '../chat-message.styled';

export const ChatAiBubble = styled.div`
  ${Body2Font};
  width: 100%;
  letter-spacing: 0.5px;
  padding: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.CONTENT_A};
  align-self: flex-start;
  word-wrap: break-word;
  display: inline-block;
`;

export const AIContainer = styled.span`
  animation: ${fadeIn} 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: both;
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const ChatAIUser = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const NliImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const AiTitle = styled.span`
  ${CaptionFont};
  align-content: center;
  font-weight: 500;
`;

export const OrderedList = styled.ol`
  padding: ${THEME.SPACING.TINY} 0 ${THEME.SPACING.TINY} ${THEME.SPACING.MEDIUM};
`;

export const UnorderedList = styled.ul`
  padding: ${THEME.SPACING.TINY} 0 ${THEME.SPACING.TINY} ${THEME.SPACING.MEDIUM};
`;

export const CustomParagraph = styled.p`
  padding-top: ${THEME.SPACING.TINY}; 
`;
