import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Body2Font, CaptionFont, OverlineFont, Subtitle2Font,
} from '../../../../themes/fonts';
import { PricingPlan } from '../../../../enums/user-plan.enum';
import MenuItem from '../../perfect-base-menu/menu-item/menu-item.component';

const getPlanBackgroundColor = (plan: string) => {
  switch (plan) {
    case PricingPlan.Scale:
    case PricingPlan.Trial:
      return THEME.COLORS_RGB.SECONDARY_012;
    case PricingPlan.Lite:
    default:
      return THEME.COLORS_RGB.ACCENT_012;
  }
};

export const StyledUserMenu = styled.div`
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  cursor: auto;
  overflow: hidden;
`;

export const CurrentPlan = styled.div<{ pricingPlan: string }>`
  border-radius: ${THEME.RADIUS.BASE};
  background-color: ${({ pricingPlan }) => getPlanBackgroundColor(pricingPlan)};
  border: 1px solid ${THEME.COLORS_RGB.SECONDARY_005};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;

export const CurrentPlanLabel = styled.div`
  ${OverlineFont};
  font-weight: 500;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const PlanDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const CurrentPlanTypeLabel = styled.div`
  ${Body2Font};
  font-weight: bold;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const CurrentPlanDaysLeft = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
  width: 100%;
  padding: ${THEME.SPACING.BASE};
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CurrentUserFullName = styled.div`
  ${Subtitle2Font};
  word-break: break-all;
  font-weight: bold;
`;

export const CurrentUserEmail = styled.div`
  ${Body2Font};
  word-break: break-all;
`;

export const PerfectMenuItem = styled(MenuItem)`
  width: 100%;
  
  :hover img {
    filter: invert(1);
  }
`;
