import { ConversationMessage } from '../dto/response/conversation-message';

export const recruiterAgentConversationWebSocketListeners = (
  currentPositionId: string,
  websocketDto: ConversationMessage,
  updateCachedData: (args: (recruiterAgentConversation: PaginationResponse<ConversationMessage[]>) => void) => void,
): void => {
  const { positionId } = websocketDto;
  if (currentPositionId === positionId) {
    updateCachedData((recruiterAgentConversation: PaginationResponse<ConversationMessage[]>) => {
      recruiterAgentConversation.results.push(websocketDto);
    });
  }
};
