import {
  SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';
import STRING_KEYS from '../../../../language/keys';
import { PERMISSIONS } from '../../../../consts/permissions.const';
import { usePermissionCheck } from '../../../../hooks/use-permissions.hook';
import { PermissionPlaceholder } from '../../../../enums/permission-placeholder.enum';
import { RolesMenuButton } from '../roles-menu-button/roles-menu-button.container';
import { RolesMenu } from '../roles-menu/roles-menu.component';
import { UserRoleName } from '../../../../enums/user-role-name.enum';
import { useGetAccountByIdQuery } from '../../../../store/rtk-query/apis/account/account.toolkit-api';
import { useGetAccountUsersQuery, useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import { PerfectSpacer } from '../../../Common/perfect-spacer/perfect-spacer.component';
import {
  GenericUpgradeButton,
} from '../../../../modules/application/components/generic-upgrade-button/generic-upgrade-button.component';
import { PricingPlan } from '../../../../enums/user-plan.enum';
import { MaximumSeatsReached } from './maximum-seats-reached/maximum-seats-reached.component';
import {
  EmailContainer,
  EmailRow,
  PerfectButton,
  PerfectInput,
  SeatsCounterWrapper,
  StyledButtonWrapper,
  Title,
  TitlesWrapper,
} from './invite-teammate-form.styled';
import { InviteTeammateFormProps } from './invite-teammate-form';

const logger = log.getLogger('InviteTeammateForm');

export const InviteTeammateForm = ({
  email,
  role,
  onEmailChange,
  onRoleChange,
  onSubmit,
  isEmailError,
}: InviteTeammateFormProps): JSX.Element => {
  const [roleMenuAnchorElement, setRoleMenuAnchorElement] = useState(null);

  const ref = useRef(null);

  const toggleRoleMenu = () => roleMenuAnchorElement ?
    setRoleMenuAnchorElement(null) :
    setRoleMenuAnchorElement(ref.current);
  const closeRoleMenu = () => setRoleMenuAnchorElement(null);

  const onSelectRoleHandler = (value: UserRoleName) => {
    onRoleChange(value);
    closeRoleMenu();
  };

  const { data: { accountId } = {} } = useGetMyUserQuery();
  const { data: { plan } = {} } = useGetAccountByIdQuery(accountId, {
    skip: !accountId,
  });

  const { userSeatsPlanLimit, isCreditsBased, pricingPlan } = plan;

  const {
    data: accountUsers = [],
    isSuccess: areAccountUsersFetched,
  } = useGetAccountUsersQuery(undefined, { skip: !isCreditsBased });

  const { t: translate } = useTranslation();

  const { allowed: allowedInvite, resolvedPermissions } = usePermissionCheck([
    PERMISSIONS.user.inviteAll,
    PERMISSIONS.user.inviteCollaborator,
  ]);

  const isInviteButtonDisabled = email === '' || !role || !allowedInvite;
  const hasLimitedNumberOfSeats = isCreditsBased && pricingPlan !== PricingPlan.Tier1;
  const hasReachedMaximumSeats =
      isCreditsBased && accountUsers.length >= userSeatsPlanLimit;

  useEffect(() => {
    if (resolvedPermissions.userPermission !== PermissionPlaceholder.Wildcard) {
      onRoleChange(UserRoleName.Collaborator);
    }
  }, [onRoleChange, resolvedPermissions.userPermission]);

  const handleEmailInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    onEmailChange(target.value);
  };

  const handleSubmit = () => {
    try {
      onSubmit({ email, role });
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <>
      <EmailContainer>
        <TitlesWrapper>
          <Title>
            {translate(
              STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_A_TEAMMATE_TITLE,
            )}
          </Title>

          {areAccountUsersFetched && hasLimitedNumberOfSeats && (
            <SeatsCounterWrapper>
              <GenericUpgradeButton isLinkButton />
            </SeatsCounterWrapper>
          )}
        </TitlesWrapper>

        <EmailRow>
          <PerfectInput
            placeholder={ translate(
              STRING_KEYS.INVITE_YOUR_TEAMMATES.PLACEHOLDER,
            ) }
            onChange={ handleEmailInputChange }
            value={ email }
            $isError={ isEmailError }
            disabled={ hasLimitedNumberOfSeats && hasReachedMaximumSeats }
          />

          {resolvedPermissions.placeholders ===
            PermissionPlaceholder.Wildcard && (
            <RolesMenuButton
              isActive={ !!roleMenuAnchorElement }
              selectedRole={ role }
              onClickOpenRoleMenuHandler={ toggleRoleMenu }
              ref={ ref }
              disabled={ hasLimitedNumberOfSeats && hasReachedMaximumSeats }
            />
          )}

          <StyledButtonWrapper>
            <PerfectButton
              onClick={ handleSubmit }
              disabled={ isInviteButtonDisabled || (hasLimitedNumberOfSeats && hasReachedMaximumSeats) }
            >
              {translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_BUTTON)}
            </PerfectButton>
          </StyledButtonWrapper>
        </EmailRow>
      </EmailContainer>

      {hasLimitedNumberOfSeats && hasReachedMaximumSeats && (
        <>
          <PerfectSpacer height={ 8 } />

          <MaximumSeatsReached />
        </>
      )}

      <RolesMenu
        handleMenuClose={ closeRoleMenu }
        onSelectRoleHandler={ onSelectRoleHandler }
        anchorElement={ roleMenuAnchorElement || undefined }
      />
    </>
  );
};
