import { createApi } from '@reduxjs/toolkit/query/react';
import log from 'loglevel';
import { axiosBaseQuery } from '../../utils/base-query';
import { ConversationMessageType } from '../../../../enums/conversation-message-type.enum';
import axiosInstance from '../../../../api';
import socketManager from '../../../../services/socket-io-manager';
import { SocketNamespace } from '../../../../enums/socket-namespace.enum';
import {
  socketRecruiterAgentConnected,
  socketRecruiterAgentDisconnected,
} from '../../../slices/app-state/app-state.toolkit-slice';
import { SocketEventName } from '../../../../enums/socket-event-name.enum';
import { POSITION_ID_PATH_PARAM, REDUCER_PATH, ROUTES } from './recruiter-agent.consts';
import { RecruiterAgentInfoResponse } from './dto/response/recruiter-agent-info.response';
import {
  createMessageQuery,
  getConversationQuery,
  getRecruiterAgentInfoQuery,
  retryGenerationQuery,
} from './recruiter-agent.toolkit-queries';
import { CreateMessageResponse } from './dto/response/create-message.response';
import { ConversationMessage } from './dto/response/conversation-message';
import { GetConversationMessageQueryArguments } from './dto/query-arguments/get-conversation-message.query-arguments';
import {
  getConversationByPositionIdResponseTransformer,
} from './transformers/get-conversation-by-position-id-response.transformer';
import { ConversationMessageChunkResponse } from './dto/response/conversation-message-chunk.response';
import {
  agentStatusChangedCacheWebsocketListeners,
} from './websocket-listeners/agent-generation-and-matching-state-changed.websocket-listeners';
import { RetryGenerationQueryArguments } from './dto/query-arguments/retry-generation.query-arguments';
import { CreateMessageQueryArguments } from './dto/query-arguments/create-message.query-arguments';
import { GenerateMessageQueryArguments } from './dto/query-arguments/generate-message.query-arguments';
import {
  recruiterAgentConversationWebSocketListeners,
} from './websocket-listeners/recruter-agent-conversation.websocket-listeners';

const logger = log.getLogger('RECRUITER_AGENT_API');

/* eslint-disable @typescript-eslint/no-explicit-any */
const isChunkResponse = (
  message: any,
): message is ConversationMessageChunkResponse => {
  return 'chunkOrder' in message;
};

// Parse SSE message
const parseSSEMessage = (rawMessage: string) => {
  const result: { id?: string; data?: string } = {};

  rawMessage
    .split('\n')
    .filter((line) => line.trim())
    .forEach((line) => {
      if (line.startsWith('id: ')) {
        result.id = line.slice(4);
      } else if (line.startsWith('data: ')) {
        result.data = line.slice(6);
      }
    });

  return result;
};

// Add this type and helper function at the top level
interface ChunkStore {
  [key: number]: ConversationMessageChunkResponse;
}

const getAccumulatedMessage = (chunks: ChunkStore): string => {
  return Object.values(chunks)
    .sort((a, b) => a.chunkOrder - b.chunkOrder)
    .map((chunk) => chunk.message)
    .join('');
};

export const recruiterAgentQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['RecruiterAgent', 'getConversation', 'getStreamingMessage'],
  endpoints: (builder) => ({
    getRecruiterAgentInfo: builder.query<RecruiterAgentInfoResponse, string>({
      query: getRecruiterAgentInfoQuery,
      providesTags: ['RecruiterAgent'],
      async onCacheEntryAdded(
        arg,
        { updateCachedData: agentInfo, cacheDataLoaded, dispatch },
      ) {
        const recruiterAgentSocket = socketManager.getSocket({
          namespace: SocketNamespace.RecruiterAgent,
          onSocketConnected: () => {
            dispatch(socketRecruiterAgentConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketRecruiterAgentDisconnected());
          },
        });

        if (recruiterAgentSocket) {
          await cacheDataLoaded;
          recruiterAgentSocket.on(
            SocketEventName.AgentGenerationAndMatchingStateChanged,
            (socketPayload) => {
              agentStatusChangedCacheWebsocketListeners(
                socketPayload,
                agentInfo,
              );
            },
          );
        }
      },
    }),
    getConversation: builder.query<PaginationResponse<ConversationMessage[]>, GetConversationMessageQueryArguments>({
      query: getConversationQuery,
      transformResponse: (response: PaginationResponse<ConversationMessage[]>) => {
        return getConversationByPositionIdResponseTransformer(response);
      },
      providesTags: ['getConversation'],
      async onCacheEntryAdded(arg, { dispatch, updateCachedData, cacheDataLoaded }) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.RecruiterAgent,
          onSocketConnected: () => {
            dispatch(socketRecruiterAgentConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketRecruiterAgentDisconnected());
          },
        });

        if (socket) {
          await cacheDataLoaded;
          socket.on(
            SocketEventName.RecruiterAgentConversationMessageAdded,
            (socketPayload: ConversationMessage) => recruiterAgentConversationWebSocketListeners(arg.positionId, socketPayload, updateCachedData),
          );
        }
      },
    }),
    generatingMessage: builder.query<ConversationMessage | null, GenerateMessageQueryArguments>({
      queryFn: () => ({
        data: null,
      }),
      providesTags: ['getConversation'],
    }),
    createMessage: builder.mutation<CreateMessageResponse, CreateMessageQueryArguments>({
      query: createMessageQuery,
      invalidatesTags: ['getConversation'],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            recruiterAgentQuery.util.invalidateTags([
              { type: 'getConversation', id: arg.positionId },
            ]),
          );
        } catch (error) {
          logger.error('Error in createMessage mutation:', error);
        }
      },
    }),
    retryGeneration: builder.mutation<string, RetryGenerationQueryArguments>({
      query: retryGenerationQuery,
      invalidatesTags: ['getConversation'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          logger.error('Error in retryGeneration mutation:', error);
        }
      },
    }),
    getStreamingMessage: builder.query<{
      currentMessage: ConversationMessage | null;
      isStreamComplete: boolean;
    }, { positionId: string; shouldStream: boolean, streamKey?: string }>({
      queryFn: () => ({
        data: {
          currentMessage: null,
          isStreamComplete: false,
        },
      }),
      providesTags: (result, error, arg) => [
        { type: 'getStreamingMessage', id: `${arg.positionId}-${arg.streamKey}` },
      ],
      async onCacheEntryAdded(
        { positionId, shouldStream },
        {
          updateCachedData, cacheDataLoaded, dispatch, getState,
        },
      ) {
        if (!shouldStream) return;

        await cacheDataLoaded;

        let buffer = '';
        let chunks: ChunkStore = {};
        let lastChunk: ConversationMessageChunkResponse | null = null;
        let timeoutId: NodeJS.Timeout;
        const state = getState();

        // Set timeout for streaming
        const streamTimeout = new Promise(() => {
          timeoutId = setTimeout(() => {
            updateCachedData((draft) => {
              draft.isStreamComplete = true;
              logger.debug('Timeout occurred. isStreamComplete is being set to true.');
            });
          }, 10000); // 30 seconds timeout
        });

        try {
          await Promise.race([
            await axiosInstance({
              method: 'get',
              url: `v1${ROUTES.RECRUITER_AGENT_MESSAGE_CHUNK.replace(POSITION_ID_PATH_PARAM, positionId)}`,
              responseType: 'stream',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              onDownloadProgress: (progressEvent) => {
                const xhr = progressEvent.currentTarget as XMLHttpRequest;
                const rawChunk = xhr?.response || '';
                buffer += rawChunk;

                // Split buffer by double newlines (SSE messages are separated by double newlines)
                const messages = buffer.split('\n\n');
                buffer = messages.pop() || ''; // Keep the last incomplete message in buffer

                messages
                  .filter((message) => message.trim())
                  .forEach((message) => {
                    try {
                      const { data } = parseSSEMessage(message);
                      if (!data) return;

                      const parsedData = JSON.parse(data);
                      const getConversationQueries = recruiterAgentQuery.util.selectInvalidatedBy(state, [{ type: 'getConversation' }]);

                      // Check if this is a chunk or final message
                      if (isChunkResponse(parsedData)) {
                        const messageExists = getConversationQueries.some((query) => {
                          const conversationData = recruiterAgentQuery.endpoints.getConversation.select(query.originalArgs)(state).data;

                          return conversationData?.results.some(
                            (msg) => {
                              return msg.generationId === parsedData.generationId;
                            },
                          );
                        });

                        if (messageExists) {
                          // If message exists, complete streaming and abort the request
                          updateCachedData((draft) => {
                            draft.isStreamComplete = true;
                          });
                          xhr.abort();

                          getConversationQueries.forEach((query) => {
                            dispatch(
                              recruiterAgentQuery.util.updateQueryData(
                                'getConversation',
                                query.originalArgs,
                                (conversationDraft) => {
                                  // Remove any streaming message with the same generationId
                                  const existingIndex = conversationDraft.results.findIndex(
                                    (msg) => msg.generationId === parsedData.generationId,
                                  );

                                  if (existingIndex !== -1) {
                                    // Remove the streaming message
                                    conversationDraft.results.splice(existingIndex, 1);
                                    conversationDraft.totalCount -= 1;
                                  }
                                },
                              ),
                            );
                          });

                          return;
                        }

                        // Handle chunk message
                        lastChunk = parsedData;
                        chunks[parsedData.chunkOrder] = parsedData;

                        // Get properly ordered message
                        const accumulatedMessage = getAccumulatedMessage(chunks);

                        logger.debug('Received chunk:', {
                          chunkInfo: {
                            positionId: parsedData.positionId,
                            generationId: parsedData.generationId,
                            chunkOrder: parsedData.chunkOrder,
                            messageLength: parsedData.message.length,
                          },
                        });

                        const streamingMessage = {
                          id: '',
                          agentId: parsedData.agentId,
                          accountId: parsedData.accountId,
                          positionId: parsedData.positionId,
                          generationId: parsedData.generationId,
                          message: accumulatedMessage,
                          senderId: parsedData.senderId,
                          messageType: ConversationMessageType.AgentMessage,
                          isTyping: true,
                        };

                        updateCachedData((draft) => {
                          draft.currentMessage = streamingMessage;
                          draft.isStreamComplete = false;
                        });

                        getConversationQueries.forEach((query) => {
                          dispatch(
                            recruiterAgentQuery.util.updateQueryData(
                              'getConversation',
                              query.originalArgs,
                              (draft) => {
                                // Find and update existing message or add new one
                                const existingMessageIndex = draft.results.findIndex(
                                  (msg) => msg.generationId === streamingMessage.generationId,
                                );

                                if (existingMessageIndex !== -1) {
                                  draft.results[existingMessageIndex] = streamingMessage;
                                } else {
                                  // const existingThinkingMessageIndex = draft.results.findIndex(
                                  //   (msg) => msg.isThinking,
                                  // );
                                  // draft.results[existingThinkingMessageIndex] = streamingMessage;
                                }
                              },
                            ),
                          );
                        });
                      } else {
                        // Handle final message
                        const finalMessage = parsedData as ConversationMessage;

                        clearTimeout(timeoutId);

                        // Clear the chunks store
                        chunks = {};

                        logger.debug('Received final message:', {
                          messageInfo: {
                            id: finalMessage.id,
                            messageType: finalMessage.messageType,
                          },
                        });

                        updateCachedData((draft) => {
                          draft.currentMessage = {
                            ...finalMessage,
                            isTyping: false,
                          };
                          draft.isStreamComplete = true;
                        });

                        getConversationQueries.forEach((query) => {
                          dispatch(
                            recruiterAgentQuery.util.updateQueryData(
                              'getConversation',
                              query.originalArgs,
                              (draft) => {
                                // Find and update existing message or add new one
                                const existingMessageIndex = draft.results.findIndex(
                                  (msg) => msg.generationId === finalMessage.generationId,
                                );

                                if (existingMessageIndex !== -1) {
                                  draft.results[existingMessageIndex] = finalMessage;
                                } else {
                                  draft.results.push(finalMessage);
                                  draft.totalCount += 1;
                                }
                              },
                            ),
                          );
                        });
                      }
                    } catch (e) {
                      logger.error('Error parsing message:', e);
                    }
                  });
              },
            }),
            streamTimeout,
          ]);
        } catch (error) {
          logger.warn(error);
        }

        // Handle any remaining buffer content
        if (buffer.trim()) {
          try {
            const { data } = parseSSEMessage(buffer);
            if (data) {
              const parsedData = JSON.parse(data);

              if (!isChunkResponse(parsedData)) {
                const finalMessage = parsedData as ConversationMessage;
                updateCachedData((draft) => {
                  draft.currentMessage = {
                    ...finalMessage,
                    isTyping: false,
                  };
                  draft.isStreamComplete = true;
                });
              }
            }
          } catch (e: any) {
            logger.error('Error parsing final buffer content:', e);

            // Fallback to accumulated message if final parsing fails
            if (lastChunk) {
              updateCachedData((draft) => {
                if (draft.currentMessage) {
                  draft.currentMessage.isTyping = false;
                  draft.isStreamComplete = true;
                }
              });
            }
          }
        }
      },
    }),
  }),
});

export const { useGetStreamingMessageQuery } = recruiterAgentQuery;
