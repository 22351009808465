import { INITIAL_LOADING } from './app-state.consts';
import { UserIdentifyMethod } from './app-state.enums';
import { AppSnackbarDto } from './dto/app-snackbar.dto';

export type AppState = {
  activeLoaders: Array<string>,
  initialLoadings: Array<string>,
  isLoggingOutFromRemoteTab: boolean,
  isOnboardingLogout: boolean,
  userRoleWasChanged: boolean,
  currentPageId: string,
  referrerPageId: string, // reference to the previous page-id (for analytics tracking)
  identifyMethod: UserIdentifyMethod,
  isNewSignup: boolean,
  snackbar: AppSnackbarDto | undefined,
  sockets: {
    talentAcquisition: {
      connected: boolean,
    },
    outreach: {
      connected: boolean,
    },
    user: {
      connected: boolean,
    },
    recruiterAgent: {
      connected: boolean,
    }
  },
  latestAppVersion: string,
  isFaviconFlashing: boolean,
  isUnsavedChanges: boolean,
}

export const initialAppState: AppState = {
  userRoleWasChanged: false,
  activeLoaders: [],
  initialLoadings: [
    INITIAL_LOADING.LOAD_ACCOUNT_DATA,
    INITIAL_LOADING.LOAD_RUDDERSTACK,
    INITIAL_LOADING.LOAD_POSITIONS,
  ],
  currentPageId: '',
  referrerPageId: '',
  latestAppVersion: '',
  isLoggingOutFromRemoteTab: false,
  isOnboardingLogout: false,
  identifyMethod: UserIdentifyMethod.Local,
  isNewSignup: false,
  snackbar: undefined,
  sockets: {
    talentAcquisition: {
      connected: false,
    },
    outreach: {
      connected: false,
    },
    user: {
      connected: false,
    },
    recruiterAgent: {
      connected: false,
    },
  },
  isFaviconFlashing: false,
  isUnsavedChanges: false,
};
