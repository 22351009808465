import { useRef, useState } from 'react';
import { getInitials } from '../../../../../utils';
import { InitialsAvatar } from '../../../../Common/InitialsAvatar';
import { IconButtonVariants } from '../../../../Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { usePermissionCheck } from '../../../../../hooks/use-permissions.hook';
import { PERMISSIONS } from '../../../../../consts/permissions.const';
import { PermissionPlaceholder } from '../../../../../enums/permission-placeholder.enum';
import { UserRoleName } from '../../../../../enums/user-role-name.enum';
import { RolesMenuButton } from '../../roles-menu-button/roles-menu-button.container';
import { RolesMenu } from '../../roles-menu/roles-menu.component';
import { ActionsMenu } from '../../action-menu/actions-menu.component';
import { ButtonVariant } from '../../roles-menu-button/roles-menu-button.enums';
import {
  useAssignUserRoleMutation,
} from '../../../../../store/rtk-query/apis/user/hooks/assign-user-role.mutation-hook';
import { canDoInvitationActions } from '../../../../../utils/permissions.util';
import { useMyUserSelector } from '../../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import ItemContent from './ItemContent';
import { InvitedColleaguesListItemProps } from './invited-teammates-list-item';
import {
  ActionContainer,
  ActionsMenuButton,
  ActionsMenuButtonIcon,
  Container,
  ContentWrapper,
  EmptyActionsMenuButton,
  StyledAvatar,
} from './invited-teammates-list-item.styled';

export const InvitedColleaguesListItem = (
  props: InvitedColleaguesListItemProps,
): JSX.Element => {
  const {
    fullName,
    email,
    profileImage,
    invitePending,
    userRoleName,
    id,
    onResetError,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [roleMenuAnchorElement, setRoleMenuAnchorElement] = useState(null);
  const { resolvedPermissions } = usePermissionCheck([PERMISSIONS.user.inviteAll, PERMISSIONS.user.inviteCollaborator]);
  const roleMenuRef = useRef(null);
  const onClickOpenRoleMenu = () => roleMenuAnchorElement ? setRoleMenuAnchorElement(null) : setRoleMenuAnchorElement(roleMenuRef.current);
  const handleMenuClose = () => setRoleMenuAnchorElement(null);
  const [actionsMenuAnchorElement, setActionsMenuAnchorElement] = useState(null);
  const actionMenuRef = useRef(null);
  const onClickOpenActionMenu = () => actionsMenuAnchorElement ? setActionsMenuAnchorElement(null) : setActionsMenuAnchorElement(actionMenuRef.current);
  const handleActionMenuClose = () => setActionsMenuAnchorElement(null);
  const [assignUserRoleAction] = useAssignUserRoleMutation();
  const { data: user } = useMyUserSelector();
  const onSelectRoleHandler = (newUserRoleName: UserRoleName) => {
    assignUserRoleAction({
      userId: id,
      userRoleName: newUserRoleName,
    });
    handleMenuClose();
  };

  const disableRolesMenuButton = () => {
    if (user.id === id) {
      return true;
    }

    return resolvedPermissions.placeholders !== PermissionPlaceholder.Wildcard;
  };

  const renderActionsMenuButton = () => {
    const canRenderActionButton = canDoInvitationActions(resolvedPermissions.placeholders, userRoleName);

    if (!canRenderActionButton) {
      return <EmptyActionsMenuButton />;
    }

    return (
      <>
        <ActionsMenuButton
          type="button"
          variant={ IconButtonVariants.Ghost }
          hoverVariant={ PerfectButtonHoverVariants.Darkened }
          StartIcon={ ActionsMenuButtonIcon }
          onClick={ onClickOpenActionMenu }
          ref={ actionMenuRef }
        />
      </>
    );
  };

  return (
    <>
      <Container
        ref={ containerRef }
      >
        <ContentWrapper>
          <StyledAvatar
            alt={ fullName }
            src={ profileImage }
          >
            <InitialsAvatar
              alt={ fullName }
              message={ getInitials(fullName) }
              fontSize="14px"
            />
          </StyledAvatar>

          <ItemContent
            email={ email }
            fullName={ fullName }
            invitePending={ invitePending }
          />
        </ContentWrapper>
        
        <ActionContainer>
          <RolesMenuButton
            isActive={ !!roleMenuAnchorElement }
            selectedRole={ userRoleName }
            onClickOpenRoleMenuHandler={ onClickOpenRoleMenu }
            ref={ roleMenuRef }
            variant={ ButtonVariant.Filled }
            disabled={ disableRolesMenuButton() }
          />
          { renderActionsMenuButton() }
        </ActionContainer>
      </Container>
      <RolesMenu
        handleMenuClose={ handleMenuClose }
        onSelectRoleHandler={ onSelectRoleHandler }
        anchorElement={ roleMenuAnchorElement || undefined }
      />
      <ActionsMenu
        handleMenuClose={ handleActionMenuClose }
        onActionClickHandler={ handleActionMenuClose }
        invitePending={ invitePending }
        anchorElement={ actionsMenuAnchorElement || undefined }
        onResetErrorHandler={ onResetError }
        userId={ id }
        email={ email }
      />
    </>
  );
};
