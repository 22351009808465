import { BrowserRouter } from 'react-router-dom';
import { FronteggProvider } from '@frontegg/react';
import { useDispatch } from 'react-redux';
import { useAppState } from '../../store/selectors';
import { MainRouter } from '../routers/main.router';
import { AnalyticsProvider } from '../../contexts/analytics';
import { deleteSnackbar } from '../../store/slices/app-state/app-state.toolkit-slice';
import { AppSnackbar } from '../Common/CustomSnackBar/app-snackbar/app-snackbar.component';
import useFlashingFavicon from '../../modules/application/hooks/flashing-favicon';
import { SplashScreen } from '../Common/Loaders/SplashScreen/SplashScreen';
import { LogRocketProvider } from '../../providers/LogRocketProvider/LogRocketProvider';
import { GlobalStyle } from './styled';

const contextOptions = {
  baseUrl: process.env.REACT_APP_FRONT_EGG_BASE_URL || '',
};

export const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    showSplashScreen,
    snackbar,
  } = useAppState();
  useFlashingFavicon();

  return (
    <>
      <GlobalStyle />
      { showSplashScreen && <SplashScreen title="Splash" /> }
      <FronteggProvider customLoginBox contextOptions={ contextOptions }>
        <AnalyticsProvider>
          <LogRocketProvider>
            <BrowserRouter>
              <MainRouter />
              { snackbar && (
                <AppSnackbar
                  title={ snackbar.title }
                  description={ snackbar.description }
                  isOpen={ !!snackbar }
                  shouldAutoDismiss={ snackbar.shouldAutoDismiss ?? true }
                  onClose={ () => dispatch(deleteSnackbar()) }
                  anchor={ snackbar.anchor }
                  variant={ snackbar.variant }
                />
              ) }
            </BrowserRouter>
          </LogRocketProvider>
        </AnalyticsProvider>
      </FronteggProvider>
    </>
  );
};
