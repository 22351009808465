import styled from 'styled-components';

import { THEME } from '../../../themes';
import { ButtonFont, CaptionFont } from '../../../themes/fonts';

export const Accent = styled.span`
  color: ${THEME.COLORS.ACCENT};
`;

export const Wrapper = styled.div`
  text-align: center;
  flex-direction: column;
`;

export const DontHaveAccountText = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const SignUpButton = styled.div`
  ${ButtonFont};
  width: 48px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  justify-self: center;
  cursor: pointer;
  margin-top: ${THEME.SPACING.TINY};
  color: ${THEME.COLORS.ACCENT};
`;
