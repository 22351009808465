import React, { memo } from 'react';
import { isProduction } from '../../../../utils/environment';
import { LogoAnimation } from '../logo-animation/logo-animation.component';
import { Container, LoadingMessage } from './SplashScreen.styled';

type SplashScreenProps = {
  title: string,
};

export const SplashScreen = memo(({
  title,
}: SplashScreenProps) :JSX.Element => {
  return (
    <Container>
      <LogoAnimation />

      {!isProduction() && (
        <>
          <LoadingMessage>Loading {title}...</LoadingMessage>
          <LoadingMessage>(Developer mode ON)</LoadingMessage>
        </>
      )}
    </Container>
  );
});

SplashScreen.displayName = 'SplashScreen';
