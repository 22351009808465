import styled  from 'styled-components';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { CaptionFont, Subtitle2Font } from '../../../../../themes/fonts';
import { TextEllipsis } from '../../../../../themes/text';
import { ASSETS } from '../../../../../assets';
import Steps from '../../../../../components/Common/outreach-steps/outreach-steps.component';

const TITLE_MAX_CHARS_LENGTH = 23;
const LABEL_CHARS_LENGTH = 4;
const STEPS_CHARS_LENGTH = 5;

const getConversationBackgroundColor = (active: boolean) => {
  if (active) {
    return hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER);
  }

  return undefined;
};

export const ConversationItemContainer = styled.div<{active: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  padding: ${THEME.SPACING.LOOSE} ${THEME.SPACING.TINY};
  margin-left: ${THEME.SPACING.BASE};
  margin-right: ${THEME.SPACING.MICRO};
  background-color: ${({ active }) => getConversationBackgroundColor(active)};
  border-radius: ${THEME.RADIUS.BASE};
  :hover {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.GHOSTLY)};
    cursor: pointer;
  }
`;

export const NewLabelIcon = styled(ASSETS.NEW_LABEL)`
  fill: ${THEME.COLORS.ACCENT};
  width: 24px;
  height: 16px;
`;

export const TaskLabelIcon = styled(ASSETS.TASK_LABEL)`
  fill: ${THEME.COLORS.ACCENT};
  width: 37px;
  height: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  width: 100%;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${THEME.SPACING.NANO};
  overflow: hidden;
`;

const getTitleMaxWidthCh = (hasLabel?: boolean, hasSteps?: boolean) => {
  let maxWidthCh = TITLE_MAX_CHARS_LENGTH;

  if (hasLabel) {
    maxWidthCh -= LABEL_CHARS_LENGTH;
  }

  if (hasSteps) {
    maxWidthCh -= STEPS_CHARS_LENGTH;
  }

  return maxWidthCh;
};

export const Title = styled.div<{ hasLabel?: boolean, hasSteps?: boolean }>`
  ${TextEllipsis};
  max-width: ${({ hasLabel, hasSteps }) => getTitleMaxWidthCh(hasLabel, hasSteps)}ch;
  ${Subtitle2Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Description = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const Dot = styled.div`
  height: 2px;
  width: 2px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  flex-shrink: 0;
  background-color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;

export const Message = styled.div<{ isLocked?: boolean }>`
  ${TextEllipsis};
  flex-grow: 1;
  filter: ${({ isLocked }) => isLocked ? 'blur(3px)' : undefined};
`;

export const DateContainer = styled.div`
  ${TextEllipsis};
  flex-shrink: 0;
`;

export const SendMessageLink = styled.div`
  ${TextEllipsis};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
`;

export const RepliedIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background-color: ${THEME.COLORS.ACCENT};
`;

export const ErrorIcon = styled(ASSETS.ALERT)`
  width: 24px;
  height: 24px;
  fill: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.MED)};
`;

export const CheckedIcon = styled(ASSETS.CHECKED_CIRCLE_DONE_FILLED)`
  fill: ${THEME.COLORS.ACCENT};
  width: 16px;
  height: 16px;
`;

export const styledStep = styled(Steps)<StepsProps & { isTask: boolean }>`
  
`;
