export enum PricingPlan {
  Trial = 'TRIAL',
  /** @deprecated */
  Lite = 'LITE',
  /** @deprecated */
  Pro = 'PRO',
  /** @deprecated */
  Scale = 'SCALE',
  Tier1 = 'TIER_1',
  Tier2 = 'TIER_2',
  Tier3 = 'TIER_3',
}
