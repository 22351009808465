import log from 'loglevel';
import { MatchResponse } from '../../match/dto/response/match.response';
import { PositionResponse } from '../dto/response/position.response';
import { MatchPublishedWebsocketDto } from '../../match/dto/socket/match-published.socket';

const logger = log.getLogger('NEW_MATCH_WEBSOCKET_LISTENERS');

const createMatchDto = (match: MatchResponse): Match => {
  return ({
    engagementStatus: match.engagement?.status || '',
    firstName: match.talent.firstName,
    lastName: match.talent.lastName,
    id: match.id,
    lastModified: match.lastModified || '',
    profileImage: match.talent.profileImage,
    publishedAt: match.publishedAt,
    viewed: false,
    isNew: true,
  });
};

export const matchPublishedPositionsCacheWebsocketListener = (
  websocketDto: MatchPublishedWebsocketDto,
  updateCachedData: (args: (positions: PositionResponse[]) => void) => void,
): void => {
  const { match: { positionId, id: matchId }, positionStatistics } = websocketDto;
  updateCachedData((positions: PositionResponse[]) => {
    const position = positions.find(({ id }) => id === positionId);
    if (position) {
      position.statistics = positionStatistics;
      logger.debug('Position statistics updated from match published socket event', matchId);
    }
  });
};

export const matchPublishedPositionCacheWebsocketListener = (
  websocketDto: MatchPublishedWebsocketDto,
  updateCachedData: (args: (position: PositionResponse) => void) => void,
): void => {
  const { match, positionStatistics } = websocketDto;
  updateCachedData((position: PositionResponse) => {
    if (position && match.positionId === position.id) {
      position.statistics = positionStatistics;
      if (!position.matches.some(({ id }) => match.id === id)) {
        logger.debug('New match was added from socket event', match.id);
        position.matches.push(createMatchDto(match));
      }
    }
  });
};
