import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { OnboardingState } from '../onboarding-state.initial-state';

export const setAiChatPromptReducer: CaseReducer<OnboardingState, PayloadAction<string>> = (state, action) => {
  state.aiChatPrompt = action.payload;
};

export const clearPromoAndAiChatPromptReducer: CaseReducer<OnboardingState, PayloadAction<string>> = (state) => {
  state.promo = '';
  state.aiChatPrompt = '';
};
