import React from 'react';
import { CustomParagraph, OrderedList, UnorderedList } from '../chat-ai-message/chat-ai-message.styled';
import { CustomParagraphProps, ListProps } from './chat-message-markdown';

export const CustomOL = ({ children, ...props }: ListProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <OrderedList { ...props }>
    {children}
  </OrderedList>
);

export const CustomUL = ({ children, ...props }: ListProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <UnorderedList { ...props }>
    {children}
  </UnorderedList>
);

export const CustomP = ({ children, ...props }: CustomParagraphProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CustomParagraph { ...props }>
    {children}
  </CustomParagraph>
);
