import { ScheduledMessageTooltip } from '../../scheduled-message-tooltip/scheduled-message-tooltip.component';
import {
  Container, LeftContainer, BoldText, InfoIcon,
} from './text-editor-header.styled';

const TextEditorHeader = ({
  label,
  infoText,
  Icon,
  EndAdornment,
  ...otherProps
}: TextEditorHeaderProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container { ...otherProps }>
      <LeftContainer>
        { Icon }
        <div>
          { label && <div>{label}</div> }
          { infoText && (
            <BoldText>
              {infoText}
              <ScheduledMessageTooltip>
                <InfoIcon />
              </ScheduledMessageTooltip>
            </BoldText>
          )}
        </div>
      </LeftContainer>
      { EndAdornment }
    </Container>
  );
};

export default TextEditorHeader;
