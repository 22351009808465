import { useAuth } from '@frontegg/react';
import { PropsWithChildren } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { AppRouting } from '../services/appRoutingResolver';
import { useGetUserOnboardingQuery } from '../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { ONBOARDING_STEP_TO_ROUTE } from '../modules/onboarding/consts/onboarding.consts';
import { useGetMyUserQuery } from '../store/rtk-query/apis/user/user.toolkit-api';
import { useCurrentSubDomain } from '../hooks/use-current-sub-domain';
import { SubDomainType } from '../store/slices/app-state/app-state.enums';
import { SplashScreen } from '../components/Common/Loaders/SplashScreen/SplashScreen';

/**
 *  This guard ensures that users are redirected to the appropriate onboarding step if they are currently in the onboarding process.
 *  If the user is not in onboarding or is already on the correct onboarding route, it renders the provided children components.
 */
export const OnboardingGuard = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();

  const { pathname } = useLocation();

  const { currentSubDomainType } = useCurrentSubDomain();

  const {
    data: { isOnboarding: isUserInOnboarding } = {},
    isLoading: isLoadingUser,
  } = useGetMyUserQuery(undefined, { skip: !isAuthenticated });
  const {
    data: { step: onboardingStep } = {},
    isLoading: isLoadingUserOnboarding,
  } = useGetUserOnboardingQuery(undefined, {
    skip: !isAuthenticated || !isUserInOnboarding,
  });

  const onboardingRouteByStep =
    ONBOARDING_STEP_TO_ROUTE[onboardingStep] ?? '';
  const needsToBeRedirected =
    currentSubDomainType !== SubDomainType.Onboarding ||
    (currentSubDomainType === SubDomainType.Onboarding &&
      onboardingRouteByStep &&
      onboardingRouteByStep !== pathname);

  if (isUserInOnboarding && needsToBeRedirected) {
    // If the user is in a different subdomain (or no subdomain) we need to use window.location.replace
    // If he's in the onboarding subdomain, we use the Redirect component to avoid full refresh
    if (currentSubDomainType !== SubDomainType.Onboarding) {
      let onboardingUrl = AppRouting.buildGlobalOnboardingUrl(window.location);

      // Remove trailing slash to avoid conflict with the step route
      if (onboardingUrl.endsWith('/')) {
        onboardingUrl = onboardingUrl.substring(0, onboardingUrl.length - 1);
      }
      window.location.replace(`${onboardingUrl}${onboardingRouteByStep}`);
    } else if (
      currentSubDomainType === SubDomainType.Onboarding &&
      onboardingRouteByStep &&
      onboardingRouteByStep !== pathname
    ) {
      return <Redirect to={ onboardingRouteByStep } push={ false } />;
    }

    return null;
  }

  if (isLoadingAuth || isLoadingUser || isLoadingUserOnboarding) {
    return <SplashScreen title="Onboarding Guard" />;
  }

  return <>{children}</>;
};
