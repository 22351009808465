import React from 'react';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { validateString } from '../../../utils';
import {
  hasUpperOrLowerCase,
  hasDigit, isLengthValid,
} from '../../../utils/passwords';
import { Rule } from './Rule';
import { Container, RuleContainer } from './styled';

type PasswordRulesProps = {
  passwordInput: string,
}

export const PasswordRules = ({
  passwordInput,
}: PasswordRulesProps):JSX.Element => {
  const { t: translate } = useTranslation();

  const rules = [
    {
      id: 1,
      title: translate(STRING_KEYS.AT_LEAST_EIGHT_CHARACTERS),
      isValid: isLengthValid(passwordInput),
    },
    {
      id: 2,
      title: translate(STRING_KEYS.AT_LEAST_ONE_UPPER_OR_LOWER_LETTER),
      isValid: hasUpperOrLowerCase(passwordInput),
    },
    {
      id: 3,
      title: translate(STRING_KEYS.CONTAINS_SYMBOL),
      isValid: !validateString(passwordInput),
    },
    {
      id: 4,
      title: translate(STRING_KEYS.CONTAINS_NUMBER),
      isValid: hasDigit(passwordInput),
    },
  ];

  const renderSteps = () => {
    const isAllChecksPass = rules.every((step) => step.isValid);

    if (isAllChecksPass) {
      return (
        <RuleContainer>
          <Rule
            title={ translate(STRING_KEYS.PERFECT_PASSWORD) }
            isValid
          />
        </RuleContainer>
      );
    }

    return rules.map(({ id, title, isValid }) => {
      return (
        <Rule
          key={ id }
          title={ title }
          isValid={ isValid }
        />
      );
    });
  };

  return (
    <Container>
      {renderSteps()}
    </Container>
  );
};
