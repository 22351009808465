import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../../consts/analytics';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import {
  useAssignUserRoleMutation,
  useDeleteUserByIdMutation,
  useGetAccountUsersQuery,
  useInviteColleagueMutation,
  useResendInviteColleagueMutation,
} from '../../../store/rtk-query/apis/user/user.toolkit-api';
import { isValidEmail } from '../../../utils';
import { UserRoleName } from '../../../enums/user-role-name.enum';
import InvitedColleaguesList from './invited-teammates-list/invited-teammates-list.component';
import {
  BodyContainer,
  EmailExist,
  EmailNotValid,
  HeaderContainer,
  HeaderTitle,
  ModalContentWrapper,
  StyledDialog,
} from './invite-teammates-dialog.styled';
import { InviteTeammateForm } from './invite-teammate-form/invite-teammate-form.component';
import {
  InviteTeammateConfirmationDialog,
} from './invite-teammate-confirmation-dialog/invite-teammate-confirmation-dialog.component';

const ELEMENT_TYPES = {
  invite_button: 'invite_button',
  close_button: 'close_button',
};

export const InviteColleagueModal = ({
  isOpen,
  onClose,
}: InviteColleagueModalProps): JSX.Element => {
  const [emailInput, setEmailInput] = useState<string>('');
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [emailInputExists, setEmailInputExists] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<UserRoleName | undefined>();
  const [
    isConfirmationDialogOpen,
    setIsConfirmationDialogOpen,
  ] = useState<boolean>(false);

  const { t: translate } = useTranslation();

  const { analyticsTrackEvent } = useAnalyticsContext();

  const {
    data: inviteColleagueUsers,
    isLoading: isLoadingGetColleagueUsers,
    error: errorGetInviteColleagueRequest,
    refetch: refetchAccountUsers,
  } = useGetAccountUsersQuery();

  const [
    inviteUser,
    {
      error: errorSendInviteColleague,
      isLoading: isLoadingSendInviteColleague,
    },
  ] = useInviteColleagueMutation();

  const [, { isError: errorResendInviteColleague, isLoading: isLoadingResendInviteColleague }] = useResendInviteColleagueMutation();
  const [, { isError: errorDeleteColleague, isLoading: isLoadingDeleteColleague }] = useDeleteUserByIdMutation();
  const [, { isError: errorAssignRole, isLoading: isLoadingAssignRole }] = useAssignUserRoleMutation();

  useEffect(() => {
    if (isOpen) {
      const payload = {
        eventName: ANALYTICS_EVENTS.INVITE_TEAMMATE_MODAL_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
      };
      analyticsTrackEvent(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleResetErrors = () => {
    setEmailInputExists(false);
    setEmailInputError(false);
  };

  const handleSubmit = () => {
    handleResetErrors();
    if (!isValidEmail(emailInput) || !selectedRole) {
      setEmailInputError(true);
    } else if (
      inviteColleagueUsers.find((colleague) => colleague.email === emailInput)
    ) {
      setEmailInputExists(true);
    } else {
      setIsConfirmationDialogOpen(true);
    }
  };

  const handleInvite = async () => {
    setIsConfirmationDialogOpen(false);
    setEmailInputExists(false);
    setEmailInputError(false);

    await inviteUser({
      email: emailInput,
      userRoleName: selectedRole || UserRoleName.Collaborator,
    });

    // TODO should happen automatically in tags invalidation from the inviteUser mutation
    refetchAccountUsers?.();

    const payload = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_INVITE_TEAMMATE_MODAL,
      eventGroup: EVENT_GROUP.CLICK,
      componentElementType: ELEMENT_TYPES.invite_button,
    };
    analyticsTrackEvent(payload);
  };

  const renderEmailError = () => {
    if (emailInputError) {
      return (
        <EmailNotValid>
          {translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.EMAIL_NOT_VALID)}
        </EmailNotValid>
      );
    }

    if (emailInputExists && emailInput.length > 0) {
      return (
        <EmailExist>
          <Trans
            defaults={ STRING_KEYS.INVITE_YOUR_TEAMMATES.EMAIL_EXISTS }
            values={ {
              firstName: emailInput,
            } }
            components={ { bold: <strong /> } }
          />
        </EmailExist>
      );
    }

    return <></>;
  };

  const renderServerError = () => {
    if (errorGetInviteColleagueRequest) {
      return <EmailNotValid>{errorGetInviteColleagueRequest}</EmailNotValid>;
    }

    if (errorSendInviteColleague) {
      return <EmailNotValid>{errorSendInviteColleague}</EmailNotValid>;
    }

    if (errorResendInviteColleague) {
      return <EmailNotValid>{errorResendInviteColleague}</EmailNotValid>;
    }

    if (errorDeleteColleague) {
      return <EmailNotValid>{errorDeleteColleague}</EmailNotValid>;
    }

    if (errorAssignRole) {
      return <EmailNotValid>{errorAssignRole}</EmailNotValid>;
    }

    return null;
  };

  const handleClose = () => {
    onClose();

    const payload = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_INVITE_TEAMMATE_MODAL,
      eventGroup: EVENT_GROUP.CLICK,
      componentElementType: ELEMENT_TYPES.close_button,
    };
    analyticsTrackEvent(payload);
  };

  return (
    <>
      <StyledDialog
        isOpen={ isOpen  }
        onClose={ handleClose }
        widthSize={ DialogWidthSize.Large }
        $isHidden={ false }
      >
        <HeaderContainer>
          <HeaderTitle>
            {translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.PEOPLE)}
          </HeaderTitle>
        </HeaderContainer>

        <BodyContainer>
          <InviteTeammateForm
            email={ emailInput }
            role={ selectedRole }
            onEmailChange={ setEmailInput }
            onRoleChange={ setSelectedRole }
            onSubmit={ handleSubmit }
            isEmailError={ emailInputError }
          />
 
          <ModalContentWrapper>
            {renderEmailError()}

            {renderServerError()}

            <InvitedColleaguesList
              inviteColleagueUsers={ inviteColleagueUsers }
              isLoadingColleagueUsers={ isLoadingGetColleagueUsers }
              isLoadingSendInviteColleague={ isLoadingSendInviteColleague }
              isLoadingResendInviteColleague={ isLoadingResendInviteColleague }
              isLoadingDeleteColleague={ isLoadingDeleteColleague }
              isLoadingAssignRole={ isLoadingAssignRole }
              onResetError={ handleResetErrors }
            />
          </ModalContentWrapper>
        </BodyContainer>
      </StyledDialog>

      {isConfirmationDialogOpen && (
        <InviteTeammateConfirmationDialog
          isOpen={ isConfirmationDialogOpen }
          onClose={ () => setIsConfirmationDialogOpen(false) }
          onConfirm={ handleInvite }
        />
      )}
    </>
  );
};
