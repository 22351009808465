import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  GenericUpgradeButton,
} from '../../../../../modules/application/components/generic-upgrade-button/generic-upgrade-button.component';
import {
  LockIcon,
  Title1,
  Title2,
  TitlesAndLockIconWrapper,
  TitlesWrapper,
  Wrapper,
} from './maximum-seats-reached.styled';

export const MaximumSeatsReached = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Wrapper>
      <TitlesAndLockIconWrapper>
        <LockIcon />
        <TitlesWrapper>
          <Title1>
            {translate(
              STRING_KEYS.INVITE_YOUR_TEAMMATES.MAXIMUM_SEATS_REACHED.TITLE1,
            )}
          </Title1>

          <Title2>
            {translate(
              STRING_KEYS.INVITE_YOUR_TEAMMATES.MAXIMUM_SEATS_REACHED.TITLE2,
            )}
          </Title2>
        </TitlesWrapper>
      </TitlesAndLockIconWrapper>

      <GenericUpgradeButton />
    </Wrapper>
  );
};
