import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@frontegg/react';
import { useAppSelector } from '../../store/selectors';
import usePageView from '../../hooks/usePageView';
import { useUserWatcher } from '../../hooks/user-watcher.hooks';
import { useBroadcastChannelWatcher } from '../../hooks/broadcast-watcher.hooks';
import { useActivityWatcher } from '../../hooks/activity-watcher.hooks';
import { usePerfectAuthentication } from '../../hooks/perfect-authentication.hook';
import { SubDomainType } from '../../store/slices/app-state/app-state.enums';
import { useIsSuperUser } from '../../hooks/superuser';
import { THEME } from '../../themes';
import { MobileBlockingMessage } from '../Common/mobile-blocking-message/mobile-blocking-message.component';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { AppRouting } from '../../services/appRoutingResolver';
import { addSubDomainToUrl, replaceSubDomain } from '../../utils/url';
import RudderStackAnalyticsAgent from '../../services/rudderStackAnalyticsAgent';
import UserLoggingInfoManger from '../../services/userLoggingInfoManger';
import { IntercomManager } from '../../services/intercom-manager';
import { selectIsRudderstackLoadingCompleted } from '../../store/selectors/app-loader.selectors';
import { useGetMyUserQuery } from '../../store/rtk-query/apis/user/user.toolkit-api';
import { useGetAccountByIdQuery } from '../../store/rtk-query/apis/account/account.toolkit-api';
import { useInitializeRudderStack } from '../../hooks/initialize-rudderstack.hook';
import { useCurrentSubDomain } from '../../hooks/use-current-sub-domain';
import PlanExpired from '../../modules/trial-ended/pages/plan-expired.page';
import { ApplicationRouter } from './application.router';
import { OnboardingRouter } from './onboarding.router';
import { AuthRouter } from './auth.router';
import { UserActivationRouter } from './user-activation.router';

const logger = log.getLogger('APP_ROUTER');

export const MainRouter = (): JSX.Element | null => {
  const { currentSubDomainName, currentSubDomainType } = useCurrentSubDomain();

  const isRudderstackLoadingCompleted = useAppSelector(
    selectIsRudderstackLoadingCompleted,
  );

  const { search: queryParams } = useLocation();

  usePageView();

  const { isPlanExpired } = usePerfectAuthentication();

  const { isAuthenticated } = useAuth();

  const { width } = useWindowDimensions();

  const [
    isMobileBlockingMessageDismissed,
    setIsMobileBlockingMessageDismissed,
  ] = useState<boolean>(false);

  // Only the onboarding pages and login are mobile responsive
  const isCurrentRouteSupportedInMobile =
    currentSubDomainType === SubDomainType.Onboarding ||
    currentSubDomainType === SubDomainType.Auth;
  const isMobile = width <= THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE;

  const { data: user } = useGetMyUserQuery(
    undefined,
    { skip: !isAuthenticated },
  );

  const {
    isOnboarding: isUserInOnboarding,
    accountId,
    id: userId,
    email: userEmail,
    firstName,
    intercomUserHash,
  } = user ?? {};

  const { data: account } = useGetAccountByIdQuery(accountId, {
    skip: !accountId,
  });

  const {
    subDomainName,
    displayName: accountName,
    status: accountStatus,
    plan,
  } = account ?? {};

  const userSubDomain = subDomainName?.toLowerCase();

  const shouldGoToOnboarding =
    (isUserInOnboarding || currentSubDomainType === SubDomainType.Onboarding) &&
    currentSubDomainType !== SubDomainType.Auth;

  const isSuperUser = useIsSuperUser();
  useUserWatcher();
  useInitializeRudderStack(isSuperUser);
  useBroadcastChannelWatcher();
  useActivityWatcher({
    disableTrackPageVisits:
      isSuperUser || !isAuthenticated || shouldGoToOnboarding,
  });

  const handleMobileBlockingMessageDismiss = (): void => {
    setIsMobileBlockingMessageDismissed(true);
  };

  useEffect(() => {
    if (isAuthenticated && isRudderstackLoadingCompleted) {
      RudderStackAnalyticsAgent.group(accountId, {
        name: accountName,
        status: accountStatus,
        plan: plan?.pricingPlan,
      });

      UserLoggingInfoManger.saveUserLoggingInfoData({
        userId,
        userEmail,
        userIsOnboarding: isUserInOnboarding,
        accountId,
        accountName,
        accountStatus,
        maximumOpenPositions: plan?.maximumOpenPositions,
      });

      if (intercomUserHash) {
        IntercomManager.init(intercomUserHash);
      }
    }

    logger.info('User was identified successfully', {
      displayName: `${firstName}`,
      accountName,
      accountId,
      accountStatus,
      userId,
      userEmail,
      plan,
    });
  }, [
    accountId,
    accountName,
    accountStatus,
    firstName,
    intercomUserHash,
    isAuthenticated,
    isRudderstackLoadingCompleted,
    isUserInOnboarding,
    plan,
    userEmail,
    userId,
  ]);

  const renderRouterBySubDomainType = (): JSX.Element | null => {
    let defaultUrl;

    // If there's no subdomain, redirect to either the account, login or onboarding subdomain
    if (isAuthenticated && userSubDomain) {
      const { location } = window;

      const newUrl = !currentSubDomainName ?
        addSubDomainToUrl(location.origin, location.host, userSubDomain) :
        replaceSubDomain(location.origin, currentSubDomainName, userSubDomain);

      defaultUrl = newUrl + queryParams;
    } else {
      defaultUrl = isUserInOnboarding ?
        AppRouting.buildGlobalOnboardingUrl(window.location) :
        AppRouting.buildGlobalLoginUrl(window.location);
    }

    switch (currentSubDomainType) {
      case SubDomainType.None:
        window.location.replace(defaultUrl);
        
        return null;
      case SubDomainType.Account:
        return <ApplicationRouter />;
      case SubDomainType.Activation:
        return <UserActivationRouter />;
      case SubDomainType.Auth:
        return <AuthRouter />;
      case SubDomainType.Onboarding:
        return <OnboardingRouter />;
      default:
        return null;
    }
  };

  if (isPlanExpired) {
    return <PlanExpired />;
  }

  return (
    <>
      {renderRouterBySubDomainType()}

      {isMobile &&
        !isCurrentRouteSupportedInMobile &&
        !isMobileBlockingMessageDismissed && (
        <MobileBlockingMessage
          onDismiss={ handleMobileBlockingMessageDismiss }
        />
      )}
    </>
  );
};
