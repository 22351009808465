/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProfile } from '../../user-profile/user-profile.component';
import { AppRouting } from '../../../../services/appRoutingResolver';
import UserRoleChangedBanner from '../../../user-role-changed-banner/user-role-changed-banner.component';
import { useAppState } from '../../../../store/selectors';
import Portal from '../../Portal/portal.commponent';
import { InviteColleagueModal } from '../../../dialogs/invite-teammates-dialog/invite-teammates-dialog.component';
import STRING_KEYS from '../../../../language/keys';
import { UpgradePlanDialog } from '../../../dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { HeaderProps } from './header';
import {
  ButtonIcon,
  HeaderContainer, HeaderContentContainer, LeftContainer, PerfectIcon, RightContainer, UpgradeButton,
} from './header.styled';

const CONTAINER_ID = 'header_global_portal';

const Header = ({
  children, isHeaderSticky, isTrialPlan, ...otherProps
}: HeaderProps): JSX.Element => {
  const {
    userRoleWasChanged,
  } = useAppState();
  const { t: translate } = useTranslation();
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);

  return (
    <>
      <Portal containerId={ CONTAINER_ID } isGlobalPortal removeAfterUnmount={ false }>
        <HeaderContainer { ...otherProps }>
          { userRoleWasChanged && <UserRoleChangedBanner /> }
          <HeaderContentContainer isHeaderSticky={ isHeaderSticky }>
            <LeftContainer>
              <Link to={ { pathname: AppRouting.ROUTES.HOME } }>
                <PerfectIcon />
              </Link>
            </LeftContainer>
            { children }
            <RightContainer>
              {
                isTrialPlan && (
                  <UpgradeButton StartIcon={ ButtonIcon } onClick={ () => setIsUpgradePlanDialogOpen(true) } >
                    { translate(STRING_KEYS.LAYOUT_HEADER.TRIAL_UPGRADE_BUTTON) }
                  </UpgradeButton>
                )
              }
              <UserProfile
                onPeopleItemClickHandler={ () => setIsInviteUsersModalOpen(true) }
              />
            </RightContainer>
          </HeaderContentContainer>
        </HeaderContainer>
      </Portal>
      { isInviteUsersModalOpen && (
        <InviteColleagueModal
          isOpen={ isInviteUsersModalOpen }
          onClose={ () => setIsInviteUsersModalOpen(false) }
        />
      ) }
      <UpgradePlanDialog
        isDialogOpen={ isUpgradePlanDialogOpen }
        onCloseDialog={ () => setIsUpgradePlanDialogOpen(false) }
      />
    </>
  );
};

export default Header;
