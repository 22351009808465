import { useEffect } from 'react';
import { useSocialLoginActions, useSocialLoginState } from '@frontegg/react';
import { ISocialLoginProviderConfigurationV2, SocialLoginProviders } from '@frontegg/rest-api';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { ASSETS } from '../../../../../assets';
import { Container, SocialLoginButton } from './social-login.styled';

const logger = log.getLogger('SOCIAL_LOGIN');

type SocialLoginOptionsProps = {
  onGoogleLoginClick: (
    configuration: ISocialLoginProviderConfigurationV2
  ) => void;
  onMicrosoftLoginClick: (
    configuration: ISocialLoginProviderConfigurationV2
  ) => void;
};

export const SocialLoginOptions = ({
  onGoogleLoginClick,
  onMicrosoftLoginClick,
}: SocialLoginOptionsProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  
  const { firstLoad, error, socialLoginsConfigV2 } = useSocialLoginState();
  const { loadSocialLoginsConfiguration } = useSocialLoginActions();

  useEffect(() => {
    if (firstLoad) {
      loadSocialLoginsConfiguration();
    }
  }, [loadSocialLoginsConfiguration, firstLoad]);

  useEffect(() => {
    if (error) {
      logger.warn('got frontegg error while loading social-login:', error);
    }
  }, [error]);

  const isSocialLoginAvailable = () => {
    if (error || !socialLoginsConfigV2 || socialLoginsConfigV2.length <= 0) {
      return false;
    }

    return socialLoginsConfigV2.some((item) => item.active);
  };

  const renderSocialLoginButton = (
    buttonConfiguration: ISocialLoginProviderConfigurationV2,
  ) => {
    if (!buttonConfiguration.active) {
      return null;
    }
    
    switch (buttonConfiguration.type) {
      case SocialLoginProviders.Google: {
        return (
          <SocialLoginButton
            key={ SocialLoginProviders.Google }
            $url={ ASSETS.GOOGLE_LOGO }
            onClick={ () => onGoogleLoginClick(buttonConfiguration) }
          >
            {translate(
              STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CONTINUE_WITH_GOOGLE,
            )}
          </SocialLoginButton>
        );
      }
      case SocialLoginProviders.Microsoft: {
        return (
          <SocialLoginButton
            key={ SocialLoginProviders.Microsoft }
            $url={ ASSETS.MICROSOFT_LOGO_IMAGE }
            onClick={ () => onMicrosoftLoginClick(buttonConfiguration) }
          >
            {translate(
              STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CONTINUE_WITH_MICROSOFT,
            )}
          </SocialLoginButton>
        );
      }
      default: {
        return null;
      }
    }
  };

  if (!socialLoginsConfigV2 || !isSocialLoginAvailable()) {
    logger.debug("social login isn't available. hiding the buttons");

    // no social options to show
    return null;
  }

  return (
    <Container>
      {socialLoginsConfigV2.map(renderSocialLoginButton)}
    </Container>
  );
};
