import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { ChatActions } from '../chat-actions/chat-actions.component';
import STRING_KEYS from '../../../../../../language/keys';
import { ConversationMessageType } from '../../../../../../enums/conversation-message-type.enum';
import { ASSETS } from '../../../../../../assets';
import { CustomOL, CustomP, CustomUL } from '../chat-message-markdown/chat-message-markdown.utils';
import { useTypingEffect } from '../../../../../../hooks/use-typing-effect/typing-effect.hooks';
import { ChatAiMessageProps } from './chat-ai-message';
import {
  AIContainer,
  AiTitle,
  ChatAiBubble,
  ChatAIUser,
  NliImage,
} from './chat-ai-message.styled';

export const ChatAiMessage = ({
  conversationMessage,
  onActionClick,
  onTypingComplete,
}: ChatAiMessageProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const isAgentMessage = conversationMessage.messageType === ConversationMessageType.AgentMessage;

  const [typedText, startTyping] = useTypingEffect({
    text: conversationMessage?.message,
    onComplete: onTypingComplete,
  });

  useEffect(() => {
    if (conversationMessage?.isTyping) {
      startTyping();
    }
  }, [conversationMessage?.isTyping, startTyping]);

  const renderActionItems = () => {
    if (!conversationMessage.suggestions || conversationMessage.isTyping) return null;
    
    return (
      <ChatActions message={ conversationMessage } onActionClick={ onActionClick } />
    );
  };

  const renderMessageContent = () => {
    return conversationMessage && conversationMessage.isTyping ? typedText : conversationMessage.message;
  };

  return (
    <AIContainer>
      <ChatAiBubble key={ conversationMessage.id }>
        <ChatAIUser>
          <NliImage src={ isAgentMessage ? ASSETS.NLI_LOGO : ASSETS.NLI_CS_LOGO } />
          <AiTitle>
            {translate(isAgentMessage ? STRING_KEYS.MATCHES_PAGE.CHAT.AI_PERFECT : STRING_KEYS.MATCHES_PAGE.CHAT.AI_PERFECT_SUCCESS_TEAM)}
          </AiTitle>
        </ChatAIUser>
        <Markdown options={ {
          overrides: {
            ol: { component: CustomOL },
            ul: { component: CustomUL },
            p: { component: CustomP },
          },
        } }
        >
          {renderMessageContent()}
        </Markdown>
      </ChatAiBubble>
      {renderActionItems()}
    </AIContainer>
  );
};
