import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import BaseButton from '../Buttons/perfect-base-button/perfect-base-button.component';
import { PerfectButtonVariants } from '../Buttons/perfect-base-button/perfect-base-button.enums';
import {
  MessageSection, PerfectLogoAvatar, Text, Title1, Title2, Wrapper,
} from './mobile-blocking-message.styled';

export const MobileBlockingMessage = ({
  onDismiss,
}: MobileBlockingMessageProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Wrapper>
      <PerfectLogoAvatar />

      <MessageSection>
        <Title1>{translate(STRING_KEYS.MOBILE_UNSUPPORTED.HEY_THERE)}</Title1>

        <Title2>
          {translate(STRING_KEYS.MOBILE_UNSUPPORTED.BEST_ON_A_LARGER_SCREEN)}
        </Title2>
      </MessageSection>

      <Text>
        {translate(STRING_KEYS.MOBILE_UNSUPPORTED.FOR_BEST_EXPERIENCE)}
      </Text>

      <BaseButton variant={ PerfectButtonVariants.Link } onClick={ onDismiss }>
        {translate(STRING_KEYS.MOBILE_UNSUPPORTED.CONTINUE_ANYWAY)}
      </BaseButton>
    </Wrapper>
  );
};
