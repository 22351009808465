import styled, { css } from 'styled-components';
import { THEME } from '../../../../../themes';
import { PerfectFeature } from '../../../../../themes/fonts';

const fontCss = css`
  ${PerfectFeature};
  color: ${THEME.COLORS_RGB.PRIMARY_DARK_LOW};
`;
export const ConversationScheduledMessage = styled.span`
  ${fontCss};
  font-size: 12px;
  
  &:before {
    content: ${THEME.SPECIAL_CHARACTERS.SCHEDULE_SEND};
  };
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const TruncatedText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ConversationSentMessage = styled.span`
  ${fontCss};
  font-size: 8px;
  
  &:before {
    content: ${THEME.SPECIAL_CHARACTERS.CHECK};
  };
`;

export const ConversationSeenMessage = styled.span`
  ${fontCss};
  font-size: 16px;
  
  &:before {
    content: ${THEME.SPECIAL_CHARACTERS.DOUBLE_CHECK};
  };
`;
