import log from 'loglevel';
import { isAfter, parseISO } from 'date-fns';
import { RecruiterAgentInfoResponse } from '../dto/response/recruiter-agent-info.response';

const logger = log.getLogger('AGENT_STATE_CHANGED_WEBSOCKET_LISTENERS');

export const agentStatusChangedCacheWebsocketListeners = (
  websocketDto: RecruiterAgentInfoResponse,
  updateCachedData: (args: (agentInfo: RecruiterAgentInfoResponse) => void) => void,
): void => {
  const {
    generationStatus, matchingStatus, positionId, updatedAt,
  } = websocketDto;
  updateCachedData((agentInfo: RecruiterAgentInfoResponse) => {
    const cachedUpdatedAtDate = parseISO(agentInfo.updatedAt);
    const incomingUpdatedAtDate = parseISO(updatedAt);
    
    if (agentInfo.positionId === positionId && isAfter(incomingUpdatedAtDate, cachedUpdatedAtDate)) {
      logger.log('Agent info updated', agentInfo);
      agentInfo.matchingStatus = matchingStatus;
      agentInfo.generationStatus = generationStatus;
    }
  });
};
