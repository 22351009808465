import { useEffect, useState } from 'react';
import {
  Container,
  RotatingTextSpan,
  TextItem,
  Wrapper,
} from './rotating-text.styled';
import { RotatingTextProps } from './rotating-text';

export const RotatingText = ({
  phrases,
  stopAtEnd = true,
}: RotatingTextProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    if (!isAnimating) return;

    // Use the duration from the current phrase
    const currentDuration = phrases[currentIndex].duration * 1000;

    const interval = setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        // If we're at the last phrase and stopAtEnd is true, stop the animation
        if (prevIndex >= phrases.length - 1 && stopAtEnd) {
          setIsAnimating(false);

          return prevIndex;
        }

        // Otherwise, move to the next phrase or loop back to the first
        return (prevIndex + 1) % phrases.length;
      });
    }, currentDuration);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(interval);
  }, [phrases, currentIndex, stopAtEnd, isAnimating]);

  // Calculate transition duration as 1/4 of the phrase duration
  const transitionDuration = phrases[currentIndex].duration / 4;

  return (
    <Container>
      <Wrapper>
        <RotatingTextSpan
          currentIndex={ currentIndex }
          transitionDuration={ transitionDuration }
        >
          {phrases.map((phrase, index) => (
            <TextItem
              key={ phrase.key }
              isActive={ index === currentIndex }
            >
              {phrase.text}
            </TextItem>
          ))}
        </RotatingTextSpan>
      </Wrapper>
    </Container>
  );
};
