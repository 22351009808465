import styled from 'styled-components';
import { THEME } from '../../../themes';
import { Body1Font, CaptionFont, Title3Font } from '../../../themes/fonts';
import Dialog from '../base-dialog/base-dialog.component';

export const HeaderContainer = styled(Dialog.Header)`
  margin-top: ${THEME.SPACING.MEDIUM};
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  flex-direction: column;
`;

export const BodyContainer = styled(Dialog.Body)`
  padding: ${THEME.SPACING.LOOSE} ${THEME.SPACING.MEDIUM};
`;

export const StyledDialog = styled(Dialog)<{$isHidden:boolean}>`
  opacity:  ${({ $isHidden }) => $isHidden ? 0 : 1}`;

export const HeaderTitle = styled.div`
  ${Title3Font};
  font-weight: 600;
  line-height: 1.5;
`;

export const Title = styled.div`
  ${Title3Font};
  font-weight: 500;
  line-height: 1.5;
`;

export const SubTitle = styled.div`
  ${Body1Font};
  line-height: 1.5;
  opacity: ${THEME.OPACITY.MED};
`;

export const Description = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.MED};
`;

export const EmailNotValid = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.ALERT};
  text-align: left;
  margin-top: ${THEME.SPACING.MICRO};
`;

export const EmailExist = styled.div`
  ${CaptionFont};
  color: ${THEME.COLORS.PRIMARY};
  text-align: left;
  margin-top: ${THEME.SPACING.MICRO};
`;

export const ModalContentWrapper = styled.div`
  position: relative;
`;
