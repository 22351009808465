import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { REDUCER_PATH } from './onboarding.consts';
import {
  createOnboardingQuery,
  getAllowedCompaniesQuery,
  getOnboardingQuery,
  sendVerificationEmailQuery,
  updateOnboardingProgressQuery,
  verifyEmailQuery,
} from './onboarding.toolkit-queries';
import { OnboardingResponse } from './dto/response/onboarding-response.dto';
import { UpdateOnboardingProgressRequest } from './dto/request/update-onboarding-progress.request';
import { CreateUserOnboardingRequest } from './dto/request/create-user-onboarding.request';
import { GetAllowedCompaniesRequest } from './dto/request/get-allowed-companies.request';
import { GetAllowedCompaniesResponse } from './dto/response/get-allowed-companies.response';

export const onboardingQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['onboarding'],
  endpoints: (builder) => ({
    getUserOnboarding: builder.query<OnboardingResponse, void>({
      query: getOnboardingQuery,
      providesTags: [{ type: 'onboarding' }],
    }),
    createUserOnboarding: builder.mutation<OnboardingResponse, CreateUserOnboardingRequest>({
      query: createOnboardingQuery,
      invalidatesTags: [{ type: 'onboarding' }],
    }),
    getAllowedCompanies: builder.query<GetAllowedCompaniesResponse, GetAllowedCompaniesRequest>({
      query: getAllowedCompaniesQuery,
    }),
    updateOnboardingProgress: builder.mutation<
      OnboardingResponse,
      UpdateOnboardingProgressRequest
    >({
      query: updateOnboardingProgressQuery,
      invalidatesTags: [{ type: 'onboarding' }],
    }),
    sendVerificationEmail: builder.mutation<void, void>({
      query: sendVerificationEmailQuery,
    }),
    verifyEmail: builder.mutation<void, { code: string }>({
      query: verifyEmailQuery,
      invalidatesTags: (result, error) => error ? [] : [{ type: 'onboarding' }],
    }),
  }),
});

export const {
  useGetUserOnboardingQuery,
  useCreateUserOnboardingMutation,
  useSendVerificationEmailMutation,
  useVerifyEmailMutation,
  useGetAllowedCompaniesQuery,
  useUpdateOnboardingProgressMutation,
} = onboardingQuery;
