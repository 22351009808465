export const CONVERSATION_ID_PATH_PARAM = ':conversationId';
export const POSITION_ID_PATH_PARAM = ':positionId';
export const MATCH_ID_PATH_PARAM = ':matchId';

export const ROUTES = {
  CONVERSATIONS: '/outreach/conversations',
  CONVERSATIONS_BY_POSITION: `/outreach/positions/${POSITION_ID_PATH_PARAM}/conversations`,
  CONVERSATIONS_MESSAGES: '/outreach/conversations/messages',
  CONVERSATION_BY_ID: `/outreach/positions/${POSITION_ID_PATH_PARAM}/conversations/${CONVERSATION_ID_PATH_PARAM}`,
  CONVERSATION_MESSAGES: `/outreach/positions/${POSITION_ID_PATH_PARAM}/matches/${MATCH_ID_PATH_PARAM}/conversation/messages`,
  MARK_MESSAGE_AS_SENT: `/outreach/positions/${POSITION_ID_PATH_PARAM}/matches/${MATCH_ID_PATH_PARAM}/conversation/mark-as-sent`,
  CONVERSATION_MESSAGE_SUGGESTIONS: `/outreach/positions/${POSITION_ID_PATH_PARAM}/matches/${MATCH_ID_PATH_PARAM}/conversation/message/suggestions`,
  MARK_CONVERSATION_MESSAGE_AS_SEEN: `/outreach/conversations/${CONVERSATION_ID_PATH_PARAM}/messages/seen`,
  ARCHIVE_CONVERSATION_BY_ID: `/outreach/positions/${POSITION_ID_PATH_PARAM}/conversations/${CONVERSATION_ID_PATH_PARAM}/archive`,
  EMAIL_SENDER: '/outreach/email-sender',
  CREATE_GOOGLE_EMAIL_SENDER: '/outreach/email-sender/google',
  CREATE_MICROSOFT_EMAIL_SENDER: '/outreach/email-sender/microsoft',
};

export const REDUCER_PATH = 'outreachApi';
