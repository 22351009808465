import styled from 'styled-components';
import BaseButton from '../../../Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../themes';
import { CaptionFont, HeadlineFont } from '../../../../themes/fonts';
import Dialog from '../../base-dialog/base-dialog.component';

export const PerfectButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  white-space: nowrap;
  width: 100%;
  height: 46px;
`;

export const PerfectDialog = styled(Dialog)`
  min-height: auto;
`;

export const DialogHeader = styled(Dialog.Header)`
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.LOOSE};
`;

export const DialogBody = styled(Dialog.Body)`
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.LOOSE};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.p`
  ${HeadlineFont};
`;

export const Description = styled.p`
  ${CaptionFont};
  opacity: 0.6;
`;
