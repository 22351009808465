import styled, { css } from 'styled-components';
import { THEME } from '../../../themes';
import { Body2Font, Subtitle1Font } from '../../../themes/fonts';

export const MenuOption = styled.div<{ $isDisabled: boolean }>`
  background-color: ${THEME.COLORS.LIGHT_GREY};
  padding: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.BASE};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.34 : 1)};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  border-radius: ${THEME.RADIUS.BASE};

  ${({ $isDisabled }) => !$isDisabled &&
    css`
      :focus,
      :hover {
        background-color: ${THEME.COLORS.ACCENT}1F;
      }
    `};
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const Title = styled.h3`
  ${Subtitle1Font};
  font-weight: bold;
`;

export const Subtitle = styled.p`
  ${Body2Font};
`;
