import styled from 'styled-components';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { drawerElevation } from '../../../../themes/elevations';
import PerfectBaseButtonComponent
  from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { hexToRgbA } from '../../../../utils/colors';
import { Body1Font } from '../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  width: calc(50% - ${THEME.SPACING.GRAND});
  height: 136px;
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.BASE};
  background-color: ${hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.MED)};
  border-bottom-right-radius: ${THEME.RADIUS.CURVY};
  border-bottom-left-radius: ${THEME.RADIUS.CURVY};
  box-shadow: ${drawerElevation};
  backdrop-filter: blur(8px);
  border: solid 1px rgba(48, 37, 133, 0.12);
  position: absolute;
  top: 0;
  right: 0;
  margin-right: ${THEME.SPACING.LOOSE};
  z-index: 60;
`;

export const LockIcon = styled(FLUENT_UI_EMOJIS.LOCKED)`
  width: 48px;
  height: 48px;
  margin-right: ${THEME.SPACING.BASE};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h2`
  ${Body1Font};
  font-weight: bold;
  color: ${THEME.COLORS.CONTENT_A};
  padding-bottom: ${THEME.SPACING.TINY};
`;

export const Description = styled.p`
  font-size: ${THEME.FONT_SIZE.BASE};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  padding-bottom: ${THEME.SPACING.TINO};
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const GetMoreCreditsButton = styled(PerfectBaseButtonComponent)`
  background-color: ${THEME.COLORS.SECONDARY};
`;

export const TalkToSales = styled(PerfectBaseButtonComponent)`
  color: ${THEME.COLORS.SECONDARY};
  font-weight: bold;
`;

export const PlusIcon = styled(ASSETS.PLUS_RECTANGLE_FILLED)`
  width: 12px;
`;

export const MessageIcon = styled(ASSETS.SQUARE_BUBBLE_CHAT)`
  width: 12px;
`;
