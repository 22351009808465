import { useTranslation } from 'react-i18next';
import React, { useState, MouseEvent } from 'react';
import Markdown from 'markdown-to-jsx';
import STRING_KEYS from '../../../../../language/keys';
import { ASSETS } from '../../../../../assets';
import { CustomOL, CustomP, CustomUL } from '../chat-message/chat-message-markdown/chat-message-markdown.utils';
import {
  AiTitle,
  ChatButton,
  ChatIcon,
  CloseButton,
  CloseIcon,
  Header,
  Message,
  MessageBubble,
  NliImage,
  Container,
} from './minimized-chat.styled';

export const FloatingChatButtonContainer = ({
  latestMessage, onClick, hide, isSmallScreen, isMinimized,
}: FloatingChatButtonContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const closeMessageBubbleHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsVisible(false);
  };

  return (
    <Container>
      { latestMessage && (!isSmallScreen || isMinimized) && (
        <MessageBubble isVisible={ isVisible && !!latestMessage } onClick={ onClick }>
          <CloseButton onClick={ closeMessageBubbleHandler }>
            <CloseIcon />
          </CloseButton>
          <Header>
            <NliImage src={ ASSETS.NLI_LOGO } />
            <AiTitle>{translate(STRING_KEYS.MATCHES_PAGE.CHAT.AI_PERFECT)}</AiTitle>
          </Header>
          <Message>
            <Markdown options={ {
              overrides: {
                ol: {
                  component: CustomOL,
                },
                ul: {
                  component: CustomUL,
                },
                p: {
                  component: CustomP,
                },
              },
            } }
            >
              {latestMessage}
            </Markdown>
          </Message>
        </MessageBubble>
      ) }
      <ChatButton onClick={ onClick } hide={ hide }>
        <ChatIcon />
      </ChatButton>
    </Container>
  );
};
