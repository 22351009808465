import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../../consts/analytics';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { Container, Description, Title } from './matches-deadend.styled';

export const MatchesDeadend = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();

  useEffect(() => {
    analyticsTrackEvent({
      eventName: ANALYTICS_EVENTS.POSITION_DEADEND_TRIGGERED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.POSITION_PAGE,
    });
  }, [analyticsTrackEvent]);

  return (
    <Container>
      <Title>
        { translate(STRING_KEYS.MATCHES_PAGE.DEADEND.TITLE) }
      </Title>
      <Description>
        <pre>{ translate(STRING_KEYS.MATCHES_PAGE.DEADEND.DESCRIPTION) }</pre>
      </Description>
    </Container>
  );
};
