import styled, { css } from 'styled-components';
import { Body1Font } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';
import Select from '../../../../components/Common/select/select.component';
import BaseInput from '../../../../components/Common/perfect-base-input/perfect-base-input.component';
import {
  PerfectPhoneInput as BasePhoneInput,
} from '../../../../components/Common/perfect-phone-input/perfect-phone-input.component';
import { Input } from '../../../../components/Common/select/select.styled';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { hexToRgbA } from '../../../../utils/colors';
import { ASSETS } from '../../../../assets';
import SelectItem from '../../../../components/Common/select/select-item/select-item.component';

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 16px;
  width: 100%;
  max-width: 400px;
`;

const commonInputStyles = css<{ $spanBothColumns?: boolean }>`
  grid-column: ${({ $spanBothColumns }) => $spanBothColumns ? 'span 2' : 'auto'};
  border-radius: 12px;
  height: 56px;
  width: 100%;
  font-size: 16px;
  background-color: ${THEME.COLORS.LIGHT_GREY};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_SMALL}px) {
    grid-column: span 2;
  }
`;

export const PerfectSelect = styled(Select)<{ $spanBothColumns?: boolean }>`
  && {
    ${commonInputStyles};

    ${Input} {
      background-color: ${THEME.COLORS.LIGHT_GREY};
      ${Body1Font}
    }
  }
`;

export const PerfectInput = styled(BaseInput)<{ $spanBothColumns?: boolean }>`
  ${commonInputStyles};

  input {
    background-color: ${THEME.COLORS.LIGHT_GREY};
    ${Body1Font}

    ::placeholder {
      color: ${THEME.COLORS.PRIMARY_DARK};
    }
  }
`;

export const PerfectPhoneInput = styled(BasePhoneInput)`
  ${commonInputStyles};

  input {
    background-color: ${THEME.COLORS.LIGHT_GREY};
    ${Body1Font}

    ::placeholder {
      color: ${THEME.COLORS.PRIMARY_DARK};
    }
  }
`;

export const PerfectButton = styled(BaseButton)<{ $spanBothColumns?: boolean }>`
  grid-column: ${({ $spanBothColumns }) => $spanBothColumns ? '1 / span 2' : 'auto'};
  width: 100%;
  background-color: ${THEME.COLORS.ACCENT};
`;

export const SelectCustomItem = styled(SelectItem)`
  :hover, :focus {
    background-color: white;
    color: ${THEME.COLORS.PRIMARY_DARK};
  }
`;

export const SelectItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const SelectItemTitle = styled.div`
  font-size: ${THEME.FONT_SIZE.TINY};
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, 0.6)};
  text-transform: uppercase;
`;

export const SelectItemRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PlusIcon = styled(ASSETS.FILLED_PLUS)`
  height: 16px;
  color: ${THEME.COLORS.ACCENT};
`;
