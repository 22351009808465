import styled from 'styled-components';
import { THEME } from '../../../themes';

const DIALOG_Z_INDEX = THEME.Z_INDEX.HIGH;

export const DialogContainer = styled.div<{
  widthSize: string;
  $zIndex?: number;
}>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  min-height: 240px;
  width: ${({ widthSize }) => widthSize};
  box-sizing: border-box;
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  z-index: ${({ $zIndex }) => $zIndex ?? DIALOG_Z_INDEX};
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  padding: 10px 0;
  overflow: hidden;
`;

export const Backdrop = styled.div<{
  disableFilter?: boolean;
  $zIndex?: number;
}>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ $zIndex }) => $zIndex ?? DIALOG_Z_INDEX - 1};
  background-color: ${THEME.COLORS_RGB.TRUE_BLACK_012};
  backdrop-filter: ${({ disableFilter }) => disableFilter ? 'unset' : 'blur(16px)'};
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: ${THEME.SPACING.BASE};
  margin-right: ${THEME.SPACING.BASE};
  z-index: 1;
`;
