import { PropsWithChildren } from 'react';
import { PageHeader } from '../../../../components/Common/headers/page-header/page-header.component';
import { ContentInnerWrapper, ContentWrapper, Wrapper } from './onboarding-steps-layout.styled';

export const OnboardingStepsLayout = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Wrapper>
      <PageHeader logoSize="small" isSticky />

      <ContentWrapper>
        <ContentInnerWrapper>{children}</ContentInnerWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
