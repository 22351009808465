import { useTranslation } from 'react-i18next';
import { DialogWidthSize } from '../../base-dialog/base-dialog.enums';
import STRING_KEYS from '../../../../language/keys';
import { THEME } from '../../../../themes';
import {
  Description,
  DialogBody,
  DialogHeader,
  PerfectButton,
  PerfectDialog,
  Title,
} from './invite-teammate-confirmation-dialog.styled';

export const InviteTeammateConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: InviteTeammateConfirmationDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <PerfectDialog isOpen={ isOpen } onClose={ onClose } widthSize={ DialogWidthSize.Small } zIndex={ THEME.Z_INDEX.DIALOG + 3 } portalUniqueId="invite">
      <DialogHeader>
        <Title>
          {translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_A_TEAMMATE_TITLE)}
        </Title>
      </DialogHeader>

      <DialogBody>
        <Description>
          {translate(
            STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_A_TEAMMATE_CONFIRMATION,
          )}
        </Description>

        <PerfectButton onClick={ onConfirm }>
          {translate(
            STRING_KEYS.INVITE_YOUR_TEAMMATES
              .INVITE_A_TEAMMATE_CONFIRM_AND_INVITE,
          )}
        </PerfectButton>
      </DialogBody>
    </PerfectDialog>
  );
};
