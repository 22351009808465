import { ContextHolder } from '@frontegg/react';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../../../api';
import { CONFIG } from '../../../config';
import { ApiError } from '../../../consts/api';
import { API_V1 } from '../consts/versions.consts';

type BaseQueryArgs = {
  url: string,
  method: AxiosRequestConfig['method'],
  data?: AxiosRequestConfig['data'],
  meta?: { headers: AxiosRequestConfig['headers'], }
  params?: AxiosRequestConfig['params'],
  version?: string,
}

export const axiosBaseQuery = (): BaseQueryFn<BaseQueryArgs, unknown, ApiError> => async ({
  url, method, data, params, version,
}) => {
  try {
    const result = await axiosInstance({
      url: `${CONFIG.BASE_URL}${version || API_V1}${url}`, method, data, params, headers: { Authorization: `Bearer ${ContextHolder.getAccessToken()}` },
    });

    return { data: result.data, meta: { headers: result.headers } };
  } catch (error) {
    const castedError = error as ApiError;

    return {
      error: {
        code: castedError.code,
        isAxiosError: castedError.isAxiosError,
        isApiError: castedError.isApiError,
        error: castedError.error,
      } as ApiError,
    };
  }
};
