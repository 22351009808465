import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Title2Font } from '../../../../themes/fonts';

export const Container = styled.div`
  font: normal 40px/50px Arial, sans-serif;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  
  display: inline-block;
  height: 50px;
  overflow: hidden;
  position: relative;
  min-width: 240px;
  text-align: center;
`;

export const RotatingTextSpan = styled.span<{ currentIndex: number, transitionDuration: number }>`
  display: inline-block;
  color: ${THEME.COLORS.ACCENT};
  position: relative;
  white-space: nowrap;
  top: ${(props) => `-${props.currentIndex * 50}px`};
  transition: top ${(props) => props.transitionDuration}s ease-in-out, opacity ${(props) => props.transitionDuration}s ease-in-out;
`;

export const TextItem = styled.span<{ isActive: boolean }>`
  ${Title2Font};
  opacity: ${(props) => props.isActive ? 1 : 0.2};
  transition: opacity 0.5s ease-in-out;
  display: block;
  height: 50px;
  font-weight: 600;
`;
