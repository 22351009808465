import React, { useRef } from 'react';
import { useIntersection } from '../../../../../hooks/useIntersection';
import { ANALYTICS_EVENTS } from '../../../../../consts/analytics';
import {
  TalentNetworkEducationDto,
} from '../../../../../modules/application/components/talent-network/mutual-education-sentence/mutual-education-sentence';
import { Badge, DateObject } from '../../../../../@types/match';
import { Badges } from './education-item-badges/education-item-badges.component';
import {
  DescriptionSection,
  EducationItemDescription,
  EducationItemSection,
  IconContainer,
  InstitutionName,
  YearsSection,
} from './education-item.styled';
import { EducationLogo } from './education-item-logo/education-item-logo.component';
import { EducationItemTalentNetwork } from './education-item-talent-network/education-item-talent-network.component';

const COMPONENT_ELEMENT_TYPE = 'education';

type EducationItemProps = {
  badges: Badge[],
  endDateObject: DateObject,
  educationId: number,
  institutionLogo: string,
  institutionName: string,
  startDateObject: DateObject,
  fieldOfStudy: string | undefined,
  degree: string | undefined,
  isLastItem: boolean,
  trackMatchElementViewed?: (eventName: string, componentElementType: string, componentElement?: string) => void,
  talentNetworkEducationMap?: Map<number, TalentNetworkEducationDto[]>,
}

const EducationItem = ({
  badges,
  endDateObject,
  institutionLogo,
  institutionName,
  startDateObject,
  fieldOfStudy,
  degree,
  isLastItem,
  educationId,
  trackMatchElementViewed,
  talentNetworkEducationMap,
}: EducationItemProps): JSX.Element => {
  const lastEducationItemRef = useRef<HTMLDivElement>(null);
  useIntersection({
    element: lastEducationItemRef,
    callback: () => {
      if (isLastItem) {
        trackMatchElementViewed?.(ANALYTICS_EVENTS.MATCH_ELEMENT_VIEWED, COMPONENT_ELEMENT_TYPE);
      }
    },
  });

  const startYear = startDateObject && startDateObject.year;
  const endYear = endDateObject && ` - ${endDateObject && endDateObject.year}`;

  const prepareDegreeAndFieldOfStudy = () => {
    if (!degree && !fieldOfStudy) {
      return null;
    }

    return (
      <EducationItemDescription>
        {degree && fieldOfStudy && (
          <div>
            {degree},
          </div>
        )}
        {degree && !fieldOfStudy && (
          <div>
            {degree}
          </div>
        )}
        {fieldOfStudy && (
          <div>
            {fieldOfStudy}
          </div>
        )}
      </EducationItemDescription>
    );
  };

  const renderTalentNetworkEducationLevel = () => {
    if (!talentNetworkEducationMap) {
      return null;
    }

    const educationNetworkedTalents = talentNetworkEducationMap.get(educationId);

    if (!educationNetworkedTalents) {
      return null;
    }

    return (
      <EducationItemTalentNetwork institutionName={ institutionName } networkedTalents={ educationNetworkedTalents }  />
    );
  };

  return (
    <EducationItemSection ref={ isLastItem ? lastEducationItemRef : undefined }>
      <IconContainer>
        <EducationLogo
          logoUrl={ institutionLogo }
          institutionName={ institutionName }
        />
      </IconContainer>
      <DescriptionSection>
        {institutionName && (
          <InstitutionName>
            {institutionName}
          </InstitutionName>
        )}
        { prepareDegreeAndFieldOfStudy() }
        {
          startYear && (
            <YearsSection>
              {startYear}
              {endYear}
            </YearsSection>
          )
        }
        {badges?.length > 0 && <Badges badges={ badges } />}
        { renderTalentNetworkEducationLevel() }
      </DescriptionSection>
    </EducationItemSection>
  );
};

EducationItem.defaultProps = {
  trackMatchElementViewed: undefined,
  talentNetworkEducationMap: undefined,
};

export default React.memo(EducationItem);
