import styled from 'styled-components';
import { Title1Font, Title2Font } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

export const Title = styled.h1`
  ${Title1Font};
  font-weight: 300;
  color: ${THEME.COLORS.CONTENT_A};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    ${Title2Font};
  }
`;

export const Subtitle = styled.h2`
  ${Title1Font};
  font-weight: 700;
  color: ${THEME.COLORS.CONTENT_A};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    ${Title2Font};
    font-weight: 700;
  }
`;

export const PerfectButton = styled(BaseButton)`
  opacity: ${THEME.OPACITY.MED};
`;
