import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MinimizeChatButton } from '../minimize-chat-button/minimize-chat-button.component';
import { ChatMessage } from '../chat-message/chat-message.component';
import { FloatingChatButtonContainer } from '../minimized-chat/minimized-chat.component';
import { ChatSnackbar } from '../chat-snackbar/chat-snackbar.component';
import { THEME } from '../../../../../themes';
import {
  ConversationMessage,
} from '../../../../../store/rtk-query/apis/recruiter-agent/dto/response/conversation-message';
import { ConversationMessageType } from '../../../../../enums/conversation-message-type.enum';
import { ChatInput } from '../chat-input/chat-input.component';
import {
  useCreateConversationMessageMutation,
} from '../../../../../store/rtk-query/apis/recruiter-agent/hooks/create-conversation-message.mutation-hook';
import { InputError } from '../chat-input/chat-input';
import { ErrorType } from '../chat-input/chat-input.enums';
import STRING_KEYS from '../../../../../language/keys';
import { convertUtcToTimezoneAdjusted } from '../../../../../utils/dates';
import { sleep } from '../../../../../utils';
import { AiGeneratingMessage } from '../ai-generating-message/ai-generating-message.component';
import { getRandomChatOpening } from '../../../../onboarding/utils/chat.utils';
import { ChatBox, ChatContainer, MinimizeWrapper } from './perfect-ai-chat.styled';
import { PerfectAiChatProps } from './perfect-ai-chat';

const DEFAULT_AGENT_MESSAGE = {
  id: `ai-${Date.now()}`,
  messageType: ConversationMessageType.AgentMessage,
  message: getRandomChatOpening(),
  isTyping: false,
  isThinking: false,
  isStaticMessage: true,
};

export const PerfectAiChat = ({
  isChatMinimized,
  onChatMinimize,
  isChatInputDisabled,
  chatSnackbarProps,
  conversation,
  onSendMessage,
  positionId,
  onError,
  isAgentGenerating,
  chatInputValue,
  isPositionMigrated,
  isChatHidden,
  setIsChatHidden,
  chatMinimizeTime,
  updateChatMinimizeTime,
  isConversationLoadingSuccess,
  userInfo,
}: PerfectAiChatProps): JSX.Element => {
  const defaultMessageConversation = [!isPositionMigrated && DEFAULT_AGENT_MESSAGE];
  const { t: translate } = useTranslation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [chatError, setChatError] = useState<InputError | undefined>(undefined);
  const [messages, setMessages] = useState<ConversationMessage[]>(defaultMessageConversation);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const isSmallScreen = window.innerWidth < THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN;
  const [createConversationMessage,
    { isSuccess: isMessageCreatedSuccess, isError: isMessageCreateError, isLoading: isMessageCreateLoading },
  ] = useCreateConversationMessageMutation();
  const [firstLoadDone, setFirstLoadDone] = useState(false);

  const afterSendingMessageHandler = async () => {
    // Disable actions of the last AI message
    setMessages((prev) => prev.map((msg, index) => msg.messageType === ConversationMessageType.AgentMessage && index === prev.length - 1 ?
      { ...msg, actionsDisabled: true } :
      msg),
    );
  };

  useEffect(() => {
    let processedMessages: ConversationMessage[] = [];

    if (isConversationLoadingSuccess) {
      processedMessages = !isPositionMigrated ? [DEFAULT_AGENT_MESSAGE, ...conversation] : [...conversation];

      if (messages.length === processedMessages.length) return;

      if (!firstLoadDone) {
        // First load: ensure no agent message is typing.
        processedMessages = processedMessages.map((msg) => msg.messageType === ConversationMessageType.AgentMessage ?
          { ...msg, isTyping: false } :
          msg,
        );
        setMessages(processedMessages);
        setFirstLoadDone(true);
      } else {
        // First, ensure all agent messages are set to not typing.
        processedMessages = processedMessages.map((msg) => msg.messageType === ConversationMessageType.AgentMessage ?
          { ...msg, isTyping: false } :
          msg,
        );

        // Then, only if the very last message in the conversation is an agent message,
        // mark it as typing.
        if (
          processedMessages.length > 0 &&
          processedMessages[processedMessages.length - 1].messageType ===
          ConversationMessageType.AgentMessage
        ) {
          processedMessages[processedMessages.length - 1] = {
            ...processedMessages[processedMessages.length - 1],
            isTyping: true,
          };
        }
        setMessages(processedMessages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, isPositionMigrated, isConversationLoadingSuccess]);

  useEffect(() => {
    if (isMessageCreatedSuccess) {
      afterSendingMessageHandler();
    }
  }, [isMessageCreatedSuccess]);

  const sendMessageHandler = useCallback(async (message: string) => {
    if (message.trim() === '') return;

    const tempId = `temp-${Date.now()}`;
    const userMessage: ConversationMessage = {
      id: tempId, // initially the fake id
      sender: {
        id: userInfo.id,
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        profilePictureUrl: `${userInfo.profileImage}`,
      },
      messageType: ConversationMessageType.UserMessage,
      message,
      isTyping: false,
    };

    setMessages(
      (prev) => [...prev.map((msg, index) => index === prev.length - 1 ? { ...msg, suggestionsDisabled: true } : msg), userMessage],
    );

    createConversationMessage({ message, positionId });
    onSendMessage();
  }, [createConversationMessage, onSendMessage, positionId, userInfo.firstName, userInfo.id, userInfo.lastName, userInfo.profileImage]);

  useEffect(() => {
    if (!isMessageCreateError && chatError) {
      setChatError(undefined);
    }

    if (isMessageCreateError && !chatError) {
      setChatError({
        message: translate(STRING_KEYS.MATCHES_PAGE.CHAT.INPUT.NETWORK_ERROR),
        type: ErrorType.NetworkError,
        action: sendMessageHandler,
      });

      onError();
    }
  }, [onError, chatError, isMessageCreateError, sendMessageHandler, translate]);

  const scrollDown = async (waitBeforeScroll = false) => {
    if (waitBeforeScroll) {
      await sleep(500);
    }

    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTypingComplete = useCallback(() => {
    setMessages((prev) => prev.map((msg, index) => index === prev.length - 1 ? { ...msg, isTyping: false } : msg));
    scrollDown();
  }, []);

  const actionClickHandler = async (action: string) => {
    // Send the action as a user message
    await sendMessageHandler(action);
  };

  const onMinimizeHandler = () => {
    updateChatMinimizeTime();
    onChatMinimize(true);
    setIsChatHidden(true);
  };

  const onMaximizeHandler = () => {
    onChatMinimize(!isChatMinimized);
    setTimeout(() => {
      setIsChatHidden(false);
    }, 300);
  };

  const unreadMessages = React.useMemo(() => {
    return messages.filter(
      (message) => {
        if (message.isStaticMessage || !message.createdAt) {
          return false;
        }

        const createdAtWithCurrentTimezone = convertUtcToTimezoneAdjusted(message?.createdAt);

        return chatMinimizeTime &&
          createdAtWithCurrentTimezone &&
          new Date(createdAtWithCurrentTimezone).getTime() > chatMinimizeTime;
      },
    );
  }, [messages, chatMinimizeTime]);

  useEffect(() => {
    scrollDown(true);
  }, [messages, isChatMinimized]);

  useEffect(() => {
    if (chatSnackbarProps.text || chatSnackbarProps.Icon) {
      setShowSnackbar(true);
    }

    if (chatSnackbarProps.text === '') {
      setShowSnackbar(false);
    }
  }, [chatSnackbarProps.text, chatSnackbarProps.Icon, chatSnackbarProps.firstAction]);

  return (
    <>
      { ((isChatHidden && isChatMinimized) || isSmallScreen) && (
        <FloatingChatButtonContainer
          onClick={ onMaximizeHandler }
          hide={ isChatMinimized }
          latestMessage={ unreadMessages[unreadMessages.length - 1]?.message || null }
          hasUnread={ unreadMessages.length > 0 }
          isSmallScreen={ isSmallScreen }
          isMinimized={ isChatMinimized }
        />
      ) }
      {!(isChatHidden && isChatMinimized) && (
        <>
          <div id="chat-container" />
          <ChatContainer isMinimized={ isChatMinimized } hide={ isChatHidden }>
            <MinimizeWrapper>
              <MinimizeChatButton onClick={ onMinimizeHandler } />
            </MinimizeWrapper>
            <ChatBox>
              {messages.map((message, index) => {
                const isGenerating = index === messages.length - 1 ? isAgentGenerating : false;

                return (
                  <ChatMessage
                    key={ message.id }
                    conversationMessage={ message }
                    onActionClick={ actionClickHandler }
                    onTypingComplete={ handleTypingComplete }
                    isAgentGenerating={ isGenerating }
                  />
                );
              })}
              { isAgentGenerating && <AiGeneratingMessage isAgentGenerating /> }
              <div ref={ messagesEndRef } />
            </ChatBox>
            { showSnackbar && <ChatSnackbar Icon={ chatSnackbarProps.Icon } text={ chatSnackbarProps.text } firstAction={ chatSnackbarProps.firstAction } /> }
            <ChatInput
              isDisabled={ isChatInputDisabled || isMessageCreateError }
              isLoading={ isMessageCreateLoading }
              isMessageCreated={ isMessageCreatedSuccess }
              onSubmit={ sendMessageHandler }
              error={ chatError }
              value={ chatInputValue }
            />
          </ChatContainer>
        </>
      )}
    </>
  );
};
