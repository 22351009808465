import log from 'loglevel';
import { PropsWithChildren } from 'react';
import { useSocketConnectorListener } from '../../../../hooks/use-socket-connector-listener.hooks';
import { useGetOpenPositionsQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import { FatalErrorPage } from '../../../../components/Common/Errors/FatalErrorPage';
import { FATAL_ERRORS_CODE } from '../../../../store/slices/app-state/app-state.consts';
import { useAppState } from '../../../../store/selectors';
import { BackgroundContainer, ChildrenWrapper, PageContainer } from './application-layout.styled';

const logger = log.getLogger('ApplicationLayout');

export const ApplicationLayout = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element | null => {
  const { showSplashScreen } = useAppState();

  const { data: { accountId } = {} } = useGetMyUserQuery();
  const {
    isError: isErrorInOpenPositions,
    error,
  } = useGetOpenPositionsQuery(undefined, { skip: !accountId });

  useSocketConnectorListener();

  if (isErrorInOpenPositions) {
    logger.error(error.message, 'Showing a fatal page', {
      appErrorCode: error.code,
      errorData: error,
    });

    return (
      <FatalErrorPage
        errorCode={ FATAL_ERRORS_CODE.POSITIONS_FROM_SERVER_FAILED }
      />
    );
  }

  if (showSplashScreen) {
    return null;
  }

  return (
    <PageContainer>
      <BackgroundContainer />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </PageContainer>
  );
};
