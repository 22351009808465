import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import STRING_KEYS from '../../../language/keys';
import { AnimatedBackground, BodyWrapper } from './create-position-transition.styled';
import { CreatePositionTransitionProps } from './create-position-transition';
import { TEXT_ANIMATION_DELAY_IN_MS } from './create-position-transition.consts';

export const CreatePositionTransition = ({
  onTransitionComplete,
  isLoading,
  userName,
}: CreatePositionTransitionProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const [
    isAllTextAnimationsComplete,
    setIsAllTextAnimationsComplete,
  ] = useState<boolean>(false);

  const textLines = [
    `${translate(STRING_KEYS.CREATE_POSITION.TRANSITION.HELLO)} ${userName}!`,
    translate(STRING_KEYS.CREATE_POSITION.TRANSITION.RECRUITING),
    translate(STRING_KEYS.CREATE_POSITION.TRANSITION.LETS_BEGIN),
  ];

  const handleTextAnimationComplete = (index: number) => {
    if (index === textLines.length - 1) {
      // Give the last text line a delay, like every other text line got, before completing the animation.
      setTimeout(() => {
        setIsAllTextAnimationsComplete(true);
      }, TEXT_ANIMATION_DELAY_IN_MS);
    }
  };

  useEffect(() => {
    if (isAllTextAnimationsComplete && !isLoading) {
      onTransitionComplete();
    }
  }, [isAllTextAnimationsComplete, isLoading, onTransitionComplete]);

  return (
    <AnimatedBackground
      initial={ {
        top: '100%',
        left: '50%',
        width: 0,
        height: 0,
        borderRadius: 9999,
      } }
      animate={ {
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        borderRadius: 0,
      } }
      transition={ { duration: 0.3 } }
    >
      {!!userName?.length && (
        <BodyWrapper>
          {textLines.map((text, index) => (
            <motion.div
              key={ text }
              initial={ { opacity: 0, y: 20 } }
              animate={ { opacity: 1, y: 0 } }
              transition={ {
                duration: 0.5,
                delay: index * (TEXT_ANIMATION_DELAY_IN_MS / 1000),
              } }
              onAnimationComplete={ () => handleTextAnimationComplete(index) }
            >
              {text}
            </motion.div>
          ))}
        </BodyWrapper>
      )}
    </AnimatedBackground>
  );
};
