import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_NAMES } from '../../../../consts/pages';
import { EVENT_GROUP } from '../../../../consts/analytics';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../consts/onboarding-analytics.consts';
import { Registration } from '../../../../components/login-and-registration/registration/registration.component';
import { SIGNUP_ERROR_CODE } from '../../../../consts';
import { useCreateUserOnboardingMutation } from '../../../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { useAppSelector } from '../../../../store/selectors';
import { selectAiChatPrompt, selectPromo } from '../../../../store/selectors/onboarding.selectors';

const QUERY_PARAM_ERROR_CODE = 'errorCode';

export const OnboardingRegistrationPage = (): JSX.Element => {
  const {  analyticsPageEvent } = useAnalyticsContext();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const errorCode = (queryParams.get(QUERY_PARAM_ERROR_CODE) ||
      0) as SIGNUP_ERROR_CODE;

  const [createUserOnboarding] = useCreateUserOnboardingMutation();

  const promo = useAppSelector(selectPromo);
  const aiChatPrompt = useAppSelector(selectAiChatPrompt);

  useEffect(() => {
    analyticsPageEvent({
      eventName: ONBOARDING_ANALYTICS_EVENTS.SIGN_UP_PAGE_VIEWED,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegistrationComplete = async () => {
    await createUserOnboarding({ aiChatPrompt, promo });
  };

  return (
    <Registration onRegistrationComplete={ handleRegistrationComplete } errorCode={ errorCode } />
  );
};
