import {
  KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';
import { useDispatch } from 'react-redux';
import STRING_KEYS from '../../../../language/keys';
import { CONFIG } from '../../../../config';
import { ANALYTICS_EVENTS, EVENT_GROUP, EVENT_TYPE } from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { useAppState } from '../../../../store/selectors';
import { RudderStackDestination } from '../../../../services/rudderStackAnalyticsAgent';
import { TrackEventPayload } from '../../../../@types/analytics/eventPayload';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../create-position/consts/position-analytics.consts';
import {
  useSendVerificationEmailMutation,
  useVerifyEmailMutation,
} from '../../../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { PAGE_TITLES } from '../../../../consts';
import { PerfectButton } from '../onboarding-setup-profile-page/onboarding-setup-profile.styled';
import {
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { invalidateTags } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import {
  Description,
  EnterCodeInstruction,
  ErrorText,
  HelperTextWrapper,
  HumanHelpTextWrapper,
  LinkToMail,
  Loader,
  LoaderWrapper,
  NoLuckText,
  ResendCode,
  SendingCodeText,
  Subtitle,
  Title,
  TitlesWrapper,
  VerificationDigitInput,
  VerificationRectContainer,
  Wrapper,
} from './onboarding-email-verification.styled';

const SUPPORT_BUTTON_COMPONENT_NAME = 'support';
const logger = log.getLogger('ONBOARDING_EMAIL_VERIFICATION');

export const OnboardingEmailVerificationPage = ({
  onSignOut,
}: EmailVerificationScreenProps): JSX.Element => {
  const dispatch = useDispatch();

  const [verificationInputs, setVerificationInputs] = useState([
    {
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      value: '',
      ref: useRef<HTMLInputElement>(null),
    },
  ]);

  const [isCodeResendRequested, setIsCodeResendRequested] = useState<boolean>(
    false,
  );

  const { t: translate } = useTranslation();

  const [
    sendVerificationEmail,
    { isUninitialized: isSendVerificationEmailUninitialized },
  ] = useSendVerificationEmailMutation();
  const [
    verifyEmail,
    {
      isLoading: isEmailVerificationLoading,
      isError: isEmailVerificationFailed,
    },
  ] = useVerifyEmailMutation();

  const { analyticsTrackEvent } = useAnalyticsContext();
  const { currentPageId, referrerPageId } = useAppState();

  useEffect(() => {
    if (isEmailVerificationFailed) {
      const intercomTrackEventPayload = {
        eventName: ANALYTICS_EVENTS.INTERCOM_TRIGGER_EMAIL_VERIFICATION_FAILED,
        event_type: EVENT_TYPE.INTERCOM_TRIGGER,
        pageName: PAGE_NAMES.EMAIL_VERIFICATION_ONBOARDING,
        pageViewId: currentPageId,
        pageReferrerId: referrerPageId,
      };
      // send only to the INTERCOM integration
      analyticsTrackEvent(intercomTrackEventPayload, [
        RudderStackDestination.INTERCOM,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailVerificationFailed]);

  const handleInputChange = async (event: SyntheticEvent, index: number) => {
    const { value } = event.target as HTMLInputElement;

    if (index < verificationInputs.length - 1 && value !== '') {
      const nextInput = verificationInputs[index + 1].ref
        .current as HTMLInputElement;

      nextInput.focus();
    }

    setVerificationInputs(
      verificationInputs.map((verInput, verInputIndex) => {
        if (verInputIndex === index) {
          return {
            ...verInput,
            value,
          };
        }

        return verInput;
      }),
    );

    const currentCode = `${verificationInputs
      .map((verInput) => verInput.value)
      .join('')}${value}`;

    if (value && currentCode.length === verificationInputs.length) {
      verificationInputs[verificationInputs.length - 1].ref.current?.blur();

      try {
        await verifyEmail({ code: currentCode }).unwrap();
        
        // TODO These tags need to be invalidated as part of the email verification endpoint in onboarding API once we use a single createApi instance
        dispatch(invalidateTags([{ type: 'user' }]));
      } catch (e) {
        logger.error(e);
      }
    }
  };

  useEffect(() => {
    if (isSendVerificationEmailUninitialized) {
      sendVerificationEmail();
    }
  }, [isSendVerificationEmailUninitialized, sendVerificationEmail]);

  const handleKeyPress = (event: KeyboardEvent, index: number) => {
    if (event.key === 'Backspace') {
      if (index > 0 && verificationInputs[index].value === '') {
        const prevInput = verificationInputs[index - 1].ref
          .current as HTMLInputElement;

        prevInput.focus();
      }
    }
  };

  const handleResendCodeRequest = () => {
    setIsCodeResendRequested(true);

    sendVerificationEmail();
  };

  const handleRequestSupportClicked = () => {
    const trackEventPayload = {
      eventName:
        CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_SUPPORT_BUTTON_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.EMAIL_VERIFICATION_ONBOARDING,
      pageViewId: currentPageId,
      pageReferrerId: referrerPageId,
      componentName: SUPPORT_BUTTON_COMPONENT_NAME,
    } as TrackEventPayload;

    analyticsTrackEvent(trackEventPayload);
  };

  const renderHelperText = () => {
    if (isCodeResendRequested) {
      return (
        <>
          <SendingCodeText>
            {translate(
              STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_SENT_AGAIN,
            )}
          </SendingCodeText>
          <HumanHelpTextWrapper>
            <NoLuckText>
              {translate(
                STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_NO_LUCK,
              )}
            </NoLuckText>
            <LinkToMail
              rel="noopener noreferrer"
              target="_blank"
              onClick={ handleRequestSupportClicked }
              href={ `mailto:${CONFIG.SUPPORT_MAIL_ADDRESS}` }
            >
              {translate(
                STRING_KEYS.ONBOARDING
                  .ONBOARDING_VERIFICATION_CODE_ASK_HUMAN_HELP,
              )}
            </LinkToMail>
          </HumanHelpTextWrapper>
        </>
      );
    }

    return (
      <ResendCode onClick={ handleResendCodeRequest }>
        {translate(
          STRING_KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_NOT_RECEIVED,
        )}
      </ResendCode>
    );
  };

  const handlePasteEvent = async (
    pastedData: string,
    inputRef: RefObject<HTMLInputElement>,
  ) => {
    const pasted = pastedData.split('').slice(0, verificationInputs.length);

    setVerificationInputs(
      verificationInputs.map((verInput, verInputIndex) => {
        return {
          ...verInput,
          value: pasted[verInputIndex],
        };
      }),
    );

    if (pasted.length === verificationInputs.length) {
      inputRef.current?.blur();

      try {
        await verifyEmail({ code: pasted.join('') }).unwrap();

        // TODO These tags need to be invalidated as part of the email verification endpoint in onboarding API once we use a single createApi instance
        dispatch(invalidateTags([{ type: 'user' }]));
      } catch (e) {
        logger.error(e);
      }
    }
  };

  useEffect(() => {
    document.title = PAGE_TITLES.ONBOARDING_EMAIL_VERIFICATION;
  }, []);

  return (
    <Wrapper>
      <TitlesWrapper>
        <Title>
          {translate(
            STRING_KEYS.ONBOARDING.ONBOARDING_EMAIL_VERIFICATION_TITLE,
          )}
        </Title>

        <Subtitle>
          {translate(
            STRING_KEYS.ONBOARDING.ONBOARDING_EMAIL_VERIFICATION_SUBTITLE,
          )}
        </Subtitle>
      </TitlesWrapper>

      <TitlesWrapper>
        <Description>
          {translate(
            STRING_KEYS.ONBOARDING.ONBOARDING_EMAIL_VERIFICATION_DESCRIPTION,
          )}
        </Description>

        <EnterCodeInstruction>
          {translate(
            STRING_KEYS.ONBOARDING.ONBOARDING_EMAIL_VERIFICATION_ENTER_CODE,
          )}
        </EnterCodeInstruction>
      </TitlesWrapper>

      <VerificationRectContainer animationToggle={ isEmailVerificationFailed }>
        {verificationInputs.map(({ ref, value }, index) => (
          <VerificationDigitInput
            key={ `verification-digit-input-${index.toString()}` }
            ref={ ref }
            onPaste={ (event) => {
              handlePasteEvent(event.clipboardData?.getData('text/plain'), ref);
            } }
            value={ value }
            onChange={ (event: SyntheticEvent) => handleInputChange(event, index) }
            onKeyDown={ (event: KeyboardEvent) => handleKeyPress(event, index) }
          />
        ))}
      </VerificationRectContainer>

      <HelperTextWrapper>
        {isEmailVerificationFailed && (
          <ErrorText>
            {translate(
              STRING_KEYS.ONBOARDING
                .ONBOARDING_VERIFICATION_CODE_VERIFICATION_FAILED,
            )}
          </ErrorText>
        )}
        {renderHelperText()}
      </HelperTextWrapper>

      <PerfectButton variant={ PerfectButtonVariants.Link } onClick={ onSignOut }>
        { translate(STRING_KEYS.ONBOARDING.SIGN_OUT) }
      </PerfectButton>

      {isEmailVerificationLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}

    </Wrapper>
  );
};
