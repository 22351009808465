import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import log from 'loglevel';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import EngagementFeedback from '../../components/engagement-feedback/engagement-feedback.component';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import STRING_KEYS from '../../../../language/keys';
import { usePrevious } from '../../../../hooks/states';
import { MatchResponse } from '../../../../store/rtk-query/apis/match/dto/response/match.response';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import {
  useGetPositionStatisticsQuerySelector,
} from '../../../../store/rtk-query/apis/position/selectors/get-position.selector';
import { positionQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { matchQuery } from '../../../../store/rtk-query/apis/match/match.toolkit-api';
import {
  useUpdatePositionAnonymizeTalentDetailsMutation,
} from '../../../../store/rtk-query/apis/position/hooks/update-anonymize-talent-details.mutation-hook';
import { PAGE_TITLES } from '../../../../consts';
import {
  ANALYTICS_EVENTS, COMPONENT_NAME, EVENT_GROUP, PRODUCT_AREA,
} from '../../../../consts/analytics';
import { enableFlashingFavicon, setSnackbar } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import {
  AnchorDirectionsEnum,
  AppSnackbarVariant,
} from '../../../../components/Common/CustomSnackBar/app-snackbar/app-snackbar.enums';
import {
  useSendMatchEngageActionMutation,
} from '../../../../store/rtk-query/apis/match/hooks/send-match-feedback-action.mutation-hook';
import { PAGE_NAMES } from '../../../../consts/pages';
import { PositionRunStatusEnum } from '../../../../enums/position-run-status.enum';
import { MatchInteractionTypeEnum } from '../../../../enums/match-interaction-type.enum';
import PageLoader from '../../components/page-loader/page-loader.component';
import PositionStatistics from '../../components/position-statistics/position-statistics.component';
import MatchesCarousel from '../../components/matches-carousel/matches-carousel.component';
import { useAppState } from '../../../../store/selectors';
import {
  getRecruiterAgentInfoQueryHook,
} from '../../../../store/rtk-query/apis/recruiter-agent/hooks/get-recruiter-agent-info.query-hook';
import {
  RecruiterAgentGenerationStatus,
  RecruiterAgentMatchingStatus,
} from '../../../../enums/recruiter-agent-position-status.enum';
import {
  useRetryGenerationMutation,
} from '../../../../store/rtk-query/apis/recruiter-agent/hooks/retry-generate.mutation-hook';
import {
  useGetConversationQueryHook,
} from '../../../../store/rtk-query/apis/recruiter-agent/hooks/get-converation.query-hook';
import { useRetryMatchingMutation } from '../../../../store/rtk-query/apis/position/hooks/retry-matching.mutation-hook';
import { FatalErrorPage } from '../../../../components/Common/Errors/FatalErrorPage';
import { FATAL_ERRORS_CODE } from '../../../../store/slices/app-state/app-state.consts';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import {
  useGetAccountCreditsQuery,
} from '../../../../store/rtk-query/apis/account/hooks/get-account-credits.query-hook';
import { MatchesPageContentType } from './matches.enums';
import {
  Container,
  ErrorContainer,
  ExclamationMarkIcon,
  HourglassIcon,
  MatcherFab,
  RetryButton,
  RetryIcon,
  Row,
  Wrapper,
} from './matches.styled';
import { PerfectAiChat } from './perfect-ai-chat/perfect-ai-chat.component';
import { MatchesEmptyState } from './matches-empty-state/matches-empty-state.component';
import { ChatSnackbarProps } from './chat-snackbar/chat-snackbar';
import { MatchesDeadend } from './matches-deadend/matches-deadend.component';
import { MatchingProgress } from './matching-progress/matching-progress.component';
import { MatchesError } from './matches-error/matches-error.component';
import { GradientBorderCard } from './animated-glowing-background/animated-glowing-background.component';

const MATCH_INTERACTION_TYPE_TO_EVENT_NAME = {
  [MatchInteractionTypeEnum.Engage]: ANALYTICS_EVENTS.MATCH_ENGAGE_BUTTON_CLICKED,
  [MatchInteractionTypeEnum.Decline]: ANALYTICS_EVENTS.MATCH_DECLINE_BUTTON_CLICKED,
  [MatchInteractionTypeEnum.Undo]: ANALYTICS_EVENTS.MATCH_ENGAGEMENT_UNDO_BUTTON_CLICKED,
};

const logger = log.getLogger('MATCHES_PAGE');

const MatchesPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    userRoleWasChanged,
  } = useAppState();
  const { positionId } = useParams<MatchUrlParams>();
  const previousPositionId = usePrevious(positionId);
  const [engagedMatch, setEngagedMatch] = useState<MatchResponse | null>(null);
  const [pageType, setPageType] = useState<MatchesPageContentType | undefined>();
  const previousPageType = usePrevious(pageType);
  const [undoButtonLoading, setUndoButtonLoading] = useState<boolean>(false);
  const { data: position } = useGetPositionByIdQuery(positionId);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState<string | undefined>(undefined);
  const { data: positionStatistics, isLoading: isPositionLoading } = useGetPositionStatisticsQuerySelector(positionId);
  const [getPosition] = positionQuery.useLazyGetPositionByIdQuery();
  const { refetch: refetchAccountCredits, isFetching: isAccountCreditsFetching } = useGetAccountCreditsQuery();
  const { analyticsPageEvent, analyticsTrackEvent } = useAnalyticsContext();
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const showEditPositionPreferencesButton = false;
  const [isChatMinimized, setIsChatMinimized] = useState(false);
  const [isChatHidden, setIsChatHidden] = useState(false);
  const [isChatInputDisabled, setIsChatInputDisabled] = useState(false);
  const [chatMinimizeTime, setChatMinimizeTime] = useState<number | null>(null);
  const emptyChatSnackbar: ChatSnackbarProps = {
    text: '',
    Icon: null,
    firstAction: null,
  };
  const [chatSnackbarProps, setChatSnackbarProps] = useState<ChatSnackbarProps>(emptyChatSnackbar);
  const [chatInputValue, setChatInputValue] = useState<string>('');

  const [
    engageByType,
    { isLoading: engageRequestLoading },
  ] = useSendMatchEngageActionMutation();

  const [
    getPendingReviewMatches,
    {
      data: pendingToReviewMatchesData,
      isLoading: isMatchLoading,
    },
  ] = matchQuery.useLazyGetPendingReviewMatchesQuery();

  const [
    getConversation,
    {
      data: conversationData,
      isLoading: isConversationLoading,
      isSuccess: isConversationSuccess,
      isError: isConversationError,
      error: conversationError,
    },
  ] = useGetConversationQueryHook();
  const {
    isLoading: isUserInfoLoading,
    data: userInfo,
  } = useGetMyUserQuery();

  const {
    data: recruiterAgentInfo,
    isFetching: isRecruiterAgentInfoFetching,
    isSuccess: isRecruiterAgentInfoSuccess,
    isError: isRecruiterAgentInfoError,
    error: recruiterAgentInfoError,
    refetch: recruiterAgentRefetch,
  } = getRecruiterAgentInfoQueryHook(positionId);
  const [retryGeneration, { isSuccess: isRetrySuccess }] = useRetryGenerationMutation();
  const [retryMatching, { isLoading: isRetryMatchingLoading }] = useRetryMatchingMutation();

  // TODO - remove the eslint after adding the byebias again
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [updateAnonymizeTalentDetails, {
    isError: isUpdateAnonymizeTalentDetailsError,
    isLoading: isUpdateAnonymizeTalentDetailsLoading,
  }] = useUpdatePositionAnonymizeTalentDetailsMutation();

  const pendingToReviewMatches = useMemo(() => {
    return pendingToReviewMatchesData?.results || [];
  }, [pendingToReviewMatchesData]);

  const chatConversation = conversationData?.results || [];

  const [showLoader, setShowLoader] = useState(false);

  const retryHandler = useCallback(() => {
    retryGeneration({ positionId });
  }, [positionId, retryGeneration]);

  const sendNewMessageHandler = () => {
    setIsChatInputDisabled(true);
  };

  const onChatErrorHandler = useCallback(() => {
    const retryButton: JSX.Element = (
      <RetryButton onClick={ retryHandler } variant={ PerfectButtonVariants.Ghost } hoverVariant={ PerfectButtonHoverVariants.Grow } StartIcon={ RetryIcon }>
        { translate(STRING_KEYS.MATCHES_PAGE.CHAT.SNACKBAR.RETRY) }
      </RetryButton>
    );

    setChatSnackbarProps(
      {
        text: translate(STRING_KEYS.MATCHES_PAGE.CHAT.SNACKBAR.SOMETHING_WENT_WRONG),
        Icon: <ExclamationMarkIcon>!</ExclamationMarkIcon>,
        firstAction: retryButton,
      },
    );
  }, [retryHandler, translate]);

  const handleChatMinimizeTime = () => {
    setChatMinimizeTime(Date.now());
  };

  const onChatNetworkErrorHandler = () => {
    if (recruiterAgentRefetch) {
      recruiterAgentRefetch();
    }

    getConversation({ positionId });
  };

  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.MATCHING) {
      setIsChatMinimized(true);
      setIsChatHidden(true);
    }
  }, [recruiterAgentInfo.matchingStatus]);

  useEffect(() => {
    if (isChatMinimized) {
      handleChatMinimizeTime();
    }
  }, [isChatMinimized]);

  useEffect(() => {
    let type;

    if (isRecruiterAgentInfoSuccess) {
      if (showLoader || !positionStatistics.positionId) {
        type = undefined;
      } else if (recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.MATCHING) {
        type = MatchesPageContentType.MatchingProgress;
      } else if (pendingToReviewMatches?.length) {
        type = MatchesPageContentType.MatchesCarousel;
      } else if (recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.ERRORED) {
        type = MatchesPageContentType.PositionNoResultsError;
      } else if (positionStatistics.emptyState) {
        type = MatchesPageContentType.EmptyState;
      } else if (positionStatistics.matchingProgress) {
        type = MatchesPageContentType.MatchingProgress;
      } else if (recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.DAILY_LIMIT) {
        type = MatchesPageContentType.DailyMatchLimit;
        setChatSnackbarProps(
          {
            text: translate(STRING_KEYS.MATCHES_PAGE.CHAT.SNACKBAR.DAILY_LIMIT_REACHED),
            Icon: <HourglassIcon />,
          },
        );
      } else if (positionStatistics.lastRunFailed) {
        if (positionStatistics.noResults) {
          type =
            position?.latestRun?.deadEndRecommendations ?
              MatchesPageContentType.PositionAdjustmentsRecommendations :
              MatchesPageContentType.PositionAdjustmentsDefault;
        } else {
          type = MatchesPageContentType.PositionNoResultsError;
        }
      }

      setPageType(type);
    }
  }, [
    pendingToReviewMatches?.length,
    positionStatistics.positionId,
    positionStatistics.lastRunFailed,
    positionStatistics.isDailyLimit,
    positionStatistics.matchingProgress,
    showLoader,
    isRecruiterAgentInfoSuccess,
    recruiterAgentInfo.matchingStatus,
    positionStatistics.emptyState,
    positionStatistics.noResults,
    position?.latestRun?.deadEndRecommendations,
    translate,
  ]);

  useEffect(() => {
    setShowLoader(true);
    if (previousPositionId) {
      setPageType(undefined);
    }
    if (positionId) {
      getPendingReviewMatches({ positionId });
      getPosition(positionId);
      getConversation({ positionId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  useEffect(() => {
    if (isRetrySuccess) {
      setChatSnackbarProps(emptyChatSnackbar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRetrySuccess]);

  useEffect(() => {
    if (isConversationLoading || isUserInfoLoading) {
      setIsChatInputDisabled(true);
    } else if (isRecruiterAgentInfoSuccess) {
      if (recruiterAgentInfo.generationStatus === RecruiterAgentGenerationStatus.ERRORED) {
        setIsChatInputDisabled(true);
        onChatErrorHandler();
      } else if (recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.DAILY_LIMIT) {
        setIsChatInputDisabled(true);
      } else if (recruiterAgentInfo.generationStatus === RecruiterAgentGenerationStatus.GENERATING) {
        setIsChatInputDisabled(true);
      } else if (recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.MATCHING) {
        setIsChatInputDisabled(true);
      } else if (recruiterAgentInfo.generationStatus === RecruiterAgentGenerationStatus.IDLE || recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.IDLE) {
        setIsChatInputDisabled(false);
      }
    }
  }, [
    isConversationLoading,
    isUserInfoLoading,
    isRecruiterAgentInfoFetching,
    isRecruiterAgentInfoSuccess,
    onChatErrorHandler,
    recruiterAgentInfo,
  ]);

  useEffect(() => {
    if (pageType) {
      document.title = `${position.jobTitle}${PAGE_TITLES.CUSTOM_PAGE_SUFFIX}`;
      analyticsPageEvent({
        eventName: ANALYTICS_EVENTS.MATCHES_PAGE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: pageType.toLowerCase(),
        productArea: PRODUCT_AREA.MATCH_PAGE,
        pagePositionId: positionId,
        pagePositionName: position.jobTitle,
        pagePositionStatus: position.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  useEffect(() => {
    if (pageType === MatchesPageContentType.PositionAdjustmentsRecommendations ||
        pageType === MatchesPageContentType.PositionAdjustmentsDefault) {
      setIsChatMinimized(false);
      setIsChatHidden(false);
    }
  }, [pageType]);

  useEffect(() => {
    if ((isMatchLoading || isPositionLoading || isUpdateAnonymizeTalentDetailsLoading) && !pollingIntervalRef.current) {
      setShowLoader(true);
    }

    if (!isMatchLoading && !isPositionLoading && !isUpdateAnonymizeTalentDetailsLoading && !isConversationLoading) {
      setShowLoader(false);
    }
  }, [isMatchLoading, isPositionLoading, isUpdateAnonymizeTalentDetailsLoading, isConversationLoading]);

  useEffect(() => {
    if (isUpdateAnonymizeTalentDetailsError) {
      dispatch(setSnackbar({
        title: translate(STRING_KEYS.ALERT),
        description: translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE),
        variant: AppSnackbarVariant.Error,
        anchor: AnchorDirectionsEnum.BottomCenter,
      }));
    }
  }, [dispatch, isUpdateAnonymizeTalentDetailsError, translate]);

  useEffect(() => {
    if (engagedMatch) {
      setTimeout(() => {
        setEngagedMatch(null);
      }, 15000); // TODO Check with product time to show
    }
  }, [engagedMatch]);

  const fetchPollingData = useCallback(() => {
    getPendingReviewMatches({ positionId });
    getPosition(positionId);
    getConversation({ positionId });
  }, [getPendingReviewMatches, getPosition, getConversation, positionId]);

  useEffect(() => {
    if (
      (positionStatistics.matchingProgress || recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.MATCHING) &&
      !pollingIntervalRef.current &&
      !engageRequestLoading
    ) {
      pollingIntervalRef.current = setInterval(fetchPollingData, 10000);
    }

    if (
      !(positionStatistics.matchingProgress || recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.MATCHING) &&
      pollingIntervalRef.current
    ) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  }, [fetchPollingData, recruiterAgentInfo.matchingStatus, positionStatistics.matchingProgress, engageRequestLoading]);

  useEffect(() => {
    if (previousPageType === MatchesPageContentType.MatchingProgress && pageType === MatchesPageContentType.MatchesCarousel) {
      dispatch(enableFlashingFavicon());
    }
  }, [previousPageType, pageType, dispatch]);

  const sendMatchAnalyticsTrackEvent = (match: MatchResponse, eventName: string, eventGroup: string, parentComponentName?: string) => {
    const trackEventPayload = {
      eventName,
      eventGroup,
      pageName: PAGE_NAMES.MATCH_PAGE,
      pagePositionId: position.id,
      pagePositionName: position.jobTitle,
      pagePositionStatus: position.status,
      componentName: COMPONENT_NAME.ENGAGEMENT_FAB,
      componentParentName: parentComponentName,
      matchId: match.id,
      matchTalentId: match.talent?.id,
      matchStatus: match.status,
      matchEngagementStatus: match.engagement?.status,
    };
    analyticsTrackEvent(trackEventPayload);
  };

  const handleGetPosition = useCallback(async () => {
    const { data: updatedPosition } = await getPosition(positionId);
    if (!updatedPosition?.hasReachedMaxDailyMatches) {
      dispatch(positionQuery.util.updateQueryData('getPositionById', positionId, (cachedPosition) => {
        cachedPosition.latestRun = {
          status: PositionRunStatusEnum.InProgress,
          triggeredAt: updatedPosition?.lastEngagedAt || new Date().toISOString(),
          noResults: false,
        };
      }));
      dispatch(positionQuery.util.updateQueryData('getOpenPositions', undefined, (cachedPositions) => {
        const cachedPosition = cachedPositions.find(({ id }) => id === positionId);
        if (cachedPosition) {
          cachedPosition.latestRun = {
            status: PositionRunStatusEnum.InProgress,
            triggeredAt: new Date().toISOString(),
            noResults: false,
          };
        }
      }));
    }
  }, [dispatch, getPosition, positionId]);

  useEffect(() => {
    if (recruiterAgentInfo.matchingStatus === RecruiterAgentMatchingStatus.MATCHING) {
      dispatch(
        matchQuery.util.updateQueryData(
          'getPendingReviewMatches',
          { positionId },
          (draft) => {
            draft.results = [];
            draft.totalCount = 0;
            draft.page = 0;
            draft.limit = 0;
          },
        ),
      );
      dispatch(
        positionQuery.util.updateQueryData(
          'getPositionById',
          positionId,
          (cachedPosition) => {
            cachedPosition.matches = [];
            cachedPosition.hasReachedMatchTuneThreshold = false;
          },
        ),
      );
      dispatch(
        positionQuery.util.updateQueryData(
          'getOpenPositions',
          undefined,
          (draft) => {
            const foundPosition = draft.find(
              (cachedPosition) => cachedPosition.id === positionId,
            );
            if (foundPosition) {
              foundPosition.matches = [];
              foundPosition.hasReachedMatchTuneThreshold = false;
            }
          },
        ),
      );
    }
  }, [recruiterAgentInfo.matchingStatus, positionId, dispatch]);

  const handleMatchEngagement = useCallback(async (engagementType: MatchInteractionTypeEnum, match: MatchResponse, isLastMatch = false) => {
    setEngagedMatch(null);
    if (isLastMatch) {
      setShowLoader(true);
    } else {
      setEngagedMatch(match);
    }
    sendMatchAnalyticsTrackEvent(match, MATCH_INTERACTION_TYPE_TO_EVENT_NAME[engagementType], EVENT_GROUP.CLICK);
    try {
      await engageByType({
        positionId,
        matchId: match.id,
        engagementType,
        isLastMatch,
      });
      refetchAccountCredits?.();
      if (isLastMatch) {
        getPendingReviewMatches({ positionId });
        await handleGetPosition();
      }
    } catch (error) {
      logger.error('Engaged process got error, the page will be updated', error);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingToReviewMatches, engageByType, positionId, getPosition, dispatch, getPendingReviewMatches]);

  const onUndoButtonClick = async () => {
    setUndoButtonLoading(true);
    if (engagedMatch) {
      await handleMatchEngagement(MatchInteractionTypeEnum.Undo, engagedMatch);
    }
    getPendingReviewMatches({ positionId });
    setEngagedMatch(null);
    setUndoButtonLoading(false);
  };

  const chipClickHandler = (suggestion: string) => {
    setChatInputValue(suggestion);
  };

  const handleRetryMatching = () => {
    retryMatching({ positionId });
  };

  const getMatchesPageContent = () => {
    if (showLoader) {
      return (<PageLoader />);
    }

    switch (pageType) {
      case MatchesPageContentType.EmptyState:
        return (<MatchesEmptyState onChipClick={ chipClickHandler } />);
      case MatchesPageContentType.DailyMatchLimit:
        return (<PositionStatistics />);
      case MatchesPageContentType.MatchingProgress:
        return (
          <MatchingProgress />
        );
      case MatchesPageContentType.PositionNoResultsError:
        return (
          <MatchesError onClickRetry={ handleRetryMatching } isLoading={ isRetryMatchingLoading } />
        );
      case MatchesPageContentType.PositionAdjustmentsRecommendations:
      case MatchesPageContentType.PositionAdjustmentsDefault:
        return (
          <MatchesDeadend />
        );
      case MatchesPageContentType.MatchesCarousel:
        return (
          <MatchesCarousel
            matches={ pendingToReviewMatches }
            matchEngagementHandler={ handleMatchEngagement }
            disableActions={ engageRequestLoading || isAccountCreditsFetching }
            anonymizeTalentDetails={ position.anonymizeTalentDetails }
          />
        );
      default:
        return (<PageLoader />);
    }
  };

  if (isConversationError || isRecruiterAgentInfoError) {
    const error = recruiterAgentInfoError || conversationError;
    const errorCode = recruiterAgentInfoError ?
      FATAL_ERRORS_CODE.RECRUITER_AGENT_INFO_FROM_SERVER_FAILED :
      FATAL_ERRORS_CODE.RECRUITER_AGENT_CONVERSATION_FROM_SERVER_FAILED;

    logger.error(error.error, 'Match page showing a fatal page', {
      appErrorCode: error.code,
      errorData: error,
      errorCode,
    });

    return (
      <ErrorContainer>
        <FatalErrorPage
          errorCode={ errorCode }
        />
      </ErrorContainer>
    );
  }

  return (
    <Wrapper isGlobalBannerVisible={ userRoleWasChanged }>
      <PerfectAiChat
        isChatMinimized={ isChatMinimized }
        onChatMinimize={ setIsChatMinimized }
        isChatInputDisabled={ isChatInputDisabled }
        chatSnackbarProps={ chatSnackbarProps }
        conversation={ chatConversation }
        onSendMessage={ sendNewMessageHandler }
        positionId={ positionId }
        onError={ onChatNetworkErrorHandler }
        isAgentGenerating={ recruiterAgentInfo.generationStatus === RecruiterAgentGenerationStatus.GENERATING }
        chatInputValue={ chatInputValue }
        isPositionMigrated={ position.migratedToNli }
        isChatHidden={ isChatHidden }
        setIsChatHidden={ setIsChatHidden }
        chatMinimizeTime={ chatMinimizeTime }
        updateChatMinimizeTime={ handleChatMinimizeTime }
        isConversationLoadingSuccess={ isConversationSuccess }
        userInfo={ userInfo }
      />
      <GradientBorderCard
        glowIntensity={ 96 }
        borderOffset={ 5 }
        cornerRadius={ 24 }
        animationSpeed={ 10 }
        isAnimating={ pageType === MatchesPageContentType.MatchingProgress }
      >
        <Container isChatMinimized={ isChatMinimized }>
          { getMatchesPageContent() }
          { showEditPositionPreferencesButton && (
            <MatcherFab>
              <Row>
                {engagedMatch && (
                  <EngagementFeedback
                    matchId={ engagedMatch?.id }
                    undoButtonLoading={ undoButtonLoading }
                    onUndoClickHandler={ onUndoButtonClick }
                  />
                )}
              </Row>
            </MatcherFab>
          )}
        </Container>
      </GradientBorderCard>
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        anchor="top-center"
        description={ snackbarErrorMessage }
        isOpen={ !!snackbarErrorMessage }
        shouldAutoDismiss
        onClose={ () => setSnackbarErrorMessage(undefined) }
      />
    </Wrapper>
  );
};

export default MatchesPage;
