import { DividerWrapper, Label, Line } from './perfect-divider.styled';

export const PerfectDivider = ({ label }: PerfectDividerProps): JSX.Element => (
  <DividerWrapper>
    <Line />

    {label && <Label >{label}</Label>}

    <Line />
  </DividerWrapper>
);
