import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import STRING_KEYS from '../../language/keys';
import { PerfectDivider } from '../Common/perfect-divider/perfect-divider.component';
import { useGetMyUserQuery } from '../../store/rtk-query/apis/user/user.toolkit-api';
import { useGetAccountByIdQuery } from '../../store/rtk-query/apis/account/account.toolkit-api';
import { PricingPlan } from '../../enums/user-plan.enum';
import { usePermissionCheck } from '../../hooks/use-permissions.hook';
import { PERMISSIONS } from '../../consts/permissions.const';
import { UpgradePlanDialog } from '../dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { BuyMoreCreditsDialog } from '../dialogs/buy-more-credits-dialog/buy-more-credits-dialog.component';
import { CONFIG } from '../../config';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../consts/analytics';
import { useAnalyticsContext } from '../../contexts/analytics/useAnalyticsContext';
import { PlanUpgradeOptionsMenuItem } from './plan-upgrade-options-menu-item/plan-upgrade-options-menu-item';
import {
  Description,
  HighVoltageIcon,
  MenuOptionsWrapper,
  PerfectMenu,
  TicketIcon,
  Title,
  Warning,
  Wrapper,
} from './plan-upgrade-options-menu.styled';

type PlanUpgradeMenuOptionsProps = {
  isOpen: boolean;
  onClose: () => void;
  anchorElement: HTMLElement;
};

export const PlanUpgradeOptionsMenu = ({
  isOpen,
  onClose,
  anchorElement,
}: PlanUpgradeMenuOptionsProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const { analyticsTrackEvent } = useAnalyticsContext();

  const [
    isBuyMoreCreditsDialogOpen,
    setIsBuyMoreCreditsDialogOpen,
  ] = useState<boolean>(false);
  const [
    isUpgradePlanDialogOpen,
    setIsUpgradePlanDialogOpen,
  ] = useState<boolean>(false);

  const { data: { accountId } = {} } = useGetMyUserQuery();
  const {
    data: { plan: { isCreditsBased, pricingPlan } } = {},
  } = useGetAccountByIdQuery(accountId, { skip: !accountId });

  const { allowed: isAllowedToUpgradePlan } = usePermissionCheck([
    PERMISSIONS.billing.upgrade,
  ]);

  const isAllowedToBuyMoreCredits =
    isAllowedToUpgradePlan && pricingPlan !== PricingPlan.Trial;

  const needsToContactSales =
    isCreditsBased &&
    [PricingPlan.Tier1, PricingPlan.Tier2].includes(pricingPlan);

  const handleContactSales = () => {
    window.open(CONFIG.CONTACT_SALES_URL, '_blank');
  };

  const openUpgradePlanDialog = () => {
    analyticsTrackEvent({
      eventName: ANALYTICS_EVENTS.UPGRADE_BUTTON_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.HOMEPAGE,
    });

    setIsUpgradePlanDialogOpen(true);
  };

  const openBuyMoreCreditsDialog = () => {
    analyticsTrackEvent({
      eventName: ANALYTICS_EVENTS.BUY_MORE_CREDITS_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.HOMEPAGE,
    });

    setIsBuyMoreCreditsDialogOpen(true);
  };

  return (
    <>
      <PerfectMenu
        isOpen={ isOpen }
        onClose={ onClose }
        anchorElement={ anchorElement }
      >
        <Wrapper>
          <Title>
            {translate(STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.TITLE)}
          </Title>

          <Description>
            {translate(STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.DESCRIPTION)}
          </Description>

          <PerfectDivider />

          {!isAllowedToUpgradePlan && (
            <Warning>
              <Trans
                i18nKey={
                  STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.NOT_ALLOWED_TO_UPGRADE
                }
              />
            </Warning>
          )}

          <MenuOptionsWrapper>
            <PlanUpgradeOptionsMenuItem
              icon={ <TicketIcon /> }
              title={ translate(
                STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.BUY_MORE_CREDITS.TITLE,
              ) }
              subtitle={ translate(
                STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.BUY_MORE_CREDITS.SUBTITLE,
              ) }
              isDisabled={ !isAllowedToBuyMoreCredits }
              onClick={ openBuyMoreCreditsDialog }
            />

            <PlanUpgradeOptionsMenuItem
              icon={ <HighVoltageIcon /> }
              title={
                needsToContactSales ?
                  translate(
                    STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.CONTACT_SALES.TITLE,
                  ) :
                  translate(
                    STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.UPGRADE_PLAN.TITLE,
                  )
              }
              subtitle={
                needsToContactSales ?
                  translate(
                    STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.CONTACT_SALES
                      .SUBTITLE,
                  ) :
                  translate(
                    STRING_KEYS.PLAN_UPGRADE_OPTIONS_MENU.UPGRADE_PLAN
                      .SUBTITLE,
                  )
              }
              isDisabled={ !isAllowedToUpgradePlan }
              onClick={
                needsToContactSales ? handleContactSales : openUpgradePlanDialog
              }
            />
          </MenuOptionsWrapper>
        </Wrapper>
      </PerfectMenu>

      <BuyMoreCreditsDialog
        isOpen={ isBuyMoreCreditsDialogOpen }
        onClose={ () => setIsBuyMoreCreditsDialogOpen(false) }
      />

      <UpgradePlanDialog
        isDialogOpen={ isUpgradePlanDialogOpen }
        onCloseDialog={ () => setIsUpgradePlanDialogOpen(false) }
      />
    </>
  );
};
