export const INITIAL_LOADING = {
  PREPARE_SUB_DOMAIN: 'PREPARE_SUB_DOMAIN',
  LOAD_ACCOUNT_DATA: 'LOAD_ACCOUNT_DATA',
  LOAD_RUDDERSTACK: 'LOAD_RUDDERSTACK',
  LOAD_FRONTEGG: 'LOAD_FRONTEGG',
  LOAD_POSITIONS: 'LOAD_POSITIONS',
};

export const LOGIN_STATE: { [key in string]: LOGIN_STATE } = {
  LOGGED_OUT: 'LOGGED_OUT',
  LOGGED_IN: 'LOGGED_IN',
  LOGIN_IN_PROGRESS: 'LOGIN_IN_PROGRESS',
};

// https://www.notion.so/perfect-match/App-Error-Codes-e88f98d8634d4c83a68f7ad0a24624cd
// range of errors: A-1000 -> A-1999
export const FATAL_ERRORS_CODE = {
  NONE: '',
  UNDEFINED: 'A-1000',
  UNKNOWN_USER: 'A-1001',
  UNKNOWN_ACCOUNT: 'A-1002',

  // Failed to get positions from server
  POSITIONS_FROM_SERVER_FAILED: 'A-1003',

  // match page - Cannot find position to show
  POSITION_DOESNT_EXIST: 'A-1004',

  // Failed to get recruiter agent conversation from server
  RECRUITER_AGENT_CONVERSATION_FROM_SERVER_FAILED: 'A-1005',

  // Failed to get recruiter agent information from server
  RECRUITER_AGENT_INFO_FROM_SERVER_FAILED: 'A-1006',

  // Onboarding - Cannot get onboarding data
  GET_ONBOARDING_PROGRESS_FROM_SERVER_FAILED: 'A-1005',

  // Uncaught Exception
  UNCAUGHT_BOUNDARY_EXCEPTION: 'A-1999',
};
