import styled, { css, keyframes } from 'styled-components';
import { THEME } from '../../../../themes';
import BaseInput from '../../../../components/Common/perfect-base-input/perfect-base-input.component';
import { Body1Font, CaptionFont } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const FormContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
  position: relative;
  width: 100%;
`;

const shakeHorizontal = keyframes` 
  0%,
  100% {
        transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
        transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
        transform: translateX(10px);
  }
  80% {
        transform: translateX(8px);
  }
  90% {
        transform: translateX(-8px); 
  }
`;

export const PerfectInput = styled(BaseInput)<{ $isError: boolean }>`
  border-radius: 12px;
  height: 56px;
  width: 100%;
  font-size: 16px;
  background-color: ${THEME.COLORS.LIGHT_GREY};
  border: solid 1px
    ${({ $isError }) => $isError ? THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_GHOST};
  animation: ${({ $isError }) => $isError ?
    css`
          ${shakeHorizontal} 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both
        ` :
    ''};

  input {
    background-color: ${THEME.COLORS.LIGHT_GREY};
    ${Body1Font}

    ::placeholder {
      color: ${THEME.COLORS.PRIMARY_DARK};
    }
  }
`;

export const EmailIcon = styled.img.attrs(() => ({
  src: ASSETS.MAIL_ALT,
  alt: 'email',
}))`
  width: 24px;
  height: 24px;
`;

export const PasswordIcon = styled.img.attrs(() => ({
  src: ASSETS.SECRET_ICON,
  alt: 'password',
}))`
  width: 24px;
  height: 24px;
`;

export const ValidationError = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  color: ${THEME.COLORS.ALERT};
`;

export const PerfectButton = styled(BaseButton)`
  width: 100%;
  background-color: ${THEME.COLORS.ACCENT};
`;
