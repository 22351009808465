import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import STRING_KEYS from '../../../../language/keys';
import { IndeterminateCircularLoader } from '../../../Common/Loaders/IndeterminateCircularLoader';
import { Divider } from '../../../Common/Divider';
import {
  ColleaguesListWrapper, Container, MyTeammates, WrapperLoader,
} from './invited-teammates-list.styled';
import { InvitedColleaguesListItem } from './invited-teammates-list-item/invited-teammates-list-item.component';
import { InvitedColleaguesListProps } from './invited-teammates-list';

export const InvitedColleaguesList = (
  props: InvitedColleaguesListProps,
): JSX.Element => {
  const {
    inviteColleagueUsers,
    isLoadingColleagueUsers,
    isLoadingSendInviteColleague,
    isLoadingResendInviteColleague,
    isLoadingDeleteColleague,
    isLoadingAssignRole,
    onResetError,
  } = props;
  const { t: translate } = useTranslation();

  const renderColleagueUsers = () => {
    if (isLoadingColleagueUsers ||
      isLoadingSendInviteColleague ||
      isLoadingResendInviteColleague ||
      isLoadingAssignRole ||
      isLoadingDeleteColleague
    ) {
      return (
        <WrapperLoader>
          <IndeterminateCircularLoader />
        </WrapperLoader>
      );
    }

    return inviteColleagueUsers?.map(({
      fullName, profilePictureUrl, verified, email, id, userRoleName,
    }, index) => {
      const isFirstItem = index === 0;

      return (
        <Fragment key={ email }>
          { isFirstItem && (
            <Divider />
          )}
          <InvitedColleaguesListItem
            fullName={ fullName || '' }
            email={ email }
            profileImage={ profilePictureUrl || '' }
            invitePending={ !verified }
            userRoleName={ userRoleName }
            id={ id }
            onResetError={ onResetError }
          />
          <Divider />
        </Fragment>
      );
    });
  };

  return (
    <Container>
      <MyTeammates>
        {
          translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.MY_TEAMMATES_TITLE,
            { counter: inviteColleagueUsers?.length })
        }
      </MyTeammates>
      <ColleaguesListWrapper>
        {renderColleagueUsers()}
      </ColleaguesListWrapper>
    </Container>
  );
};

export default InvitedColleaguesList;
