import { ConversationMinimizedResponseDto } from '../../../../../store/rtk-query/apis/outreach/dto/response/conversation-minimized.response';
import { ConversationItemType } from '../conversation-item/conversation-item.enums';
import { ConversationStatusEnum } from '../../../../../store/rtk-query/apis/outreach/enums/conversation-status.enum';
import { ConversationMessageStatusEnum } from '../../../../../enums/outreach/conversation-message-status.enum';
import { MessageDirectionEnum } from '../../../../../store/rtk-query/apis/outreach/enums/message-direction.enum';
import { ConversationItemProps, ConversationMessage } from '../conversation-item/conversation-item';
import { ConversationGroupsEnum } from '../../../../../enums/outreach/conversation-groups.enum';
import { CONVERSATIONS_GROUP_ICONS } from '../conversation-side-panel/conversation-side-panel.component';

export const getConversationType = (conversation?: ConversationMinimizedResponseDto, isAutoMode?: boolean): ConversationItemType => {
  if (conversation && (conversation?.isArchived)) {
    return ConversationItemType.Archived;
  }

  if (conversation && (conversation?.isError || conversation?.latestMessage?.wasBounced)) {
    return ConversationItemType.Error;
  }

  if (isAutoMode && conversation && conversation?.nextOpenLinkedinTask) {
    return ConversationItemType.Task;
  }

  if (isAutoMode && conversation && conversation?.nextScheduledMessage) {
    return ConversationItemType.Scheduled;
  }

  if (conversation?.status === ConversationStatusEnum.New) {
    return ConversationItemType.New;
  }

  if (conversation?.status === ConversationStatusEnum.Replied && !conversation?.latestMessage?.seen) {
    return ConversationItemType.Replied;
  }

  return ConversationItemType.Default;
};

const getConversationMessage = (conversation?: ConversationMinimizedResponseDto, isAutoMode?: boolean): ConversationMessage | undefined => {
  if (isAutoMode && conversation?.nextOpenLinkedinTask) {
    return {
      text: conversation.nextOpenLinkedinTask?.payload?.sanitizedBodyText || '',
      date: conversation.nextOpenLinkedinTask?.sendAt,
    };
  }

  if (conversation?.latestMessage) {
    return {
      text: conversation.latestMessage?.payload?.sanitizedBodyText,
      date: conversation.latestMessage?.actionTime,
      status: conversation.latestMessage?.opened ? ConversationMessageStatusEnum.Seen : ConversationMessageStatusEnum.Sent,
      isSelfMessage: conversation.latestMessage?.messageDirection === MessageDirectionEnum.Outbound,
      shouldBlur: conversation.latestMessage?.shouldBlur,
      wasBounced: conversation.latestMessage.wasBounced,
    };
  }

  return undefined;
};

export const getConversationItemProps = (
  conversation?: ConversationMinimizedResponseDto,
  isAutoMode?: boolean,
): ConversationItemProps => {
  return {
    conversationId: conversation?.id || '',
    positionId: conversation?.positionId || '',
    type: getConversationType(conversation, isAutoMode),
    url: conversation?.match?.talent.profileImage || '',
    fullName: conversation ? `${conversation.match.talent.firstName} ${conversation.match.talent.lastName}` : '',
    conversationMessage: getConversationMessage(conversation, isAutoMode),
  };
};

export const getGroupIcon = (groupName: string): JSX.Element => {
  if (groupName.includes(ConversationGroupsEnum.Step)) {
    return CONVERSATIONS_GROUP_ICONS.STEP;
  }

  return CONVERSATIONS_GROUP_ICONS[groupName as ConversationGroupsEnum];
};

export const sortMinimizedConversationByLastAction = (
  conversation1: ConversationMinimizedResponseDto,
  conversation2: ConversationMinimizedResponseDto,
): number => {
  return new Date(conversation2.lastActionAt)?.getTime() - new Date(conversation1.lastActionAt)?.getTime();
};

export const sortMinimizedConversationByNextScheduleTime = (
  conversation1: ConversationMinimizedResponseDto,
  conversation2: ConversationMinimizedResponseDto,
): number => {
  if (conversation2.nextScheduledMessage?.sendAt && conversation1.nextScheduledMessage?.sendAt) {
    return new Date(conversation2.nextScheduledMessage.sendAt)?.getTime() - new Date(conversation2.nextScheduledMessage.sendAt)?.getTime();
  }

  return 0;
};

export const sortMinimizedConversationByNextTaskTime = (
  conversation1: ConversationMinimizedResponseDto,
  conversation2: ConversationMinimizedResponseDto,
): number => {
  if (conversation2.nextOpenLinkedinTask?.sendAt && conversation1.nextOpenLinkedinTask?.sendAt) {
    return new Date(conversation2.nextOpenLinkedinTask.sendAt)?.getTime() - new Date(conversation2.nextOpenLinkedinTask.sendAt)?.getTime();
  }

  return 0;
};
