import { useRef, useState } from 'react';
import { PRIVACY_CLASS } from '../../../consts/fullstory';
import StyledAvatar from '../styled-avatar/styled-avatar.component';
import { useGetMyUserQuery } from '../../../store/rtk-query/apis/user/user.toolkit-api';
import { UserProfileMenu } from './user-profile-menu/user-profile-menu.component';
import { Container, HamburgerIcon } from './user-profile.styled';
import { UserProfileProps } from './user-profile';

export const UserProfile = ({
  onPeopleItemClickHandler,
}: UserProfileProps): JSX.Element => {
  const {
    data: { firstName, profileImage, lastName } = {},
  } = useGetMyUserQuery();

  const [userProfileAnchorElement, setUserProfileAnchorElement] = useState<HTMLDivElement>(null);
  const actionButtonRef = useRef(null);
  
  const openUserProfileMenu = () => setUserProfileAnchorElement(actionButtonRef.current);
  const closeUserProfileMenu = () => {
    setUserProfileAnchorElement(null);
  };

  const handlePeopleItemClick = () => {
    setUserProfileAnchorElement(null);
    onPeopleItemClickHandler();
  };

  return (
    <Container
      ref={ actionButtonRef }
      isShowUserMenu={ !!userProfileAnchorElement }
      onClick={ openUserProfileMenu }
    >
      <HamburgerIcon />

      <StyledAvatar
        className={ PRIVACY_CLASS.EXCLUDE }
        name={ `${firstName}-${lastName}` }
        initialsFontSizePx={ 16 }
        image={ profileImage }
        borderWidthPx={ 0 }
        width={ 32 }
        height={ 32 }
      />

      <UserProfileMenu
        anchorElement={ userProfileAnchorElement }
        onClose={ closeUserProfileMenu }
        onPeopleItemClickHandler={ handlePeopleItemClick }
      />
    </Container>
  );
};
