import { ApiError } from '../../../../../consts/api';
import { recruiterAgentQuery } from '../recruiter-agent.toolkit-api';
import { ConversationMessage } from '../dto/response/conversation-message';
import { GetConversationMessageQueryArguments } from '../dto/query-arguments/get-conversation-message.query-arguments';

// Type for the hook return value
export type UseConversationQueryReturn = [
  (args: GetConversationMessageQueryArguments) => void,
  {
    data: PaginationResponse<ConversationMessage[]>;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    isFetching: boolean;
    error: ApiError | undefined;
  }
];

export const useGetConversationQueryHook = (
  options?: {
    skip?: boolean;
    pollingInterval?: number;
    refetchOnFocus?: boolean;
  },
): UseConversationQueryReturn => {
  const defaultOptions = {
    skip: false,
    pollingInterval: 10000,
    refetchOnFocus: true,
    ...options,
  };

  const [
    getConversation,
    {
      data,
      isLoading,
      isError,
      isSuccess,
      isFetching,
      error,
    },
  ] = recruiterAgentQuery.useLazyGetConversationQuery({
    pollingInterval: defaultOptions.pollingInterval,
    refetchOnFocus: defaultOptions.refetchOnFocus,
  });

  const triggerQuery = (args: GetConversationMessageQueryArguments) => {
    if (!defaultOptions.skip) {
      getConversation(args);
    }
  };

  const defaultPaginationResponse: PaginationResponse<ConversationMessage[]> = {
    results: [],
    totalCount: 0,
    page: 1,
    limit: 10,
  };

  return [
    triggerQuery,
    {
      data: data || defaultPaginationResponse,
      error: error as ApiError,
      isLoading,
      isError,
      isSuccess,
      isFetching,
    },
  ];
};
