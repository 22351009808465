import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import log from 'loglevel';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthActions } from '@frontegg/react';
import STRING_KEYS from '../../../../language/keys';
import { Divider } from '../../Divider';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../../../consts/analytics';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { useDaysLeftPlan } from '../../../../hooks/days-left-plan.hook';
import { IntercomManager } from '../../../../services/intercom-manager';
import { PerfectMenuSize } from '../../perfect-base-menu/perfect-base-menu.enums';
import PerfectMenu from '../../perfect-base-menu/perfect-base-menu.component';
import StyledAvatar from '../../styled-avatar/styled-avatar.component';
import { PRIVACY_CLASS } from '../../../../consts/fullstory';
import { PerfectSpacer } from '../../perfect-spacer/perfect-spacer.component';
import { useCreateCustomerPortalSessionMutation } from '../../../../store/rtk-query/apis/payment/payment.toolkit-api';
import { ASSETS } from '../../../../assets';
import { PricingPlan } from '../../../../enums/user-plan.enum';
import {
  GenericUpgradeButton,
} from '../../../../modules/application/components/generic-upgrade-button/generic-upgrade-button.component';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import { useGetOpenPositionsQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { useGetAccountByIdQuery } from '../../../../store/rtk-query/apis/account/account.toolkit-api';
import { logout } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import {
  CurrentPlan,
  CurrentPlanDaysLeft,
  CurrentPlanLabel,
  CurrentPlanTypeLabel,
  CurrentUserEmail,
  CurrentUserFullName,
  PerfectMenuItem,
  PlanDescriptionContainer,
  StyledUserMenu,
  UserDetails,
  UserDetailsContainer,
} from './user-profile-menu.styled';

const ANALYTICS_COMPONENT_NAME = 'log_out_menu';

const logger = log.getLogger('USER_PROFILE_MENU');

export const UserProfileMenu = ({
  onPeopleItemClickHandler,
  anchorElement,
  onClose,
}: UserProfileMenuProps): JSX.Element => {
  const {
    data: {
      firstName, profileImage, lastName, email, accountId,
    } = {},
  } = useGetMyUserQuery();
  const { data: { plan, subDomainName } = {} } = useGetAccountByIdQuery(accountId);
  const { data: positionsList = [] } = useGetOpenPositionsQuery();

  const [
    createCustomerPortalSession,
    { isLoading: isCreatingCustomerPortalSession },
  ] = useCreateCustomerPortalSessionMutation();

  const { t: translate } = useTranslation();

  const { silentLogout } = useAuthActions();

  const dispatch = useDispatch();

  const location = useLocation();

  const { positionId } = useParams<MatchUrlParams>();

  const { analyticsTrackEvent } = useAnalyticsContext();

  const fullName = `${firstName || ''} ${lastName || ''}`;

  const differenceInDaysToEndPlanDate = useDaysLeftPlan();

  const currentPosition = positionsList.find(
    (position) => position.id === positionId,
  );

  const handleLogoutClick = () => {
    logger.debug('user clicked on logout button in his profile menu');

    // TODO figure out how to get talent id here and how to get match status here
    const trackEventPayload = {
      eventName: ANALYTICS_EVENTS.USER_LOGOUT_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: AppRouting.getPageName(location.pathname)?.toString(),
      pagePositionId: positionId,
      pagePositionName: currentPosition?.jobTitle,
      pagePositionStatus: currentPosition?.status,
      componentName: ANALYTICS_COMPONENT_NAME,
    };
    analyticsTrackEvent(trackEventPayload);

    dispatch(logout());
    silentLogout({
      callback: () => {
        window.location.assign(`${AppRouting.buildGlobalLoginUrl(window.location)}?userSubDomain=${subDomainName}`);
      },
    });
  };

  const getPlanName = () => {
    if (plan?.pricingPlan) {
      return translate(
        STRING_KEYS.PRICING_PLAN[plan.pricingPlan] || STRING_KEYS.UNKNOWN_PLAN,
      );
    }

    return null;
  };

  const handleBillingCenterClick = async () => {
    try {
      const callbackUrl = new URL(window.location.href).toString();
      const { url } = await createCustomerPortalSession({
        callbackUrl,
      }).unwrap();
      window.open(url, '_blank');
    } catch (e) {
      logger.error(e);
    }
  };
  
  return (
    <>
      <PerfectMenu
        isOpen={ !!anchorElement }
        onClose={ onClose }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
        size={ PerfectMenuSize.Large }
      >
        <StyledUserMenu>
          <UserDetailsContainer>
            <StyledAvatar
              className={ PRIVACY_CLASS.EXCLUDE }
              image={ profileImage }
              name={ `${firstName}-${lastName}` }
              initialsFontSizePx={ 16 }
              borderWidthPx={ 0 }
              width={ 32 }
              height={ 32 }
            />

            <UserDetails>
              {fullName && (
                <CurrentUserFullName>{fullName}</CurrentUserFullName>
              )}
              {email && <CurrentUserEmail>{email}</CurrentUserEmail>}
            </UserDetails>
          </UserDetailsContainer>

          <CurrentPlan pricingPlan={ plan?.pricingPlan }>
            <div>
              <CurrentPlanLabel>
                {translate(STRING_KEYS.CURRENT_PRODUCT_PLAN)}
              </CurrentPlanLabel>

              <PlanDescriptionContainer>
                <CurrentPlanTypeLabel>{getPlanName()}</CurrentPlanTypeLabel>

                {plan?.pricingPlan === PricingPlan.Trial && (
                  <CurrentPlanDaysLeft>
                    {translate(
                      differenceInDaysToEndPlanDate === 1 ?
                        STRING_KEYS.DAYS_LEFT_ONE :
                        STRING_KEYS.DAYS_LEFT_PLURAL,
                      { count: differenceInDaysToEndPlanDate },
                    )}
                  </CurrentPlanDaysLeft>
                )}
              </PlanDescriptionContainer>
            </div>

            <GenericUpgradeButton isLinkButton onClick={ onClose } />
          </CurrentPlan>

          <PerfectSpacer height={ 16 } />

          <Divider />

          <PerfectMenuItem
            onClick={ onPeopleItemClickHandler }
            imageUrl={ ASSETS.USER_GROUP }
          >
            {translate(STRING_KEYS.USER_PROFILE_MENU.ITEMS.PEOPLE)}
          </PerfectMenuItem>

          {plan?.pricingPlan !== PricingPlan.Trial && (
            <PerfectMenuItem
              onClick={ handleBillingCenterClick }
              imageUrl={ ASSETS.CREDIT_CARD }
              disabled={ isCreatingCustomerPortalSession }
            >
              {translate(STRING_KEYS.USER_PROFILE_MENU.ITEMS.BILLING_CENTER)}
            </PerfectMenuItem>
          )}

          <PerfectMenuItem
            onClick={ IntercomManager.showChat }
            imageUrl={ ASSETS.SUPPORT_CHAT }
          >
            {translate(STRING_KEYS.USER_PROFILE_MENU.ITEMS.SUPPORT)}
          </PerfectMenuItem>

          <PerfectMenuItem onClick={ handleLogoutClick } imageUrl={ ASSETS.LOGOUT }>
            {translate(STRING_KEYS.USER_PROFILE_MENU.ITEMS.LOGOUT)}
          </PerfectMenuItem>
        </StyledUserMenu>
      </PerfectMenu>
    </>
  );
};
