import styled from 'styled-components';
import {
  Body1Font, HeadlineFont, Title2Font, TitleBold,
} from '../../../themes/fonts';
import { THEME } from '../../../themes';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-block-start: ${THEME.SPACING.BASE};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_SMALL}px) {
    padding-inline: 16px;
  }

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    padding-inline: 24px;
    max-width: 400px;
  }
`;

export const Title = styled.div`
  ${TitleBold};
  font-weight: bold;
  margin-bottom: ${THEME.SPACING.BASE};
  text-align: center;

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    ${Title2Font};
    font-weight: bold;
  }
`;

export const SubTitle = styled.div`
  ${HeadlineFont};
  font-weight: 400;
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  text-align: center;

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.MOBILE_LARGE}px) {
    ${Body1Font};
    font-weight: 400;
  }
`;
