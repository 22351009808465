export const ANALYTICS_EVENTS = {
  MATCH_ENGAGE_BUTTON_CLICKED: 'engage_button_clicked',
  MATCH_DECLINE_BUTTON_CLICKED: 'decline_button_clicked',
  MATCH_ENGAGEMENT_UNDO_BUTTON_CLICKED: 'match_engagement_undo',
  MATCH_CONTACT_INFO_SHOWN: 'contact_info_shown',
  HOME_PAGE_VIEWED: 'home_page_viewed',
  USER_LOGOUT_CLICKED: 'clicked_on_log_out',
  LOGIN_PAGE_VIEWED: 'login_page_viewed',
  POSITION_ITEM_CLICKED: 'clicked_on_position_card',
  POSITION_CLOSE_CLICK: 'clicked_on_close_position',
  MATCHES_PAGE_VIEWED: 'matches_page_viewed',
  POSITION_INSIGHTS_WIDGET_VIEWED: 'insights_widget_viewed',
  MATCH_CLICK_ON_SOCIAL: 'clicked_on_social',
  MATCH_ELEMENT_VIEWED: 'match_element_viewed',
  MATCH_ENGAGE_PANEL_SHOWN: 'engagement_panel_shown',
  CLICKED_ON_CONTACT_INFO: 'clicked_on_contact_info',
  ATS_PROMO_MODAL_SHOWN: 'ats_promo_modal_shown',
  CLICKED_ON_ATS_PROMO: 'clicked_on_ats_promo',
  SYNC_POSITION_WITH_ATS_SHOWN: 'sync_position_with_ats_shown',
  CLICKED_MODAL_SYNC_POSITION_WITH_ATS: 'clicked_modal_sync_position_with_ats',
  CLICKED_ON_ADD_TALENT_TO_ATS: 'clicked_on_add_talent_to_ats',
  CLICKED_ON_REACH_YOUR_MATCHES: 'clicked_on_reach_your_matches',
  CLICKED_ON_PREMIUM_MODAL: 'clicked_on_premium_modal',
  PREMIUM_MODAL_SHOWN: 'premium_modal_shown',
  CLICKED_ON_GET_UNLIMITED_MATCHES: 'clicked_on_get_unlimited_matches',
  NOTIFICATION_SHOWN: 'notification_shown',
  CLICKED_ON_NOTIFICATION: 'clicked_on_notification',

  // DEDICATED INTERCOM EVENTS
  INTERCOM_TRIGGER_EMAIL_VERIFICATION_FAILED: 'intercom_trigger_email_verification_failed',
  INTERCOM_TRIGGER_ONBOARDING_COMPLETED: 'intercom_trigger_onboarding_completed',
  INTERCOM_TRIGGER_ATS_LINK_POSITION_HELP: 'intercom_trigger_ats_link_position_help',
  INTERCOM_TRIGGER_ADD_SIGNATURE_HELP: 'intercom_trigger_add_signature_help',

  OUTREACH_PAGE_VIEW: 'outreach_page_view',
  OUTREACH_EMPTY_PAGE_VIEW: 'outreach_empty_page_view',
  CLICKED_ON_CONNECT_MAIL_OUTREACH: 'clicked_on_connect_mail_outreach',
  TALENT_NETWORK_INFO_SHOWN: 'talent_network_info_shown',
  ENGAGEMENT_PANEL_TALENT_NETWORK_LOADED: 'engagement_panel_talent_network_loaded',
  ENGAGEMENT_PANEL_TALENT_NETWORK_SEE_MORE: 'engagement_panel_talent_network_see_more',
  MATCH_PAGE_WIDGET_VIEWED: 'match_page_widget_viewed',
  MATCH_PAGE_BADGE_VIEWED: 'match_page_badge_viewed',
  CLICKED_ON_INVITE_TEAMMATE: 'clicked_on_invite_teammate',
  INVITE_TEAMMATE_MODAL_SHOWN: 'invite_teammate_modal_shown',
  CLICKED_ON_INVITE_TEAMMATE_MODAL: 'clicked_on_invite_teammate_modal',
  SIGN_UP_DETAILS_PAGE_VIEWED: 'sign_up_details_page_viewed',
  POSITION_DEADEND_TRIGGERED: 'position_deadend_triggered',
  UPGRADE_BUTTON_CLICKED: 'upgrade_button_clicked',
  TRIAL_ENDED_MODAL_SHOWN: 'trial_ended_modal_shown',
  BUY_MORE_CREDITS_CLICKED: 'buy_more_credits_clicked',
  POSITION_ERROR_SHOWN: 'position_error_shown',
};

export const APP_USER_TYPE = 'APP_USER';

export const APP_USER_PERMISSION_TYPE = {
  REGULAR: 'regular',
  SUPER_USER: 'super_user',
};

export const EVENT_TYPE = {
  USER_ACTION: 'user_action',
  INTERCOM_TRIGGER: 'intercom_trigger',
};

export const PRODUCT_AREA = {
  ADD_POSITION: 'add_position',
  POSITION_PAGE: 'position_page',
  ONBOARDING_PAGE: 'onboarding',
  HOMEPAGE: 'homepage',
  MATCH_PAGE: 'match_page',
  TALENT_OUTREACH: 'talent_outreach',
  POSITION_OVERVIEW_PAGE: 'position_overview_page',
};

export const ACTION_SOURCE_LOCATION = {
  ENGAGEMENT_MODAL: 'engagement_modal',
  POSITION_CARD: 'position_card',
};

export const EVENT_GROUP = {
  CLICK: 'click',
  IMP: 'imp',
  PAGE_VIEW: 'page_view',
};

export const POSITION_CARD_COMPONENT_ELEMENT_TYPE = {
  TO_REVIEW: 'to_review',
  TO_REACHOUT: 'to_reachout',
  TO_REPLY: 'to_reply',
  TO_MEET: 'to_meet',
  REGULAR: 'regular',
};

export const COMPONENT_NAME = {
  SIGN_IN: 'sign_in',
  COMPANY: 'company',
  LOCATION: 'location',
  NAME: 'name',
  EMPLOYEE_TYPE: 'employee_type',
  JOB_TITLE: 'job_title',
  EXPERIENCE_LEVEL: 'experience_level',
  BACKGROUND: 'background',
  FIELD_OF_STUDY: 'education',
  SKILL: 'skills',
  ADD_POSITION_PRO: 'add_position_pro',
  ADD_POSITION_LITE: 'add_position_lite',
  LITE_POSITION_LIMIT: 'lite_position_limit',
  LITE_MATCHES_LIMIT: 'lite_matches_limit',
  PRO_POSITION_LIMIT: 'pro_position_limit',
  ENGAGEMENT_FAB: 'engagement_fab',
  UPGRADED_SUCCESSFULLY: 'upgraded_successfully',
  GO_TO_MY_POSITIONS: 'go_to_my_positions',
  MATCHES_ALMOST_GONE: 'matches_almost_gone',
  END_OF_PREMIUM_INDICATION: 'end_of_premium_indication',
  ENGAGEMENT_POP_UP: 'engagement_pop_up',
  LITE_TALENT_NETWORK: 'lite_talent_network',
};

export const ELEMENT_TYPE = {
  AUTOCOMPLETE: 'autocomplete',
  MANUAL: 'manual',
  SUGGESTIONS: 'suggestions',
  FILE: 'file',
  LINK: 'link',
  GO_PRO: 'go_pro',
  CLOSE: 'close',
};
