import {
  useCallback, useEffect, useRef, useState,
} from 'react';

export interface TypingEffectProps {
  text: string;
  onComplete: () => void;
  speed?: number;
}

export const useTypingEffect = ({
  text,
  onComplete,
  speed = 10,
}: TypingEffectProps): [string, () => void] => {
  const [displayText, setDisplayText] = useState('');
  const [active, setActive] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const indexRef = useRef(0);

  // Reset if the text changes.
  useEffect(() => {
    setDisplayText('');
    indexRef.current = 0;
    setActive(false);
  }, [text]);

  // Function to start the typing effect.
  const startTyping = useCallback(() => {
    setActive(true);
  }, []);

  useEffect(() => {
    if (!active) {
      // Return a no-op cleanup function for consistency.
      return () => {
        // Do nothing
      };
    }

    intervalRef.current = setInterval(() => {
      if (indexRef.current < text.length) {
        indexRef.current++;
        setDisplayText(text.slice(0, indexRef.current));
      }
      if (indexRef.current >= text.length) {
        if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        setActive(false);
        onComplete();
      }
    }, speed);

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [active, text, onComplete, speed]);

  return [displayText, startTyping];
};
