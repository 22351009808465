import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { accountQuery } from '../account/account.toolkit-api';
import { AccountPlanResponse } from '../account/dto/response/account-info.response';
import {
  buyPositionQuery,
  createCustomerPortalSessionQuery,
  createCustomerSessionQuery,
  getPositionPriceQuery,
} from './payment.toolkit-queries';
import { PositionPriceResponse } from './dto/response/position-price.response';
import { REDUCER_PATH } from './payment.consts';
import { BuyPositionQueryArguments } from './dto/query-arguments/buy-position.query-arguments';
import { CreateCustomerPortalSessionResponse } from './dto/response/create-customer-portal-session.response';
import { CreateCustomerPortalSessionRequest } from './dto/request/create-customer-portal-session.request';
import { CreateCustomerSessionResponse } from './dto/response/create-customer-session.response';

export const paymentQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPositionPrice: builder.query<PositionPriceResponse, void>({
      query: getPositionPriceQuery,
    }),
    buyPosition: builder.mutation<AccountPlanResponse, BuyPositionQueryArguments>({
      query: buyPositionQuery,
      async onQueryStarted({ accountId }, { dispatch, queryFulfilled }) {
        const { data: accountPlan } = await queryFulfilled;
        dispatch(
          accountQuery.util.updateQueryData('getAccountById', accountId, (account) => {
            if (account) {
              account.plan = accountPlan;
            }
          }),
        );
      },
    }),
    createCustomerPortalSession: builder.mutation<CreateCustomerPortalSessionResponse, CreateCustomerPortalSessionRequest>({
      query: createCustomerPortalSessionQuery,
    }),
    createCustomerSession: builder.mutation<CreateCustomerSessionResponse, void>({
      query: createCustomerSessionQuery,
    }),
  }),
});

export const {
  useCreateCustomerPortalSessionMutation, useCreateCustomerSessionMutation, useGetPositionPriceQuery, useBuyPositionMutation,
} = paymentQuery;
