/* eslint-disable */
export const CHAT_OPENING_LINES = [
  "🚀 **Let's 10X Your Recruitment!<br>**Ready to find top matches? Just tell me the **💼 job title** and **📍 location**, and I'll take it from there!<br><br> *Example: \"Software Engineer, New York\"*",
  "🔥 **Let’s kick things off!<br>** What’s the **💼 role** you’re hiring for, and where should candidates be **📍 located**? *Example: “Marketing Director, London”*",
  "🎯 **Let’s get started!<br>** What’s the **💼 job title** and **📍 location** for this role?<br><br> *Example: “Senior Product Manager, San Francisco”*",
  "👋 **Excited to get started!<br>** Tell me the **💼 role title** and where you need candidates **📍 based**, and we’ll find great matches.<br><br> *Example: “UX Designer, Remote”*",
  "🚀 **Let’s find the right fit!<br>** What’s the **💼 job title** and **📍 location** for this role?<br><br> *Example: “Data Analyst, Chicago”*",
  "✨ **Let’s make hiring effortless!<br>** What’s the **💼 position** and where should candidates be **📍 located**?<br><br> *Example: “Product Owner, Berlin”*",
  "👀 **Let’s match you with top talent!<br>** What’s the **💼 job title** and **📍 location** you’re hiring for?<br><br> *Example: “Sales Executive, Toronto”*",
  "🚀 **Tell me the essentials!<br>** What’s the **💼 role** and where should candidates be **📍 based**?<br><br> *Example: “Customer Success Manager, Sydney”*",
];
