import { useAuth } from '@frontegg/react';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRouting } from '../services/appRoutingResolver';
import { SplashScreen } from '../components/Common/Loaders/SplashScreen/SplashScreen';
import { useCurrentSubDomain } from '../hooks/use-current-sub-domain';
import { SubDomainType } from '../store/slices/app-state/app-state.enums';

/**
 *  This guard ensures that users who are not authenticated are redirected to the login page.
 */
export const AuthGuard = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();

  const { pathname } = useLocation();

  const { currentSubDomainType } = useCurrentSubDomain();

  if (
    !isAuthenticated &&
    (currentSubDomainType !== SubDomainType.Auth ||
      (currentSubDomainType === SubDomainType.Auth &&
        pathname !== AppRouting.ROUTES.GLOBAL_LOGIN))
  ) {
    window.location.replace(AppRouting.buildGlobalLoginUrl(window.location));

    return null;
  }

  if (isLoadingAuth) {
    return <SplashScreen title="Auth Guard" />;
  }

  return <>{children}</>;
};
