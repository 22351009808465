import styled from 'styled-components';
import { ASSETS } from '../../../../assets';
import { hexToRgbA } from '../../../../utils/colors';
import { THEME } from '../../../../themes';
import { Body2Font } from '../../../../themes/fonts';

export const ShapesIcon = styled(ASSETS.SHAPES)`
  width: 26px;
  height: 26px;
  fill: ${hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.DIVIDER)};
  margin-right: ${THEME.SPACING.BASE};
  margin-top: ${THEME.SPACING.TINY};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${THEME.SPACING.MICRO};
`;

export const Title = styled.div`
  ${Body2Font};
  font-weight: 500;
`;

export const Text = styled.div`
  ${Body2Font};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LearnMoreLink = styled.a`
  color: ${THEME.COLORS.ACCENT};
  text-decoration: underline;
  cursor: pointer;
`;
