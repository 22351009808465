import { Container, Content, GlowEffect } from './animated-glowing-background.styled';

export const GradientBorderCard = ({
  children,
  glowIntensity = 10,
  borderOffset = 3,
  cornerRadius = 24,
  animationSpeed = 10,
  isAnimating = true,
}: GradientBorderCardProps): JSX.Element => {
  if (!isAnimating) {
    return (
      <>
        {children}
      </>
    );
  }
  
  return (
    <Container>
      <GlowEffect
        glowIntensity={ glowIntensity }
        borderOffset={ borderOffset }
        cornerRadius={ cornerRadius }
        animationSpeed={ animationSpeed }
        isAnimating={ isAnimating }
      />
      <Content cornerRadius={ cornerRadius }>
        {children}
      </Content>
    </Container>
  );
};
