import React from 'react';
import { PageHeader } from '../../Common/headers/page-header/page-header.component';
import { WarningSnackBar } from '../../Common/CustomSnackBar/WarningSnackBar';
import LoginLogger from '../../Common/Loaders/login-loader/login-loader.component';
import TermsOfUseModal from '../../Common/terms-of-use-modal/terms-of-use-modal.component';
import { AccountLogo } from '../account-logo/account-logo.component';
import {
  Container, ContentContainer, LayoutWrapper, SubTitle, Title,
} from './login-and-registration-layout.styled';
import { LoginAndRegistrationLayoutProps } from './login-and-registration-layout';

export const LoginAndRegistrationLayout = ({
  children,
  title,
  subTitle,
  error,
  onErrorCloseHandler,
  isLoading = false,
  isTermsOfUseModalVisible = false,
  handleCloseTermsOfUseModal,
  showAccountLogo = false,
  userSubDomain,
}: LoginAndRegistrationLayoutProps): JSX.Element => (
  <LayoutWrapper>
    <PageHeader isSticky />

    {error && (
      <WarningSnackBar title={ error } onClose={ onErrorCloseHandler } isOpen />
    )}

    <LoginLogger isLoading={ !!isLoading } />

    {isTermsOfUseModalVisible && (
      <TermsOfUseModal onModalClose={ handleCloseTermsOfUseModal } />
    )}

    <Container>
      <ContentContainer>
        {showAccountLogo && <AccountLogo userSubDomain={ userSubDomain } />}

        <Title>{title}</Title>

        {subTitle && <SubTitle>{subTitle}</SubTitle>}

        {children}
      </ContentContainer>
    </Container>
  </LayoutWrapper>
);
