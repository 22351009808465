import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import log from 'loglevel';
import { InfoDialogBase } from '../../info-dialogs/info-dialog-base.component';
import { THEME } from '../../../../themes';
import { FLUENT_UI_EMOJIS } from '../../../../assets';
import STRING_KEYS from '../../../../language/keys';
import {
  useBuyPositionMutation,
  useGetPositionPriceQuery,
} from '../../../../store/rtk-query/apis/payment/payment.toolkit-api';
import { setSnackbar } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import {
  AnchorDirectionsEnum,
  AppSnackbarVariant,
} from '../../../Common/CustomSnackBar/app-snackbar/app-snackbar.enums';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/user.toolkit-api';

const logger = log.getLogger('BUY_POSITION_DIALOG');

const BuyPositionDialog = ({
  isDialogOpen,
  closeButtonHandler,
  buyPositionSuccessHandler,
}: BuyPositionDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const { data: { accountId } = {} } = useGetMyUserQuery();
  const { data: { price: positionPrice } = {} } = useGetPositionPriceQuery();

  const [
    buyPosition,
    {
      isLoading: isBuyPositionLoading,
      isSuccess: isBuyPositionSuccess,
      isError: isBuyPositionError,
    },
  ] = useBuyPositionMutation();

  useEffect(() => {
    if (positionPrice == null) {
      logger.error(
        'Buy position dialog is open and position price is not defined',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBuyPositionSuccess) {
      buyPositionSuccessHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuyPositionSuccess]);

  useEffect(() => {
    if (isBuyPositionError) {
      dispatch(
        setSnackbar({
          title: translate(STRING_KEYS.ALERT),
          description: translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE),
          variant: AppSnackbarVariant.Error,
          anchor: AnchorDirectionsEnum.BottomCenter,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuyPositionError]);

  const onBuyPositionButtonClick = () => {
    buyPosition({ accountId });
  };

  return (
    <InfoDialogBase
      title={ translate(STRING_KEYS.BUY_POSITION_DIALOG.TITLE) }
      description={ translate(STRING_KEYS.BUY_POSITION_DIALOG.DESCRIPTION, {
        positionPrice,
      }) }
      isDialogOpen={ isDialogOpen }
      onCloseDialog={ closeButtonHandler }
      primaryButton={ {
        text: translate(STRING_KEYS.BUY_POSITION_DIALOG.PRIMARY_BUTTON_TITLE, {
          positionPrice,
        }),
        handler: onBuyPositionButtonClick,
        isLoading: isBuyPositionLoading,
      } }
      secondaryButton={ {
        text: translate(STRING_KEYS.BUY_POSITION_DIALOG.SECONDARY_BUTTON_TITLE),
        handler: closeButtonHandler,
      } }
      buttonsColor={ THEME.COLORS.SECONDARY }
      Icon={ FLUENT_UI_EMOJIS.WOMAN_RAISING_HAND }
    />
  );
};

export default BuyPositionDialog;
